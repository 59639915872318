"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useResponsibility = void 0;
var react_1 = require("react");
var firestore_1 = require("firebase/firestore");
var firestore;
function ResponsibilityStateConfig(_firebaseApp) {
    firestore = (0, firestore_1.getFirestore)(_firebaseApp);
}
exports.default = ResponsibilityStateConfig;
/**
 * Firebase hook for getting members
 * This hook is for getting the user's collection member based on company
 * @param {string | null} companyId
 * @returns Firestore team
 *
 */
function useResponsibility(companyId) {
    var _a = (0, react_1.useState)(), tasks = _a[0], setTasks = _a[1];
    (0, react_1.useEffect)(function () {
        var unsubscribe = null;
        if (companyId) {
            unsubscribe = (0, firestore_1.onSnapshot)((0, firestore_1.collection)(firestore, 'companies', companyId, 'tasks'), function (snapshot) {
                var docs = [];
                snapshot.forEach(function (doc) {
                    docs.push(__assign({ taskID: doc.id }, doc.data()));
                });
                setTasks(docs);
            });
        }
        return function () {
            unsubscribe && unsubscribe();
        };
    }, [companyId]);
    return tasks;
}
exports.useResponsibility = useResponsibility;
