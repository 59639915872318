import React, { useEffect } from 'react';
import analytics from 'shared/utilities/analytics';
import ClarityFunction from 'shared/utilities/ClarityFunction';
import GAEvents from 'shared/utilities/events';

import NavigationRoute from './navigations';

export default function App() {
  useEffect(() => {
    analytics.logEvent(GAEvents.app_open);

    return () => {
      analytics.logEvent(GAEvents.app_close);
    };
  }, []);

  return (
    <>
      <ClarityFunction />
      <NavigationRoute />
    </>
  );
}
