import { Chip, useTheme } from '@material-ui/core';
import { ITeam } from '@dayone/models';
import React from 'react';

export default function SelectedBranches(props: { disabled?: boolean; branches: ITeam[] }) {
  const branches = props.branches || [];
  const theme = useTheme();

  return (
    <>
      {branches.map((branch) => (
        <Chip disabled={props.disabled} key={branch.teamId} style={{ margin: theme.spacing(1) }} label={branch.name} />
      ))}
    </>
  );
}
