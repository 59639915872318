import { FC, useState, useCallback, useEffect } from 'react';
import { Box, Paper, Grid, Button, makeStyles, createStyles, Theme } from '@material-ui/core';

import { useAppDispatch, notificationSlice, settingSlice, meSlice, useAppSelector } from '@dayone/redux';
import { useNotification } from '@dayone/hooks';
import NotificationEmpty from './components/NotificationEmpty';
import useFilterNotifications from './helpers/useFilterNotifications';
import NotificationList from './components/NotificationList';

const Notification: FC = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { isEmpty, notificationTypes } = useFilterNotifications();
  const styles = useStyles();

  const companyId = useAppSelector<any>(settingSlice.selectActiveWorkspaceID);
  const currentUserId = useAppSelector<any>(meSlice.selectCurrentUid);

  const notifications = useNotification(currentUserId, companyId);
  useEffect(() => {
    dispatch(notificationSlice.setNotifications(notifications));
  }, [notifications, dispatch]);

  const markAllAsRead = useCallback(async () => {
    setIsLoading(true);
    try {
      await dispatch(notificationSlice.markAllAsRead()).unwrap();
    } catch (err) {
      // console.error(err);
    }
    setIsLoading(false);
  }, [dispatch]);

  if (isEmpty) return <NotificationEmpty />;

  return (
    <Box mt="40px">
      <Grid container>
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          <Paper elevation={0} className={styles.container}>
            <Box mb={2} display="flex" justifyContent="flex-end">
              <Button variant="text" color="primary" onClick={markAllAsRead} disabled={isLoading}>
                Mark all as read
              </Button>
            </Box>
            {notificationTypes.map((item) => (
              <NotificationList key={item.name} name={item.name} notifications={item.notifications} />
            ))}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Notification;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
    },
  })
);
