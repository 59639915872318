import { Box, Button, Divider } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import React from 'react';
import VideoWithLightBox from './VideoWithLightBox';

/**
 * Interface for JSON for populating country code
 * @interface
 * @property {File | undefine} file
 * @property {string} url
 * @property {'ADD' | 'REMOVE' | 'UNCHANGE'} action
 */
export interface IVideo {
  file?: File;
  thumbnailUrl?: string;
  url: string;
  caption?: string;
  action: 'ADD' | 'REMOVE' | 'UNCHANGE';
}

export default function VideoCell(props: { video: IVideo; onRemove?: () => void; hasDivider?: boolean }) {
  const theme = useTheme();
  if (props.video.action === 'REMOVE') return <></>;

  return (
    <Box p={2} boxSizing="border-box" style={{ width: theme.spacing(40) }}>
      <Box mb={2} display="flex" justifyContent="space-between">
        <VideoWithLightBox url={props.video.url} thumbnailUrl={props.video.thumbnailUrl} />
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" flexGrow={1} ml={8}>
          <Button
            onClick={() => {
              if (props.onRemove && props.video) props.onRemove();
            }}
            color="secondary"
          >
            REMOVE
          </Button>
        </Box>
      </Box>
      {props.hasDivider && <Divider />}
    </Box>
  );
}
