import { Grid } from '@material-ui/core';
import { faqSlice, useAppSelector } from '@dayone/redux';
import KnowledgeList from './components/KnowledgeList';
import { useEffect } from 'react';
import GAEvents from 'shared/utilities/events';
import { useCustomDimensionsEvents } from 'shared/components/hooks';

export default function KnowledgeOverView() {
  const faqs = useAppSelector(faqSlice.selectAnsweredFaqs);
  const logEvent = useCustomDimensionsEvents();

  useEffect(() => {
    logEvent(GAEvents.knowledge_view);
  }, [logEvent]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <KnowledgeList knowledges={faqs} statusType="ANSWERED"></KnowledgeList>
      </Grid>
    </Grid>
  );
}
