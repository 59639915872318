"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecurringType = exports.DayOfWeek = exports.TaskType = exports.TaskStatus = exports.TaskFlag = void 0;
var TaskFlag;
(function (TaskFlag) {
    TaskFlag[TaskFlag["nothing"] = 0] = "nothing";
    TaskFlag[TaskFlag["urgent"] = 1] = "urgent";
    TaskFlag[TaskFlag["notDone"] = 2] = "notDone";
})(TaskFlag = exports.TaskFlag || (exports.TaskFlag = {}));
var TaskStatus;
(function (TaskStatus) {
    TaskStatus[TaskStatus["open"] = 0] = "open";
    TaskStatus[TaskStatus["pendding"] = 1] = "pendding";
    TaskStatus[TaskStatus["closed"] = 2] = "closed";
})(TaskStatus = exports.TaskStatus || (exports.TaskStatus = {}));
var TaskType;
(function (TaskType) {
    TaskType[TaskType["daily"] = 0] = "daily";
    TaskType[TaskType["adHoc"] = 1] = "adHoc";
})(TaskType = exports.TaskType || (exports.TaskType = {}));
var DayOfWeek;
(function (DayOfWeek) {
    DayOfWeek[DayOfWeek["SUNDAY"] = 0] = "SUNDAY";
    DayOfWeek[DayOfWeek["MONDAY"] = 1] = "MONDAY";
    DayOfWeek[DayOfWeek["TUESDAY"] = 2] = "TUESDAY";
    DayOfWeek[DayOfWeek["WEDNESDAY"] = 3] = "WEDNESDAY";
    DayOfWeek[DayOfWeek["THURSDAY"] = 4] = "THURSDAY";
    DayOfWeek[DayOfWeek["FRIDAY"] = 5] = "FRIDAY";
    DayOfWeek[DayOfWeek["SATURDAY"] = 6] = "SATURDAY";
})(DayOfWeek = exports.DayOfWeek || (exports.DayOfWeek = {}));
var RecurringType;
(function (RecurringType) {
    RecurringType[RecurringType["DAY"] = 0] = "DAY";
    RecurringType[RecurringType["WEEK"] = 1] = "WEEK";
    RecurringType[RecurringType["MONTH"] = 2] = "MONTH";
})(RecurringType = exports.RecurringType || (exports.RecurringType = {}));
