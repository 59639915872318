import { useAccount, useInternalAdminsIds, useInvitesOfUser, useWorkspace } from '@dayone/hooks';
import {
  internalAdminsSlice,
  meSlice,
  myInviteSlice,
  settingSlice,
  useAppDispatch,
  useAppSelector,
  workspaceSlice,
} from '@dayone/redux';
import { IWorkspaceModel } from '@dayone/models';
import React, { useEffect } from 'react';
import analytics from 'shared/utilities/analytics';
import hotjar from 'shared/utilities/hotjar';
import { ProgressIndicator } from 'shared/components/progressIndicator';

/**
 * withAccount Higher order component
 * To get firebase account collection of user
 * @param {jsx} WrappedComponent Child JSX component
 * @returns Child component or Loading screen
 *
 */

const withAccount = (WrappedComponent: any) => {
  return (props: any) => {
    const dispatch = useAppDispatch();
    const details = useAppSelector<any>(meSlice.selectDetails);
    const accountState = useAppSelector<any>(meSlice.selectAccount);
    const workspaceState = useAppSelector<IWorkspaceModel[]>(workspaceSlice.getWorkspaces);
    const activeWorkspaceID = useAppSelector<any>(settingSlice.selectActiveWorkspaceID);
    const internalAdminIds = useAppSelector<any>(internalAdminsSlice.selectInternalAdmins);
    const uid = details?.uid ?? null;
    const isInternalAdmins = !!(uid && internalAdminIds?.users.includes(uid));

    /**
     * Load account
     */
    const account = useAccount(uid);
    useEffect(() => {
      dispatch(meSlice.setAccount(account));
      // Dispatch is a function that will not change and it is not a dependancy
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account]);

    const internalAdminsData = useInternalAdminsIds();

    useEffect(() => {
      // Internal admin needed to be fetched first because it will affect to how the whole system work (hide show navbar, hideshow information part)
      if (internalAdminsData.initialised) {
        dispatch(internalAdminsSlice.setInternalAdmins(internalAdminsData.internalAdmin));
      }
      // Dispatch is a function that will not change and it is not a dependancy
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [internalAdminsData]);

    /**
     * Load workspace
     */
    const workspaces = useWorkspace(accountState?.companies, isInternalAdmins);
    useEffect(() => {
      dispatch(workspaceSlice.setWorkspaces(workspaces));
      // Dispatch is a function that will not change and it is not a dependancy
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workspaces]);

    useEffect(() => {
      if (workspaceState.length > 0) {
        const ws = workspaceState.find((ws) => ws.id === activeWorkspaceID);
        if (!ws) {
          dispatch(settingSlice.setActiveWorkspace(workspaceState[0].id));
        } else {
          const { id: companyID, name: companyName } = ws;
          analytics.setCompany({ companyID, companyName });
        }
      }
      // Dispatch is a function that will not change and it is not a dependancy
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workspaceState, activeWorkspaceID]);

    /**
     * Load invites
     */
    const invites = useInvitesOfUser(accountState?.email);
    useEffect(() => {
      dispatch(myInviteSlice.setInvites(invites));
      // Dispatch is a function that will not change and it is not a dependancy
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invites]);

    // Account is loaded
    if (accountState) {
      // Analytics for unique user
      analytics.uniqueUser(details);
      // Hotjar for unique user
      // hotjar.identify(details);

      return <WrappedComponent {...props} />;
    }

    // Waiting for firestore to load data
    return <ProgressIndicator />;
  };
};

export default withAccount;
