import React from 'react';
import { ICommentModel, IMemberModel } from '@dayone/models';
import { Avatar, Box, Tooltip, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { ImageWithLightBox } from 'shared/components/uploadImage';
import Moment from 'react-moment';
import DeleteIcon from '@material-ui/icons/Delete';
import { DeleteConfirmDialog } from 'shared/components/deleteConfirmDialog';
import calendarConfiguration from 'shared/utilities/momentCalendar';
import refData from '@dayone/refdata';
import TickIcon from '@material-ui/icons/Done';

export default function Comment(props: {
  comment: ICommentModel;
  members: IMemberModel[];
  onDelete: (commentId: string) => void;
}) {
  const theme = useTheme();
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'));

  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

  const displayName = props.members.find((m) => m.memberId === props.comment.uid)?.displayName;

  const messageHeader = (
    <Box display="flex" justifyContent="space-between" mb={2} alignItems="center">
      <Typography variant="subtitle2" component="p">
        {displayName ? displayName : <i>[user removed]</i>}
      </Typography>
      <Tooltip title={refData.screen.responsibility['btn-delete-comment']}>
        <DeleteIcon
          fontSize="small"
          onClick={() => {
            setDeleteDialogOpen(true);
          }}
          style={{ color: theme.palette.action.active }}
        />
      </Tooltip>

      <DeleteConfirmDialog
        open={deleteDialogOpen}
        title="Are you sure you want to delete comment?"
        content="The comment will be deleted immediately. You can’t undo this action."
        onConfirm={(confirm) => {
          setDeleteDialogOpen(false);
          if (confirm) {
            props.onDelete(props.comment.commentId);
          }
        }}
      />
    </Box>
  );

  const renderImage = (image: string, index: number) => (
    <Box key={index} pr={2} pb={2}>
      <ImageWithLightBox
        width={isXsDown ? theme.spacing(9) : undefined}
        height={isXsDown ? theme.spacing(6) : undefined}
        url={image}
      />
    </Box>
  );

  const messageContent = (
    <>
      <Box display="flex" flexDirection="row" alignItems="center">
        {props.comment.isCompletedTask && (
          <Box color="success.main" mr={2}>
            <TickIcon />
          </Box>
        )}
        <Typography variant="body1">{props.comment.message}</Typography>
      </Box>
      {props.comment?.attachments && props.comment.attachments.length > 0 && (
        <Box display="flex" flexWrap="wrap" pt={3}>
          {props.comment.attachments.map(renderImage)}
        </Box>
      )}
    </>
  );

  const messageFooter = (
    <Box mt={3}>
      <Typography color="textSecondary" variant="caption">
        <Moment calendar={calendarConfiguration} date={props.comment.ts} />
      </Typography>
    </Box>
  );

  const avatar = (
    <Avatar src={props.members.find((m) => m.memberId === props.comment.uid)?.photoURL}>
      {props.members.find((m) => m.memberId === props.comment.uid)?.displayName[0]}
    </Avatar>
  );

  return (
    <Box id={props.comment.commentId} key={props.comment.commentId} display="flex" px={2} py={3}>
      {avatar}
      <Box flexGrow={1} pl={2}>
        {messageHeader}
        {messageContent}
        {messageFooter}
      </Box>
    </Box>
  );
}
