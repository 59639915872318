"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useNotification = void 0;
var react_1 = require("react");
var firestore_1 = require("firebase/firestore");
var firebaseApp;
var firestore;
function NotificationStateConfig(_firebaseApp) {
    firebaseApp = _firebaseApp;
    firestore = (0, firestore_1.getFirestore)(firebaseApp);
}
exports.default = NotificationStateConfig;
var SECS_PER_DAY = 86400;
var date4WeeksAgo = Date.now() - SECS_PER_DAY * 1000 * 7 * 2;
var timestamp4WeeksAgo = firestore_1.Timestamp.fromDate(new Date(date4WeeksAgo));
function useNotification(userId, companyId) {
    var _a = (0, react_1.useState)([]), notifications = _a[0], setNotifications = _a[1];
    (0, react_1.useEffect)(function () {
        var unsubscribe = null;
        if (userId && companyId) {
            var q = (0, firestore_1.query)((0, firestore_1.collection)(firestore, 'notifications'), (0, firestore_1.where)('receiveUserId', '==', userId), (0, firestore_1.where)('companyID', '==', companyId), (0, firestore_1.where)('createdAt', '>=', timestamp4WeeksAgo));
            unsubscribe = (0, firestore_1.onSnapshot)(q, function (snapshot) {
                var docs = [];
                snapshot.docs.forEach(function (doc) {
                    docs.push(__assign(__assign({}, doc.data()), { id: doc.id }));
                });
                setNotifications(docs.sort(function (item1, item2) { return (item1.createdAt < item2.createdAt ? 1 : -1); }));
            }, function (err) { return console.error(err); });
        }
        return function () {
            unsubscribe && unsubscribe();
        };
    }, [userId, companyId]);
    return notifications;
}
exports.useNotification = useNotification;
