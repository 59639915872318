const GAEvents = {
  app_open: 'app_open',
  app_close: 'app_close',
  login: 'login',
  logout: 'logout',
  background: 'background',
  foreground: 'foreground',
  page_view: 'page_view',
  announcement_view: 'announcement_view',
  announcement_view_details: 'announcement_view_details',
  announcement_acknowledge: 'announcement_acknowledge',
  task_view: 'task_view',
  task_view_details: 'task_view_details',
  task_complete_subtask: 'task_complete_subtask',
  task_complete: 'task_complete',
  task_comment: 'task_comment',
  repository_view: 'repository_view',
  repository_view_document: 'repository_view_document',
  knowledge_view: 'knowledge_view',
  knowledge_view_details: 'knowledge_view_details',
  knowledge_search: 'knowledge_search',
  knowledge_ask_question: 'knowledge_ask_question',
  playbook_view: 'playbook_view',
  playbook_view_details: 'playbook_view_details',
  playbook_feedback: 'playbook_feedback',
  promo_action: 'promo_action',
  select_team: 'select_team',
  select_company: 'select_company',
};

export default GAEvents;
