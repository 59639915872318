import { signOut } from '@dayone/firebase';
import React from 'react';
import { persistor } from '@dayone/redux';
import { useAppDispatch } from '@dayone/redux';
import analytics from 'shared/utilities/analytics';
import GAEvents from 'shared/utilities/events';
import { useCustomDimensionsEvents } from 'shared/components/hooks';

export default function Logout() {
  const dispatch = useAppDispatch();
  const logEvent = useCustomDimensionsEvents();

  (async () => {
    await logEvent(GAEvents.logout);
    analytics.uniqueUser(undefined);
    analytics.setCompany({
      companyID: '',
      companyName: '',
    });
    await persistor.flush();
    await persistor.purge();
    dispatch({ type: 'logout/clearData', payload: {} });
    signOut();
  })();

  return <React.Fragment>Logout</React.Fragment>;
}
