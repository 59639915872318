import { Box, ButtonBase } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import React, { useState } from 'react';

import LightBox from './LightBox';

export default function ImageWithLightBox(props: {
  thumbnailUrl?: string;
  url: string;
  alt?: string;
  width?: number;
  height?: number;
}) {
  const theme = useTheme();
  const [openLightBox, setOpenLightBox] = useState(false);

  const handlePreview = () => {
    setOpenLightBox(true);
  };

  const width = props.width || theme.spacing(11.25);
  const height = props.height || theme.spacing(7.5);

  return (
    <>
      <Box width={width} height={height}>
        {(props.thumbnailUrl || props.url) && (
          <ButtonBase onClick={handlePreview}>
            <img
              style={{
                width: width,
                height: height,
                objectFit: 'cover',
              }}
              alt={props.alt}
              src={props.thumbnailUrl ?? props.url}
            />
          </ButtonBase>
        )}
      </Box>
      {openLightBox && <LightBox open={openLightBox} onClose={() => setOpenLightBox(false)} url={props.url} />}
    </>
  );
}
