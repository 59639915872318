import { Button, Grid, Typography } from '@material-ui/core';
import { sendPasswordResetEmail } from '@dayone/firebase';
import { useSnackbar } from 'shared/components/hooks';
import { useCallback } from 'react';

export default function ResetPassword(props: { email: string }) {
  const enqueueSnackbar = useSnackbar();

  const { email } = props;

  const handleResetEmail = useCallback(() => {
    sendPasswordResetEmail(email)
      .then(() => {
        enqueueSnackbar('A link to reset your password has been sent to your registered email', {
          variant: 'success',
        });
      })
      .catch((err) => {});
  }, [enqueueSnackbar, email]);
  return (
    <Grid container spacing={2} direction="column">
      <Grid item>
        <Typography variant="h6">Reset Your Password</Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography variant="subtitle1">
          To reset your password, click the button below. We’ll send you an email with instructions to follow.
        </Typography>
      </Grid>
      <Grid item>
        <Button type="submit" variant="contained" color="primary" onClick={handleResetEmail}>
          RESET PASSWORD
        </Button>
      </Grid>
    </Grid>
  );
}
