import { FC, useCallback, useState } from 'react';

import PhoneVerification from './PhoneVerification';
import ReceiveCode from './ReceiveCode';

interface Props {
  cancel: () => void;
  next: () => void;
  setIsTabHidden: (isTabHidden: boolean) => void;
  setCredential: (credential: any) => void;
}

const LoginPhone: FC<Props> = ({ cancel, next, setIsTabHidden, setCredential }) => {
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);

  const onPhoneNumberSuccess = (phoneNumber: string) => {
    setPhoneNumber(phoneNumber);
    setIsTabHidden(true);
  };
  const handlePhoneVerificationError = useCallback(() => {
    setPhoneNumber(null);
    setIsTabHidden(false);
  }, [setPhoneNumber, setIsTabHidden]);

  const handleBack = useCallback(() => {
    setPhoneNumber(null);
  }, [setPhoneNumber]);

  return phoneNumber ? (
    <PhoneVerification
      phoneNumber={phoneNumber}
      onError={handlePhoneVerificationError}
      onBack={handleBack}
      onNext={next}
      setCredential={setCredential}
    />
  ) : (
    <ReceiveCode onSuccess={onPhoneNumberSuccess} onCancel={cancel} />
  );
};

export default LoginPhone;
