import { useEffect, useState } from 'react';
import useCustomDimensionsEvents from './useCustomDimensionsEvents';

/**
 * Hook to count view duration in seconds and log events.
 */
const useLogEventWithViewDuration = (eventName: string, params?: any) => {
  const [viewDuration, setViewDuration] = useState(0);
  const [startViewTime, setStartViewTime] = useState(Math.floor(Date.now() / 1000));
  const logEvent = useCustomDimensionsEvents();

  useEffect(() => {
    window.addEventListener('focus', startCount);
    window.addEventListener('blur', calculateViewDuration);

    return () => {
      onExitScreen();
      window.removeEventListener('focus', startCount);
      window.removeEventListener('blur', calculateViewDuration);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startCount = () => {
    const secondsNow = Math.floor(Date.now() / 1000);
    setStartViewTime(secondsNow);
  };

  const calculateViewDuration = () => {
    const currentSessionViewDuration = Math.floor(Date.now() / 1000) - startViewTime;
    const totalViewDuration = viewDuration + currentSessionViewDuration;
    setViewDuration(totalViewDuration);
    return totalViewDuration;
  };

  const onExitScreen = () => {
    const totalViewDuration = calculateViewDuration();
    logEvent(eventName, { ...params, viewDuration: totalViewDuration });
  };
};

export default useLogEventWithViewDuration;
