"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useGetTestDataQuery = exports.analyticsApi = void 0;
var react_1 = require("@reduxjs/toolkit/query/react");
var baseQuery = (0, react_1.fetchBaseQuery)({
    baseUrl: 'https://api.weareday.one/',
    prepareHeaders: function (headers, _a) {
        var getState = _a.getState;
        var token = getState().me.token;
        if (token) {
            headers.set('authorization', "Bearer ".concat(token));
        }
        return headers;
    },
});
exports.analyticsApi = (0, react_1.createApi)({
    reducerPath: 'analytics',
    baseQuery: baseQuery,
    endpoints: function (builder) { return ({
        getTestData: builder.query({
            query: function () { return "data"; },
        }),
    }); },
});
exports.useGetTestDataQuery = exports.analyticsApi.useGetTestDataQuery;
exports.default = exports.analyticsApi.reducer;
