import { Box } from '@material-ui/core';
import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { SecondaryNav } from 'shared/components/navigations';
import { TeamRoutes } from 'shared/utilities/routeConfig';
import { meSlice, useAppSelector } from '@dayone/redux';
import { UpdatePageTitle } from 'shared/components/navigations';

export default function TeamRoute() {
  const navigate = useNavigate();
  const role = useAppSelector<any>(meSlice.selectRole) as 'admin' | 'user' | '*' | null;
  const routes = TeamRoutes.filter((i) => i.role.includes('*') || i.role.includes(role));

  return (
    <React.Fragment>
      {/* <Route
        exact
        path={routes.filter((item) => !item.hiddenNav).map((item) => item.url)}
        component={() => (
          <SecondaryNav
            items={routes.filter((item) => !item.hiddenInNav)}
            callback={(item) => {
              navigate(item.url);
            }}
          />
        )}
      /> */}
      <SecondaryNav
        items={routes.filter((item) => !item.hiddenInNav)}
        callback={(item) => {
          navigate(item.url);
        }}
      />
      <Box pb={5} />

      <Routes>
        {routes.map((item, index) => {
          const indexof = item.url.indexOf('/', 1);
          const pathURL = indexof > -1 ? item.url.substring(indexof) : '/';
          return (
            <Route
              path={pathURL}
              key={index}
              element={
                <>
                  <UpdatePageTitle title={item.pageTitle} />
                  <item.component />
                </>
              }
            />
          );
        })}
      </Routes>
    </React.Fragment>
  );
}
