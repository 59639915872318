"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setAccount = exports.reset = exports.selectCurrentUid = exports.selectIsFullyRegistered = exports.selectRole = exports.selectUsers = exports.selectInternalAdmins = exports.selectPhotoURL = exports.selectAccountName = exports.selectCompanies = exports.selectAccount = exports.selectDetails = exports.selectMe = exports.selectActiveWorkspace = exports.selectCurrentWorkspaceID = exports.selectWorkspaces = exports.setFullRegistration = exports.updateBusinessInfo = exports.setBusinessInfo = exports.setAccountInfo = exports.updateAccount = exports.setUserPushToken = exports.setEmail = exports.setDetails = exports.updateUserEmail = exports.updateDocById = exports.meSliceConfig = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var firestore_1 = require("firebase/firestore");
var storage_1 = require("firebase/storage");
var auth_1 = require("firebase/auth");
var firebase_1 = require("@dayone/firebase");
var auth;
var firestore;
var storage;
var firebaseApp;
var meSliceConfig = function (_firebaseApp) {
    firestore = (0, firestore_1.getFirestore)(_firebaseApp);
    auth = (0, auth_1.getAuth)(_firebaseApp);
    storage = (0, storage_1.getStorage)(_firebaseApp);
    firebaseApp = _firebaseApp;
};
exports.meSliceConfig = meSliceConfig;
var addDocThenReturnRef = function (firestore, collectionName, docData) { return __awaiter(void 0, void 0, void 0, function () {
    var companiesRef;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                companiesRef = (0, firestore_1.collection)(firestore, collectionName);
                return [4 /*yield*/, (0, firestore_1.addDoc)(companiesRef, docData)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
var updateDocById = function (firestore, collectionName, uid, dataToUpdate) { return __awaiter(void 0, void 0, void 0, function () {
    var collectionRef, docRef;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                collectionRef = (0, firestore_1.collection)(firestore, collectionName);
                docRef = (0, firestore_1.doc)(collectionRef, uid);
                return [4 /*yield*/, (0, firestore_1.updateDoc)(docRef, dataToUpdate)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}); };
exports.updateDocById = updateDocById;
exports.updateUserEmail = (0, toolkit_1.createAsyncThunk)('me/updateUserEmail', function (email) { return __awaiter(void 0, void 0, void 0, function () {
    var res, error;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, firebase_1.callable.updateEmail(firebaseApp, { email: email })];
            case 1:
                res = _a.sent();
                error = (res.data || {}).error;
                if (error)
                    throw new Error(error);
                return [2 /*return*/];
        }
    });
}); });
// eslint-disable-next-line
exports.setDetails = (0, toolkit_1.createAsyncThunk)('me/setDetails', function (authUser) { return __awaiter(void 0, void 0, void 0, function () {
    var role, token, error_1, user;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                role = 'user';
                token = null;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, (0, firebase_1.getIdToken)()];
            case 2:
                token = _a.sent();
                return [3 /*break*/, 4];
            case 3:
                error_1 = _a.sent();
                console.log(error_1);
                return [3 /*break*/, 4];
            case 4:
                user = {
                    uid: auth.currentUser ? auth.currentUser.uid : '',
                    displayName: auth.currentUser ? auth.currentUser.displayName : null,
                    email: auth.currentUser ? auth.currentUser.email : null,
                    photoURL: auth.currentUser ? auth.currentUser.photoURL : null,
                    phoneNumber: auth.currentUser ? auth.currentUser.phoneNumber : null,
                    emailVerified: auth.currentUser ? auth.currentUser.emailVerified : false,
                };
                return [2 /*return*/, { user: user, token: token !== null && token !== void 0 ? token : null, role: role }];
        }
    });
}); });
exports.setEmail = (0, toolkit_1.createAsyncThunk)('me/setEmail', function (email) { return __awaiter(void 0, void 0, void 0, function () {
    var auth;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                auth = (0, auth_1.getAuth)();
                if (!auth.currentUser) return [3 /*break*/, 2];
                if (!email) return [3 /*break*/, 2];
                return [4 /*yield*/, (0, auth_1.updateEmail)(auth.currentUser, email.toLowerCase())];
            case 1:
                _a.sent();
                _a.label = 2;
            case 2: return [2 /*return*/, (0, firebase_1.sendEmailVerification)()];
        }
    });
}); });
exports.setUserPushToken = (0, toolkit_1.createAsyncThunk)('me/setUserPushToken', function (args, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var token, state, uid, userDocumentRef, userSnapshoot, userData;
    var _a, _b, _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                token = args.token;
                state = thunkAPI.getState();
                uid = (_a = state.me.details) === null || _a === void 0 ? void 0 : _a.uid;
                if (!(token && uid)) return [3 /*break*/, 2];
                userDocumentRef = (0, firestore_1.doc)((0, firestore_1.collection)(firestore, 'accounts'), uid);
                return [4 /*yield*/, (0, firestore_1.getDoc)(userDocumentRef)];
            case 1:
                userSnapshoot = _d.sent();
                if (userSnapshoot.exists()) {
                    userData = userSnapshoot.data();
                    if (((_c = (_b = userData.expoPushTokens) === null || _b === void 0 ? void 0 : _b.length) !== null && _c !== void 0 ? _c : 0) !== 1 || userData.expoPushTokens[0] !== token) {
                        (0, firestore_1.updateDoc)(userDocumentRef, {
                            expoPushTokens: [token],
                        });
                    }
                }
                _d.label = 2;
            case 2: return [2 /*return*/];
        }
    });
}); });
exports.updateAccount = (0, toolkit_1.createAsyncThunk)('me/updateAccount', function (profile, _a) {
    var getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var auth, state, uid, authUpdateObject, profilePhoto, baseStorageRef, uploadTask, uploadedProfilePhoto;
        var _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    auth = (0, auth_1.getAuth)();
                    state = getState();
                    uid = (_d = (_c = (_b = state === null || state === void 0 ? void 0 : state.me) === null || _b === void 0 ? void 0 : _b.details) === null || _c === void 0 ? void 0 : _c.uid) !== null && _d !== void 0 ? _d : null;
                    if (uid === null)
                        return [2 /*return*/];
                    if (!auth.currentUser) return [3 /*break*/, 8];
                    if (!profile.account.email) return [3 /*break*/, 2];
                    return [4 /*yield*/, (0, auth_1.updateEmail)(auth.currentUser, profile.account.email.toLowerCase())];
                case 1:
                    _e.sent();
                    _e.label = 2;
                case 2:
                    authUpdateObject = {
                        displayName: profile.account.displayName,
                    };
                    if (!profile.photo) return [3 /*break*/, 5];
                    profilePhoto = profile.photo;
                    baseStorageRef = (0, storage_1.ref)(storage, "/accounts/".concat(uid, "/photo.jpg"));
                    return [4 /*yield*/, (0, storage_1.uploadBytes)(baseStorageRef, profilePhoto, { contentType: profilePhoto.type })];
                case 3:
                    uploadTask = _e.sent();
                    return [4 /*yield*/, (0, storage_1.getDownloadURL)(uploadTask.ref)];
                case 4:
                    uploadedProfilePhoto = _e.sent();
                    authUpdateObject.photoURL = uploadedProfilePhoto;
                    _e.label = 5;
                case 5: return [4 /*yield*/, (0, auth_1.updateProfile)(auth.currentUser, authUpdateObject)];
                case 6:
                    _e.sent();
                    if (!uid) return [3 /*break*/, 8];
                    return [4 /*yield*/, (0, exports.updateDocById)(firestore, 'accounts', uid, __assign(__assign({}, profile.account), (authUpdateObject.photoURL && { photoURL: authUpdateObject.photoURL })))];
                case 7:
                    _e.sent();
                    _e.label = 8;
                case 8: return [2 /*return*/];
            }
        });
    });
});
exports.setAccountInfo = (0, toolkit_1.createAsyncThunk)('me/setAccountInfo', function (accountInfo, _a) {
    var getState = _a.getState, rejectWithValue = _a.rejectWithValue;
    return __awaiter(void 0, void 0, void 0, function () {
        var auth_2, queryCheckExist, querySnapshot, docs_1, state, uid, error_2;
        var _b, _c, _d, _e;
        return __generator(this, function (_f) {
            switch (_f.label) {
                case 0:
                    _f.trys.push([0, 9, , 10]);
                    auth_2 = (0, auth_1.getAuth)();
                    if (!accountInfo.email) return [3 /*break*/, 6];
                    queryCheckExist = (0, firestore_1.query)((0, firestore_1.collection)(firestore, 'accounts'), (0, firestore_1.where)('email', '==', accountInfo.email));
                    return [4 /*yield*/, (0, firestore_1.getDocs)(queryCheckExist)];
                case 1:
                    querySnapshot = _f.sent();
                    docs_1 = [];
                    querySnapshot.forEach(function (doc) {
                        docs_1.push(__assign(__assign({}, doc.data()), { id: doc.id }));
                    });
                    if (docs_1.length > 0 && docs_1[0].id !== ((_b = auth_2.currentUser) === null || _b === void 0 ? void 0 : _b.uid)) {
                        rejectWithValue({ code: 'emailalready' });
                        // return result;
                    }
                    if (!auth_2.currentUser) return [3 /*break*/, 6];
                    if (!(accountInfo.email && (auth_2.currentUser.email !== accountInfo.email || auth_2.currentUser.email === ''))) return [3 /*break*/, 3];
                    return [4 /*yield*/, (0, auth_1.updateEmail)(auth_2.currentUser, accountInfo.email.toLowerCase())];
                case 2:
                    _f.sent();
                    _f.label = 3;
                case 3: return [4 /*yield*/, (0, firebase_1.sendEmailVerification)()];
                case 4:
                    _f.sent();
                    return [4 /*yield*/, (0, auth_1.updateProfile)(auth_2.currentUser, {
                            displayName: accountInfo.displayName,
                        })];
                case 5:
                    _f.sent();
                    _f.label = 6;
                case 6:
                    state = getState();
                    uid = (_d = (_c = state === null || state === void 0 ? void 0 : state.me) === null || _c === void 0 ? void 0 : _c.details) === null || _d === void 0 ? void 0 : _d.uid;
                    accountInfo.fullyRegistered = false;
                    if (!uid) return [3 /*break*/, 8];
                    return [4 /*yield*/, (0, exports.updateDocById)(firestore, 'accounts', uid, __assign(__assign({}, accountInfo), { email: (_e = accountInfo.email) === null || _e === void 0 ? void 0 : _e.toLocaleLowerCase() }))];
                case 7:
                    _f.sent();
                    _f.label = 8;
                case 8: return [3 /*break*/, 10];
                case 9:
                    error_2 = _f.sent();
                    console.log(error_2);
                    throw error_2;
                case 10: return [2 /*return*/];
            }
        });
    });
});
exports.setBusinessInfo = (0, toolkit_1.createAsyncThunk)('me/setBusinessInfo', function (businessData, _a) {
    var getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var state, uid, companyLogoFile, businessInfo, inviteCode, companiesDocRef, companyID, userDocRef, baseStorageRef, uploadTask, companyLogo;
        var _b, _c, _d, _e, _f;
        return __generator(this, function (_g) {
            switch (_g.label) {
                case 0:
                    state = getState();
                    uid = (_d = (_c = (_b = state === null || state === void 0 ? void 0 : state.me) === null || _b === void 0 ? void 0 : _b.details) === null || _c === void 0 ? void 0 : _c.uid) !== null && _d !== void 0 ? _d : null;
                    if (uid === null)
                        return [2 /*return*/];
                    companyLogoFile = businessData.companyLogoFile, businessInfo = businessData.businessInfo, inviteCode = businessData.inviteCode;
                    return [4 /*yield*/, addDocThenReturnRef(firestore, 'companies', __assign({ createdBy: uid, createdAt: firestore_1.Timestamp.now(), inviteCode: inviteCode || '', packageId: 'freePackage' }, businessInfo))];
                case 1:
                    companiesDocRef = _g.sent();
                    companyID = companiesDocRef.id;
                    userDocRef = (0, firestore_1.doc)(firestore, 'companies', companiesDocRef.id, 'users', uid);
                    return [4 /*yield*/, (0, firestore_1.setDoc)(userDocRef, {
                            email: (_f = (_e = state === null || state === void 0 ? void 0 : state.me) === null || _e === void 0 ? void 0 : _e.account) === null || _f === void 0 ? void 0 : _f.email,
                            role: 'admin',
                        })];
                case 2:
                    _g.sent();
                    if (!companyLogoFile) return [3 /*break*/, 6];
                    baseStorageRef = (0, storage_1.ref)(storage, "/companies/".concat(companyID, "/logo.jpg"));
                    return [4 /*yield*/, (0, storage_1.uploadBytes)(baseStorageRef, companyLogoFile, { contentType: companyLogoFile.type })];
                case 3:
                    uploadTask = _g.sent();
                    return [4 /*yield*/, (0, storage_1.getDownloadURL)(uploadTask.ref)];
                case 4:
                    companyLogo = _g.sent();
                    return [4 /*yield*/, (0, firestore_1.updateDoc)(companiesDocRef, {
                            companyLogo: companyLogo,
                        })];
                case 5:
                    _g.sent();
                    _g.label = 6;
                case 6: 
                // update account
                return [4 /*yield*/, (0, exports.updateDocById)(firestore, 'accounts', uid, {
                        companies: (0, firestore_1.arrayUnion)(companyID),
                    })];
                case 7:
                    // update account
                    _g.sent();
                    return [2 /*return*/];
            }
        });
    });
});
exports.updateBusinessInfo = (0, toolkit_1.createAsyncThunk)('me/updateBusinessInfo', function (businessData, _a) {
    var getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var state, uid, activeWorkspace, companyLogoFile, businessInfo, id, docRef, baseStorageRef, uploadTask, companyLogo, baseStorageRef;
        var _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    state = getState();
                    uid = (_d = (_c = (_b = state === null || state === void 0 ? void 0 : state.me) === null || _b === void 0 ? void 0 : _b.details) === null || _c === void 0 ? void 0 : _c.uid) !== null && _d !== void 0 ? _d : null;
                    activeWorkspace = (0, exports.selectActiveWorkspace)(state);
                    if (!activeWorkspace) {
                        return [2 /*return*/];
                    }
                    if (uid === null)
                        return [2 /*return*/];
                    companyLogoFile = businessData.companyLogoFile, businessInfo = businessData.businessInfo, id = businessData.id;
                    docRef = (0, firestore_1.doc)((0, firestore_1.collection)(firestore, 'companies'), id);
                    // create companies
                    return [4 /*yield*/, (0, firestore_1.updateDoc)(docRef, __assign({ createdBy: uid, updatedAt: firestore_1.Timestamp.now(), inviteCode: '' }, businessInfo))];
                case 1:
                    // create companies
                    _e.sent();
                    if (!(companyLogoFile instanceof File)) return [3 /*break*/, 5];
                    baseStorageRef = (0, storage_1.ref)(storage, "/companies/".concat(id, "/logo.jpg"));
                    return [4 /*yield*/, (0, storage_1.uploadBytes)(baseStorageRef, companyLogoFile, { contentType: companyLogoFile.type })];
                case 2:
                    uploadTask = _e.sent();
                    return [4 /*yield*/, (0, storage_1.getDownloadURL)(uploadTask.ref)];
                case 3:
                    companyLogo = _e.sent();
                    return [4 /*yield*/, (0, firestore_1.updateDoc)(docRef, {
                            companyLogo: companyLogo,
                        })];
                case 4:
                    _e.sent();
                    return [3 /*break*/, 8];
                case 5:
                    if (!!companyLogoFile) return [3 /*break*/, 8];
                    baseStorageRef = (0, storage_1.ref)(storage, "/companies/".concat(activeWorkspace.id, "/logo.jpg"));
                    return [4 /*yield*/, (0, storage_1.deleteObject)(baseStorageRef)];
                case 6:
                    _e.sent();
                    return [4 /*yield*/, (0, firestore_1.updateDoc)(docRef, {
                            companyLogo: null,
                        })];
                case 7:
                    _e.sent();
                    _e.label = 8;
                case 8: return [2 /*return*/];
            }
        });
    });
});
exports.setFullRegistration = (0, toolkit_1.createAsyncThunk)('me/setFullRegistration', function (arg, _a) {
    var getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var state, uid;
        var _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    state = getState();
                    uid = (_c = (_b = state === null || state === void 0 ? void 0 : state.me) === null || _b === void 0 ? void 0 : _b.details) === null || _c === void 0 ? void 0 : _c.uid;
                    if (!uid) return [3 /*break*/, 2];
                    return [4 /*yield*/, (0, exports.updateDocById)(firestore, 'accounts', uid, {
                            fullyRegistered: true,
                        })];
                case 1:
                    _d.sent();
                    _d.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    });
});
var initialState = {
    details: null,
    account: null,
    token: null,
    role: null,
};
var meSlice = (0, toolkit_1.createSlice)({
    name: 'me',
    initialState: initialState,
    reducers: {
        reset: function () { return initialState; },
        setAccount: {
            reducer: function (state, action) {
                state.account = action.payload;
            },
            prepare: function (payload) {
                if (payload) {
                    return {
                        payload: {
                            id: payload.id,
                            email: (payload === null || payload === void 0 ? void 0 : payload.email) || '',
                            firstName: (payload === null || payload === void 0 ? void 0 : payload.firstName) || '',
                            lastName: (payload === null || payload === void 0 ? void 0 : payload.lastName) || '',
                            displayName: (payload === null || payload === void 0 ? void 0 : payload.displayName) || '',
                            accountType: (payload === null || payload === void 0 ? void 0 : payload.accountType) || null,
                            companies: (payload === null || payload === void 0 ? void 0 : payload.companies) || [],
                            fullyRegistered: (payload === null || payload === void 0 ? void 0 : payload.fullyRegistered) !== null ? payload === null || payload === void 0 ? void 0 : payload.fullyRegistered : null,
                        },
                    };
                }
                return { payload: null };
            },
        },
    },
    extraReducers: function (builder) {
        builder
            .addCase(exports.setDetails.fulfilled, function (state, action) {
            state.details = action.payload.user;
            state.role = action.payload.role;
            state.token = action.payload.token;
        })
            // eslint-disable-next-line
            .addCase(exports.setDetails.rejected, function (state, action) {
            // eslint-disable-next-line
            state = __assign({}, initialState);
        });
        // .addCase(setAccountInfo.fulfilled, (state: IMeInitialState, action: PayloadAction<unknown>) => {});
    },
});
// Selectors
var selectWorkspaces = function (state) { return state.workspace.workspaces; };
exports.selectWorkspaces = selectWorkspaces;
var selectCurrentWorkspaceID = function (state) { return state.setting.activeWorkspaceID; };
exports.selectCurrentWorkspaceID = selectCurrentWorkspaceID;
exports.selectActiveWorkspace = (0, toolkit_1.createSelector)([exports.selectCurrentWorkspaceID, exports.selectWorkspaces], function (workspaceID, workspaces) {
    if (workspaces)
        return workspaces.find(function (ws) { return ws.id === workspaceID; });
    return undefined;
});
var selectMe = function (state) { return state.me; };
exports.selectMe = selectMe;
var selectDetails = function (state) { return state.me.details; };
exports.selectDetails = selectDetails;
var selectAccount = function (state) { return state.me.account; };
exports.selectAccount = selectAccount;
var selectCompanies = function (state) { var _a; return (_a = state.me.account) === null || _a === void 0 ? void 0 : _a.companies; };
exports.selectCompanies = selectCompanies;
var selectAccountName = function (state) { var _a; return (_a = state.me.account) === null || _a === void 0 ? void 0 : _a.displayName; };
exports.selectAccountName = selectAccountName;
var selectPhotoURL = function (state) { var _a; return (_a = state.me.details) === null || _a === void 0 ? void 0 : _a.photoURL; };
exports.selectPhotoURL = selectPhotoURL;
var selectInternalAdmins = function (state) { var _a; return (_a = state.internalAdmins) === null || _a === void 0 ? void 0 : _a.internalAdmins; };
exports.selectInternalAdmins = selectInternalAdmins;
var selectUsers = function (state) { var _a; return (_a = state.member) === null || _a === void 0 ? void 0 : _a.roles; };
exports.selectUsers = selectUsers;
exports.selectRole = (0, toolkit_1.createSelector)([exports.selectDetails, exports.selectUsers, exports.selectInternalAdmins], function (detail, users, internalAdmins) {
    var _a, _b;
    if (internalAdmins && (detail === null || detail === void 0 ? void 0 : detail.uid) && (internalAdmins === null || internalAdmins === void 0 ? void 0 : internalAdmins.users.includes(detail === null || detail === void 0 ? void 0 : detail.uid))) {
        return 'internalAdmin';
    }
    return (_b = (_a = users === null || users === void 0 ? void 0 : users.find(function (u) { return u.userId === (detail === null || detail === void 0 ? void 0 : detail.uid); })) === null || _a === void 0 ? void 0 : _a.role) !== null && _b !== void 0 ? _b : null;
});
exports.selectIsFullyRegistered = (0, toolkit_1.createSelector)([exports.selectAccount], function (account) {
    var _a;
    // console.log({ details, account })
    if (account === null)
        return false;
    // account.companies?.length > 0 &&
    if (account.fullyRegistered === false || !((_a = account.companies) === null || _a === void 0 ? void 0 : _a.length))
        return false;
    if (account.email && account.firstName && account.lastName && account.displayName)
        return true;
    return false;
});
var selectCurrentUid = function (state) { var _a, _b; return (_b = (_a = state.me.details) === null || _a === void 0 ? void 0 : _a.uid) !== null && _b !== void 0 ? _b : null; };
exports.selectCurrentUid = selectCurrentUid;
// export const selectCompanyID = ({ me: { account } }: RootState) =>
//   ((account) => {
//     if (account) {
//       const companies = account?.companies as Array<string>;
//       const companyId = companies.length > 0 ? companies[0] : 'NoCompany';
//       return companyId;
//     }
//     return 'NoCompany';
//   })(account);
// Actions
exports.reset = (_a = meSlice.actions, _a.reset), exports.setAccount = _a.setAccount;
exports.default = meSlice.reducer;
