import { settingSlice, useAppDispatch } from '@dayone/redux';
import { useEffect } from 'react';

export default function UpdatePageTitle(props: { title: string }) {
  const dispatch = useAppDispatch();
  useEffect(() => {
    document.title = props.title;
    dispatch(settingSlice.setPageTitle(props.title));
    // Dispatch is a function that will not change and it is not a dependancy
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
}
