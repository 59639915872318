import { Box, Container } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { Logo } from 'shared/components/logo';

import PhoneRegister from 'screens/authentication/components/PhoneRegister';
import PhoneVerification from 'screens/authentication/components/PhoneVerification';

export default function LoginPhone() {
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
  const [verificationErrorMsg, setVerificationErrorMsg] = useState<string>('');

  const onPhoneNumberSuccess = (phoneNumber: string) => {
    setPhoneNumber(phoneNumber);
  };
  const handlePhoneVerificationError = useCallback(
    (errorMessage) => {
      setVerificationErrorMsg(errorMessage);
      setPhoneNumber(null);
    },
    [setPhoneNumber, setVerificationErrorMsg]
  );

  const handleBack = useCallback(() => {
    setPhoneNumber(null);
  }, [setPhoneNumber]);

  return (
    <React.Fragment>
      <Box m={5} mb={8}>
        <Logo />
      </Box>
      <Container maxWidth="md">
        {phoneNumber ? (
          <PhoneVerification phoneNumber={phoneNumber} onError={handlePhoneVerificationError} onBack={handleBack} />
        ) : (
          <PhoneRegister errorMessage={verificationErrorMsg} onSuccess={onPhoneNumberSuccess} />
        )}
      </Container>
    </React.Fragment>
  );
}
