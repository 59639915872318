import { FC } from 'react';

import { NOTIFICATION_TYPES } from '@dayone/models';

import announcement from 'assets/announcement.svg';
import comment from 'assets/comment.svg';
import knowledge from 'assets/knowledge.svg';
import repo from 'assets/repo.svg';
import task from 'assets/task.svg';
import user from 'assets/user.svg';

const announcementTypes = [NOTIFICATION_TYPES.ANNOUNCEMENT];
const knowledgeTypes = [NOTIFICATION_TYPES.KNOWLEDGE];
const taskTypes = [
  NOTIFICATION_TYPES.TASK_CREATED,
  NOTIFICATION_TYPES.TASK_UPDATED,
  NOTIFICATION_TYPES.TASK_COMPLETED,
  NOTIFICATION_TYPES.TASK_DELETED,
  NOTIFICATION_TYPES.ALL_TASK_COMPLETED,
  NOTIFICATION_TYPES.TASK_REMINDER,
];
const commentTypes = [NOTIFICATION_TYPES.TASK_COMMENT];
const repositoryTypes = [NOTIFICATION_TYPES.REPOSITORY];
const userTypes = [
  NOTIFICATION_TYPES.MEMBER_INVITATION_ACCEPTED,
  NOTIFICATION_TYPES.MEMBER_INVITATION_RECEIVED,
  NOTIFICATION_TYPES.TEAM_MEMBER_ADDED,
];

interface Props {
  type: NOTIFICATION_TYPES;
}

const NotificationIcon: FC<Props> = ({ type }) => {
  const renderIconSrc = () => {
    if (announcementTypes.includes(type)) return announcement;
    if (knowledgeTypes.includes(type)) return knowledge;
    if (taskTypes.includes(type)) return task;
    if (commentTypes.includes(type)) return comment;
    if (repositoryTypes.includes(type)) return repo;
    if (userTypes.includes(type)) return user;
    return null;
  };

  const src = renderIconSrc();

  if (!src) return null;

  return <img src={src} width={24} height={24} alt="notification-icon" />;
};

export default NotificationIcon;
