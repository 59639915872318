"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMember = void 0;
var react_1 = require("react");
var firestore_1 = require("firebase/firestore");
var firestore;
function MemberStateConfig(_firebaseApp) {
    firestore = (0, firestore_1.getFirestore)(_firebaseApp);
}
exports.default = MemberStateConfig;
/**
 * Firebase hook for getting members
 * This hook is for getting the user's collection member based on company
 * @param {string | null} companyId
 * @returns Firestore team
 *
 */
function useMember(companyId) {
    var _a = (0, react_1.useState)(), members = _a[0], setMembers = _a[1];
    (0, react_1.useEffect)(function () {
        var unsubscribe = null;
        if (companyId) {
            var q = (0, firestore_1.query)((0, firestore_1.collection)(firestore, 'accounts'), (0, firestore_1.where)('companies', 'array-contains', companyId));
            unsubscribe = (0, firestore_1.onSnapshot)(q, function (snapshot) {
                var docs = [];
                snapshot.forEach(function (doc) {
                    docs.push(__assign({ memberId: doc.id }, doc.data()));
                });
                setMembers(docs);
            });
        }
        return function () {
            unsubscribe && unsubscribe();
        };
    }, [companyId]);
    return members;
}
exports.useMember = useMember;
