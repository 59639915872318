"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFeatureFlag = void 0;
var react_1 = require("react");
var firestore_1 = require("firebase/firestore");
var firestore;
function FeatureFlagStateConfig(_firebaseApp) {
    firestore = (0, firestore_1.getFirestore)(_firebaseApp);
}
exports.default = FeatureFlagStateConfig;
function useFeatureFlag(companyId) {
    var _a = (0, react_1.useState)(), isCustomized = _a[0], setIsCustomized = _a[1];
    var _b = (0, react_1.useState)(), featureFlag = _b[0], setFeatureFlag = _b[1];
    (0, react_1.useEffect)(function () {
        var unsubscribe = null;
        if (companyId && companyId !== 'undefined') {
            unsubscribe = (0, firestore_1.onSnapshot)((0, firestore_1.doc)(firestore, 'packages', 'customised'), function (doc) {
                if (doc.exists()) {
                    var data = doc.data();
                    var companyIds = data.companies;
                    setIsCustomized(companyIds.includes(companyId));
                }
            });
        }
        return function () {
            unsubscribe && unsubscribe();
        };
    }, [companyId]);
    (0, react_1.useEffect)(function () {
        var unsubscribe = null;
        if (isCustomized !== undefined && companyId && companyId !== 'undefined') {
            if (isCustomized) {
                var companyFeatureFlagRef = (0, firestore_1.collection)(firestore, 'companies', companyId, 'featureFlags');
                unsubscribe = (0, firestore_1.onSnapshot)((0, firestore_1.query)(companyFeatureFlagRef), function (snapshot) {
                    var data = {};
                    snapshot.forEach(function (doc) {
                        data[doc.id] = doc.data();
                    });
                    setFeatureFlag(data);
                });
            }
            else {
                (0, firestore_1.onSnapshot)((0, firestore_1.doc)(firestore, 'companies', companyId), function (companyDoc) {
                    if (companyDoc.exists()) {
                        var company = companyDoc.data();
                        if (company.packageId) {
                            unsubscribe = (0, firestore_1.onSnapshot)((0, firestore_1.doc)(firestore, 'packages', company.packageId), function (packageDoc) {
                                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
                                if (packageDoc.exists()) {
                                    var data = packageDoc.data();
                                    setFeatureFlag({
                                        user: { maxStaff: (_a = data.userMaxStaff) !== null && _a !== void 0 ? _a : 0, maxAdmin: (_b = data.userMaxAdmin) !== null && _b !== void 0 ? _b : 0 },
                                        announcement: {
                                            numberOfFileLimit: (_c = data.announNumberOfFileLimit) !== null && _c !== void 0 ? _c : 0,
                                            filesizeLimit: (_d = data.announFilesizeLimit) !== null && _d !== void 0 ? _d : 0,
                                        },
                                        team: { maxTeam: (_e = data.teamMaxTeam) !== null && _e !== void 0 ? _e : 0 },
                                        task: {
                                            numberOfFileLimit: (_f = data.taskNumberOfFileLimit) !== null && _f !== void 0 ? _f : 0,
                                            filesizeLimit: (_g = data.taskFilesizeLimit) !== null && _g !== void 0 ? _g : 0,
                                            numberOfFileLimitComment: (_h = data.commentNumberOfFileLimit) !== null && _h !== void 0 ? _h : 0,
                                            filesizeLimitComment: (_j = data.commentFilesizeLimit) !== null && _j !== void 0 ? _j : 0,
                                        },
                                        repository: {
                                            storageLimit: (_k = data.repoStorageLimit) !== null && _k !== void 0 ? _k : 0,
                                            filesizeLimitImage: (_l = data.repoFilesizeLimitImage) !== null && _l !== void 0 ? _l : 0,
                                            filesizeLimitVideo: (_m = data.repoFilesizeLimitVideo) !== null && _m !== void 0 ? _m : 0,
                                            filesizeLimitPdf: (_o = data.repoFilesizeLimitPdf) !== null && _o !== void 0 ? _o : 0,
                                        },
                                        knowledge: {
                                            storageLimit: (_p = data.knowledgeStorageLimit) !== null && _p !== void 0 ? _p : 0,
                                            numberOfFileLimitImage: (_q = data.knowledgeNumberOfFileLimitImage) !== null && _q !== void 0 ? _q : 0,
                                            numberOfFileLimitVideo: (_r = data.knowledgeNumberOfFileLimitVideo) !== null && _r !== void 0 ? _r : 0,
                                            filesizeLimitImage: (_s = data.knowledgeFilesizeLimitImage) !== null && _s !== void 0 ? _s : 0,
                                            filesizeLimitVideo: (_t = data.knowledgeFilesizeLimitVideo) !== null && _t !== void 0 ? _t : 0,
                                        },
                                    });
                                }
                            });
                        }
                    }
                });
            }
        }
        return function () {
            unsubscribe && unsubscribe();
        };
    }, [isCustomized, companyId]);
    return featureFlag;
}
exports.useFeatureFlag = useFeatureFlag;
