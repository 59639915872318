import React, { useState, useRef, useCallback } from 'react';
import {
  Card,
  CardContent,
  Box,
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  DialogContentText,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { IFaqCategoryModel, IFaqModel } from '@dayone/models';
import { faqCategorySlice, faqSlice, useAppDispatch, useAppSelector } from '@dayone/redux';
import { useSnackbar } from 'shared/components/hooks';
import CategoryCreateEditDialog, { IRef as ICreateEditDialogRef } from './components/CategoryCreateEditDialog';
import Category from './components/KnowledgeCategory';

const KnowledgeManageCategory = () => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const selectedCategoryRef = useRef<IFaqCategoryModel>();
  const creatingDialogRef = useRef<ICreateEditDialogRef>(null);
  const editingDialogRef = useRef<ICreateEditDialogRef>(null);

  const initialValues: ICategoryCreationForm = {
    category: null,
  };
  const dispatch = useAppDispatch();
  const enqueueSnackbar = useSnackbar();
  const categories = useAppSelector<IFaqCategoryModel[]>(faqCategorySlice.selectAllFaqCategories);
  const formik = useFormik({
    initialValues,
    validate: (values) => {
      const isEditing = values.category?.id;
      const MAXIMUM_NUMBER_OF_CATEGORY = 9;

      if (categories.length === MAXIMUM_NUMBER_OF_CATEGORY && !isEditing) {
        return {
          category: 'You have reached the maximum number of categories',
        };
      }

      if (!values.category || values.category.name.trim().length === 0) {
        return {
          category: 'Category name is required',
        };
      }

      if (
        values.category?.name === 'Others' ||
        categories.find((_category) => _category.name === values.category?.name && _category.id !== values.category.id)
      ) {
        return {
          category: 'Category name is already in use',
        };
      }
    },
    onSubmit: async (values) => {
      formik.resetForm();

      if (values.category) {
        const isEditing = values.category?.id;
        const { name, ...data } = values.category;
        const payload = {
          name: name.trim(),
          ...data,
        };

        if (!isEditing) {
          dispatch(faqCategorySlice.createKnowledgeCategory(payload))
            .unwrap()
            .then(() => {
              enqueueSnackbar('The category has been created successfully', { variant: 'success' });
            });
          creatingDialogRef.current?.toggle();
        } else {
          dispatch(faqCategorySlice.updateKnowledgeCategory(payload))
            .unwrap()
            .then(() => {
              enqueueSnackbar('The category has been renamed successfully', { variant: 'success' });
            });
          editingDialogRef.current?.toggle();
        }
      }
    },
  });
  const faqs = useAppSelector<IFaqModel[]>(faqSlice.selectAllFaqs);

  const handleEditCategory = useCallback(
    (category: IFaqCategoryModel) => {
      editingDialogRef.current?.toggle();
      formik.setFieldValue('category', category);
    },
    [formik]
  );

  const handleDeleteCategory = useCallback(
    (category: IFaqCategoryModel) => {
      selectedCategoryRef.current = category;
      setIsDeleteModalOpen(true);
    },
    [selectedCategoryRef, setIsDeleteModalOpen]
  );

  const renderCategories = useCallback(
    (_category: IFaqCategoryModel) => (
      <React.Fragment key={_category.id}>
        <Category
          category={_category}
          isEditable
          faqs={faqs}
          onEdit={handleEditCategory}
          onDelete={handleDeleteCategory}
        />
        <Box sx={{ mb: 2 }} />
      </React.Fragment>
    ),
    [faqs, handleEditCategory, handleDeleteCategory]
  );

  return (
    <>
      <Card elevation={0}>
        <CardContent>
          <Box>
            <Grid container>
              <Grid item lg={8} md={12}>
                {categories.map(renderCategories)}
                <Category
                  category={{ name: 'Others' }}
                  categoryCounter={() => faqs.filter(({ categories }) => !categories?.length)?.length || 0}
                  faqs={faqs}
                  onEdit={handleEditCategory}
                  onDelete={handleDeleteCategory}
                />
              </Grid>
            </Grid>
          </Box>
        </CardContent>
        <CategoryCreateEditDialog useDialogFor="create" formik={formik} ref={creatingDialogRef} />
        <CategoryCreateEditDialog useDialogFor="edit" formik={formik} ref={editingDialogRef} />
        <Dialog fullWidth open={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
          <DialogTitle>Are you sure you want to delete the category?</DialogTitle>
          <DialogContent>
            <DialogContentText>You can’t undo this action.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setIsDeleteModalOpen(false);
                selectedCategoryRef.current = undefined;
              }}
              color="primary"
            >
              CANCEL
            </Button>
            <Button
              onClick={() => {
                setIsDeleteModalOpen(false);
                dispatch(
                  faqCategorySlice.deleteKnowledgeCategory({ cateogryId: selectedCategoryRef.current?.id || '' })
                )
                  .unwrap()
                  .catch();
                selectedCategoryRef.current = undefined;
              }}
              color="primary"
              autoFocus
            >
              DELETE
            </Button>
          </DialogActions>
        </Dialog>
      </Card>
      <Box sx={{ mt: 6 }}>
        <Button onClick={() => creatingDialogRef.current?.toggle()} color="primary" variant="contained">
          CREATE CATEGORY
        </Button>
      </Box>
    </>
  );
};

export default KnowledgeManageCategory;

interface ICategoryCreationForm {
  category: IFaqCategoryModel | null;
}
