"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setIsOptionSheetOpen = exports.setEditingList = exports.setEditMode = exports.setCameraPicture = exports.setComments = exports.selectIsOptionSheetOpen = exports.selectEditingList = exports.selectEditMode = exports.selectCameraPicture = exports.selectComments = exports.userDeleteComment = exports.deleteComment = exports.createComment = exports.createCompleteTaskComment = exports.commentSliceConfig = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var firestore_1 = require("firebase/firestore");
var storage_1 = require("firebase/storage");
var uuid_1 = require("uuid");
var firestore;
var storage;
var commentSliceConfig = function (_firebaseApp) {
    firestore = (0, firestore_1.getFirestore)(_firebaseApp);
    storage = (0, storage_1.getStorage)(_firebaseApp);
};
exports.commentSliceConfig = commentSliceConfig;
var initialState = {
    comments: [],
    cameraPicture: null,
    isEditMode: false,
    editingList: [],
    isOptionSheetOpen: false,
};
exports.createCompleteTaskComment = (0, toolkit_1.createAsyncThunk)('comment/completeTask', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var state, companyID, _a, email, uid, taskID, topicDocRef, topicSnapshoot, commentCollectionRef, message, data, commentDocRef, blobs, imageURI, err_1;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                state = thunkAPI.getState();
                companyID = state.setting.activeWorkspaceID;
                _a = (_b = state.me.details) !== null && _b !== void 0 ? _b : {}, email = _a.email, uid = _a.uid;
                taskID = state.task.selectedTaskID;
                if (!(companyID && taskID && uid)) return [3 /*break*/, 8];
                topicDocRef = (0, firestore_1.doc)(firestore, 'companies', companyID, 'tasks', taskID, 'topic', 'taskCompleted');
                return [4 /*yield*/, (0, firestore_1.getDoc)(topicDocRef)];
            case 1:
                topicSnapshoot = _c.sent();
                if (!topicSnapshoot.exists())
                    createCompleteTopic(companyID, taskID, uid);
                commentCollectionRef = (0, firestore_1.collection)(firestore, 'companies', companyID, 'tasks', taskID, 'comments');
                message = payload.message;
                if (!message || message === '')
                    message = 'Task completed';
                data = {
                    message: message,
                    uid: uid,
                    email: email !== null && email !== void 0 ? email : '',
                    type: '',
                    attachments: [],
                    ts: firestore_1.Timestamp.now(),
                    topicID: 'taskCompleted',
                    isCompletedTask: true,
                    isDeleted: false,
                };
                return [4 /*yield*/, (0, firestore_1.addDoc)(commentCollectionRef, data)];
            case 2:
                commentDocRef = _c.sent();
                _c.label = 3;
            case 3:
                _c.trys.push([3, 7, , 8]);
                return [4 /*yield*/, Promise.all(payload.attachments.map(function (image) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, getBlob(image)];
                            case 1: return [2 /*return*/, _a.sent()];
                        }
                    }); }); }))];
            case 4:
                blobs = _c.sent();
                return [4 /*yield*/, uploadImages(blobs, companyID, taskID, commentDocRef.id)];
            case 5:
                imageURI = _c.sent();
                return [4 /*yield*/, (0, firestore_1.updateDoc)(commentDocRef, { attachments: imageURI })];
            case 6:
                _c.sent();
                return [3 /*break*/, 8];
            case 7:
                err_1 = _c.sent();
                console.log({ err: err_1 });
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}); });
var createCompleteTopic = function (companyID, taskID, uid) {
    if (companyID && taskID) {
        (0, firestore_1.setDoc)((0, firestore_1.doc)(firestore, 'companies', companyID, 'tasks', taskID, 'topics', 'taskCompleted'), {
            topicName: 'Task completed',
            createdAt: firestore_1.Timestamp.now(),
            createdBy: uid,
        });
    }
};
// CRUD - Create, Read, Update, Delete
exports.createComment = (0, toolkit_1.createAsyncThunk)('comment/create', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var state, companyID, _a, email, uid, taskID, commentCollectionRef, data, commentDocRef, files, imageURI;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                state = thunkAPI.getState();
                companyID = state.setting.activeWorkspaceID;
                _a = (_b = state.me.details) !== null && _b !== void 0 ? _b : {}, email = _a.email, uid = _a.uid;
                taskID = state.task.selectedTaskID;
                if (!(companyID && taskID && uid)) return [3 /*break*/, 4];
                commentCollectionRef = (0, firestore_1.collection)(firestore, 'companies', companyID, 'tasks', taskID, 'comments');
                data = {
                    message: payload.message,
                    uid: uid,
                    email: email !== null && email !== void 0 ? email : '',
                    type: '',
                    attachments: [],
                    ts: firestore_1.Timestamp.now(),
                    topicID: payload.topicID || '',
                    isCompletedTask: payload.isCompletedTask || false,
                    isDeleted: false,
                };
                return [4 /*yield*/, (0, firestore_1.addDoc)(commentCollectionRef, data)];
            case 1:
                commentDocRef = _c.sent();
                files = payload.attachments.map(function (x) { return x.file; }).filter(function (x) { return !!x; });
                return [4 /*yield*/, uploadImages(files, companyID, taskID, commentDocRef.id)];
            case 2:
                imageURI = _c.sent();
                return [4 /*yield*/, (0, firestore_1.updateDoc)(commentDocRef, { attachments: imageURI })];
            case 3:
                _c.sent();
                _c.label = 4;
            case 4: return [2 /*return*/];
        }
    });
}); });
var getBlob = function (uri) { return __awaiter(void 0, void 0, void 0, function () {
    var blob;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, new Promise(function (resolve, reject) {
                    var xhr = new XMLHttpRequest();
                    xhr.onload = function () {
                        setTimeout(function () {
                            resolve(xhr.response);
                        }, 1000);
                    };
                    xhr.onerror = function (e) {
                        console.log(e);
                        reject(new TypeError('Network request failed'));
                    };
                    xhr.responseType = 'blob';
                    xhr.open('GET', uri, true);
                    xhr.send(null);
                })];
            case 1:
                blob = _a.sent();
                return [2 /*return*/, blob];
        }
    });
}); };
var uploadImages = function (images, companyId, taskId, commentId) { return __awaiter(void 0, void 0, void 0, function () {
    var commentStorageRef, imageURI;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                commentStorageRef = (0, storage_1.ref)(storage, "companies/".concat(companyId, "/tasks/").concat(taskId, "/comments"));
                return [4 /*yield*/, Promise.all(images.map(function (image) { return __awaiter(void 0, void 0, void 0, function () {
                        var fileRef, metadata;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!image) return [3 /*break*/, 3];
                                    fileRef = (0, storage_1.ref)(commentStorageRef, "/".concat(commentId, "/").concat((0, uuid_1.v4)(), ".jpg"));
                                    metadata = {
                                        contentType: 'image/jpeg',
                                    };
                                    return [4 /*yield*/, (0, storage_1.uploadBytes)(fileRef, image, metadata)];
                                case 1:
                                    _a.sent();
                                    return [4 /*yield*/, (0, storage_1.getDownloadURL)(fileRef)];
                                case 2: return [2 /*return*/, _a.sent()];
                                case 3: return [2 /*return*/, ''];
                            }
                        });
                    }); }))];
            case 1:
                imageURI = _a.sent();
                return [2 /*return*/, imageURI];
        }
    });
}); };
exports.deleteComment = (0, toolkit_1.createAsyncThunk)('comment/delete', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var state, companyID, taskID, commentDocRef;
    return __generator(this, function (_a) {
        state = thunkAPI.getState();
        companyID = state.setting.activeWorkspaceID;
        taskID = state.task.selectedTaskID;
        if (companyID && taskID && payload.commentId) {
            commentDocRef = (0, firestore_1.doc)(firestore, 'companies', companyID, 'tasks', taskID, 'comments', payload.commentId);
            (0, firestore_1.updateDoc)(commentDocRef, { isDeleted: true });
        }
        return [2 /*return*/];
    });
}); });
exports.userDeleteComment = (0, toolkit_1.createAsyncThunk)('comment/userDelete', function (_a, thunkAPI) {
    var commentId = _a.commentId;
    return __awaiter(void 0, void 0, void 0, function () {
        var state, companyID, taskID, commentDocRef, comment, _b, uid, attachments, message, isCompletedTask;
        var _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    state = thunkAPI.getState();
                    companyID = state.setting.activeWorkspaceID;
                    taskID = state.task.selectedTaskID;
                    if (!(companyID && taskID && commentId)) return [3 /*break*/, 2];
                    commentDocRef = (0, firestore_1.doc)(firestore, 'companies', companyID, 'tasks', taskID, 'comments', commentId);
                    return [4 /*yield*/, (0, firestore_1.getDoc)(commentDocRef)];
                case 1:
                    comment = _e.sent();
                    if (comment.exists()) {
                        _b = comment.data(), uid = _b.uid, attachments = _b.attachments, message = _b.message, isCompletedTask = _b.isCompletedTask;
                        if (uid === ((_d = (_c = state.me) === null || _c === void 0 ? void 0 : _c.details) === null || _d === void 0 ? void 0 : _d.uid)) {
                            (0, firestore_1.updateDoc)(commentDocRef, {
                                isDeleted: !isCompletedTask,
                                attachments: isCompletedTask ? [] : attachments,
                                message: isCompletedTask ? 'Task completed' : message,
                            });
                        }
                    }
                    _e.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    });
});
var commentSlice = (0, toolkit_1.createSlice)({
    name: 'comment',
    initialState: initialState,
    reducers: {
        setCameraPicture: function (state, action) {
            state.cameraPicture = action.payload;
        },
        setComments: {
            reducer: function (state, action) {
                if (!action.payload)
                    state.comments = [];
                else
                    state.comments = action.payload;
            },
            prepare: function (payload) {
                if (payload) {
                    var commentModels = [];
                    commentModels = payload.map(function (comment) {
                        var _a, _b;
                        return {
                            commentId: comment.commentId || '',
                            uid: comment.uid || '',
                            email: comment.email || '',
                            type: comment.type || '',
                            message: comment.message || '',
                            ts: comment.ts instanceof firestore_1.Timestamp ? (_b = (_a = comment.ts) === null || _a === void 0 ? void 0 : _a.toDate().toISOString()) !== null && _b !== void 0 ? _b : '' : '',
                            attachments: comment.attachments || [],
                            topicID: comment.topicID || '',
                            isCompletedTask: comment.isCompletedTask || false,
                            isDeleted: comment.isDeleted || false,
                        };
                    });
                    return { payload: commentModels };
                }
                return { payload: null };
            },
        },
        setEditMode: function (state, action) {
            state.isEditMode = action.payload;
        },
        setEditingList: function (state, action) {
            state.editingList = action.payload;
        },
        setIsOptionSheetOpen: function (state, action) {
            state.isOptionSheetOpen = action.payload;
        },
    },
});
// Selectors
var selectComments = function (state) { return state.comment.comments; };
exports.selectComments = selectComments;
var selectCameraPicture = function (state) { return state.comment.cameraPicture; };
exports.selectCameraPicture = selectCameraPicture;
var selectEditMode = function (state) { return state.comment.isEditMode; };
exports.selectEditMode = selectEditMode;
var selectEditingList = function (state) { return state.comment.editingList; };
exports.selectEditingList = selectEditingList;
var selectIsOptionSheetOpen = function (state) { return state.comment.isOptionSheetOpen; };
exports.selectIsOptionSheetOpen = selectIsOptionSheetOpen;
// Actions
exports.setComments = (_a = commentSlice.actions, _a.setComments), exports.setCameraPicture = _a.setCameraPicture, exports.setEditMode = _a.setEditMode, exports.setEditingList = _a.setEditingList, exports.setIsOptionSheetOpen = _a.setIsOptionSheetOpen;
exports.default = commentSlice.reducer;
