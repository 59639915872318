import { Grid } from '@material-ui/core';
import React from 'react';

import PenddingInvitesSection from './components/PenddingInvitesSection';
import StaffOverviewTable from './components/StaffOverviewTable';

export default function MemberOverview() {
  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <StaffOverviewTable />
      </Grid>
      <Grid item xs={12}>
        <PenddingInvitesSection />
      </Grid>
    </Grid>
  );
}
