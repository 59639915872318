/**
 * Interface for JSON for populating country code
 * @interface
 * @property {string} iso
 * @property {string} label
 * @property {string} code
 */
export interface ICountryCode {
  iso: string;
  label: string;
  code: string;
}

/**
 * Helper JSON for populating country code
 * @property {string} iso
 * @property {string} label
 * @property {string} code
 */
const countryCode: ICountryCode[] = [
  { iso: 'AD', label: 'Andorra', code: '376' },
  { iso: 'AE', label: 'United Arab Emirates', code: '971' },
  { iso: 'AF', label: 'Afghanistan', code: '93' },
  { iso: 'AG', label: 'Antigua and Barbuda', code: '1-268' },
  { iso: 'AI', label: 'Anguilla', code: '1-264' },
  { iso: 'AL', label: 'Albania', code: '355' },
  { iso: 'AM', label: 'Armenia', code: '374' },
  { iso: 'AO', label: 'Angola', code: '244' },
  { iso: 'AQ', label: 'Antarctica', code: '672' },
  { iso: 'AR', label: 'Argentina', code: '54' },
  { iso: 'AS', label: 'American Samoa', code: '1-684' },
  { iso: 'AT', label: 'Austria', code: '43' },
  { iso: 'AU', label: 'Australia', code: '61' },
  { iso: 'AW', label: 'Aruba', code: '297' },
  { iso: 'AX', label: 'Alland Islands', code: '358' },
  { iso: 'AZ', label: 'Azerbaijan', code: '994' },
  { iso: 'BA', label: 'Bosnia and Herzegovina', code: '387' },
  { iso: 'BB', label: 'Barbados', code: '1-246' },
  { iso: 'BD', label: 'Bangladesh', code: '880' },
  { iso: 'BE', label: 'Belgium', code: '32' },
  { iso: 'BF', label: 'Burkina Faso', code: '226' },
  { iso: 'BG', label: 'Bulgaria', code: '359' },
  { iso: 'BH', label: 'Bahrain', code: '973' },
  { iso: 'BI', label: 'Burundi', code: '257' },
  { iso: 'BJ', label: 'Benin', code: '229' },
  { iso: 'BL', label: 'Saint Barthelemy', code: '590' },
  { iso: 'BM', label: 'Bermuda', code: '1-441' },
  { iso: 'BN', label: 'Brunei Darussalam', code: '673' },
  { iso: 'BO', label: 'Bolivia', code: '591' },
  { iso: 'BR', label: 'Brazil', code: '55' },
  { iso: 'BS', label: 'Bahamas', code: '1-242' },
  { iso: 'BT', label: 'Bhutan', code: '975' },
  { iso: 'BV', label: 'Bouvet Island', code: '47' },
  { iso: 'BW', label: 'Botswana', code: '267' },
  { iso: 'BY', label: 'Belarus', code: '375' },
  { iso: 'BZ', label: 'Belize', code: '501' },
  { iso: 'CA', label: 'Canada', code: '1' },
  { iso: 'CC', label: 'Cocos (Keeling) Islands', code: '61' },
  { iso: 'CD', label: 'Congo, Democratic Republic of the', code: '243' },
  { iso: 'CF', label: 'Central African Republic', code: '236' },
  { iso: 'CG', label: 'Congo, Republic of the', code: '242' },
  { iso: 'CH', label: 'Switzerland', code: '41' },
  { iso: 'CI', label: "Cote d'Ivoire", code: '225' },
  { iso: 'CK', label: 'Cook Islands', code: '682' },
  { iso: 'CL', label: 'Chile', code: '56' },
  { iso: 'CM', label: 'Cameroon', code: '237' },
  { iso: 'CN', label: 'China', code: '86' },
  { iso: 'CO', label: 'Colombia', code: '57' },
  { iso: 'CR', label: 'Costa Rica', code: '506' },
  { iso: 'CU', label: 'Cuba', code: '53' },
  { iso: 'CV', label: 'Cape Verde', code: '238' },
  { iso: 'CW', label: 'Curacao', code: '599' },
  { iso: 'CX', label: 'Christmas Island', code: '61' },
  { iso: 'CY', label: 'Cyprus', code: '357' },
  { iso: 'CZ', label: 'Czech Republic', code: '420' },
  { iso: 'DE', label: 'Germany', code: '49' },
  { iso: 'DJ', label: 'Djibouti', code: '253' },
  { iso: 'DK', label: 'Denmark', code: '45' },
  { iso: 'DM', label: 'Dominica', code: '1-767' },
  { iso: 'DO', label: 'Dominican Republic', code: '1-809' },
  { iso: 'DZ', label: 'Algeria', code: '213' },
  { iso: 'EC', label: 'Ecuador', code: '593' },
  { iso: 'EE', label: 'Estonia', code: '372' },
  { iso: 'EG', label: 'Egypt', code: '20' },
  { iso: 'EH', label: 'Western Sahara', code: '212' },
  { iso: 'ER', label: 'Eritrea', code: '291' },
  { iso: 'ES', label: 'Spain', code: '34' },
  { iso: 'ET', label: 'Ethiopia', code: '251' },
  { iso: 'FI', label: 'Finland', code: '358' },
  { iso: 'FJ', label: 'Fiji', code: '679' },
  { iso: 'FK', label: 'Falkland Islands (Malvinas)', code: '500' },
  { iso: 'FM', label: 'Micronesia, Federated States of', code: '691' },
  { iso: 'FO', label: 'Faroe Islands', code: '298' },
  { iso: 'FR', label: 'France', code: '33' },
  { iso: 'GA', label: 'Gabon', code: '241' },
  { iso: 'GB', label: 'United Kingdom', code: '44' },
  { iso: 'GD', label: 'Grenada', code: '1-473' },
  { iso: 'GE', label: 'Georgia', code: '995' },
  { iso: 'GF', label: 'French Guiana', code: '594' },
  { iso: 'GG', label: 'Guernsey', code: '44' },
  { iso: 'GH', label: 'Ghana', code: '233' },
  { iso: 'GI', label: 'Gibraltar', code: '350' },
  { iso: 'GL', label: 'Greenland', code: '299' },
  { iso: 'GM', label: 'Gambia', code: '220' },
  { iso: 'GN', label: 'Guinea', code: '224' },
  { iso: 'GP', label: 'Guadeloupe', code: '590' },
  { iso: 'GQ', label: 'Equatorial Guinea', code: '240' },
  { iso: 'GR', label: 'Greece', code: '30' },
  { iso: 'GS', label: 'South Georgia and the South Sandwich Islands', code: '500' },
  { iso: 'GT', label: 'Guatemala', code: '502' },
  { iso: 'GU', label: 'Guam', code: '1-671' },
  { iso: 'GW', label: 'Guinea-Bissau', code: '245' },
  { iso: 'GY', label: 'Guyana', code: '592' },
  { iso: 'HK', label: 'Hong Kong', code: '852' },
  { iso: 'HM', label: 'Heard Island and McDonald Islands', code: '672' },
  { iso: 'HN', label: 'Honduras', code: '504' },
  { iso: 'HR', label: 'Croatia', code: '385' },
  { iso: 'HT', label: 'Haiti', code: '509' },
  { iso: 'HU', label: 'Hungary', code: '36' },
  { iso: 'ID', label: 'Indonesia', code: '62' },
  { iso: 'IE', label: 'Ireland', code: '353' },
  { iso: 'IL', label: 'Israel', code: '972' },
  { iso: 'IM', label: 'Isle of Man', code: '44' },
  { iso: 'IN', label: 'India', code: '91' },
  { iso: 'IO', label: 'British Indian Ocean Territory', code: '246' },
  { iso: 'IQ', label: 'Iraq', code: '964' },
  { iso: 'IR', label: 'Iran, Islamic Republic of', code: '98' },
  { iso: 'IS', label: 'Iceland', code: '354' },
  { iso: 'IT', label: 'Italy', code: '39' },
  { iso: 'JE', label: 'Jersey', code: '44' },
  { iso: 'JM', label: 'Jamaica', code: '1-876' },
  { iso: 'JO', label: 'Jordan', code: '962' },
  { iso: 'JP', label: 'Japan', code: '81' },
  { iso: 'KE', label: 'Kenya', code: '254' },
  { iso: 'KG', label: 'Kyrgyzstan', code: '996' },
  { iso: 'KH', label: 'Cambodia', code: '855' },
  { iso: 'KI', label: 'Kiribati', code: '686' },
  { iso: 'KM', label: 'Comoros', code: '269' },
  { iso: 'KN', label: 'Saint Kitts and Nevis', code: '1-869' },
  { iso: 'KP', label: "Korea, Democratic People's Republic of", code: '850' },
  { iso: 'KR', label: 'Korea, Republic of', code: '82' },
  { iso: 'KW', label: 'Kuwait', code: '965' },
  { iso: 'KY', label: 'Cayman Islands', code: '1-345' },
  { iso: 'KZ', label: 'Kazakhstan', code: '7' },
  { iso: 'LA', label: "Lao People's Democratic Republic", code: '856' },
  { iso: 'LB', label: 'Lebanon', code: '961' },
  { iso: 'LC', label: 'Saint Lucia', code: '1-758' },
  { iso: 'LI', label: 'Liechtenstein', code: '423' },
  { iso: 'LK', label: 'Sri Lanka', code: '94' },
  { iso: 'LR', label: 'Liberia', code: '231' },
  { iso: 'LS', label: 'Lesotho', code: '266' },
  { iso: 'LT', label: 'Lithuania', code: '370' },
  { iso: 'LU', label: 'Luxembourg', code: '352' },
  { iso: 'LV', label: 'Latvia', code: '371' },
  { iso: 'LY', label: 'Libya', code: '218' },
  { iso: 'MA', label: 'Morocco', code: '212' },
  { iso: 'MC', label: 'Monaco', code: '377' },
  { iso: 'MD', label: 'Moldova, Republic of', code: '373' },
  { iso: 'ME', label: 'Montenegro', code: '382' },
  { iso: 'MF', label: 'Saint Martin (French part)', code: '590' },
  { iso: 'MG', label: 'Madagascar', code: '261' },
  { iso: 'MH', label: 'Marshall Islands', code: '692' },
  { iso: 'MK', label: 'Macedonia, the Former Yugoslav Republic of', code: '389' },
  { iso: 'ML', label: 'Mali', code: '223' },
  { iso: 'MM', label: 'Myanmar', code: '95' },
  { iso: 'MN', label: 'Mongolia', code: '976' },
  { iso: 'MO', label: 'Macao', code: '853' },
  { iso: 'MP', label: 'Northern Mariana Islands', code: '1-670' },
  { iso: 'MQ', label: 'Martinique', code: '596' },
  { iso: 'MR', label: 'Mauritania', code: '222' },
  { iso: 'MS', label: 'Montserrat', code: '1-664' },
  { iso: 'MT', label: 'Malta', code: '356' },
  { iso: 'MU', label: 'Mauritius', code: '230' },
  { iso: 'MV', label: 'Maldives', code: '960' },
  { iso: 'MW', label: 'Malawi', code: '265' },
  { iso: 'MX', label: 'Mexico', code: '52' },
  { iso: 'MY', label: 'Malaysia', code: '60' },
  { iso: 'MZ', label: 'Mozambique', code: '258' },
  { iso: 'NA', label: 'Namibia', code: '264' },
  { iso: 'NC', label: 'New Caledonia', code: '687' },
  { iso: 'NE', label: 'Niger', code: '227' },
  { iso: 'NF', label: 'Norfolk Island', code: '672' },
  { iso: 'NG', label: 'Nigeria', code: '234' },
  { iso: 'NI', label: 'Nicaragua', code: '505' },
  { iso: 'NL', label: 'Netherlands', code: '31' },
  { iso: 'NO', label: 'Norway', code: '47' },
  { iso: 'NP', label: 'Nepal', code: '977' },
  { iso: 'NR', label: 'Nauru', code: '674' },
  { iso: 'NU', label: 'Niue', code: '683' },
  { iso: 'NZ', label: 'New Zealand', code: '64' },
  { iso: 'OM', label: 'Oman', code: '968' },
  { iso: 'PA', label: 'Panama', code: '507' },
  { iso: 'PE', label: 'Peru', code: '51' },
  { iso: 'PF', label: 'French Polynesia', code: '689' },
  { iso: 'PG', label: 'Papua New Guinea', code: '675' },
  { iso: 'PH', label: 'Philippines', code: '63' },
  { iso: 'PK', label: 'Pakistan', code: '92' },
  { iso: 'PL', label: 'Poland', code: '48' },
  { iso: 'PM', label: 'Saint Pierre and Miquelon', code: '508' },
  { iso: 'PN', label: 'Pitcairn', code: '870' },
  { iso: 'PR', label: 'Puerto Rico', code: '1' },
  { iso: 'PS', label: 'Palestine, State of', code: '970' },
  { iso: 'PT', label: 'Portugal', code: '351' },
  { iso: 'PW', label: 'Palau', code: '680' },
  { iso: 'PY', label: 'Paraguay', code: '595' },
  { iso: 'QA', label: 'Qatar', code: '974' },
  { iso: 'RE', label: 'Reunion', code: '262' },
  { iso: 'RO', label: 'Romania', code: '40' },
  { iso: 'RS', label: 'Serbia', code: '381' },
  { iso: 'RU', label: 'Russian Federation', code: '7' },
  { iso: 'RW', label: 'Rwanda', code: '250' },
  { iso: 'SA', label: 'Saudi Arabia', code: '966' },
  { iso: 'SB', label: 'Solomon Islands', code: '677' },
  { iso: 'SC', label: 'Seychelles', code: '248' },
  { iso: 'SD', label: 'Sudan', code: '249' },
  { iso: 'SE', label: 'Sweden', code: '46' },
  { iso: 'SG', label: 'Singapore', code: '65' },
  { iso: 'SH', label: 'Saint Helena', code: '290' },
  { iso: 'SI', label: 'Slovenia', code: '386' },
  { iso: 'SJ', label: 'Svalbard and Jan Mayen', code: '47' },
  { iso: 'SK', label: 'Slovakia', code: '421' },
  { iso: 'SL', label: 'Sierra Leone', code: '232' },
  { iso: 'SM', label: 'San Marino', code: '378' },
  { iso: 'SN', label: 'Senegal', code: '221' },
  { iso: 'SO', label: 'Somalia', code: '252' },
  { iso: 'SR', label: 'Suriname', code: '597' },
  { iso: 'SS', label: 'South Sudan', code: '211' },
  { iso: 'ST', label: 'Sao Tome and Principe', code: '239' },
  { iso: 'SV', label: 'El Salvador', code: '503' },
  { iso: 'SX', label: 'Sint Maarten (Dutch part)', code: '1-721' },
  { iso: 'SY', label: 'Syrian Arab Republic', code: '963' },
  { iso: 'SZ', label: 'Swaziland', code: '268' },
  { iso: 'TC', label: 'Turks and Caicos Islands', code: '1-649' },
  { iso: 'TD', label: 'Chad', code: '235' },
  { iso: 'TF', label: 'French Southern Territories', code: '262' },
  { iso: 'TG', label: 'Togo', code: '228' },
  { iso: 'TH', label: 'Thailand', code: '66' },
  { iso: 'TJ', label: 'Tajikistan', code: '992' },
  { iso: 'TK', label: 'Tokelau', code: '690' },
  { iso: 'TL', label: 'Timor-Leste', code: '670' },
  { iso: 'TM', label: 'Turkmenistan', code: '993' },
  { iso: 'TN', label: 'Tunisia', code: '216' },
  { iso: 'TO', label: 'Tonga', code: '676' },
  { iso: 'TR', label: 'Turkey', code: '90' },
  { iso: 'TT', label: 'Trinidad and Tobago', code: '1-868' },
  { iso: 'TV', label: 'Tuvalu', code: '688' },
  { iso: 'TW', label: 'Taiwan, Province of China', code: '886' },
  { iso: 'TZ', label: 'United Republic of Tanzania', code: '255' },
  { iso: 'UA', label: 'Ukraine', code: '380' },
  { iso: 'UG', label: 'Uganda', code: '256' },
  { iso: 'US', label: 'United States', code: '1' },
  { iso: 'UY', label: 'Uruguay', code: '598' },
  { iso: 'UZ', label: 'Uzbekistan', code: '998' },
  { iso: 'VA', label: 'Holy See (Vatican City State)', code: '379' },
  { iso: 'VC', label: 'Saint Vincent and the Grenadines', code: '1-784' },
  { iso: 'VE', label: 'Venezuela', code: '58' },
  { iso: 'VG', label: 'British Virgin Islands', code: '1-284' },
  { iso: 'VI', label: 'US Virgin Islands', code: '1-340' },
  { iso: 'VN', label: 'Vietnam', code: '84' },
  { iso: 'VU', label: 'Vanuatu', code: '678' },
  { iso: 'WF', label: 'Wallis and Futuna', code: '681' },
  { iso: 'WS', label: 'Samoa', code: '685' },
  { iso: 'XK', label: 'Kosovo', code: '383' },
  { iso: 'YE', label: 'Yemen', code: '967' },
  { iso: 'YT', label: 'Mayotte', code: '262' },
  { iso: 'ZA', label: 'South Africa', code: '27' },
  { iso: 'ZM', label: 'Zambia', code: '260' },
  { iso: 'ZW', label: 'Zimbabwe', code: '263' },
];

export default countryCode;

export const countryToFlag = (isoCode: string) => {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode.toUpperCase().replace(/./g, (char: string) => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode;
};

export const labelOrIso = (countryCode: ICountryCode) => {
  return countryCode.label.length > 15 ? countryCode.iso : countryCode.label;
};
