import { FC, useState, useCallback } from 'react';

import NewPhoneVerification from './NewPhoneVerification';
import UpdatePhoneLogin from './UpdatePhoneLogin';

interface Props {
  onCancel: () => void;
  onNext: () => void;
}

const UpdatePhone: FC<Props> = ({ onCancel, onNext }) => {
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
  const [verificationErrorMsg, setVerificationErrorMsg] = useState<string>('');

  const onPhoneNumberSuccess = (phoneNumber: string) => {
    setPhoneNumber(phoneNumber);
  };

  const handlePhoneVerificationError = useCallback(
    (errorMessage) => {
      setVerificationErrorMsg(errorMessage);
      setPhoneNumber(null);
    },
    [setPhoneNumber, setVerificationErrorMsg]
  );

  const handleBack = useCallback(() => {
    setPhoneNumber(null);
  }, [setPhoneNumber]);

  if (!phoneNumber)
    return (
      <UpdatePhoneLogin errorMessage={verificationErrorMsg} onSuccess={onPhoneNumberSuccess} onCancel={onCancel} />
    );

  return (
    <NewPhoneVerification
      phoneNumber={phoneNumber}
      onError={handlePhoneVerificationError}
      onBack={handleBack}
      onNext={onNext}
    />
  );
};

export default UpdatePhone;
