import { ReactNode } from 'react';
import Link from './Link';

const Element = ({ attributes, children, element }: { attributes: unknown; children: ReactNode; element: unknown }) => {
  switch (
    (
      element as {
        type: string;
      }
    ).type
  ) {
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>;
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>;
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>;
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'link':
      return (
        <Link
          element={
            element as {
              href: string;
            }
          }
          attributes={attributes}
        >
          {children}
        </Link>
      );
    default:
      return <p {...attributes}>{children}</p>;
  }
};

export default Element;
