import { useState } from 'react';
import Moment from 'react-moment';
import { Typography, Card, CardContent, Box, TextField, Grid } from '@material-ui/core';
import { DataGrid, GridCellParams, GridColDef, GridSortModel } from '@material-ui/data-grid';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { useInternalAdminUsers } from '@dayone/hooks';
import { workspaceSlice, useAppSelector } from '@dayone/redux';
import { ProgressIndicator } from 'shared/components/progressIndicator';

const commonColSettings = {
  disableColumnMenu: true,
};

const companyColumns: GridColDef[] = [
  { field: 'email', headerName: 'Email', flex: 2, ...commonColSettings },
  { field: 'name', headerName: 'Name', flex: 1.2, ...commonColSettings },
  { field: 'phoneNumber', headerName: 'Phone number', flex: 1, ...commonColSettings },
  { field: 'workspaces', headerName: 'Workspaces', flex: 1.6, ...commonColSettings },
  {
    field: 'createdDate',
    headerName: 'Created date',
    flex: 1.5,
    renderCell: (params: GridCellParams) => (
      <Box pl={1}>{params.value ? <Moment format="D MMM YYYY">{params.value.toLocaleString()}</Moment> : ''}</Box>
    ),
    ...commonColSettings,
  },
];

const InformationUserOverview = () => {
  const workspaces = useAppSelector<any>(workspaceSlice.getWorkspaces);
  const { isLoading, users, companyId, setCompanyId } = useInternalAdminUsers();
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'email', sort: 'asc' }]);
  const [pageSize, setPageSize] = useState(50);

  return (
    <>
      <Card elevation={0}>
        <CardContent>
          <Typography variant="h6">User Overview</Typography>
          <Box mx={-2} mb={-3}>
            <Box m={2}>
              <Grid container>
                <Grid item xs={12} sm={6} md={4}>
                  <Autocomplete
                    openOnFocus
                    value={workspaces.find((workspace: any) => workspace.id === companyId)}
                    options={workspaces}
                    onChange={(event, value) => {
                      setCompanyId(value?.id);
                    }}
                    getOptionLabel={(branch) => branch.name}
                    renderInput={(params) => <TextField {...params} label="Workspace" variant="outlined" />}
                  />
                </Grid>
              </Grid>
            </Box>
            <DataGrid
              autoHeight
              style={{ borderWidth: 0 }}
              /** start pagination configs */
              pagination
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
              rowsPerPageOptions={[10, 20, 50, 100, 200]}
              /** end pagination configs */
              rows={users}
              columns={companyColumns}
              sortModel={sortModel}
              onSortModelChange={setSortModel}
            />
          </Box>
        </CardContent>
      </Card>
      <ProgressIndicator loading={isLoading} />
    </>
  );
};

export default InformationUserOverview;
