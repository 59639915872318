import React, { useCallback, useMemo } from 'react';
import {
  Paper,
  Box,
  Button,
  useMediaQuery,
  Grid,
  Card,
  CardContent,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DataGrid, GridCellParams } from '@material-ui/data-grid';
import SearchIcon from '@material-ui/icons/Search';
import { ITaskModel, TaskFlag } from '@dayone/models';
import TaskOverviewFilter from './components/TaskSearchFilter';
import TaskOverviewFilterDrawer from './components/TaskSearchFilterDrawer';
import { taskSlice, teamSlice, useAppSelector } from '@dayone/redux';
import _ from 'lodash';
import columnWithResponsive from './components/TaskSearchColumns';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import moment from 'moment';
import { EmptyData } from 'shared/components/emptyData';
import listEmpty from 'assets/list-empty.svg';

export default function TaskSearch() {
  const theme = useTheme();
  const navigate = useNavigate();

  const matchesLg = useMediaQuery(theme.breakpoints.down('md'));
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const tasks = useAppSelector<any>(taskSlice.selectTasks)
    ?.map((task: any) => ({
      ...task,
      nextOccurrentUnix: moment(task.nextOccurrent).unix(),
    }))
    .filter((task: any) => {
      const startOfTomorrow = moment().startOf('day').add(1, 'day').unix();
      return startOfTomorrow <= task.nextOccurrentUnix;
    });
  const branches = useAppSelector<any>(teamSlice.selectActiveTeams);

  const [mobileOpen, setMobileOpen] = React.useState(false);

  //filter
  const [searchParams, setSearchParams] = useSearchParams();
  const keyword = searchParams.get('keyword') ?? '';
  const selectedBranches = useMemo(() => {
    const query = searchParams.get('branches');
    return (!query || query === '' ? [] : query.split(',')) as string[];
  }, [searchParams]);

  const selectedInterval = useMemo(() => {
    const query = searchParams.get('interval');
    return (!query || query === '' ? [] : query.split(',')) as string[];
  }, [searchParams]);

  const pageSize = Number(searchParams.get('size') ?? 10);

  const getQueryParams = useCallback(
    () => ({
      keyword: keyword,
      branches: selectedBranches.join(','),
      interval: selectedInterval.join(','),
      size: pageSize.toString(),
    }),
    [keyword, selectedBranches, selectedInterval, pageSize]
  );
  const setKeyword = useCallback(
    (keyword: string) => {
      const queryParams = getQueryParams();
      setSearchParams({ ...queryParams, keyword: keyword }, { replace: true });
    },
    [getQueryParams, setSearchParams]
  );
  const setSelectedBranches = useCallback(
    (branches: string[]) => {
      const queryParams = getQueryParams();
      setSearchParams({ ...queryParams, branches: branches.join(',') }, { replace: true });
    },
    [setSearchParams, getQueryParams]
  );
  const setSelectedInterval = useCallback(
    (intervals: string[]) => {
      const queryParams = getQueryParams();
      setSearchParams({ ...queryParams, interval: intervals.join(',') }, { replace: true });
    },
    [getQueryParams, setSearchParams]
  );

  const setPageSize = useCallback(
    (pageSize: number) => {
      const queryParams = getQueryParams();
      setSearchParams({ ...queryParams, size: pageSize.toString() }, { replace: true });
    },
    [getQueryParams, setSearchParams]
  );

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen((prev) => !prev);
  }, [setMobileOpen]);

  const onChangeKeywords = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setKeyword(event.target.value);
    },
    [setKeyword]
  );

  const filterTask = useCallback(
    (task: ITaskModel) => {
      return (
        (task.name.toLowerCase().indexOf(keyword.toLowerCase()) > -1 ||
          task.description.toLowerCase().indexOf(keyword.toLowerCase()) > -1) &&
        (selectedBranches.length === 0 || selectedBranches.some((branch) => task.branchID === branch)) &&
        (selectedInterval.length === 0 ||
          selectedInterval.some((interval) => {
            if (interval === 'One-off') return !task.recurringType && task.recurringType !== 0;
            return task.recurringType === +interval;
          }))
      );
    },
    [selectedBranches, selectedInterval, keyword]
  );

  const handleFilterChanged = useCallback(
    (filter: string[], setFilter: (value: string[]) => void, checked: boolean, value: string) => {
      if (checked) {
        if (filter.indexOf(value) === -1) setFilter([...filter, value]);
      } else {
        setFilter(filter.filter((selectedValue) => selectedValue !== value));
      }
    },
    []
  );

  const handleBranchChange = useCallback(
    (checked: boolean, value: string) => {
      handleFilterChanged(selectedBranches, setSelectedBranches, checked, value);
    },
    [handleFilterChanged, selectedBranches, setSelectedBranches]
  );

  const handleIntervalFilterChange = useCallback(
    (checked: boolean, value: string) => {
      handleFilterChanged(selectedInterval, setSelectedInterval, checked, value);
    },
    [handleFilterChanged, selectedInterval, setSelectedInterval]
  );

  const drawerMobile = (
    <TaskOverviewFilterDrawer
      mobileOpen={mobileOpen}
      onDrawerToggle={handleDrawerToggle}
      selectedBranches={selectedBranches}
      selectedInterval={selectedInterval}
      onBranchChange={handleBranchChange}
      onIntervalChange={handleIntervalFilterChange}
      allBranches={branches.map((branch: any) => ({ label: branch.name, value: branch.teamId }))}
    />
  );

  const leftMenu = (
    <Grid item lg={3}>
      <Box p={2}>
        <TaskOverviewFilter
          selectedBranches={selectedBranches}
          selectedInterval={selectedInterval}
          onBranchChange={handleBranchChange}
          onIntervalChange={handleIntervalFilterChange}
          allBranches={branches.map((branch: any) => ({ label: branch.name, value: branch.teamId }))}
        />
      </Box>
    </Grid>
  );
  const filteredTasks = useMemo(() => tasks.filter((task: any) => filterTask(task)), [tasks, filterTask]);
  const result = useMemo(
    () =>
      _.orderBy(
        filteredTasks.map((x: any) => ({ ...x, isUrgent: x.flag === TaskFlag.urgent })),
        ['nextOccurrentUnix'],
        ['asc']
      ),
    [filteredTasks]
  );

  const renderContent = () => {
    if (!result || !result.length)
      return (
        <EmptyData
          imageSrc={listEmpty}
          emptyText="No results found"
          suggestion="Search for something else or create a new task"
        />
      );

    return (
      <Paper elevation={0}>
        <Card elevation={0}>
          <CardContent>
            <DataGrid
              getRowId={(row) => row.taskID}
              autoHeight
              rowHeight={theme.spacing(7)}
              disableSelectionOnClick
              rows={result}
              columns={columnWithResponsive(
                matches,
                (branchID) => branches.find((branch: any) => branch.teamId === branchID)?.name
              )}
              nonce={'No Tasks'}
              pagination
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[5, 10, 20]}
              onCellClick={(params: GridCellParams, event: React.MouseEvent) => {
                if (params.field === 'name') {
                  const task: ITaskModel = params.row as ITaskModel;
                  navigate(`/tasks/detail/${task.taskID}`);
                }
              }}
            />
          </CardContent>
        </Card>
      </Paper>
    );
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box pb={2}>
          <Button color="primary" startIcon={<ArrowBackIcon />} onClick={() => navigate(-1)}>
            {`BACK`}
          </Button>
        </Box>
      </Grid>
      {matchesLg ? drawerMobile : leftMenu}
      <Grid item xs={12} sm={12} lg={9}>
        <Box pb={3} display="flex" alignItems="center">
          <TextField
            fullWidth
            size="small"
            label="Search for a task"
            variant="outlined"
            value={keyword}
            onChange={onChangeKeywords}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          {matchesLg ? (
            <Box pl={3} onClick={handleDrawerToggle}>
              <FilterListIcon color="action" />
            </Box>
          ) : null}
        </Box>
        {renderContent()}
      </Grid>
    </Grid>
  );
}
