import { FC, useState } from 'react';
import { Box, Tabs, Tab } from '@material-ui/core';

import LoginPhone from './LoginPhone';
import LoginEmail from './LoginEmail';

interface Props {
  next: () => void;
  cancel: () => void;
  setCredential: (credential: any) => void;
}

const ReLogin: FC<Props> = ({ next, cancel, setCredential }) => {
  const [tabValue, setTabValue] = useState<number>(0);
  const [isTabHidden, setIsTabHidden] = useState<boolean>(false);

  const renderContent = () => {
    if (tabValue === 0)
      return <LoginPhone cancel={cancel} next={next} setIsTabHidden={setIsTabHidden} setCredential={setCredential} />;
    if (tabValue === 1) return <LoginEmail cancel={cancel} next={next} setCredential={setCredential} />;
    return null;
  };

  return (
    <Box p={1}>
      {!isTabHidden && (
        <Tabs value={tabValue} onChange={(_e: any, value: number) => setTabValue(value)} indicatorColor="primary">
          <Tab label="Login via phone" style={{ flexGrow: 1 }} />
          <Tab label="Login via email" style={{ flexGrow: 1 }} />
        </Tabs>
      )}
      {renderContent()}
    </Box>
  );
};

export default ReLogin;
