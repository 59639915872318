import React from 'react';
import { Box, Typography, Link } from '@material-ui/core';
import { GridCellParams, GridColDef } from '@material-ui/data-grid';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import { ITaskModel, TaskStatus, TaskFlag } from '@dayone/models';
import moment from 'moment';

export default function TaskOverviewColumns(
  matches: boolean,
  getBranchName: (branchID: string | undefined) => string | undefined
): GridColDef[] {
  const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();
  return [
    {
      field: 'occurrentDate',
      headerName: 'Time',
      flex: matches ? 0 : 0.15,
      width: matches ? 90 : 0,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        const task = params.row as ITaskModel;
        if (task.flag === TaskFlag.urgent) {
          return (
            <Box display="flex" justifyContent="center" alignItems="center" flex={1}>
              <PriorityHighIcon />
            </Box>
          );
        }
        return <Typography variant={'body2'}>{moment(task.occurrentDate).format('h:mm A')}</Typography>;
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      sortable: false,
      flex: matches ? 0 : 0.65,
      width: matches ? 560 : 0,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        const task = params.row as ITaskModel;
        return (
          <Box display="flex" alignItems="center" width="100%">
            {/* {task.flag === TaskFlag.urgent && <PriorityHighIcon fontSize="small" />} */}
            <Box pl={1} overflow="hidden">
              <Typography
                variant={'body2'}
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  textDecoration: task.status === TaskStatus.closed ? 'line-through' : 'initial',
                }}
              >
                <Link href={`/tasks/detail/${task.taskID}`} onClick={preventDefault}>
                  {params.value}
                </Link>
              </Typography>
            </Box>
          </Box>
        );
      },
    },
    {
      field: 'branchID',
      headerName: 'Team',
      flex: matches ? 0 : 0.2,
      width: matches ? 160 : 0,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        const branchID = params.value?.toString();
        return (
          <Box pl={1}>
            <Typography variant={'body2'}>{getBranchName(branchID) ?? ''}</Typography>
          </Box>
        );
      },
    },
  ];
}
