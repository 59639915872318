"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.notificationSlice = exports.featureFlagSlice = exports.devSlice = exports.playbookSlice = exports.myInviteSlice = exports.settingSlice = exports.internalAdminsSlice = exports.announcementSlice = exports.commentSlice = exports.topicSlice = exports.taskSlice = exports.faqCategorySlice = exports.faqSlice = exports.memberSlice = exports.promoSlice = exports.teamSlice = exports.workspaceSlice = exports.repositorySlice = exports.meSlice = void 0;
/**
 * meSlice
 */
exports.meSlice = __importStar(require("./reducers/meSlice"));
/**
 * repositorySlice
 */
exports.repositorySlice = __importStar(require("./reducers/repositorySlice"));
/**
 * workspaceSlice
 */
exports.workspaceSlice = __importStar(require("./reducers/workspaceSlice"));
/**
 * teamSlice
 */
exports.teamSlice = __importStar(require("./reducers/teamSlice"));
/**
 * promoSlice
 */
exports.promoSlice = __importStar(require("./reducers/promoSlice"));
/**
 * teamSlice
 */
exports.memberSlice = __importStar(require("./reducers/memberSlice"));
/**
 * faqSlice
 */
exports.faqSlice = __importStar(require("./reducers/faqSlice"));
/**
 * faqCategorySlice
 */
exports.faqCategorySlice = __importStar(require("./reducers/faqCategorySlice"));
/**
 * taskSlice
 */
exports.taskSlice = __importStar(require("./reducers/taskSlice"));
/**
 * topicSlice
 */
exports.topicSlice = __importStar(require("./reducers/topicSlice"));
/**
 * commentSlice
 */
exports.commentSlice = __importStar(require("./reducers/commentSlice"));
/**
 * announcementSlice
 */
exports.announcementSlice = __importStar(require("./reducers/announcementSlice"));
/**
 * internalAdminsSlice
 */
exports.internalAdminsSlice = __importStar(require("./reducers/internalAdminsSlice"));
/**
 * settingSlice
 */
exports.settingSlice = __importStar(require("./reducers/settingSlice"));
/**
 * myInviteSlice
 */
exports.myInviteSlice = __importStar(require("./reducers/myInviteSlice"));
/**
 * playbookSlice
 */
exports.playbookSlice = __importStar(require("./reducers/playbookSlice"));
/**
 * devSlice
 */
exports.devSlice = __importStar(require("./reducers/devSlice"));
exports.featureFlagSlice = __importStar(require("./reducers/featureFlagSlice"));
exports.notificationSlice = __importStar(require("./reducers/notificationSlice"));
