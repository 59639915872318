import { Grid } from '@material-ui/core';
import { faqSlice, useAppSelector } from '@dayone/redux';
import KnowledgeList from './components/KnowledgeList';

export default function KnowledgeArchived() {
  const faqs = useAppSelector(faqSlice.selectArchivedFaqs);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <KnowledgeList knowledges={faqs} statusType="ARCHIVED"></KnowledgeList>
      </Grid>
    </Grid>
  );
}
