// Knowledge
import KnowledgeArchived from 'screens/knowledge/KnowledgeArchived';
import KnowledgeCreate from 'screens/knowledge/KnowledgeCreate';
import KnowledgeEdit from 'screens/knowledge/KnowledgeEdit';
import KnowledgeOverview from 'screens/knowledge/KnowledgeOverview';
import KnowledgeUnanswered from 'screens/knowledge/KnowledgeUnanswered';
// Announcement
import AnnouncementOverview from 'screens/announcement/AnnouncementOverview';
import CreateAnnouncement from 'screens/announcement/AnnouncementCreate';
import EditAnnouncement from 'screens/announcement/AnnouncementEdit';
import AnnouncementArchive from 'screens/announcement/AnnouncementArchive';
// Team
import Team from 'screens/team/TeamDetail';
import TeamOverview from 'screens/team/TeamOverview';
// Repository
import Repository from 'screens/repository/RepositoryOverview';
import EditMemberDetails from 'screens/member/EditMemberDetails';
import MemberOverview from 'screens/member/MemberOverview';
import EditTask from 'screens/task/TaskUpdate';
import CreateTaskThread from 'screens/task/TaskThreadCreate';
import UpdateTaskThread from 'screens/task/TaskThreadUpdate';
// Task
import Task from 'screens/task/TaskDetail';
import TaskOverview from 'screens/task/TaskOverview';
import ThreadComment from 'screens/task/TaskThreadOverview';
// Settings
import SettingOverview from 'screens/setting/SettingOverview';
import InviteScreen from 'screens/setting/SettingInvite';
import Profile from 'screens/profile/Profile';
import Account from 'screens/profile/Account';
// Internal Admins
import InformationOverview from 'screens/information/InformationOverview';
import InformationUserOverview from 'screens/information/InformationUserOverview';
import InformationCreateCompany from 'screens/information/InformationCreateCompany';
import InformationCreateUserAccount from 'screens/information/InformationCreateUserAccount';
// Debug
import Debug from 'screens/debug/Debug';
import TaskSearch from 'screens/task/TaskSearch';
import PlaybookOverview from 'screens/playbook/PlaybookOverview';
import PlaybookForm from 'screens/playbook/PlaybookForm';
import RepositorySearch from 'screens/repository/RepositorySearch';
import KnowledgeManageCategory from 'screens/knowledge/KnowledgeManageCategory';
import SettingCustomise from 'screens/setting/SettingsCustomise';
/**
 * interface to configure information of route
 * @label string: Label of route, which show in 2nd Navigation
 * @url string: URL
 * @component component: the component will be render
 * @hiddenNav boolean: true - 2nd navigation will be show
 * @hiddenInNav boolean: true - Route will be hidden in 2nd navigation
 * @role ('admin' | 'user' | '*')[]: list all roles can see the route
 */
interface IRoute {
  pageTitle: string;
  label: string;
  url: string;
  component: () => any;
  hiddenNav: boolean;
  hiddenInNav: boolean;
  role: ('admin' | 'internalAdmin' | 'user' | '*' | null)[];
}

const TeamRoutes: IRoute[] = [
  {
    pageTitle: 'Teams',
    label: 'Overview',
    url: '/teams',
    component: TeamOverview,
    hiddenNav: false,
    hiddenInNav: false,
    role: ['admin', 'user', 'internalAdmin'],
  },
  {
    pageTitle: 'Teams',
    label: 'Create Team',
    url: '/teams/create',
    component: Team,
    hiddenNav: false,
    hiddenInNav: false,
    role: ['admin', 'internalAdmin'],
  },
  {
    pageTitle: 'Edit Team',
    label: 'Edit',
    url: '/teams/edit/:branchID',
    component: Team,
    hiddenNav: true,
    hiddenInNav: true,
    role: ['admin', 'internalAdmin'],
  },
];

const InformationRoutes: IRoute[] = [
  {
    pageTitle: 'Dayone Admin',
    label: 'Overview',
    url: '/information',
    component: InformationOverview,
    hiddenNav: false,
    hiddenInNav: false,
    role: ['internalAdmin'],
  },
  {
    pageTitle: 'Dayone Admin',
    label: 'User Overview',
    url: '/information/user-overview',
    component: InformationUserOverview,
    hiddenNav: false,
    hiddenInNav: false,
    role: ['internalAdmin'],
  },
  {
    pageTitle: 'Dayone Admin',
    label: 'Create Company',
    url: '/information/create-company',
    component: InformationCreateCompany,
    hiddenNav: false,
    hiddenInNav: false,
    role: ['internalAdmin'],
  },
  {
    pageTitle: 'Dayone Admin',
    label: 'Create User Account',
    url: '/information/create-user-account',
    component: InformationCreateUserAccount,
    hiddenNav: false,
    hiddenInNav: false,
    role: ['internalAdmin'],
  },
];

const AnnouncementRoutes: IRoute[] = [
  {
    pageTitle: 'Announcements',
    label: 'Overview',
    url: '/announcements',
    component: AnnouncementOverview,
    hiddenNav: false,
    hiddenInNav: false,
    role: ['admin', 'user', 'internalAdmin'],
  },
  {
    pageTitle: 'Announcements',
    label: 'Create Message',
    url: '/announcements/create',
    component: CreateAnnouncement,
    hiddenNav: false,
    hiddenInNav: false,
    role: ['admin', 'user', 'internalAdmin'],
  },
  {
    pageTitle: 'View Announcement',
    label: 'Edit Message',
    url: '/announcements/edit/:id',
    component: EditAnnouncement,
    hiddenNav: true,
    hiddenInNav: true,
    role: ['admin', 'user', 'internalAdmin'],
  },
  {
    pageTitle: 'Announcements',
    label: 'History',
    url: '/announcements/archive',
    component: AnnouncementArchive,
    hiddenNav: false,
    hiddenInNav: false,
    role: ['admin', 'user', 'internalAdmin'],
  },
];

const KnowledgeRoutes: IRoute[] = [
  {
    pageTitle: 'Knowledge',
    label: 'Overview',
    url: '/knowledge',
    component: KnowledgeOverview,
    hiddenNav: false,
    hiddenInNav: false,
    role: ['admin', 'user', 'internalAdmin'],
  },
  {
    pageTitle: 'Knowledge',
    label: 'Manage Categories',
    url: '/knowledge/category/manage',
    component: KnowledgeManageCategory,
    hiddenNav: false,
    hiddenInNav: false,
    role: ['admin', 'user', 'internalAdmin'],
  },
  {
    pageTitle: 'Knowledge',
    label: 'Create FAQ',
    url: '/knowledge/create',
    component: KnowledgeCreate,
    hiddenNav: false,
    hiddenInNav: false,
    role: ['admin', 'internalAdmin'],
  },
  {
    pageTitle: 'Knowledge',
    label: 'Unanswered',
    url: '/knowledge/unanswered',
    component: KnowledgeUnanswered,
    hiddenNav: false,
    hiddenInNav: false,
    role: ['admin', 'internalAdmin'],
  },
  {
    pageTitle: 'Edit FAQ',
    label: 'Edit',
    url: '/knowledge/edit/:faqID',
    component: KnowledgeEdit,
    hiddenNav: true,
    hiddenInNav: true,
    role: ['admin', 'user', 'internalAdmin'],
  },
  {
    pageTitle: 'Knowledge',
    label: 'Archived',
    url: '/knowledge/archived',
    component: KnowledgeArchived,
    hiddenNav: false,
    hiddenInNav: false,
    role: ['admin', 'internalAdmin'],
  },
];

const TaskRoutes: IRoute[] = [
  {
    pageTitle: 'Responsibilities',
    label: 'Overview',
    url: '/tasks',
    component: TaskOverview,
    hiddenNav: false,
    hiddenInNav: false,
    role: ['admin', 'user', 'internalAdmin'],
  },
  {
    pageTitle: 'Responsibilities',
    label: 'Search',
    url: '/tasks/search',
    component: TaskSearch,
    hiddenNav: true,
    hiddenInNav: true,
    role: ['admin', 'user', 'internalAdmin'],
  },
  {
    pageTitle: 'Responsibilities',
    label: 'Create Task',
    url: '/tasks/create',
    component: EditTask,
    hiddenNav: false,
    hiddenInNav: false,
    role: ['admin', 'internalAdmin'],
  },
  {
    pageTitle: 'Task Comment Topics',
    label: 'Task Details',
    url: '/tasks/detail/:taskID',
    component: Task,
    hiddenNav: true,
    hiddenInNav: true,
    role: ['admin', 'user', 'internalAdmin'],
  },
  {
    pageTitle: 'Edit Task',
    label: 'Edit Task',
    url: '/tasks/edit/:taskID',
    component: EditTask,
    hiddenNav: true,
    hiddenInNav: true,
    role: ['admin', 'user', 'internalAdmin'],
  },
  {
    pageTitle: 'Task Comments',
    label: 'Topic',
    url: '/tasks/topic/detail',
    component: ThreadComment,
    hiddenNav: true,
    hiddenInNav: true,
    role: ['admin', 'user', 'internalAdmin'],
  },
  {
    pageTitle: 'Task Comment Topics',
    label: 'Topic',
    url: '/tasks/topic/create/:taskID',
    component: CreateTaskThread,
    hiddenNav: true,
    hiddenInNav: true,
    role: ['admin', 'internalAdmin'],
  },
  {
    pageTitle: 'Task Comment Topics',
    label: 'Topic',
    url: '/tasks/topic/edit/:topicID',
    component: UpdateTaskThread,
    hiddenNav: true,
    hiddenInNav: true,
    role: ['admin', 'internalAdmin'],
  },
];

const RepositoryRoutes: IRoute[] = [
  {
    pageTitle: 'Repository',
    label: 'Overview',
    url: '/repository',
    component: Repository,
    hiddenNav: true,
    hiddenInNav: true,
    role: ['admin', 'user', 'internalAdmin'],
  },
  {
    pageTitle: 'Search all files',
    label: 'Search all files',
    url: '/repository/search',
    component: RepositorySearch,
    hiddenNav: true,
    hiddenInNav: true,
    role: ['admin', 'user', 'internalAdmin'],
  },
  // {// todo: remove if no needed
  //   pageTitle: 'Repository',
  //   label: 'New Folder',
  //   url: '/repository/createFolder',
  //   component: Repository,
  //   hiddenNav: false,
  //   hiddenInNav: false,
  //   role: ['admin', 'internalAdmin'],
  // },
  // {// todo: remove if no needed
  //   pageTitle: 'Repository',
  //   label: 'Update Folder',
  //   url: '/repository/updateFolder/:id',
  //   component: Repository,
  //   hiddenNav: true,
  //   hiddenInNav: true,
  //   role: ['admin', 'internalAdmin'],
  // },
];

const MemberRoutes: IRoute[] = [
  {
    pageTitle: 'Members',
    label: 'Overview',
    url: '/members',
    component: MemberOverview,
    hiddenNav: false,
    hiddenInNav: false,
    role: ['admin', 'user', 'internalAdmin'],
  },
  {
    pageTitle: 'Edit Member Details',
    label: 'Edit Member',
    url: '/members/edit/:id',
    component: EditMemberDetails,
    hiddenNav: true,
    hiddenInNav: true,
    role: ['admin', 'user', 'internalAdmin'],
  },
];

const ProfileRoutes: IRoute[] = [
  {
    pageTitle: 'Profile',
    label: 'Overview',
    url: '/profile',
    component: Profile,
    hiddenNav: false,
    hiddenInNav: false,
    role: ['*'],
  },
  {
    pageTitle: 'Profile',
    label: 'Account',
    url: '/profile/account',
    component: Account,
    hiddenNav: false,
    hiddenInNav: false,
    role: ['*'],
  },
];

const SettingRoutes: IRoute[] = [
  {
    pageTitle: 'Settings',
    label: 'Overview',
    url: '/setting',
    component: SettingOverview,
    hiddenNav: false,
    hiddenInNav: false,
    role: ['*'],
  },
  {
    pageTitle: 'Settings',
    label: 'Customise',
    url: '/setting/customise',
    component: SettingCustomise,
    hiddenNav: false,
    hiddenInNav: false,
    role: ['*'],
  },
  {
    pageTitle: 'Settings',
    label: 'Invites',
    url: '/setting/invites',
    component: InviteScreen,
    hiddenNav: false,
    hiddenInNav: false,
    role: ['*'],
  },
];

const PlaybookRoutes: IRoute[] = [
  {
    pageTitle: 'Playbook',
    label: 'Overview',
    url: '/playbook',
    component: PlaybookOverview,
    hiddenNav: false,
    hiddenInNav: false,
    role: ['internalAdmin'],
  },
  {
    pageTitle: 'Create',
    label: 'Create',
    url: '/playbook/create',
    component: PlaybookForm,
    hiddenNav: false,
    hiddenInNav: false,
    role: ['internalAdmin'],
  },
];

const DebugRoutes: IRoute[] = [
  {
    pageTitle: 'Debug',
    label: 'Main Debug',
    url: '/debug',
    component: Debug,
    hiddenNav: false,
    hiddenInNav: false,
    role: ['internalAdmin'],
  },
];

export {
  KnowledgeRoutes,
  InformationRoutes,
  AnnouncementRoutes,
  TeamRoutes,
  TaskRoutes,
  RepositoryRoutes,
  MemberRoutes,
  ProfileRoutes,
  SettingRoutes,
  PlaybookRoutes,
  DebugRoutes,
};
