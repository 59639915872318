import { Box, IconButton } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import React, { useState } from 'react';
import MediaIcon from '@material-ui/icons/PlayArrowSharp';
import { LightBox } from 'shared/components/uploadImage';

export default function VideoWithLightBox(props: {
  url: string;
  thumbnailUrl?: string;
  alt?: string;
  width?: number;
  height?: number;
}) {
  const theme = useTheme();
  const [openLightBox, setOpenLightBox] = useState(false);

  const handlePreview = () => {
    setOpenLightBox(true);
  };

  const width = props.width || theme.spacing(11.25);
  const height = props.height || theme.spacing(7.5);

  return (
    <>
      <Box
        width={width}
        height={height}
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{
          backgroundImage: `url(${props.thumbnailUrl})`,
          backgroundSize: 'cover',
        }}
      >
        <IconButton onClick={handlePreview}>
          <MediaIcon style={{ cursor: 'pointer' }} />
        </IconButton>
      </Box>
      {openLightBox && (
        <LightBox type="Video" open={openLightBox} onClose={() => setOpenLightBox(false)} url={props.url} />
      )}
    </>
  );
}
