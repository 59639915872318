import React, { useCallback, useEffect, useMemo } from 'react';
import { Paper, Box, useMediaQuery, Typography, Grid, Card, CardContent } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DataGrid, GridCellParams } from '@material-ui/data-grid';
import { ITaskModel, TaskFlag } from '@dayone/models';
import TaskOverviewFilter from './components/TaskOverviewFilter';
import TaskOverviewFilterDrawer from './components/TaskOverviewFilterDrawer';
import { taskSlice, teamSlice, useAppSelector } from '@dayone/redux';
import _ from 'lodash';
import columnWithResponsive from './components/TaskOverviewColumns';
import moment from 'moment';

import { EmptyData } from 'shared/components/emptyData';
import boxEmpty from 'assets/box-empty.svg';
import listEmpty from 'assets/list-empty.svg';
import GAEvents from 'shared/utilities/events';
import { useCustomDimensionsEvents } from 'shared/components/hooks';

export default function TaskOverview() {
  const theme = useTheme();
  const navigate = useNavigate();

  const matchesLg = useMediaQuery(theme.breakpoints.down('md'));
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const tasks = useAppSelector<any>(taskSlice.selectTasks);
  const todayTasks = useMemo(
    () =>
      tasks
        .map((task: any) => ({ ...task, occurrentDateUnix: moment(task.occurrentDate).unix() }))
        .filter((task: any) => {
          const startOfDay = moment().startOf('day').unix();
          const endOfDay = moment().endOf('day').unix();
          return startOfDay <= task.occurrentDateUnix && task.occurrentDateUnix <= endOfDay;
        }),
    [tasks]
  );
  const branches = useAppSelector<any>(teamSlice.selectActiveTeams);
  const logEvent = useCustomDimensionsEvents();

  const [mobileOpen, setMobileOpen] = React.useState(false);

  //filter
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    logEvent(GAEvents.task_view);
  }, [logEvent]);

  const selectedBranches = useMemo(() => {
    const query = searchParams.get('branches');
    return (!query || query === '' ? [] : query.split(',')) as string[];
  }, [searchParams]);

  const selectedTaskStatus = useMemo(() => {
    const query = searchParams.get('status');
    return (!query || query === '' ? [] : query.split(',')) as string[];
  }, [searchParams]);

  const pageSize = useMemo(() => Number(searchParams.get('size') ?? 10), [searchParams]);
  const getQueryParams = useCallback(
    () => ({
      branches: selectedBranches.join(','),
      status: selectedTaskStatus.join(','),
      size: pageSize.toString(),
    }),
    [selectedBranches, selectedTaskStatus, pageSize]
  );
  const setSelectedBranches = useCallback(
    (branches: string[]) => {
      const queryParams = getQueryParams();
      setSearchParams({ ...queryParams, branches: branches.join(',') }, { replace: true });
    },
    [getQueryParams, setSearchParams]
  );

  const setSelectedTaskStatus = useCallback(
    (status: string[]) => {
      const queryParams = getQueryParams();
      setSearchParams({ ...queryParams, status: status.join(',') }, { replace: true });
    },
    [getQueryParams, setSearchParams]
  );

  const setPageSize = useCallback(
    (pageSize: number) => {
      const queryParams = getQueryParams();
      setSearchParams({ ...queryParams, size: pageSize.toString() }, { replace: true });
    },
    [getQueryParams, setSearchParams]
  );

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen((prev) => !prev);
  }, [setMobileOpen]);

  const filterTask = useCallback(
    (task: ITaskModel) => {
      return (
        (selectedBranches.length === 0 || selectedBranches.some((branch) => task.branchID === branch)) &&
        (selectedTaskStatus.length === 0 || selectedTaskStatus.some((status) => task.status === Number(status)))
      );
    },
    [selectedBranches, selectedTaskStatus]
  );

  const handleFilterChanged = useCallback(
    (filter: string[], setFilter: (value: string[]) => void, checked: boolean, value: string) => {
      if (checked) {
        if (filter.indexOf(value) === -1) setFilter([...filter, value]);
      } else {
        setFilter(filter.filter((selectedValue) => selectedValue !== value));
      }
    },
    []
  );

  const handleBranchChange = useCallback(
    (checked: boolean, value: string) => {
      handleFilterChanged(selectedBranches, setSelectedBranches, checked, value);
    },
    [selectedBranches, setSelectedBranches, handleFilterChanged]
  );

  const handleStatusFilterChange = useCallback(
    (checked: boolean, value: string) => {
      handleFilterChanged(selectedTaskStatus, setSelectedTaskStatus, checked, value);
    },
    [selectedTaskStatus, setSelectedTaskStatus, handleFilterChanged]
  );

  const onSearchAllTasks = useCallback(() => {
    navigate('search');
  }, [navigate]);

  const drawerMobile = (
    <TaskOverviewFilterDrawer
      mobileOpen={mobileOpen}
      onDrawerToggle={handleDrawerToggle}
      selectedBranches={selectedBranches}
      selectedStatus={selectedTaskStatus}
      onBranchChange={handleBranchChange}
      onStatusChange={handleStatusFilterChange}
      onSearchAllTasks={onSearchAllTasks}
      allBranches={branches.map((branch: any) => ({ label: branch.name, value: branch.teamId }))}
    />
  );

  const leftMenu = (
    <Grid item lg={3}>
      <Box p={2}>
        <TaskOverviewFilter
          selectedBranches={selectedBranches}
          selectedStatus={selectedTaskStatus}
          onBranchChange={handleBranchChange}
          onStatusChange={handleStatusFilterChange}
          onSearchAllTasks={onSearchAllTasks}
          allBranches={branches.map((branch: any) => ({ label: branch.name, value: branch.teamId }))}
        />
      </Box>
    </Grid>
  );

  const filteredTasks = useMemo(() => todayTasks.filter((task: any) => filterTask(task)), [todayTasks, filterTask]);
  const result = useMemo(
    () =>
      _.orderBy(
        filteredTasks.map((x: any) => ({ ...x, isUrgent: x.flag === TaskFlag.urgent })),
        ['isUrgent', 'occurrentDateUnix'],
        ['desc', 'asc']
      ),
    [filteredTasks]
  );

  const renderContent = () => {
    const isSearch = !!selectedBranches.length || !!selectedTaskStatus.length;
    const imageSrc = isSearch ? listEmpty : boxEmpty;
    const emptyText = isSearch ? 'No results found' : 'Nothing to see here';
    const suggestion = isSearch ? 'Search for something else or create a new task' : 'There are no tasks today';

    if (!result || !result.length)
      return <EmptyData imageSrc={imageSrc} emptyText={emptyText} suggestion={suggestion} />;

    return (
      <Paper elevation={0}>
        <Card elevation={0}>
          <CardContent>
            <DataGrid
              getRowId={(row) => row.taskID}
              autoHeight
              rowHeight={theme.spacing(7)}
              disableSelectionOnClick
              rows={result}
              columns={columnWithResponsive(
                matches,
                (branchID) => branches.find((branch: any) => branch.teamId === branchID)?.name
              )}
              nonce={'No Tasks'}
              pagination
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
              rowsPerPageOptions={[5, 10, 20]}
              onCellClick={(params: GridCellParams, event: React.MouseEvent) => {
                if (params.field === 'name') {
                  const task: ITaskModel = params.row as ITaskModel;
                  navigate(`detail/${task.taskID}`);
                }
              }}
            />
          </CardContent>
        </Card>
      </Paper>
    );
  };

  return (
    <Grid container spacing={3}>
      {matchesLg ? drawerMobile : leftMenu}
      <Grid item xs={12} sm={12} lg={9}>
        <Box pb={3} display="flex" alignItems="center">
          <Typography variant="h6">Tasks for the day</Typography>
          {matchesLg ? (
            <>
              <Box pl={3}></Box>
              <FilterListIcon onClick={handleDrawerToggle} color="action" />
            </>
          ) : null}
        </Box>
        {renderContent()}
      </Grid>
    </Grid>
  );
}
