import React from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { featureFlagSlice, RootState, settingSlice, useAppSelector, workspaceSlice } from '@dayone/redux';
import { IWorkspaceModel } from '@dayone/models';
import bytesToMegaBytes from 'shared/utilities/bytesToMegaBytes';

const LOW_WARNING_THRESHOLD = 70;
const MEDIUM_WARNING_THRESHOLD = 80;
const HIGH_WARNING_THRESHOLD = 90;

const StorageProgressBar = () => {
  const workSpaces = useAppSelector<IWorkspaceModel[]>(workspaceSlice.selectAllWorkspaces);
  const activeWorkspaceID = useAppSelector<RootState>(settingSlice.selectActiveWorkspaceID);
  const workSpace = workSpaces.find((item) => item.id === activeWorkspaceID);
  const currentStorageSize = workSpace?.repoStorageSize || 0;
  const { storageLimit } = useAppSelector(featureFlagSlice.selectFeatureFlags).repository;

  const storageProgress = (currentStorageSize / storageLimit) * 100;

  const note = storageProgress > LOW_WARNING_THRESHOLD ? `(${Math.floor(storageProgress)}% used)` : '';
  const storageProgressText = `${bytesToMegaBytes(currentStorageSize, 1)} MB of ${bytesToMegaBytes(
    storageLimit,
    1
  )} MB used`;

  const StyledProgressBar = withStyles((theme: Theme) => {
    let barColor;
    switch (true) {
      case storageProgress > HIGH_WARNING_THRESHOLD:
        barColor = theme.palette.error.dark;
        break;
      case storageProgress > MEDIUM_WARNING_THRESHOLD:
        barColor = theme.palette.warning.main;
        break;
      default:
        barColor = theme.palette.primary.main;
    }

    return createStyles({
      root: {
        height: 7,
        borderRadius: 3,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
      colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
      },
      bar: {
        borderRadius: 3,
        backgroundColor: barColor,
      },
    });
  })(LinearProgress);

  if (storageLimit > 0)
    return (
      <Box my={2}>
        <StyledProgressBar variant="determinate" value={Math.min(storageProgress, 100)} />
        <Box sx={{ justifyContent: 'space-between', display: 'flex' }}>
          <Typography variant="subtitle2" component="span">
            Storage {note}
          </Typography>
          <Typography variant="body2" component="span">
            {storageProgressText}
          </Typography>
        </Box>
      </Box>
    );

  /* preserve margins */
  return <Box my={2}></Box>;
};
export default StorageProgressBar;
