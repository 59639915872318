import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/Info';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { SelectedBranches } from 'shared/components/teamSelector';
import { DeleteConfirmDialog } from 'shared/components/deleteConfirmDialog';
import { useSnackbar } from 'shared/components/hooks';
import { memberSlice, teamSlice, useAppSelector, useAppDispatch, IMemberData } from '@dayone/redux';
import { ProgressIndicator } from 'shared/components/progressIndicator';
import refData from '@dayone/refdata';

export default function EditMemberDetails() {
  const { id = '' } = useParams();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const enqueueSnackbar = useSnackbar();

  const roles = [
    { value: 'admin', label: 'Admin' },
    { value: 'user', label: 'User' },
  ];

  const teams = useAppSelector<any>(teamSlice.selectTeamsOverview);
  const member: IMemberData | null = useAppSelector<any>(memberSlice.selectMemberByID)(id);

  // const meRole = useAppSelector<any>(meSlice.selectRole) as 'admin' | 'user' | null;
  // const canEditable = meRole === 'admin' ? false : true;

  const formik = useFormik({
    initialValues: {
      firstName: member?.firstName,
      lastName: member?.lastName,
      displayName: member?.displayName,
      mobileNumber: member?.phoneNumber,
      email: member?.email,
      branches: member
        ? teams.filter((team: any) => team.users.indexOf(member.memberId) > -1).map((team: any) => team.teamId)
        : [],

      role: member?.role,
    },
    validationSchema: Yup.object().shape({
      role: Yup.string().required('Required'),
    }),
    onSubmit: (values) => {
      const params = {
        memberId: id,
        firstName: values.firstName ? values.firstName : '',
        lastName: values.lastName ? values.lastName : '',
        displayName: values.displayName ? values.displayName : '',
        branches: values.branches,
        role: values.role ?? '',
        email: values.email ? values.email : '',
        mobileNumber: values.mobileNumber ? values.mobileNumber : '',
      };
      dispatch(memberSlice.updateMember(params))
        .unwrap()
        .then(() => {
          enqueueSnackbar('The member has been updated successfully', {
            variant: 'success',
          });
          navigate(-1);
        })
        .catch((err) => {
          enqueueSnackbar(err.message, {
            variant: 'error',
          });
        })
        .finally(() => {
          formik.setSubmitting(false);
        });
    },
  });
  const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] = React.useState(false);
  const openDeleteConfirmDialog = useCallback(() => setDeleteConfirmDialogOpen(true), [setDeleteConfirmDialogOpen]);

  const selectedBranches = useMemo(
    () => teams.filter((team: any) => formik.values.branches.indexOf(team.teamId) > -1).map((team: any) => team.name),
    [teams, formik.values.branches]
  );

  const handleConfirmDelete = (confirm: boolean) => {
    setDeleteConfirmDialogOpen(false);

    if (confirm) {
      formik.setSubmitting(true);
      const params = {
        memberId: id,
      };
      dispatch(memberSlice.deleteMember(params))
        .then(() => {
          enqueueSnackbar(`${member?.displayName} has been removed from the company!`, {
            variant: 'warning',
          });
          navigate(-1);
        })
        .catch((err) => {})
        .finally(() => {
          formik.setSubmitting(false);
        });
    }
  };

  const handleBranchCheckAll: (checked: boolean) => void = (checked) => {
    if (checked) {
      formik.setFieldValue(
        'branches',
        teams.map((team: any) => team.teamId)
      );
    } else {
      formik.setFieldValue('branches', []);
    }
  };

  return (
    <form style={{ display: 'block' }} onSubmit={formik.handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box marginLeft={{ xs: 2, sm: 0 }} mb={2}>
            <Button color="primary" onClick={() => navigate(-1)} startIcon={<ArrowBackIcon />}>
              Back
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} lg={8}>
          <Paper elevation={0}>
            <Card elevation={0}>
              <CardContent>
                <Grid container spacing={4}>
                  <Grid item xs={12} container justifyContent="flex-end">
                    <Tooltip title={refData.screen.member['btn-delte']}>
                      <IconButton size="medium" aria-label="delete" onClick={openDeleteConfirmDialog}>
                        <DeleteIcon color="secondary" />
                      </IconButton>
                    </Tooltip>

                    <DeleteConfirmDialog
                      title={`Are you sure you want to delete ${member?.displayName}'s profile?`}
                      content={`${member?.displayName} will be removed from the company immediately. You can’t undo this action.`}
                      open={deleteConfirmDialogOpen}
                      onConfirm={handleConfirmDelete}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={7}>
                        <TextField
                          id="firstName"
                          label="First name"
                          variant="outlined"
                          fullWidth
                          value={formik.values.firstName}
                          onChange={formik.handleChange}
                          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                          helperText={formik.touched.firstName && formik.errors.firstName}
                          // disabled={!canEditable}
                          disabled={true}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <TextField
                          id="lastName"
                          label="Last name"
                          variant="outlined"
                          fullWidth
                          value={formik.values.lastName}
                          onChange={formik.handleChange}
                          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                          helperText={formik.touched.lastName && formik.errors.lastName}
                          // disabled={!canEditable}
                          disabled={true}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="displayName"
                          label="Display name"
                          variant="outlined"
                          fullWidth
                          value={formik.values.displayName}
                          onChange={formik.handleChange}
                          error={formik.touched.displayName && Boolean(formik.errors.displayName)}
                          helperText={formik.touched.displayName && formik.errors.displayName}
                          // disabled={!canEditable}
                          disabled={true}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="mobileNumber"
                          label="Mobile number"
                          variant="outlined"
                          fullWidth
                          value={formik.values.mobileNumber}
                          onChange={formik.handleChange}
                          error={formik.touched.mobileNumber && Boolean(formik.errors.mobileNumber)}
                          helperText={formik.touched.mobileNumber && formik.errors.mobileNumber}
                          // disabled={!canEditable}
                          disabled={true}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="email"
                          label="Email"
                          variant="outlined"
                          fullWidth
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          error={formik.touched.email && Boolean(formik.errors.email)}
                          helperText={formik.touched.email && formik.errors.email}
                          // disabled={!canEditable}
                          disabled={true}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Box mb={1}>
                      <Typography variant="h6">Team</Typography>
                      <Box display="flex" alignItems="center">
                        <Typography style={{ marginRight: theme.spacing(1) }} variant="subtitle1">
                          Assigning staff to team
                        </Typography>
                        <Tooltip title={refData.screen.member['assigning-staff-to-branch']}>
                          <InfoIcon />
                        </Tooltip>
                      </Box>
                    </Box>
                    <Box p={2}>
                      <SelectedBranches branches={selectedBranches} />
                    </Box>
                  </Grid>
                  <Grid item xs={12} container>
                    <Grid item xs={12} container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="body1" color="textSecondary">
                          All teams ({formik.values.branches?.length ?? 0} selected)
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid item xs={12} container>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  checked={formik.values.branches.length === teams.length}
                                  onChange={(event, checked) => {
                                    handleBranchCheckAll(checked);
                                  }}
                                  name={'All teams'}
                                />
                              }
                              label={<Typography variant="body1">All team</Typography>}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Box pl={2}>
                              <Grid item xs={12} container>
                                {teams.map((team: any) => (
                                  <Grid key={team.teamId} item xs={12} sm={6}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          color="primary"
                                          name="branches"
                                          value={team.teamId}
                                          onChange={formik.handleChange}
                                          checked={formik.values.branches.includes(team.teamId)}
                                        />
                                      }
                                      label={<Typography variant="body1">{team.name}</Typography>}
                                    />
                                  </Grid>
                                ))}
                              </Grid>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" flexDirection="Column">
                      <Typography variant="h6">Role</Typography>
                      <RadioGroup name="role" value={formik.values.role} onChange={formik.handleChange}>
                        {roles.length > 0
                          ? roles.map((role, index) => (
                              <FormControlLabel
                                key={index}
                                style={{ marginLeft: 0 }}
                                value={role.value}
                                control={<Radio color="primary" />}
                                label={role.label}
                                checked={role.value === formik.values.role}
                              />
                            ))
                          : null}
                      </RadioGroup>
                      <Typography variant="caption" color="textSecondary">
                        Admins have rights to make edits
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Box mt={2}>
            <Button size="large" color="primary" variant="contained" type="submit">
              UPDATE
            </Button>
          </Box>
        </Grid>
      </Grid>
      <ProgressIndicator loading={formik.isSubmitting} />
    </form>
  );
}
