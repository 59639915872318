import { ReactNode } from 'react';
import { useSelected, useFocused, useSlateStatic, ReactEditor } from 'slate-react';
import LinkOffIcon from '@material-ui/icons/LinkOff';

import { removeLink } from 'shared/utilities/richTextHelpers';

import './link.scss';

const Link = ({
  attributes,
  element,
  children,
}: {
  attributes: unknown;
  element: {
    href: string;
  };
  children: ReactNode;
}) => {
  const editor = useSlateStatic();
  const selected = useSelected();
  const focused = useFocused();

  return (
    <span className="element-link">
      <a {...attributes} href={element.href}>
        {children}
      </a>
      {selected && focused && (
        <span className="popup" contentEditable={false}>
          <a href={element.href} rel="noreferrer" target="_blank">
            {element.href}
          </a>
          <button onClick={() => removeLink(editor as ReactEditor)}>
            <LinkOffIcon fontSize="small" />
          </button>
        </span>
      )}
    </span>
  );
};

export default Link;
