import React, { useRef, useState } from 'react';
import Moment from 'react-moment';
import { Typography, Card, CardContent, Box } from '@material-ui/core';
import { DataGrid, GridCellParams, GridColDef, GridSortModel, GridRowParams } from '@material-ui/data-grid';

import { useInternalAdminsCompaines } from '@dayone/hooks';
import { IInternalAdminCompanyModel } from '@dayone/models';
import { settingSlice, useAppDispatch } from '@dayone/redux';
import { ProgressIndicator } from 'shared/components/progressIndicator';
import { useSnackbar } from 'shared/components/hooks';
import ConfirmationDialog, { IConfirmationRef } from './components/ConfirmationDialog';

const commonColSettings = {
  disableColumnMenu: true,
};

const companyColumns: GridColDef[] = [
  { field: 'companyName', headerName: 'Company name', flex: 2, ...commonColSettings },
  { field: 'members', headerName: 'Members', flex: 1.2, ...commonColSettings },
  { field: 'teams', headerName: 'Teams', flex: 1, ...commonColSettings },
  { field: 'announcements', headerName: 'Announcements', flex: 1.6, ...commonColSettings },
  { field: 'tasks', headerName: 'Tasks', flex: 1, ...commonColSettings },
  { field: 'faqs', headerName: 'FAQs', flex: 1, ...commonColSettings },
  { field: 'repositories', headerName: 'Repositories', flex: 1.4, ...commonColSettings },
  {
    field: 'lastActivityDate',
    headerName: 'Last comment',
    flex: 1.5,
    renderCell: (params: GridCellParams) => (
      <Box pl={1}>{params.value ? <Moment format="D MMM YYYY">{params.value.toLocaleString()}</Moment> : ''}</Box>
    ),
    ...commonColSettings,
  },
];

const InformationOverview = () => {
  const state = useInternalAdminsCompaines();
  const companies: IInternalAdminCompanyModel[] = state.companies;
  const isLoading: boolean = state.isLoading;
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'companyName', sort: 'asc' }]);
  const [pageSize, setPageSize] = useState(50);
  const enqueueSnackbar = useSnackbar();
  const dialogRef = useRef<IConfirmationRef>(null);
  const dispatch = useAppDispatch();

  // Simple ref to share clicked row's data
  const selectedCompanyRef = useRef<IInternalAdminCompanyModel>();

  const onRowClick = (event: GridRowParams) => {
    const selectedCompany = event.row as IInternalAdminCompanyModel;
    const { companyName } = selectedCompany;

    selectedCompanyRef.current = selectedCompany;
    dialogRef.current?.toggle(`Are you sure you want to log on to “${companyName}”?`);
  };
  const onCancel = () => dialogRef.current?.toggle();
  const onConfirm = () => {
    dispatch(settingSlice.setActiveWorkspace(selectedCompanyRef.current?.id));
    enqueueSnackbar(`${selectedCompanyRef.current?.companyName} is now active!`, {
      variant: 'success',
    });

    dialogRef.current?.toggle();
  };

  return (
    <>
      <Card elevation={0}>
        <CardContent>
          <Typography variant="h6">Company Overview</Typography>
          <Box mx={-2} mb={-3}>
            <DataGrid
              autoHeight
              style={{ borderWidth: 0 }}
              /** start pagination configs */
              pagination
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
              rowsPerPageOptions={[10, 20, 50, 100, 200]}
              /** end pagination configs */
              rows={companies}
              columns={companyColumns}
              sortModel={sortModel}
              onSortModelChange={setSortModel}
              onRowClick={onRowClick}
            />
          </Box>
        </CardContent>
      </Card>
      <ProgressIndicator loading={isLoading} />
      <ConfirmationDialog ref={dialogRef} onCancel={onCancel} onConfirm={onConfirm} />
    </>
  );
};

export default InformationOverview;
