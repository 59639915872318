import React from 'react';
import { Typography, Icon, useTheme, Box } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useAppSelector, meSlice } from '@dayone/redux';
import { IMenuCategory } from 'shared/utilities/menuData';

const MenuCategory = (props: {
  category: IMenuCategory;
  callback: (input: { icon: string; label: string; url: string }) => void;
}) => {
  const { category, callback } = props;
  const theme = useTheme();
  const role = useAppSelector<any>(meSlice.selectRole);

  return (
    <List style={{ padding: 0, marginBottom: theme.spacing(2) }}>
      <Box m={2} mb={1}>
        <Typography variant="subtitle2">{category.categoryHeader}</Typography>
      </Box>
      {/* Role is option properties so if an menu item doesn't have role it means visible to every role */}
      {category.items.map(
        (item) =>
          (item?.role ? item.role === role : true) && (
            <ListItem button key={item.label} onClick={() => callback(item)}>
              <ListItemIcon>
                <Icon>{item.icon}</Icon>
              </ListItemIcon>
              <ListItemText
                style={{ wordBreak: 'break-word', margin: 0 }}
                primary={<Typography variant="body2">{item.label}</Typography>}
              />
            </ListItem>
          )
      )}
    </List>
  );
};

export default MenuCategory;
