import React from 'react';
import { Tooltip } from '@material-ui/core';
import { ITaskModel } from '@dayone/models';
import moment from 'moment';

export default function RecurrenceTooltip({ task, children }: { task: ITaskModel; children: React.ReactElement }) {
  let tooltip = 'One-off';

  switch (task.recurringType) {
    case 0:
      tooltip = `Repeats every ${task.recurringRepeat} day(s).`;
      break;
    case 1:
      tooltip = `Repeats every ${task.recurringRepeat} week(s)`;
      if (task.recurringDayOfWeek?.length) {
        tooltip += ` on ${task.recurringDayOfWeek?.map((day) => moment().weekday(day).format('ddd')).join(', ')}.`;
      } else tooltip += '.';
      break;
    case 2:
      tooltip = `Repeats every ${task.recurringRepeat} month(s).`;
      break;
    default:
      break;
  }
  if (task.endDate) tooltip += `\nEnds on ${moment(task.endDate).format('DD MMM')}.`;

  return <Tooltip title={<div style={{ whiteSpace: 'pre-line' }}>{tooltip}</div>}>{children}</Tooltip>;
}
