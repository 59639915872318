import React from 'react';
import { useAppDispatch, useAppSelector } from '@dayone/redux';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'shared/components/hooks';
import { ITeam } from '@dayone/models';
import { announcementSlice, teamSlice } from '@dayone/redux';
import AnnouncementForm from './components/AnnouncementForm';
import { IImage } from 'shared/components/uploadImage';

export default function CreateAnnouncement() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const teams = useAppSelector<any>(teamSlice.selectActiveTeams);
  const enqueueSnackbar = useSnackbar();

  const onFormSubmit = (values: {
    pin: boolean;
    message: string;
    branches: ITeam[];
    images: IImage[];
    isScheduled: boolean;
    broadcastDate?: string;
  }) => {
    dispatch(announcementSlice.createAnnouncement(values))
      .unwrap()
      .then(() => {
        const message = values.isScheduled
          ? 'The message has been scheduled successfully!'
          : 'The message has been broadcast successfully!';
        enqueueSnackbar(message, {
          variant: 'success',
        });
        navigate(-1);
      })
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      });
  };

  return (
    <AnnouncementForm
      displayBackButton={false}
      onBack={() => {
        navigate(-1);
      }}
      pinable
      branches={teams}
      showSubmitButton
      onFormSubmit={onFormSubmit}
    />
  );
}
