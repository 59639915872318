import { IRepositoryModel } from '@dayone/models';
import { repositorySlice, settingSlice, useAppDispatch, useAppSelector } from '@dayone/redux';
import { Box } from '@material-ui/core';
import React from 'react';
import { useSnackbar } from 'shared/components/hooks';
import { DeleteConfirmDialog } from 'shared/components/deleteConfirmDialog';

export default function DeleteButton(props: {
  children: JSX.Element | JSX.Element[];
  repository: IRepositoryModel;
  containerStyle?: React.CSSProperties;
  onDeleted?: () => void;
}) {
  const dispatch = useAppDispatch();
  const companyId = useAppSelector<any>(settingSlice.selectActiveWorkspaceID);
  const enqueueSnackbar = useSnackbar();

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleConfirmDelete = (confirm: boolean) => {
    setDialogOpen(false);
    if (confirm) {
      handleDelete();
    }
  };
  const handleDelete = () => {
    dispatch(
      repositorySlice.removeRepository({
        id: props.repository.id,
        companyId: companyId,
      })
    ).then(() => {
      enqueueSnackbar(`${props.repository.fileName} has been deleted!`, {
        variant: 'warning',
      });
      if (props.onDeleted) {
        props.onDeleted();
      }
    });
  };
  return (
    <>
      <Box style={props.containerStyle} onClick={handleOpenDialog}>
        {props.children}
      </Box>
      <DeleteConfirmDialog
        title={`Are you sure you want to delete "${props.repository.fileName}"?`}
        content="You can’t undo this action."
        open={dialogOpen}
        onConfirm={handleConfirmDelete}
      />
    </>
  );
}
