"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.setTeams = exports.selectTeamByID = exports.selectCurrentUserTeams = exports.selectTeamsOverview = exports.selectActiveTeams = exports.selectTeams = exports.deleteTeam = exports.updateTeam = exports.createTeam = exports.teamSliceConfig = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var firestore_1 = require("firebase/firestore");
var models_1 = require("@dayone/models");
var common_1 = require("@dayone/common");
var firestore;
function teamSliceConfig(_firebaseApp) {
    firestore = (0, firestore_1.getFirestore)(_firebaseApp);
}
exports.teamSliceConfig = teamSliceConfig;
// CRUD - Create, Read, Update, Delete
exports.createTeam = (0, toolkit_1.createAsyncThunk)('team/create', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var state, maxTeam, teams, activeTeams, teamDocRef;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                state = thunkAPI.getState();
                maxTeam = state.featureFlag.featureFlags.team.maxTeam;
                teams = state.team.teams;
                activeTeams = teams.filter(function (item) { return !item.isInactive; });
                if (maxTeam <= activeTeams.length)
                    throw new Error(common_1.validationErrors.default('Team.MaxTeam', { MAX_TEAM: maxTeam }));
                teamDocRef = (0, firestore_1.collection)(firestore, 'companies', payload.companyID, 'branches');
                return [4 /*yield*/, (0, firestore_1.addDoc)(teamDocRef, {
                        name: payload.team.name,
                        users: payload.team.users,
                    })];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); });
exports.updateTeam = (0, toolkit_1.createAsyncThunk)('team/update', function (payload) { return __awaiter(void 0, void 0, void 0, function () {
    var teamDocRef;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                teamDocRef = (0, firestore_1.doc)(firestore, 'companies', payload.companyID, 'branches', payload.team.teamId);
                return [4 /*yield*/, (0, firestore_1.updateDoc)(teamDocRef, {
                        name: payload.team.name,
                        users: payload.team.users,
                    })];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); });
exports.deleteTeam = (0, toolkit_1.createAsyncThunk)('team/delete', function (payload) { return __awaiter(void 0, void 0, void 0, function () {
    var teamDocRef;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                teamDocRef = (0, firestore_1.doc)(firestore, 'companies', payload.companyID, 'branches', payload.teamId);
                return [4 /*yield*/, (0, firestore_1.updateDoc)(teamDocRef, { isInactive: true })];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); });
var initialState = {
    initialised: false,
    teams: [],
};
var teamSlice = (0, toolkit_1.createSlice)({
    name: 'team',
    initialState: initialState,
    reducers: {
        setTeams: {
            reducer: function (state, action) {
                if (action.payload) {
                    state.teams = action.payload;
                    state.initialised = true;
                }
            },
            prepare: function (payload) {
                if (payload) {
                    var teamModels = payload.map(function (team) {
                        var _a, _b, _c;
                        return ({
                            teamId: team.teamId,
                            name: (_a = team.name) !== null && _a !== void 0 ? _a : '',
                            users: (_b = team.users) !== null && _b !== void 0 ? _b : [],
                            isInactive: (_c = team.isInactive) !== null && _c !== void 0 ? _c : false,
                        });
                    });
                    return { payload: teamModels };
                }
                return { payload: null };
            },
        },
    },
});
var compareTeam = function (team1, team2) {
    if (team1.name && team2.name) {
        var name1 = team1.name.toUpperCase();
        var name2 = team2.name.toUpperCase();
        return name1 < name2 ? -1 : name1 > name2 ? 1 : 0;
    }
    else {
        return 0;
    }
};
// Selectors
var selectTeams = function (state) { return state.team.teams; };
exports.selectTeams = selectTeams;
var selectActiveTeams = function (state) {
    return state.team.teams.filter(function (_a) {
        var isInactive = _a.isInactive;
        return !isInactive;
    });
};
exports.selectActiveTeams = selectActiveTeams;
var selectTasks = function (state) { return state.task.tasks; };
exports.selectTeamsOverview = (0, toolkit_1.createSelector)([exports.selectActiveTeams, selectTasks], function (teams, tasks) {
    if (!teams)
        return [];
    return __spreadArray([], teams, true).sort(compareTeam).map(function (team) { return (__assign(__assign({}, team), { dailyTask: tasks.filter(function (task) { return task.branchID === team.teamId && task.type === models_1.TaskType.daily; }).length, adhocTask: tasks.filter(function (task) { return task.branchID === team.teamId && task.type === models_1.TaskType.adHoc; }).length })); });
});
var selectCurrentUserTeams = function (state) {
    var uid = state.me.details.uid;
    var activeTeams = (0, exports.selectActiveTeams)(state);
    return (activeTeams || []).filter(function (branch) { return branch.users.includes(uid); });
};
exports.selectCurrentUserTeams = selectCurrentUserTeams;
exports.selectTeamByID = (0, toolkit_1.createSelector)(exports.selectTeams, function (teams) {
    return function callbackFunction(teamId) {
        return teams.find(function (team) { return team.teamId === teamId; }) || null;
    };
});
// Actions
exports.setTeams = teamSlice.actions.setTeams;
exports.default = teamSlice.reducer;
