import { Box } from '@material-ui/core';
import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { SecondaryNav } from 'shared/components/navigations';

import { TaskRoutes } from 'shared/utilities/routeConfig';
import { meSlice, useAppSelector } from '@dayone/redux';
import { UpdatePageTitle } from 'shared/components/navigations';

export default function TaskRoute() {
  const navigate = useNavigate();
  const role = useAppSelector<any>(meSlice.selectRole) as 'admin' | 'user' | '*' | null;
  const routes = TaskRoutes.filter((i) => i.role.includes('*') || i.role.includes(role));

  return (
    <React.Fragment>
      <SecondaryNav
        items={routes.filter((item) => !item.hiddenInNav)}
        callback={(item) => {
          navigate(item.url);
        }}
      />

      {/* <Route
        exact
        path={[
          `${path}`,
          `${path}/detail/:taskID`,
          `${path}/create`,
          `${path}/edit/:taskID`,
          `${path}/topic/detail/:topicID`,
          `${path}/topic/create/:taskID`,
          `${path}/topic/edit/:topicID`,
        ]}
        component={() => (
          <SecondaryNav
            items={items}
            callback={(item) => {
              console.log('TaskRoute SecondaryNav ', item);
              navigate(item.url);
            }}
          />
        )}
      /> */}
      <Box pb={5} />

      <Routes>
        {routes.map((item, index) => {
          const indexof = item.url.indexOf('/', 1);
          const pathURL = indexof > -1 ? item.url.substring(indexof) : '/';
          return (
            <Route
              path={pathURL}
              key={index}
              element={
                <>
                  <UpdatePageTitle title={item.pageTitle} />
                  <item.component />
                </>
              }
            />
          );
        })}
        {/* <Route exact path={`${path}`} component={TaskOverview} />
        <Route exact path={`${path}/detail/:taskID`} component={Task} />
        <Route exact path={`${path}/create`} component={EditTask} />
        <Route exact path={`${path}/edit/:taskID`} component={EditTask} />
        <Route exact path={`${path}/topic/detail/:topicID`} component={ThreadComment} />
        <Route exact path={`${path}/topic/create/:taskID`} component={CreateTaskThread} />
        <Route exact path={`${path}/topic/edit/:topicID`} component={UpdateTaskThread} /> */}
      </Routes>
    </React.Fragment>
  );
}
