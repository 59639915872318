import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import FullRegistration from 'screens/onboarding/FullRegistration';

export default function OnboardingRoute() {
  return (
    <Routes>
      <Route path={`/onboarding/fullregistration`} element={<FullRegistration />} />
      <Route path="*" element={<Navigate replace to="/onboarding/fullregistration" />} />
    </Routes>
  );
}
