import { useState } from 'react';
import {
  Box,
  Button,
  CardMedia,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { IBusinessState } from '@dayone/redux';

import FullRegistrationLayout from './FullRegistrationLayout';
import industries from '../helpers/industries.json';
import numberOfEmployees from '../helpers/numberOfEmployees.json';

const validationFormSchema = yup.object({
  name: yup.string().required('Business name is required'),
  companyLogoFile: yup.mixed(),
  industry: yup.string().typeError('Industry is required'),
  companySize: yup.number().typeError('Field is required'),
});

interface ICompanyProps {
  onUpdateInfo: (values: IBusinessState) => void;
  gotoNextStep: () => void;
  gotoPreStep: () => void;
}

const BusinessInfo = ({ onUpdateInfo, gotoNextStep, gotoPreStep }: ICompanyProps) => {
  const [file, setFile] = useState<string>();

  const formik = useFormik({
    initialValues: {
      name: '',
      companyLogoFile: null,
      industry: null,
      companySize: null,
    },
    validationSchema: validationFormSchema,
    onSubmit: (values: any) => {
      const { name, companyLogoFile, industry, companySize } = values;
      onUpdateInfo({
        companyLogoFile: companyLogoFile as File,
        businessInfo: {
          name,
          industry,
          companySize,
        },
      });
      gotoNextStep();
    },
  });

  const onFileChange = (e: any) => {
    const companyLogoPath = window.URL.createObjectURL(e.target.files[0]);
    setFile(companyLogoPath);
    formik.setFieldValue('companyLogoFile', e.target.files[0]);
  };

  return (
    <FullRegistrationLayout
      renderHeader={() => (
        <Box pl={[3, 0]} pr={[3, 0]}>
          <Typography variant="h4" align="center">
            Tell us more about your business.
          </Typography>
        </Box>
      )}
      gotoPreStep={gotoPreStep}
    >
      <Box p={2}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <TextField
                fullWidth
                variant="outlined"
                name="name"
                label="Name of business"
                helperText={
                  formik.touched.name && formik.errors.name
                    ? formik.errors.name
                    : 'Enter parent company name for multiple brands'
                }
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
              />
            </Grid>
            <Grid item>
              <Grid container justifyContent="space-around" alignItems="flex-end" spacing={3}>
                <Grid item xs={3}>
                  {file ? (
                    <CardMedia
                      image={file}
                      style={{ height: 96, width: 96, borderRadius: '4px', backgroundSize: 'contain' }}
                    />
                  ) : (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      style={{ backgroundColor: '#BDBDBD', height: 96, width: 96, borderRadius: '4px' }}
                    >
                      <ImageIcon style={{ color: '#fff' }} />
                    </Box>
                  )}
                </Grid>
                <Grid item>
                  <label htmlFor="upload-photo">
                    <input
                      id="upload-photo"
                      style={{ display: 'none' }}
                      name="upload-photo"
                      type="file"
                      accept="image/*"
                      onChange={onFileChange}
                    />
                    <Button variant="outlined" color="primary" startIcon={<CloudUploadIcon />} component="span">
                      UPLOAD COMPANY LOGO
                    </Button>
                  </label>
                </Grid>
              </Grid>
              {formik.touched.companyLogoFile && (
                <Box ml={1.5}>
                  <FormHelperText error>{formik.errors.companyLogoFile}</FormHelperText>
                </Box>
              )}
            </Grid>
            <Grid item>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="outlined-age-native-simple">Industry</InputLabel>
                <Select
                  name="industry"
                  label="Industry"
                  displayEmpty
                  value={formik.values.industry}
                  onChange={formik.handleChange}
                  error={formik.touched.industry && Boolean(formik.errors.industry)}
                >
                  <MenuItem disabled value="">
                    <em>Select industry</em>
                  </MenuItem>
                  {industries.map((item, index) => (
                    <MenuItem key={index + ''} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.industry && <FormHelperText error>{formik.errors.industry}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="outlined-age-native-simple">Number of employees</InputLabel>
                <Select
                  name="companySize"
                  label="Number of employees"
                  displayEmpty
                  value={formik.values.companySize}
                  onChange={formik.handleChange}
                  error={formik.touched.companySize && Boolean(formik.errors.companySize)}
                >
                  <MenuItem disabled value="">
                    <em>Select number of employees</em>
                  </MenuItem>
                  {numberOfEmployees.map((item, index) => (
                    <MenuItem key={index + ''} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.companySize && <FormHelperText error>{formik.errors.companySize}</FormHelperText>}
              </FormControl>
            </Grid>
          </Grid>
          <Grid item container justifyContent="center">
            <Grid item xs={12} sm={8} lg={8}>
              <Box pt={5}>
                <Button type="submit" fullWidth variant="contained" color="primary">
                  Complete registration
                </Button>
              </Box>
            </Grid>
            <Box pt={1}>
              <Typography color="textSecondary" variant="caption">
                You can come back and change this later
              </Typography>
            </Box>
          </Grid>
        </form>
      </Box>
    </FullRegistrationLayout>
  );
};

export default BusinessInfo;
