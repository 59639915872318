import {
  useTheme,
  Box,
  Grid,
  Button,
  TextField,
  useMediaQuery,
  Paper,
  CardContent,
  Card,
  Tooltip,
} from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useSnackbar } from 'notistack';
import { ITaskModel } from '@dayone/models';
import { taskSlice, topicSlice, useAppDispatch, useAppSelector } from '@dayone/redux';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useCallback } from 'react';
import { DeleteConfirmDialog } from 'shared/components/deleteConfirmDialog';
import refData from '@dayone/refdata';

export default function TaskThreadUpdate() {
  const theme = useTheme();
  const { topicID = '' } = useParams();
  const topic = useAppSelector<any>(topicSlice.selectTopicByID)(topicID);
  const task: ITaskModel | null = useAppSelector<any>(taskSlice.selectTaskByID)(topic?.taskID || undefined);
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

  const handleShowDeleteDialog = useCallback(() => {
    setDeleteDialogOpen(true);
  }, [setDeleteDialogOpen]);

  const initialValues = {
    name: topic?.topicName ?? '',
  };

  const handleDelete = useCallback(() => {
    if (topicID && topic && topic.taskID)
      try {
        dispatch(topicSlice.deleteTopic({ taskId: topic.taskID, topicId: topicID })).then(() => {
          navigate(`/tasks/detail/${topic?.taskID}`);
          enqueueSnackbar(`The comment topic has been deleted!`, {
            variant: 'warning',
            anchorOrigin: {
              vertical: matches ? 'bottom' : 'top',
              horizontal: matches ? 'center' : 'right',
            },
          });
        });
      } catch (error) {
        //Do something about error
      }
  }, [topic, topicID, dispatch, navigate, enqueueSnackbar, matches]);

  const onSubmit = (values: { name: string }) => {
    if (topicID)
      try {
        dispatch(
          topicSlice.updateTopic({ topicName: values.name, taskId: topic?.taskID || null, topicId: topicID })
        ).then(() => {
          navigate(-1);
          enqueueSnackbar(`The Comment Topic has been updated successfully!`, {
            variant: 'success',
            anchorOrigin: {
              vertical: matches ? 'bottom' : 'top',
              horizontal: matches ? 'center' : 'right',
            },
          });
        });
      } catch (error) {
        //Do something about error
      }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationFormSchema,
    enableReinitialize: true,
    onSubmit: onSubmit,
  });

  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={3}>
        {/* Back button */}
        <Grid item xs={12}>
          <Box pb={2}>
            <Button color="primary" startIcon={<ArrowBackIcon />} onClick={handleBack}>
              {`BACK`}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} lg={8}>
          <Paper elevation={0}>
            <DeleteConfirmDialog
              open={deleteDialogOpen}
              title="Are you sure you want to delete this comment topic?"
              content="All comments on this topic will be deleted. You can’t undo this action."
              onConfirm={(confirm) => {
                setDeleteDialogOpen(false);
                if (confirm) handleDelete();
              }}
            />
            <Card elevation={0}>
              <CardContent>
                <Box display="flex" justifyContent="flex-end" onClick={handleShowDeleteDialog} mb={2}>
                  <Tooltip title={refData.screen.responsibility['btn-delete-comment-topic']}>
                    <DeleteIcon color="secondary" />
                  </Tooltip>
                </Box>
                <TextField
                  fullWidth
                  label="Task name"
                  name="taskname"
                  disabled
                  value={task?.name ?? ''}
                  variant="outlined"
                />
                <Box pt={3} />
                <TextField
                  fullWidth
                  label="Comment topic"
                  name="name"
                  defaultValue={formik.values.name}
                  variant="outlined"
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </CardContent>
            </Card>
          </Paper>
        </Grid>
        {/* Submit Button */}
        <Grid item xs={12} sm={12} lg={8}>
          <Box pt={2} />
          <Button fullWidth={matches} type="submit" variant="contained" color="primary">
            Update
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

const validationFormSchema = yup.object({
  name: yup.string().required('Topic name is required'),
});
