import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import React from 'react';

export default function DeleteConfirmDialog(props: {
  title: string;
  content: string;
  open: boolean;
  onConfirm: (confirm: boolean) => void;
}) {
  const classes = useStyles();
  return (
    <Dialog
      classes={{
        paper: classes.paper,
      }}
      open={props.open}
      onClose={() => props.onConfirm(false)}
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{props.content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onConfirm(false)} color="primary">
          cancel
        </Button>
        <Button onClick={() => props.onConfirm(true)} color="primary" autoFocus>
          delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(2),
  },
}));
