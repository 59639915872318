import { useState } from 'react';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  TextField,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
  Box,
  createTheme,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { workspaceSlice, useAppDispatch, useAppSelector, internalAdminsSlice } from '@dayone/redux';
import { ProgressIndicator } from 'shared/components/progressIndicator';
import { useSnackbar } from 'shared/components/hooks';

const PHONE_REG_EXP = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{2,4})[-. )]*(\d{2,4})[-. ]*(\d{2,4})(?: *x(\d+))?\s*$/;
const MAXIMUM_NUMBER_OF_PHONE_NUMBER = 8;

const validationFormSchema = yup.object({
  companyID: yup.string().required('Company name is required'),
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  displayName: yup.string().required('Display name is required'),
  phoneNumber: yup
    .string()
    .required('Phone number is required')
    .min(MAXIMUM_NUMBER_OF_PHONE_NUMBER, `It should be at least ${MAXIMUM_NUMBER_OF_PHONE_NUMBER} numbers`)
    .matches(PHONE_REG_EXP, 'Phone number is not valid'),
  email: yup.string().required('Enter your email address.').email('Please enter a valid email address.'),
  password: yup.string().required('Enter your password.'),
});

const roles = [
  { value: 'admin', label: 'Admin' },
  { value: 'user', label: 'User' },
];

const InformationCreateUserAccount = () => {
  const theme = createTheme();
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);
  const workspaces = useAppSelector<any>(workspaceSlice.getWorkspaces);
  const enqueueSnackbar = useSnackbar();
  const formik = useFormik({
    initialValues: {
      companyID: '',
      firstName: '',
      lastName: '',
      displayName: '',
      phoneNumber: '',
      email: '',
      password: '',
      role: roles[0].value,
    },
    validationSchema: validationFormSchema,
    onSubmit: async (values: any) => {
      setLoading(true);
      dispatch(internalAdminsSlice.createUser({ values }))
        .unwrap()
        .then(() => {
          enqueueSnackbar('The user account has been created successfully!', {
            variant: 'success',
          });
        })
        .catch((ex: Error) => {
          enqueueSnackbar(ex.message, {
            variant: 'error',
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  return (
    <>
      <Card elevation={0}>
        <CardContent>
          <Grid container direction="column" spacing={3}>
            <Grid xs={12} item container>
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="outlined-age-native-simple">Company name</InputLabel>
                <Select
                  name="companyID"
                  label="Company name"
                  displayEmpty
                  value={formik.values.companyID}
                  onChange={formik.handleChange}
                  error={formik.touched.companyID && Boolean(formik.errors.companyID)}
                >
                  <MenuItem disabled value="-1">
                    <em>Select company name</em>
                  </MenuItem>
                  {workspaces.map((item: any, index: number) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.companyID && <FormHelperText error>{formik.errors.companyID}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item direction="row" container spacing={3}>
              <Grid md={8} xs={12} item>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="firstName"
                  label="First name"
                  helperText={formik.touched.firstName && formik.errors.firstName && formik.errors.firstName}
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                />
              </Grid>
              <Grid md={4} xs={12} item>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="lastName"
                  label="Last name"
                  helperText={formik.touched.lastName && formik.errors.lastName && formik.errors.lastName}
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                />
              </Grid>
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                variant="outlined"
                name="displayName"
                label="Display name"
                helperText={formik.touched.displayName && formik.errors.displayName && formik.errors.displayName}
                value={formik.values.displayName}
                onChange={formik.handleChange}
                error={formik.touched.displayName && Boolean(formik.errors.displayName)}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                variant="outlined"
                name="phoneNumber"
                label="Phone number"
                helperText={formik.touched.phoneNumber && formik.errors.phoneNumber && formik.errors.phoneNumber}
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
                error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                variant="outlined"
                name="email"
                label="Email"
                helperText={formik.touched.email && formik.errors.email && formik.errors.email}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                variant="outlined"
                name="password"
                label="Password"
                type="password"
                helperText={formik.touched.password && formik.errors.password && formik.errors.password}
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
              />
            </Grid>
            <Grid item>
              <Box display="flex" flexDirection="Column">
                <Typography variant="h6">Role</Typography>
                <RadioGroup name="role" value={formik.values.role} onChange={formik.handleChange}>
                  {roles.length > 0
                    ? roles.map((role, index) => {
                        return (
                          <FormControlLabel
                            key={index}
                            style={{ marginLeft: 0 }}
                            value={role.value}
                            control={<Radio color="primary" />}
                            label={role.label}
                            checked={role.value === formik.values.role}
                          />
                        );
                      })
                    : null}
                </RadioGroup>
                <Typography variant="caption" color="textSecondary">
                  Admins have rights to make edits
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Button
        onClick={() => formik.handleSubmit()}
        style={{ marginTop: theme.spacing(5) }}
        color="primary"
        variant="contained"
      >
        CREATE NEW USER ACCOUNT
      </Button>
      <ProgressIndicator loading={isLoading} />
    </>
  );
};

export default InformationCreateUserAccount;
