import React from 'react';
import { repositorySlice, useAppSelector } from '@dayone/redux';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Button,
  Typography,
  ListItemSecondaryAction,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';
import { IRepositoryModel } from '@dayone/models';
import moment from 'moment';

import RepositoryIcon from 'screens/repository/components/RepositoryIcon';

export default function SugestList(props: {
  text: string;
  sugestListShow: boolean;
  openFile: (file: IRepositoryModel) => void;
  openFolder: (path: string) => void;
  navigateToSearch: (text: string) => void;
}) {
  const classes = useStyles();

  const repositories: IRepositoryModel[] = useAppSelector<any>(repositorySlice.selectSharedRepositoriesWithCurrentUser);
  const { text } = props;
  const result = repositories
    .filter((file: IRepositoryModel) => text && text !== '' && file.fileName.toLowerCase().includes(text.toLowerCase()))
    .sort((a, b) => moment(b.updatedAt).unix() - moment(a.updatedAt).unix());

  const openFile = (file: IRepositoryModel) => {
    if (file.isFolder) {
      openFolder(file);
    } else {
      props.openFile(file);
    }
  };
  const openFolder = (folder: IRepositoryModel) => {
    const path = folder.folderPath + (folder.folderPath === '' ? '' : '/') + folder.fileName;
    props.openFolder(path);
  };

  return (
    <Paper className={classes.container}>
      <List component="nav" aria-label="main mailbox folders">
        {result.slice(0, 5).map((repo, index) => (
          <ListItem key={index}>
            <ListItemIcon>
              <RepositoryIcon fileType={repo.fileType} uri={repo.thumbnailUrl} />
            </ListItemIcon>
            <ListItemText
              primary={
                <div onClick={() => openFile(repo)} className={classes.searchTitle}>
                  {repo.fileName}
                </div>
              }
              secondary={
                <div onClick={() => props.openFolder(repo.folderPath)} className={classes.searchTitle}>
                  {repo.folderPath === '' ? 'All files' : repo.folderPath}
                </div>
              }
            />
            <ListItemSecondaryAction>
              <Typography variant="caption">
                {moment(repo.updatedAt).isValid() && moment(repo.updatedAt).format('DD MMM')}
              </Typography>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
        {result.length === 0 && <ListItem>No result</ListItem>}
        {result.length > 5 && (
          <ListItem>
            <Button
              onClick={() => props.navigateToSearch(text)}
              fullWidth
              color="primary"
              variant="outlined"
              title="See all results"
            >
              See all results
            </Button>
          </ListItem>
        )}
      </List>
    </Paper>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: theme.spacing(65),
      position: 'absolute',
      zIndex: 1,
      borderRadius: 0,
    },
    searchTitle: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      paddingRight: theme.spacing(3),
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  })
);
