import {
  Box,
  Grid,
  Typography,
  Button,
  useTheme,
  Paper,
  IconButton,
  useMediaQuery,
  Card,
  CardContent,
} from '@material-ui/core';
import { DataGrid, GridColDef, GridRowId } from '@material-ui/data-grid';
import DeleteIcon from '@material-ui/icons/Delete';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useState } from 'react';
import { DeleteConfirmDialog } from 'shared/components/deleteConfirmDialog';
import * as Yup from 'yup';
import { memberSlice, useAppSelector, useAppDispatch } from '@dayone/redux';
import InviteNewStaff from './InviteNewStaff';

const defaultTableColumn = {
  hideSortIcons: true,
  disableColumnMenu: true,
};

const columns: GridColDef[] = [
  { field: 'inviteeEmail', headerName: 'Email', flex: 2.8, ...defaultTableColumn },
  { field: 'status', headerName: 'Status', flex: 1, ...defaultTableColumn },
];

export default function PenddingInvitesSection() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const [selectedInvites, setSelectedInvites] = useState<GridRowId[]>([]);
  const invites = useAppSelector<any>(memberSlice.selectInvites);
  const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] = React.useState(false);
  const [inviteDialogOpen, setInviteDialogOpen] = React.useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  const handleConfirmDelete = (confirm: boolean) => {
    setDeleteConfirmDialogOpen(false);
    if (confirm) {
      const inviteIds = selectedInvites.map((id) => id.toLocaleString());
      dispatch(
        memberSlice.deleteInvites({
          inviteIds,
        })
      )
        .then(() => {
          enqueueSnackbar('The invite has been deleted!', {
            variant: 'warning',
            anchorOrigin: {
              vertical: isXs ? 'bottom' : 'top',
              horizontal: isXs ? 'center' : 'right',
            },
          });
        })
        .catch((err) => {});
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email('Invalid email').required('Email is required'),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = (value: { email: string }) => {
    const inviteeEmail = value.email.toLowerCase();
    dispatch(
      memberSlice.createInvite({
        inviteeEmail,
      })
    )
      .then((result) => {
        if (result && result.payload === 'emailalready') {
          enqueueSnackbar(`${inviteeEmail} is already a part of this workspace!`, {
            variant: 'warning',
            anchorOrigin: {
              vertical: isXs ? 'bottom' : 'top',
              horizontal: isXs ? 'center' : 'right',
            },
          });
          formik.resetForm();
        } else if (result && result.payload === 'invited') {
          formik.setFieldError('email', 'This email has been invited');
        } else {
          formik.resetForm();
          enqueueSnackbar('The invitation has been sent successfully', {
            variant: 'success',
            anchorOrigin: {
              vertical: isXs ? 'bottom' : 'top',
              horizontal: isXs ? 'center' : 'right',
            },
          });
        }
      })
      .catch((err) => {})
      .finally(() => {
        formik.setSubmitting(false);
      });
  };

  const closeInviteDialog = () => {
    setInviteDialogOpen(false);
  };

  return (
    <Box px={{ xs: 2, sm: 0 }}>
      <Box mb={3}>
        <Typography variant="h5">Pending Invites</Typography>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Paper elevation={0} square>
            <Card elevation={0}>
              <CardContent>
                {selectedInvites.length ? (
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="body1" color="primary">
                      {selectedInvites.length} selected
                    </Typography>
                    <IconButton
                      style={{ padding: 4 }}
                      size="small"
                      aria-label="delete"
                      onClick={() => setDeleteConfirmDialogOpen(true)}
                    >
                      <DeleteIcon color="action" />
                    </IconButton>
                    <DeleteConfirmDialog
                      title="Are you sure you want to delete invite?"
                      content="The invite will be deleted immediately. You can’t undo this action."
                      open={deleteConfirmDialogOpen}
                      onConfirm={handleConfirmDelete}
                    />
                  </Box>
                ) : (
                  <Typography variant="h6">Invite List</Typography>
                )}
              </CardContent>
            </Card>
            <DataGrid
              autoHeight
              style={{ borderWidth: 0 }}
              /** start pagination configs */
              pagination
              page={page}
              onPageChange={setPage}
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
              rowsPerPageOptions={[5, 10, 25]}
              /** end pagination configs */
              rows={invites}
              columns={columns}
              checkboxSelection
              selectionModel={selectedInvites}
              onSelectionModelChange={setSelectedInvites}
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Button
            size="large"
            onClick={() => {
              setInviteDialogOpen(true);
            }}
            color="primary"
            variant="contained"
          >
            INVITE NEW STAFF
          </Button>
        </Grid>
      </Grid>
      <InviteNewStaff open={inviteDialogOpen} onClose={closeInviteDialog}></InviteNewStaff>
    </Box>
  );
}
