import { IBusinessState, meSlice, useAppDispatch } from '@dayone/redux';
import { useCallback, useState } from 'react';
import PersonalInfo from './components/PersonalInfo';
import AccountType from './components/AccountType';
import BusinessInfo from './components/BusinessInfo';
import RegistrationComplete from './components/RegistrationComplete';
import { Steps } from './helpers/registrationSteps';

export default function FullRegistration() {
  const dispatch = useAppDispatch();

  const [step, setStep] = useState<Steps>(Steps.PersonalInfo);

  const gotoStep = useCallback((stepId: Steps) => () => setStep(stepId), [setStep]);

  const onUpdateBusinessInfo = useCallback(
    (businessData: IBusinessState) => dispatch(meSlice.setBusinessInfo(businessData)),
    [dispatch]
  );

  const onChoosesAccountType = useCallback(
    (accountType: string) => {
      dispatch(
        meSlice.setAccountInfo({
          accountType,
        })
      );

      setStep(accountType as Steps);
    },
    [setStep, dispatch]
  );

  const setFullRegistration = useCallback(() => {
    dispatch(meSlice.setFullRegistration());
  }, [dispatch]);

  const formSteps = () => {
    switch (step) {
      case Steps.PersonalInfo:
        return (
          <PersonalInfo
            gotoNextStep={(isRegistrationComplete) => {
              setStep(isRegistrationComplete ? Steps.RegistrationComplete : Steps.AccountType);
              // setStep(Steps.RegistrationComplete);
            }}
          />
        );
      case Steps.AccountType:
        return <AccountType gotoPreStep={gotoStep(Steps.PersonalInfo)} onChoosesAccountType={onChoosesAccountType} />;
      case Steps.BusinessInfo:
        return (
          <BusinessInfo
            onUpdateInfo={onUpdateBusinessInfo}
            gotoNextStep={gotoStep(Steps.RegistrationComplete)}
            gotoPreStep={gotoStep(Steps.AccountType)}
          />
        );
      case Steps.EmployeeInfo:
      case Steps.RegistrationComplete:
        return <RegistrationComplete gotoDashboard={setFullRegistration} />;
    }
  };

  return formSteps();
}
