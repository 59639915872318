"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.reset = exports.setTaskTypeFilterSelected = exports.setActiveBranchID = exports.setPageTitle = exports.setActiveWorkspace = exports.initializeDefaultSettings = exports.selectActiveTeam = exports.selectCurrentUserTeams = exports.selectActiveTeamID = exports.selectActiveWorkspaceID = exports.selectActiveWorkspace = exports.selectCurrentWorkspaceID = exports.selectWorkspaces = exports.selectTaskTypeFilterSelected = exports.selectPageTitle = exports.selectWebInitialised = exports.selectInitialised = exports.settingSliceConfig = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var firebaseApp;
var settingSliceConfig = function (_firebaseApp) {
    // eslint-disable-next-line
    firebaseApp = _firebaseApp;
};
exports.settingSliceConfig = settingSliceConfig;
var initialState = {
    activeWorkspaceID: 'undefined',
    activeBranchID: '',
    pageTitle: 'Dashboard',
    taskTypeFilterSelected: null,
};
var settingSlice = (0, toolkit_1.createSlice)({
    name: 'setting',
    initialState: initialState,
    reducers: {
        reset: function () { return initialState; },
        setActiveWorkspace: function (state, action) {
            state.activeWorkspaceID = action.payload;
        },
        setPageTitle: function (state, action) {
            state.pageTitle = action.payload;
        },
        setActiveBranchID: function (state, action) {
            state.activeBranchID = action.payload;
        },
        setTaskTypeFilterSelected: function (state, action) {
            state.taskTypeFilterSelected = action.payload;
        },
    },
    extraReducers: function (builder) {
        // eslint-disable-next-line
        builder.addCase('workspace/setWorkspaces', function (state, action) {
            // console.log('settings/setWorkspaces/fulfilled', state.activeWorkspace, { state, action });
            // const coms = action.payload as ICompany[];
            // if (!state.activeWorkspaceID && coms && coms.length > 0) {
            //   console.log('setActiveWorkspace', coms[0].id);
            //   // state.activeWorkspaceID = coms[0].id;
            // }
        });
    },
});
// Selectors
var selectInitialised = function (state) {
    return state.faq.initialised &&
        state.faqCategory.initialised &&
        state.announcement.initialised &&
        state.team.initialised &&
        state.promo.initialized &&
        state.member.initialised &&
        state.task.initialised &&
        state.workspace.initialised &&
        state.repository.initialized &&
        state.playbook.initialized;
};
exports.selectInitialised = selectInitialised;
var selectWebInitialised = function (state) {
    return state.faq.initialised &&
        state.faqCategory.initialised &&
        state.announcement.initialised &&
        state.team.initialised &&
        state.member.initialised &&
        state.task.initialised &&
        state.workspace.initialised &&
        state.repository.initialized;
};
exports.selectWebInitialised = selectWebInitialised;
var selectPageTitle = function (state) { return state.setting.pageTitle; };
exports.selectPageTitle = selectPageTitle;
var selectTaskTypeFilterSelected = function (state) { return state.setting.taskTypeFilterSelected; };
exports.selectTaskTypeFilterSelected = selectTaskTypeFilterSelected;
var selectWorkspaces = function (state) {
    return state.workspace.workspaces;
};
exports.selectWorkspaces = selectWorkspaces;
var selectCurrentWorkspaceID = function (state) { return state.setting.activeWorkspaceID; };
exports.selectCurrentWorkspaceID = selectCurrentWorkspaceID;
exports.selectActiveWorkspace = (0, toolkit_1.createSelector)([exports.selectCurrentWorkspaceID, exports.selectWorkspaces], function (workspaceID, workspaces) {
    if (workspaces)
        return workspaces.find(function (ws) { return ws.id === workspaceID; });
    return undefined;
});
exports.selectActiveWorkspaceID = (0, toolkit_1.createSelector)([exports.selectActiveWorkspace], function (workspace) {
    if (workspace)
        return workspace.id;
    return 'undefined';
});
var selectActiveTeamID = function (state) { return state.setting.activeBranchID; };
exports.selectActiveTeamID = selectActiveTeamID;
var selectCurrentUserTeams = function (state) {
    var uid = state.me.details.uid;
    return (state.team.teams || []).filter(function (branch) { return branch.users.includes(uid) && !(branch === null || branch === void 0 ? void 0 : branch.isInactive); });
};
exports.selectCurrentUserTeams = selectCurrentUserTeams;
exports.selectActiveTeam = (0, toolkit_1.createSelector)([exports.selectActiveTeamID, exports.selectCurrentUserTeams], function (activeTeamID, currentUserTeams) {
    // If user haven't been assigned to any branches
    if (!(currentUserTeams === null || currentUserTeams === void 0 ? void 0 : currentUserTeams.length)) {
        return null;
    }
    if (activeTeamID) {
        var activeTeam = currentUserTeams.find(function (_a) {
            var teamId = _a.teamId;
            return teamId === activeTeamID;
        });
        // Make sure that active branch id save in redux is not deleted on the database.
        if (activeTeam) {
            return activeTeam;
        }
        else {
            return currentUserTeams[0];
        }
    }
    return null;
});
// Thunks
/**
 * Just a simple thunk to initilize default settings values on the first run
 */
exports.initializeDefaultSettings = (0, toolkit_1.createAsyncThunk)('settings/initializeDefaultSettings', function (payload, thunkAPI) {
    var state = thunkAPI.getState();
    var activeTeamID = (0, exports.selectActiveTeamID)(state);
    if (activeTeamID) {
        return;
    }
    var teamsBelongToUser = (0, exports.selectCurrentUserTeams)(state);
    if (teamsBelongToUser === null || teamsBelongToUser === void 0 ? void 0 : teamsBelongToUser.length) {
        thunkAPI.dispatch(settingSlice.actions.setActiveBranchID(teamsBelongToUser[0].teamId));
    }
});
exports.setActiveWorkspace = (_a = settingSlice.actions, _a.setActiveWorkspace), exports.setPageTitle = _a.setPageTitle, exports.setActiveBranchID = _a.setActiveBranchID, exports.setTaskTypeFilterSelected = _a.setTaskTypeFilterSelected, exports.reset = _a.reset;
exports.default = settingSlice.reducer;
