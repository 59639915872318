import { Box, CircularProgress } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Pagination from '@material-ui/lab/Pagination';
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { useLogEventWithViewDuration } from 'shared/components/hooks';
import GAEvents from 'shared/utilities/events';

export default function LightBoxPdf(props: { open: boolean; onClose: () => void; url: string; alt?: string }) {
  const pageRef = useRef<HTMLDivElement>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [zoom, setZoom] = useState(1);
  const [minWidth, setMinWidth] = useState(window.innerHeight * 0.9 * 0.75);

  const height = window.innerHeight * 0.9;

  useLogEventWithViewDuration(GAEvents.repository_view_document, {
    fileType: 'Pdf',
  });

  const updatePageSize = useCallback(() => {
    const pageEl = pageRef.current;
    if (pageEl === null) return;
    const scaleValue = (window.innerHeight * 0.9) / pageEl.clientHeight;
    setZoom(scaleValue);
    setMinWidth(pageEl.clientWidth * scaleValue);
  }, []);

  const onDocumentLoadSuccess = useCallback((info: { numPages: number }) => {
    setNumPages(info.numPages);
  }, []);

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPageNumber(newPage);
  }, []);

  useEffect(() => {
    setIsLoaded(false);
  }, [props.url, numPages]);

  useLayoutEffect(() => {
    if (!isLoaded) return;
    updatePageSize();
  }, [numPages, isLoaded, updatePageSize]);

  const onRenderSuccess = () => setIsLoaded(true);

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <Box
        position="absolute"
        top="50%"
        left="50%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        style={{
          transform: 'translate(-50%, -50%)',
          backgroundColor: '#fff',
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems={isLoaded ? undefined : 'center'}
          height={height}
          minWidth={isLoaded ? 'unset' : minWidth}
          style={{
            overflowY: 'hidden',
          }}
        >
          <Box style={{ zoom }}>
            <Document loading={<CircularProgress />} file={props.url} onLoadSuccess={onDocumentLoadSuccess}>
              <Page inputRef={pageRef} pageNumber={pageNumber} onRenderSuccess={onRenderSuccess} />
            </Document>
          </Box>
        </Box>
        {isLoaded && (
          <Box display="flex" justifyContent="center" borderTop={1} paddingTop={1} paddingBottom={1}>
            <Pagination color="primary" count={numPages} page={pageNumber} onChange={handleChangePage} />
          </Box>
        )}
      </Box>
    </Modal>
  );
}
