import React from 'react';
import { Box, Button } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

export default function BackButton(props: { onClick?: () => void }) {
  const navigate = useNavigate();
  const handleNavigateBack = () => {
    if (props.onClick) {
      props.onClick();
    } else {
      navigate(-1);
    }
  };
  return (
    <Box marginLeft={{ xs: 2, sm: 0 }} mb={2}>
      <Button color="primary" onClick={handleNavigateBack} startIcon={<ArrowBackIcon />}>
        Back
      </Button>
    </Box>
  );
}
