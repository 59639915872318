import { Button, Grid, TextField, Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useFormik } from 'formik';

import { ProgressIndicator } from 'shared/components/progressIndicator';
import { useNavigate } from 'react-router-dom';

import countryCode, { ICountryCode, countryToFlag, labelOrIso } from 'shared/utilities/countryCode';
import { phoneRegisterSchema as validationSchema } from 'shared/utilities/schemas';
import pageError from 'shared/utilities/phoneLoginPageError';

export interface IFormikInitialValue {
  countryCode: ICountryCode;
  phoneNumber: string;
}
interface IProps {
  errorMessage: string;
  onSuccess: (phoneNumber: string) => void;
}

const initialValues: IFormikInitialValue = {
  countryCode: { iso: 'SG', label: 'Singapore', code: '65' },
  phoneNumber: '',
};

export default function PhoneRegister(props: IProps) {
  const navigate = useNavigate();
  const { errorMessage } = props;

  const onSubmit = async (values: IFormikInitialValue) => {
    const phoneWithCountryCode = `+${values.countryCode.code}${values.phoneNumber}`;
    props.onSuccess(phoneWithCountryCode);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: onSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      {formik.isSubmitting && <ProgressIndicator />}
      <div id="recaptchaContainer"></div>
      <Grid container justifyContent="center">
        <Grid item md={12} xs={12}>
          <Typography variant="h4" align="center">{`Let's get started!`}</Typography>
          <Typography variant="h4" align="center">{`Register or sign in with your phone number.`}</Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <Box px={5}>
            <Grid item container direction="column" spacing={3}>
              <Grid item>
                {errorMessage && (
                  <Alert variant="outlined" severity="error">
                    {pageError(errorMessage)}
                  </Alert>
                )}
              </Grid>
              <Grid item>
                <Autocomplete
                  autoSelect
                  fullWidth
                  options={countryCode}
                  onChange={(event, value) => formik.setFieldValue('countryCode', value)}
                  value={formik.values.countryCode}
                  defaultValue={formik.values.countryCode}
                  getOptionLabel={(option) => `${labelOrIso(option)} (+${option.code} )`}
                  getOptionSelected={(option, value) => (value ? option.code === value.code : true)}
                  renderOption={(option) => (
                    <Box display="flex" alignItems="center" textOverflow="ellipsis">
                      {`${countryToFlag(option.iso)} ${labelOrIso(option)} (+${option.code})`}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Country"
                      variant="outlined"
                      error={formik.touched.countryCode && Boolean(formik.errors.countryCode)}
                      helperText={formik.touched.countryCode && formik.errors.countryCode}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  placeholder="Phone number"
                  variant="outlined"
                  type="tel"
                  name="phoneNumber"
                  label="Phone number"
                  defaultValue={formik.values.phoneNumber}
                  onChange={formik.handleChange}
                  error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                  helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                />
              </Grid>
              <Grid item>
                <Box px={5} mt={1}>
                  <Button fullWidth type="submit" variant="contained" color="primary" disabled={formik.isSubmitting}>
                    Verify Number
                  </Button>
                </Box>
              </Grid>
              <Grid item>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Button color="primary" onClick={() => navigate('../email')}>
                    Already a user? login via email
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
}
