"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePlaybook = void 0;
var react_1 = require("react");
var firestore_1 = require("firebase/firestore");
var firestore;
function PlayBookStateConfig(_firebaseApp) {
    firestore = (0, firestore_1.getFirestore)(_firebaseApp);
}
exports.default = PlayBookStateConfig;
function usePlaybook() {
    var _a = (0, react_1.useState)(), playbooks = _a[0], setPlaybooks = _a[1];
    (0, react_1.useEffect)(function () {
        var unsubscribe = null;
        unsubscribe = (0, firestore_1.onSnapshot)((0, firestore_1.collection)(firestore, 'playbooks'), function (querySnapshot) {
            var docs = [];
            querySnapshot.forEach(function (doc) {
                docs.push(__assign(__assign({}, doc.data()), { id: doc.id }));
            });
            setPlaybooks(docs);
        });
        return function () {
            unsubscribe && unsubscribe();
        };
    }, []);
    return playbooks;
}
exports.usePlaybook = usePlaybook;
