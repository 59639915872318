import { Avatar, useTheme } from '@material-ui/core';
import {
  Folder as FolderIcon,
  PictureAsPdf as PictureAsPdfIcon,
  PlayCircleFilled as PlayCircleFilledIcon,
  Image as ImageIcon,
} from '@material-ui/icons';
import { FileType } from '@dayone/models';

export default function RepositoryIcon(props: { fileType: FileType; uri?: string }) {
  const { fileType, uri } = props;
  const theme = useTheme();

  switch (fileType) {
    case 'Image':
      if (!uri) return <ImageIcon style={{ fill: theme.palette.action.active }} fontSize="large" />;
      else
        return (
          <Avatar
            variant="square"
            style={{
              width: theme.spacing(4),
              height: theme.spacing(4),
            }}
            src={uri}
          />
        );

    case 'Video':
      if (!uri) return <PlayCircleFilledIcon style={{ fill: theme.palette.action.active }} fontSize="large" />;
      else
        return (
          <Avatar
            variant="square"
            style={{
              width: theme.spacing(4),
              height: theme.spacing(4),
            }}
            src={uri}
          />
        );
    case 'Pdf':
      if (!uri) return <PictureAsPdfIcon style={{ fill: theme.palette.error.dark }} fontSize="large" />;
      else
        return (
          <Avatar
            variant="square"
            style={{
              width: theme.spacing(4),
              height: theme.spacing(4),
            }}
            src={uri}
          />
        );
    default:
      return <FolderIcon style={{ fill: theme.palette.warning.light }} fontSize="large" />;
  }
}
