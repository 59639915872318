import { Box } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { useLogEventWithViewDuration } from 'shared/components/hooks';
import GAEvents from 'shared/utilities/events';

export default function LightBox(props: {
  type?: 'Image' | 'Video';
  open: boolean;
  onClose: () => void;
  url: string;
  alt?: string;
}) {
  const type = props.type ?? 'Image';

  useLogEventWithViewDuration(GAEvents.repository_view_document, {
    fileType: type,
  });

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <Box
        position="absolute"
        top="50%"
        left="50%"
        display="flex"
        justifyContent="center"
        style={{
          transform: 'translate(-50%, -50%)',
        }}
      >
        {type === 'Image' ? (
          <img alt={props.alt} style={{ maxWidth: '90vw', maxHeight: '90vh', objectFit: 'contain' }} src={props.url} />
        ) : type === 'Video' ? (
          <video controls style={{ maxWidth: '90vw', maxHeight: '90vh' }}>
            <source src={props.url} />
          </video>
        ) : null}
      </Box>
    </Modal>
  );
}
