import { FC } from 'react';
import { Box, Typography } from '@material-ui/core';

import { INotificationModel } from '@dayone/models';
import NotificationItem from './NotificationItem';

interface Props {
  name: string;
  notifications: INotificationModel[];
}

const NotificationList: FC<Props> = ({ name, notifications }) => {
  if (!notifications || !notifications.length) return null;

  return (
    <Box display="flex" flexDirection="column" mt={4}>
      <Box mb={1}>
        <Typography style={{ fontSize: 20, fontWeight: 500 }}>{name}</Typography>
      </Box>
      {notifications.map((notification) => (
        <Box mt={1} key={notification.id}>
          <NotificationItem name={name} item={notification} />
        </Box>
      ))}
    </Box>
  );
};

export default NotificationList;
