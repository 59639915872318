"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRepository = void 0;
var react_1 = require("react");
var firestore_1 = require("firebase/firestore");
var firebaseApp;
var firestore;
function RepositoryStateConfig(_firebaseApp) {
    firebaseApp = _firebaseApp;
    firestore = (0, firestore_1.getFirestore)(_firebaseApp);
}
exports.default = RepositoryStateConfig;
// eslint-disable-next-line
// const signedUrlForRepository = (docs: IRepository[]) => {
//   const nearlyExpireRepositories = docs
//     .filter((doc) => {
//       if (doc.isFolder) return false;
//       const matches = !!doc.fileUrl && doc.fileUrl.match(/Expires=(\d+)/);
//       if (!!matches && matches.length > 1) {
//         const expires = matches[1] as string;
//         const expireDate = new Date(+expires * 1000);
//         const dayBeforeExpires = Math.round(((expireDate.getTime() - new Date().getTime()) / 1000) * 60 * 60 * 24);
//         return dayBeforeExpires <= 0;
//       }
//       return true;
//     })
//     .map((doc) => ({
//       id: doc.id,
//       storageRef: doc.storageRef ?? '',
//     }));
//   if (nearlyExpireRepositories.length > 0)
//     callable
//       .signedUrlRepository(firebaseApp, { repositories: nearlyExpireRepositories })
//       .catch((error) => console.log('signedUrlRepository', error));
// };
/**
 * Firebase hook for getting members
 * This hook is for getting the user's collection member based on company
 * @param {string | null} companyId
 * @returns Firestore team
 *
 */
function useRepository(companyId) {
    var _a = (0, react_1.useState)(), repositories = _a[0], setRepositories = _a[1];
    (0, react_1.useEffect)(function () {
        var unsubscribe = null;
        if (companyId) {
            var repositoriesRef = (0, firestore_1.collection)(firestore, 'companies', companyId, 'repositories');
            unsubscribe = (0, firestore_1.onSnapshot)((0, firestore_1.query)(repositoriesRef, (0, firestore_1.orderBy)('createdAt', 'desc')), function (snapshot) {
                var docs = [];
                snapshot.forEach(function (doc) {
                    docs.push(__assign({ id: doc.id }, doc.data()));
                });
                setRepositories(docs);
                // update signedUrl for download feature
                // signedUrlForRepository(docs);
            });
        }
        return function () {
            unsubscribe && unsubscribe();
        };
    }, [companyId]);
    return repositories;
}
exports.useRepository = useRepository;
