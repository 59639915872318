"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAccounts = exports.useAccount = void 0;
var react_1 = require("react");
var firestore_1 = require("firebase/firestore");
var firestore;
function AccountStateConfig(_firebaseApp) {
    firestore = (0, firestore_1.getFirestore)(_firebaseApp);
}
exports.default = AccountStateConfig;
// MUST MIRROR THE DATA STRUCTURE OF FIREBASE
/**
 * Firebase hook for getting companies
 * This hook is for getting the user's account
 * @param {string | null} uid
 * @returns Firestore account
 *
 */
var useAccount = function (uid) {
    var _a = (0, react_1.useState)(null), account = _a[0], setAccount = _a[1];
    (0, react_1.useEffect)(function () {
        var unsubscribe = null;
        if (uid) {
            unsubscribe = (0, firestore_1.onSnapshot)((0, firestore_1.doc)(firestore, 'accounts', uid), function (doc) {
                if (doc.exists()) {
                    setAccount(doc.data());
                }
            });
        }
        return function () {
            unsubscribe && unsubscribe();
        };
    }, [uid]);
    return account;
};
exports.useAccount = useAccount;
function useAccounts(uids) {
    var _a = (0, react_1.useState)(), accounts = _a[0], setAccounts = _a[1];
    (0, react_1.useEffect)(function () {
        var unsubscribe = null;
        if (uids && uids.length > 0) {
            var q = (0, firestore_1.query)((0, firestore_1.collection)(firestore, 'accounts'), (0, firestore_1.where)((0, firestore_1.documentId)(), 'in', uids));
            unsubscribe = (0, firestore_1.onSnapshot)(q, function (snapshot) {
                var docs = [];
                snapshot.docs.forEach(function (doc) {
                    docs.push(__assign(__assign({}, doc.data()), { id: doc.id }));
                });
                setAccounts(docs);
            });
        }
        return function () {
            unsubscribe && unsubscribe();
        };
    }, [JSON.stringify(uids)]);
    return accounts;
}
exports.useAccounts = useAccounts;
