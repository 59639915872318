/**
 * Interface for JSON for populating country code
 * @interface
 * @property {string} icon
 * @property {string} label
 * @property {string} url
 */
export interface IMenuCategory {
  categoryHeader: string;
  items: {
    icon: string;
    label: string;
    url: string;
    role?: 'admin' | 'internalAdmin' | 'user';
  }[];
}

/**
 * Helper JSON for populating menu
 * @property {string} icon
 * @property {string} label
 * @property {string} url
 */
const menuData: IMenuCategory[] = [
  {
    categoryHeader: 'App',
    items: [
      { icon: 'group', label: 'Teams', url: '/teams' },
      { icon: 'announcement', label: 'Announcements', url: '/announcements' },
      { icon: 'school', label: 'Knowledge', url: '/knowledge' },
      { icon: 'playlist_add_check', label: 'Responsibilities', url: '/tasks' },
      { icon: 'inventory', label: 'Repository', url: '/repository' },
    ],
  },
  {
    categoryHeader: 'Management',
    items: [
      { icon: 'apps', label: 'App', url: '/dashboard' },
      { icon: 'group_add', label: 'Members', url: '/members' },
    ],
  },
  // {
  //   categoryHeader: 'Analytics',
  //   items: [{ icon: 'group_add', label: 'Members', url: '/members' }],
  // },
  {
    categoryHeader: 'Internal Admin',
    items: [
      { icon: 'library_books', label: 'Information', url: '/information', role: 'internalAdmin' },
      { icon: 'auto_stories', label: 'Playbook', url: '/playbook', role: 'internalAdmin' },
      { icon: 'bug_report', label: 'Debug', url: '/debug', role: 'internalAdmin' },
    ],
  },
];

export default menuData;
