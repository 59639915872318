import { Box, Button, Grid, TextField, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { IAccountState, meSlice, useAppDispatch, useAppSelector } from '@dayone/redux';
import FullRegistrationLayout from './FullRegistrationLayout';
import { useEffect } from 'react';
import { ProgressIndicator } from 'shared/components/progressIndicator';
import { signOut } from '@dayone/firebase';

const validationFormSchema = yup.object({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name required'),
  displayName: yup.string().required('Display name is required'),
  email: yup.string().required('Enter your email address').email('Please enter a valid email address'),
});

interface IPersonalInfoProps {
  gotoNextStep: (isRegistrationComplete: boolean) => void;
}

const PersonalInfo = ({ gotoNextStep }: IPersonalInfoProps) => {
  const account = useAppSelector<any>(meSlice.selectAccount);
  const dispatch = useAppDispatch();
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      displayName: '',
      email: '',
    },
    validationSchema: validationFormSchema,
    onSubmit: (values: IAccountState) => {
      if (account === null) return;

      if (
        values.firstName !== account.firstName ||
        values.lastName !== account.lastName ||
        values.displayName !== account.displayName ||
        values.email !== account.email
      ) {
        dispatch(meSlice.setAccountInfo(values))
          .unwrap()
          .then(() => {
            formik.setSubmitting(false);
            gotoNextStep((account?.companies?.length ?? 0) > 0);
          })
          .catch((err: any) => {
            formik.setSubmitting(false);
            switch (err.code) {
              case 'auth/email-already-in-use':
                formik.setFieldError('email', 'Email address is already being used. Please try another email');
                break;
              case 'auth/requires-recent-login':
                formik.setFieldError('email', 'Session is timeout, please login again.');
                setTimeout(() => {
                  (async () => {
                    dispatch({ type: 'logout/clearData', payload: {} });
                    signOut();
                  })();
                }, 2000);
                break;
            }
          })
          .finally(() => {});
      } else {
        gotoNextStep((account?.companies?.length ?? 0) > 0);
      }
    },
  });

  useEffect(() => {
    if (account === null) return;

    formik.setValues({
      firstName: account.firstName,
      lastName: account.lastName,
      displayName: account.displayName,
      email: account.email,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  return (
    <FullRegistrationLayout
      renderHeader={() => (
        <Box pl={[1.5, 3]} pr={[1.5, 3]}>
          <Typography variant="h4" align="center">
            Welcome! Tell us more about yourself.
          </Typography>
        </Box>
      )}
    >
      <Box p={2}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container direction="column" spacing={3}>
            <Grid item container direction="row" spacing={3}>
              <Grid item xs={7}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="firstName"
                  label="First name"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                  helperText={formik.touched.firstName && formik.errors.firstName}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="lastName"
                  label="Last name"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />
              </Grid>
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                variant="outlined"
                name="displayName"
                label="Display name"
                helperText={
                  formik.touched.displayName && formik.errors.displayName
                    ? formik.errors.displayName
                    : 'How do your colleagues usually address you?'
                }
                value={formik.values.displayName}
                onChange={formik.handleChange}
                error={formik.touched.displayName && Boolean(formik.errors.displayName)}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                variant="outlined"
                name="email"
                label="Email address"
                helperText={
                  formik.touched.email && formik.errors.email
                    ? formik.errors.email
                    : 'This will allow us to reach out to you where necessary'
                }
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
              />
            </Grid>
          </Grid>
          <Grid item container justifyContent="center">
            <Grid item xs={12} sm={8} lg={8}>
              <Box pt={4}>
                <Button type="submit" fullWidth variant="contained" color="primary">
                  NEXT
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
        <ProgressIndicator loading={formik.isSubmitting}></ProgressIndicator>
      </Box>
    </FullRegistrationLayout>
  );
};

export default PersonalInfo;
