/**
 * Hook for resuse the snackbar logic
 */
import { useTheme, useMediaQuery } from '@material-ui/core';
import { useSnackbar as useSnackbarNative, VariantType } from 'notistack';

const useSnackbar = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const { enqueueSnackbar } = useSnackbarNative();

  return (message: string, { variant }: { variant: VariantType }) =>
    enqueueSnackbar(message, {
      variant: variant,
      anchorOrigin: {
        vertical: matches ? 'bottom' : 'top',
        horizontal: matches ? 'center' : 'right',
      },
    });
};

export default useSnackbar;
