"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.increment = exports.selectCount = exports.invokeCallFunction = exports.invokeApi = exports.crossState = exports.devSliceConfig = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var firebase_1 = require("@dayone/firebase");
var firebaseApp;
var devSliceConfig = function (_firebaseApp) {
    firebaseApp = _firebaseApp;
};
exports.devSliceConfig = devSliceConfig;
var initialState = {
    initialised: false,
    counter: 0,
    dev: {},
};
exports.crossState = (0, toolkit_1.createAsyncThunk)('dev/crossState', function (arg, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        console.log('createAsyncThunk: dev/crossState', { arg: arg, thunkAPI: thunkAPI });
        // thunkAPI.dispatch(setCrossState('testasdfasdfasdfsd'));
        thunkAPI.dispatch({ type: 'dev/crossState', text: 'asdfasd' });
        return [2 /*return*/, { test: 'test' }];
    });
}); });
exports.invokeApi = (0, toolkit_1.createAsyncThunk)('dev/invokeApi', function (arg, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                console.log('invokeApi');
                return [4 /*yield*/, fetch("https://api.weareday.one/vote", {
                        method: 'post',
                        headers: new Headers({
                            Authorization: "Bearer ".concat(thunkAPI.getState().me.token),
                            'Content-Type': 'application/x-www-form-urlencoded',
                        }),
                        signal: thunkAPI.signal,
                    })];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.json()];
            case 2: return [2 /*return*/, _a.sent()];
        }
    });
}); });
exports.invokeCallFunction = (0, toolkit_1.createAsyncThunk)('dev/invokeCallFunction', function (action) { return __awaiter(void 0, void 0, void 0, function () {
    var result, error_1, result, error_2, result, error_3, result, error_4, result, error_5, postgresResults, error_6;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                console.log("invokeCallFunction ".concat(action));
                if (!(action === 'testCallWithAppCheckDebug')) return [3 /*break*/, 4];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, firebase_1.callable.testCallWithAppCheckDebug(firebaseApp)];
            case 2:
                result = _a.sent();
                console.log('testCallWithAppCheckDebug', { result: result });
                return [3 /*break*/, 4];
            case 3:
                error_1 = _a.sent();
                console.log({ error: error_1 });
                return [3 /*break*/, 4];
            case 4:
                if (!(action === 'testCallDebug')) return [3 /*break*/, 8];
                _a.label = 5;
            case 5:
                _a.trys.push([5, 7, , 8]);
                return [4 /*yield*/, firebase_1.callable.testCallDebug(firebaseApp)];
            case 6:
                result = _a.sent();
                console.log('testCallDebug', { result: result });
                return [3 /*break*/, 8];
            case 7:
                error_2 = _a.sent();
                console.log({ error: error_2 });
                return [3 /*break*/, 8];
            case 8:
                if (!(action === 'testWithErrorCallDebug')) return [3 /*break*/, 12];
                _a.label = 9;
            case 9:
                _a.trys.push([9, 11, , 12]);
                return [4 /*yield*/, firebase_1.callable.testWithErrorCallDebug(firebaseApp)];
            case 10:
                result = _a.sent();
                console.log('testWithErrorCallDebug', { result: result });
                return [3 /*break*/, 12];
            case 11:
                error_3 = _a.sent();
                console.log({ error: error_3 });
                return [3 /*break*/, 12];
            case 12:
                if (!(action === 'testWithDataCallDebug')) return [3 /*break*/, 16];
                _a.label = 13;
            case 13:
                _a.trys.push([13, 15, , 16]);
                return [4 /*yield*/, firebase_1.callable.testWithDataCallDebug(firebaseApp)];
            case 14:
                result = _a.sent();
                console.log('testWithDataCallDebug', { result: result });
                return [3 /*break*/, 16];
            case 15:
                error_4 = _a.sent();
                console.log({ error: error_4 });
                return [3 /*break*/, 16];
            case 16:
                if (!(action === 'analyticsCallDebug')) return [3 /*break*/, 20];
                _a.label = 17;
            case 17:
                _a.trys.push([17, 19, , 20]);
                return [4 /*yield*/, firebase_1.callable.analyticsCallDebug(firebaseApp)];
            case 18:
                result = _a.sent();
                console.log('analyticsCallDebug', { result: result });
                return [3 /*break*/, 20];
            case 19:
                error_5 = _a.sent();
                console.log({ error: error_5 });
                return [3 /*break*/, 20];
            case 20:
                if (!(action === 'postgresCallDebug')) return [3 /*break*/, 24];
                _a.label = 21;
            case 21:
                _a.trys.push([21, 23, , 24]);
                return [4 /*yield*/, firebase_1.callable.postgresCallDebug(firebaseApp)];
            case 22:
                postgresResults = _a.sent();
                console.log('postgresCallDebug', { postgresResults: postgresResults });
                return [3 /*break*/, 24];
            case 23:
                error_6 = _a.sent();
                console.log({ error: error_6 });
                return [3 /*break*/, 24];
            case 24: return [2 /*return*/];
        }
    });
}); });
var devSlice = (0, toolkit_1.createSlice)({
    name: 'development',
    initialState: initialState,
    reducers: {
        increment: function (state) {
            console.warn('This is just a test on hook. Increment action invoked.');
            state.counter += 1;
        },
    },
    extraReducers: function (builder) {
        // eslint-disable-next-line
        builder.addCase(exports.invokeApi.fulfilled, function (state, action) {
            //   state.counter = action.payload;
        });
    },
});
// Selectors
var selectCount = function (state) { return state.development.counter; };
exports.selectCount = selectCount;
// Actions
exports.increment = devSlice.actions.increment;
exports.default = devSlice.reducer;
