import { useState, useRef, useCallback } from 'react';
import {
  Box,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  Switch,
  TextField,
  Tooltip,
  Typography,
  IconButton,
  Select,
  MenuItem,
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { TimePicker, DatePicker } from '@material-ui/pickers';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import InfoIcon from '@material-ui/icons/Info';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import React from 'react';
import { DeleteConfirmDialog } from 'shared/components/deleteConfirmDialog';
import { DayOfWeek, RecurringType, TaskStatus, TaskType } from '@dayone/models';
import refData from '@dayone/refdata';
import { RichText } from 'shared/components/richtext';

export default function TaskPaper(props: {
  disableUrgentSwitch: boolean;
  creationLog: React.ReactNode | undefined;
  allowClone: boolean;
  onClone: () => void;
  showDelete: boolean;
  onDelete: () => void | null;
  nameField: { value: string; error: boolean | undefined; helpText: React.ReactNode };
  descriptionField: { value: string; error: boolean | undefined; helpText: React.ReactNode };
  flagField: { checked: boolean; onChange: (checked: boolean) => void };
  branchField: {
    value: string;
    branches: { id: string; name: string }[];
    onChange: (value: string | null) => void;
    error: boolean | undefined;
    helpText: React.ReactNode;
  };
  handleChange: (fieldName: string, val: string) => void;
  typeField: { value: TaskType; onChange: (value: TaskType) => void };
  statusField: { value: TaskStatus; onChange: (value: TaskStatus) => void };
  subTaskItems: { content: string; id: string | null }[];
  handleSubTaskChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  addSubTask: () => void;
  removeSubTask: (index: number) => void;
  error: string | any[] | undefined;
  touched: any[] | undefined;
  formik: any;
}) {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const subTasksContainerRef = useRef(null);

  const handleShowDeleteDialog = () => {
    setDeleteDialogOpen(true);
  };
  const {
    nameField,
    descriptionField,
    flagField,
    branchField,
    typeField,
    handleChange,
    handleSubTaskChange,
    subTaskItems,
    removeSubTask,
    addSubTask,
    onDelete,
    error,
    touched,
    allowClone,
    onClone,
    showDelete,
    formik,
    disableUrgentSwitch,
    creationLog,
  } = props;
  const isReachToSubtasksNumberLimit = subTaskItems?.length >= 20;

  const onKeyPress = useCallback(
    (nextIndex: number) => (event: React.KeyboardEvent<any>) => {
      if (event.key !== 'Enter') return;

      event.preventDefault();
      const focusToNextInput = () => {
        const input = (subTasksContainerRef.current! as HTMLElement).querySelectorAll(`input[type="text"]`)[nextIndex];
        input && (input as HTMLInputElement).focus();
      };
      if (nextIndex === subTaskItems.length && !isReachToSubtasksNumberLimit) {
        addSubTask();
        setTimeout(focusToNextInput, 0);
      } else {
        focusToNextInput();
      }
    },
    [subTasksContainerRef, subTaskItems, isReachToSubtasksNumberLimit, addSubTask]
  );

  const isSubtaskErrorByIndex = useCallback(
    (index: number) => touched && touched[index] && error && error instanceof Array && error[index],
    [touched, error]
  );
  const gethelperTextByIndex = useCallback(
    (index: number) => touched && touched[index] && error && error instanceof Array && error[index]?.content,
    [touched, error]
  );

  return (
    <Paper elevation={0}>
      <DeleteConfirmDialog
        open={deleteDialogOpen}
        title="Are you sure you want to delete task?"
        content="The task will be deleted immediately. You can’t undo this action."
        onConfirm={(confirm) => {
          setDeleteDialogOpen(false);
          if (confirm) onDelete && onDelete();
        }}
      />
      <Card elevation={0}>
        <CardContent>
          <Box display="flex" justifyContent="flex-end">
            {allowClone && (
              <Tooltip title={refData.screen.responsibility['btn-duplicate-task']}>
                <IconButton onClick={onClone}>
                  <FileCopyIcon />
                </IconButton>
              </Tooltip>
            )}
            {showDelete && (
              <Tooltip title={refData.screen.responsibility['btn-delete-task']}>
                <IconButton onClick={handleShowDeleteDialog}>
                  <DeleteIcon color="secondary" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          <Box>{creationLog}</Box>
          <Box pt={3}>
            <Typography variant="h6">Task details</Typography>
          </Box>
          <Box alignItems="center" display="flex">
            <Typography variant="subtitle1">Include details on what needs to be done and the steps to take</Typography>
            <Box pl={1} />
            <Tooltip title={refData.screen.responsibility['about-subtasks']}>
              <InfoIcon />
            </Tooltip>
          </Box>
          <Box pt={4} />
          <TextField
            fullWidth
            label="Name"
            name="name"
            multiline
            rows={2}
            value={nameField.value}
            variant="outlined"
            onChange={(event) => handleChange('name', event.target.value)}
            error={nameField.error}
            helperText={nameField.helpText}
          />
          <Box pt={3} />
          <RichText
            defaultValue={descriptionField.value}
            onChange={(val) => handleChange('description', val)}
            error={descriptionField.error}
            helperText={descriptionField.helpText}
          />
          <Box pt={3} />
          <Grid ref={subTasksContainerRef} container spacing={3}>
            {subTaskItems.map((subtask, index) => (
              <Grid item container spacing={1} key={`subtask${index}`}>
                <Grid item xs sm={10}>
                  <TextField
                    fullWidth
                    label="Subtask description"
                    name={`subTaskItems[${index}].content`}
                    variant="outlined"
                    value={subtask.content}
                    onChange={handleSubTaskChange}
                    onKeyPress={onKeyPress(index + 1)}
                    error={isSubtaskErrorByIndex(index)}
                    helperText={gethelperTextByIndex(index)}
                  />
                </Grid>
                {index !== 0 && (
                  <Grid item sm={1} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
                    <IconButton onClick={() => removeSubTask(index)}>
                      <RemoveCircleIcon color="primary" />
                    </IconButton>
                  </Grid>
                )}
                {index === subTaskItems.length - 1 && (
                  <Grid item sm={1} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
                    <IconButton disabled={isReachToSubtasksNumberLimit} onClick={addSubTask}>
                      <AddCircleIcon color={!isReachToSubtasksNumberLimit ? 'primary' : 'disabled'} />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            ))}
          </Grid>
          <Box pt={3}>
            <Typography variant="h6">Settings</Typography>
          </Box>
          <Box alignItems="center" display="flex">
            <Typography variant="subtitle1">Set team assignment and schedule</Typography>
            <Box pl={1} />
            <Tooltip
              title={
                // use div style={{ whiteSpace: 'pre-line' }} to preverse \n character
                <div style={{ whiteSpace: 'pre-line' }}>
                  {refData.screen.responsibility['set-tasks-type-and-team-assignment']}
                </div>
              }
            >
              <InfoIcon />
            </Tooltip>
          </Box>
          <Box pt={3} />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={7} lg={7}>
              <Autocomplete
                disableClearable={true}
                openOnFocus={true}
                value={branchField.branches.find((branch) => branch.id === branchField.value)}
                options={branchField.branches}
                onChange={(event, value) => {
                  branchField.onChange(value?.id ?? null);
                }}
                getOptionLabel={(branch) => branch.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Team"
                    variant="outlined"
                    error={branchField.error}
                    helperText={branchField.helpText}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={7} lg={7}>
              {!disableUrgentSwitch && (
                <Box display="flex" alignItems="center">
                  <FormControlLabel
                    control={
                      <Switch
                        name="flag"
                        checked={flagField.checked}
                        color="primary"
                        onChange={(event, checked) => {
                          if (checked) {
                            typeField.onChange(TaskType.daily);
                            formik.setValues({
                              ...formik.values,
                              type: TaskType.adHoc,
                              // startDate: new Date(),
                              endDate: null,
                              recurringDayOfWeek: [],
                              recurringRepeat: '',
                              recurringType: null,
                              occurrentDate: null,
                              nextOccurrent: null,
                            });
                          }

                          flagField.onChange(checked);
                        }}
                      />
                    }
                    label="Urgent"
                  />
                </Box>
              )}
              <Box display="flex" alignItems="center">
                <Tooltip title="">
                  <FormControlLabel
                    control={
                      <Switch
                        name="imageSubmission"
                        checked={formik.values.imageSubmission}
                        color="primary"
                        onChange={(event, checked) => formik.setFieldValue('imageSubmission', checked)}
                      />
                    }
                    label="Image submission"
                  />
                </Tooltip>
              </Box>
            </Grid>
            <Grid item xs={12} sm={7} lg={7}>
              <Box pb={3}>
                <FormLabel component="legend">Schedule task for </FormLabel>
              </Box>
              <DatePicker
                error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                helperText={formik.touched.startDate && formik.errors.startDate}
                disabled={flagField.checked}
                invalidDateMessage=""
                onChange={(date) => formik.setFieldValue('startDate', date)}
                value={formik.values.startDate}
                fullWidth
                label="Date"
                type="text"
                format="DD MMM yyyy"
                minDate={new Date()}
                InputLabelProps={{
                  shrink: true,
                }}
                TextFieldComponent={(params: any) => <TextField {...params} variant="outlined" />}
              />
              <Box pt={3} />
              <TimePicker
                disabled={flagField.checked}
                invalidDateMessage=""
                error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                helperText={formik.touched.startDate && formik.errors.startDate && 'Time is required'}
                onChange={(date) => formik.setFieldValue('startDate', date)}
                value={formik.values.startDate}
                fullWidth
                label="Time"
                InputLabelProps={{
                  shrink: true,
                }}
                TextFieldComponent={(params: any) => <TextField {...params} variant="outlined" />}
              />
            </Grid>
            <Grid item xs={12} sm={7} lg={7}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Type</FormLabel>
                <FormControlLabel
                  disabled={flagField.checked}
                  name="type"
                  onChange={(event, checked) => {
                    if (checked) {
                      typeField.onChange(TaskType.adHoc);
                    }
                  }}
                  value={TaskType.adHoc}
                  control={<Radio color="primary" checked={typeField.value === TaskType.adHoc} />}
                  label="One-off"
                />
                <FormControlLabel
                  disabled={flagField.checked}
                  name="type"
                  onChange={(event, checked) => {
                    if (checked) {
                      typeField.onChange(TaskType.daily);
                      formik.setFieldValue('recurringType', RecurringType.DAY);
                      formik.setFieldValue('recurringRepeat', 1);
                    }
                  }}
                  value={TaskType.daily}
                  control={<Radio color="primary" checked={typeField.value === TaskType.daily} />}
                  label="Recurring"
                />
              </FormControl>
            </Grid>
            {formik.values.type === TaskType.daily && (
              <Grid item xs={12} sm={7} lg={7}>
                <Box pl={3}>
                  <Typography variant="caption">Repeats every</Typography>
                  <Box flexDirection="row">
                    <TextField
                      disabled={flagField.checked}
                      variant="outlined"
                      style={{ width: '43px', marginRight: 8 }}
                      error={formik.touched.recurringRepeat && Boolean(formik.errors.recurringRepeat)}
                      helperText={
                        <div style={{ whiteSpace: 'nowrap', marginLeft: -14 }}>
                          {formik.touched.recurringRepeat && formik.errors.recurringRepeat}
                        </div>
                      }
                      value={formik.values.recurringRepeat}
                      onChange={(event) => {
                        const value = event.target.value;
                        if (!Number.isNaN(Number(value)) || value === '') {
                          formik.setFieldValue('recurringRepeat', value);
                        }
                      }}
                    />
                    <FormControl variant="outlined" style={{ width: '161px' }}>
                      <Select
                        disabled={flagField.checked}
                        value={formik.values.recurringType}
                        onChange={(event) => {
                          formik.setFieldValue('recurringType', event.target.value);
                          if (event.target.value === RecurringType.WEEK) {
                            /* pre-select the day-of-week of startDate */
                            const startDateDOW = moment(formik.values.startDate).day();
                            if (startDateDOW) {
                              formik.setFieldValue('recurringDayOfWeek', [startDateDOW]);
                            }
                          }
                        }}
                      >
                        <MenuItem value={RecurringType.DAY}>Day(s)</MenuItem>
                        <MenuItem value={RecurringType.WEEK}>Week(s)</MenuItem>
                        <MenuItem value={RecurringType.MONTH}>Month(s) </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  {formik.values.recurringType === RecurringType.WEEK && (
                    <Box pt={1} flexDirection="column" display="flex">
                      <FormControl variant="outlined">
                        <Typography variant="caption">Repeats on</Typography>
                        <ToggleButtonGroup
                          value={formik.values.recurringDayOfWeek}
                          onChange={(event, value) => formik.setFieldValue('recurringDayOfWeek', value.sort())}
                          color="error"
                        >
                          <ToggleButton value={DayOfWeek.SUNDAY}>Sun</ToggleButton>
                          <ToggleButton value={DayOfWeek.MONDAY}>Mon</ToggleButton>
                          <ToggleButton value={DayOfWeek.TUESDAY}>Tue</ToggleButton>
                          <ToggleButton value={DayOfWeek.WEDNESDAY}>Wed</ToggleButton>
                          <ToggleButton value={DayOfWeek.THURSDAY}>Thu</ToggleButton>
                          <ToggleButton value={DayOfWeek.FRIDAY}>Fri</ToggleButton>
                          <ToggleButton value={DayOfWeek.SATURDAY}>Sat</ToggleButton>
                        </ToggleButtonGroup>
                      </FormControl>
                      {formik.touched.recurringRepeat && !!formik.errors.recurringDayOfWeek && (
                        <Typography variant="caption" color="error">
                          {formik.errors.recurringDayOfWeek}
                        </Typography>
                      )}
                    </Box>
                  )}
                  <Box pt={1}>
                    <Typography variant="caption">Ends</Typography>
                  </Box>
                  <Box pl={1}>
                    <FormControl component="fieldset" disabled={flagField.checked}>
                      <FormControlLabel
                        name="ends"
                        onChange={() => {
                          formik.setValues({
                            ...formik.values,
                            isTaskHasEndDate: false,
                            endDate: null,
                          });
                        }}
                        value={false}
                        control={<Radio color="primary" checked={!formik.values.isTaskHasEndDate} />}
                        label="Never"
                      />
                      <Box flexDirection="row">
                        <FormControlLabel
                          name="ends"
                          onChange={(event, checked) => {
                            if (checked) {
                              formik.setValues({
                                ...formik.values,
                                isTaskHasEndDate: true,
                                endDate: formik.values.startDate,
                              });
                            }
                          }}
                          value={true}
                          control={<Radio color="primary" checked={formik.values.isTaskHasEndDate} />}
                          label="On"
                        />
                        <DatePicker
                          invalidDateMessage=""
                          disabled={!formik.values.isTaskHasEndDate}
                          onChange={(date) => formik.setFieldValue('endDate', date)}
                          error={Boolean(formik.errors.endDate)}
                          helperText={formik.touched.endDate && formik.errors.endDate}
                          value={formik.values.endDate || null}
                          format="DD MMM yyyy"
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          minDate={formik.values.startDate || new Date()}
                          TextFieldComponent={(params: any) => <TextField {...params} variant="outlined" />}
                        />
                      </Box>
                    </FormControl>
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Paper>
  );
}
