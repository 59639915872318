import {
  useTheme,
  Box,
  Grid,
  Button,
  Typography,
  useMediaQuery,
  Paper,
  CardContent,
  Card,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { ImageWithLightBox } from 'shared/components/uploadImage';
import EditIcon from '@material-ui/icons/Edit';
import CommentIcon from '@material-ui/icons/ChatBubble';
import RepeatIcon from '@material-ui/icons/Repeat';
import { commentSlice, taskSlice, teamSlice, topicSlice, useAppDispatch, useAppSelector } from '@dayone/redux';
import { useCallback, useEffect } from 'react';
import { TaskFlag, TaskType } from '@dayone/models';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import refData from '@dayone/refdata';
import moment from 'moment';
import RecurrenceTooltip from './components/RecurrenceTooltip';
import GAEvents from 'shared/utilities/events';
import { useCustomDimensionsEvents, useSnackbar } from 'shared/components/hooks';

export default function Task() {
  const classes = useStyles();
  const theme = useTheme();
  const enqueueSnackbar = useSnackbar();
  const { taskID = '' } = useParams();
  const task = useAppSelector<any>(taskSlice.selectTaskByID)(taskID);
  const branch = useAppSelector<any>(teamSlice.selectTeamByID)(task?.branchID);
  const comments = useAppSelector<any>(commentSlice.selectComments);
  const topics = useAppSelector<any>(topicSlice.selectTopics).map((topic: any) => {
    const topicComments = comments.filter((cmt: any) => cmt.topicID === topic.topicId && !cmt.isDeleted);

    let lastActiveTime = moment(topic.createdAt);
    if (topicComments.length) {
      lastActiveTime = topicComments
        .map((x: any) => moment(x.ts))
        .reduce((prev: any, curr: any) => (curr.isAfter(prev) ? curr : prev));
    }
    return { ...topic, lastActiveTime: lastActiveTime };
  });
  const commonComments = comments.filter((x: any) => x.topicID === '' && !x.isDeleted);
  if (commonComments.length) {
    topics.push({
      topicId: 'commonTopic',
      taskID: taskID || null,
      topicName: 'Common topic',
      lastActiveTime: commonComments
        .map((x: any) => moment(x.ts))
        .reduce((prev: any, curr: any) => (curr.isAfter(prev) ? curr : prev)),
      createdAt: '',
      createdBy: '',
    });
  }

  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const logEvent = useCustomDimensionsEvents();

  useEffect(() => {
    dispatch(taskSlice.setSelectedTaskID(taskID));
    logEvent(GAEvents.task_view_details, { isAdHoc: task?.type === TaskType.adHoc });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskID, dispatch]);

  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  useEffect(() => {
    if (!task) {
      enqueueSnackbar('This task is deleted!', {
        variant: 'warning',
      });
    }
  }, [task, enqueueSnackbar]);

  if (!task) return null;

  return (
    <Grid container spacing={3}>
      {/* Back button */}
      <Grid item xs={12}>
        <Box pb={2}>
          <Button color="primary" startIcon={<ArrowBackIcon />} onClick={handleBack}>
            {`BACK`}
          </Button>
        </Box>
      </Grid>
      <Grid item lg={10} xs={12}>
        <Box m={-1.5} display="flex" flexDirection={{ xs: 'column', lg: 'row' }}>
          <Box p={1.5} flexGrow={1}>
            <Paper elevation={0}>
              <Card elevation={0}>
                <CardContent>
                  <Box display="flex" justifyContent="flex-end">
                    <Tooltip title={refData.screen.responsibility['btn-comment']}>
                      <CommentIcon
                        style={{ color: theme.palette.action.active, marginRight: '20px' }}
                        onClick={() => navigate('/tasks/topic/detail/')}
                      />
                    </Tooltip>
                    <Tooltip title={refData.screen.responsibility['btn-edit-task']}>
                      <EditIcon
                        onClick={() => {
                          navigate(`/tasks/edit/${task?.taskID}`);
                        }}
                        style={{ color: theme.palette.action.active }}
                      />
                    </Tooltip>
                  </Box>
                  <Box pt={4} />
                  <Box>
                    {moment(task?.nextOccurrent).isAfter(moment(new Date()), 'day') ? (
                      <Typography variant="body2">{`Next occurrence: ${moment(task?.nextOccurrent).format(
                        'DD MMM [at] hh:mm a'
                      )}`}</Typography>
                    ) : (
                      <Typography variant="body2">{`Occurrence: ${moment(task?.occurrentDate).format(
                        'DD MMM [at] hh:mm a'
                      )}`}</Typography>
                    )}
                  </Box>
                  <Box display="flex" alignItems="center" sx={{ mt: 1, mb: 1 }}>
                    {task?.flag === TaskFlag.urgent && <PriorityHighIcon />}
                    <Typography variant="h5">{task?.name}</Typography>
                    {task?.type === TaskType.daily && (
                      <RecurrenceTooltip task={task}>
                        <RepeatIcon style={{ marginLeft: 8 }} />
                      </RecurrenceTooltip>
                    )}
                  </Box>
                  <Typography variant="body1">{branch?.name}</Typography>
                  {task && task.images && task.images.length > 0 && (
                    <Box display="flex" flexWrap="wrap" pt={3} pb={2}>
                      {task.images.map((image: string, index: number) => (
                        <Box key={`detail${index}`} pr={2} pb={2}>
                          <ImageWithLightBox
                            width={isXsDown ? theme.spacing(9) : undefined}
                            height={isXsDown ? theme.spacing(6) : undefined}
                            url={image}
                          />
                        </Box>
                      ))}
                    </Box>
                  )}
                  <CardContent>
                    <Typography variant="body2" component="h2">
                      <div dangerouslySetInnerHTML={{ __html: task?.description ?? '' }} />
                    </Typography>
                    {task && task.subTaskItems && task.subTaskItems.length > 0 && (
                      <Box mt={3}>
                        {task.subTaskItems.map((subtask: any, index: number) => (
                          <Typography
                            key={`subtask${index}`}
                            variant="body2"
                            className={
                              task.subTaskCompleted.includes(subtask.id) ? classes.subtaskCompleted : undefined
                            }>
                            {`• ${subtask.content}`}
                          </Typography>
                        ))}
                      </Box>
                    )}
                  </CardContent>
                </CardContent>
              </Card>
            </Paper>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles({
  listItemText: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
  subtaskCompleted: { textDecoration: 'line-through' },
});
