import { FC } from 'react';

import UpdatePhone from './UpdatePhone';
import UpdateEmail from './UpdateEmail';

interface Props {
  updateField: 'email' | 'phone' | null;
  cancel: () => void;
  next: () => void;
  credential: any;
}

const Update: FC<Props> = ({ updateField, cancel, next, credential }) => {
  const isEmail = updateField === 'email';

  return !updateField ? null : isEmail ? (
    <UpdateEmail onCancel={cancel} onNext={next} credential={credential} />
  ) : (
    <UpdatePhone onCancel={cancel} onNext={next} />
  );
};

export default Update;
