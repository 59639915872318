import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@material-ui/core';
import React, { useCallback } from 'react';

const intervalFilter = [
  { label: 'One-off', value: 'One-off' },
  { label: 'Day', value: '0' },
  { label: 'Week', value: '1' },
  { label: 'Month', value: '2' },
];

export default function TaskOverviewFilter(props: {
  selectedBranches: string[];
  onBranchChange: (checked: boolean, value: string) => void;
  selectedInterval: string[];
  onIntervalChange: (checked: boolean, value: string) => void;
  allBranches: { label: string; value: string }[];
}) {
  const renderFilter = useCallback(
    (
      title: string,
      selectedValues: string[],
      items: { label: string; value: string }[],
      onChange: (checked: boolean, value: string) => void
    ) => {
      return (
        <Box pb={3}>
          <FormControl component="fieldset">
            <FormLabel component="legend">{title}</FormLabel>
            <FormGroup>
              {items.map((item, index) => {
                return (
                  <FormControlLabel
                    key={item.label}
                    style={{ marginLeft: 10 }}
                    label={`${item.label}`}
                    control={
                      <Checkbox
                        id={`${index}`}
                        checked={selectedValues.some((value) => value === item.value)}
                        color="primary"
                        onChange={(event, checked) => onChange(checked, item.value)}
                        name={`${item.label}`}
                      />
                    }
                  />
                );
              })}
            </FormGroup>
          </FormControl>
        </Box>
      );
    },
    []
  );

  return (
    <>
      {renderFilter('Teams', props.selectedBranches, props.allBranches, props.onBranchChange)}
      {renderFilter('Recurrence interval', props.selectedInterval, intervalFilter, props.onIntervalChange)}
    </>
  );
}
