"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.setInvites = exports.getInvites = exports.acceptInvite = exports.myInviteSliceConfig = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var firestore_1 = require("firebase/firestore");
var firestore;
var myInviteSliceConfig = function (_firebaseApp) {
    firestore = (0, firestore_1.getFirestore)(_firebaseApp);
};
exports.myInviteSliceConfig = myInviteSliceConfig;
exports.acceptInvite = (0, toolkit_1.createAsyncThunk)('myInvite/acceptInvite', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var state, uid, account, companyID, id, companies, topicDocRef;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                state = thunkAPI.getState();
                uid = (_a = state.me.details) === null || _a === void 0 ? void 0 : _a.uid;
                account = state.me.account;
                companyID = payload.companyID, id = payload.id;
                console.log('myInvite/acceptInvite');
                if (!(uid && account)) return [3 /*break*/, 2];
                companies = account.companies ? __spreadArray([], account.companies, true) : [];
                if (!companies.includes(companyID))
                    companies.push(companyID);
                return [4 /*yield*/, (0, firestore_1.updateDoc)((0, firestore_1.doc)(firestore, 'accounts', uid), { companies: companies })];
            case 1:
                _b.sent();
                _b.label = 2;
            case 2:
                if (!(companyID && id)) return [3 /*break*/, 4];
                topicDocRef = (0, firestore_1.doc)(firestore, 'companies', companyID, 'invites', id);
                return [4 /*yield*/, (0, firestore_1.updateDoc)(topicDocRef, { status: 'accepted' })];
            case 3:
                _b.sent();
                _b.label = 4;
            case 4: return [2 /*return*/];
        }
    });
}); });
var initialState = {
    initialised: false,
    myInvites: [],
};
var myInviteSlice = (0, toolkit_1.createSlice)({
    name: 'myInvites',
    initialState: initialState,
    reducers: {
        setInvites: {
            reducer: function (state, action) {
                if (action.payload) {
                    state.initialised = true;
                    state.myInvites = action.payload;
                }
            },
            prepare: function (payload) {
                if (payload) {
                    var myInvites = payload.map(function (invite) {
                        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
                        return {
                            id: invite.id,
                            companyID: (_a = invite.companyID) !== null && _a !== void 0 ? _a : '',
                            companyName: (_b = invite.companyName) !== null && _b !== void 0 ? _b : '',
                            email: (_c = invite.email) !== null && _c !== void 0 ? _c : '',
                            inviteeEmail: (_d = invite.inviteeEmail) !== null && _d !== void 0 ? _d : '',
                            inviteeUid: (_e = invite.inviteeUid) !== null && _e !== void 0 ? _e : '',
                            status: (_f = invite.status) !== null && _f !== void 0 ? _f : '',
                            createdAt: invite.createdAt instanceof firestore_1.Timestamp ? (_h = (_g = invite.createdAt) === null || _g === void 0 ? void 0 : _g.toDate().toISOString()) !== null && _h !== void 0 ? _h : '' : '',
                            uid: (_j = invite.uid) !== null && _j !== void 0 ? _j : '',
                        };
                    });
                    return { payload: myInvites };
                }
                return { payload: null };
            },
        },
    },
});
var getInvites = function (state) {
    return state.myInvite.myInvites.filter(function (i) { return i.status !== 'accepted'; });
};
exports.getInvites = getInvites;
// Actions
exports.setInvites = myInviteSlice.actions.setInvites;
exports.default = myInviteSlice.reducer;
