import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import PeopleIcon from '@material-ui/icons/People';
import React, { useState } from 'react';
import { ProgressIndicator } from 'shared/components/progressIndicator';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import refData from '@dayone/refdata';
import { Autocomplete } from '@material-ui/lab';
import { memberSlice, featureFlagSlice, useAppDispatch, useAppSelector } from '@dayone/redux';
import { useSnackbar } from 'shared/components/hooks';
import { validationErrors } from '@dayone/common';

interface IFomikModel {
  emails: string[];
}
const formikInitialValues: IFomikModel = {
  emails: [],
};

interface IProps {
  open: boolean;
  onClose: () => void;
}

export default function InviteNewStaff(props: IProps) {
  const { open, onClose } = props;
  const [email, setEmail] = useState('');
  const dispatch = useAppDispatch();
  const enqueueSnackbar = useSnackbar();
  const featureFlag = useAppSelector(featureFlagSlice.selectFeatureFlags);
  const { maxStaff } = featureFlag.user;
  const invites = useAppSelector<any>(memberSlice.selectInvites);
  const members = useAppSelector<any>(memberSlice.selectMembers);
  const formik = useFormik({
    initialValues: formikInitialValues,
    validationSchema: Yup.object().shape({
      emails: Yup.array().min(1, 'Email is required').required('Email is required'),
    }),
    onSubmit: async (values) => {
      const invitedEmails = invites.map((i: any) => i.inviteeEmail);
      const memberEmails = members.map((m: any) => m.email);
      const emails = [...invitedEmails, ...memberEmails];

      const newEmails = values.emails.filter((email) => !emails.includes(email));
      if (emails.length + newEmails.length > maxStaff) {
        enqueueSnackbar(validationErrors.default('User.MaxStaff', { MAX_STAFF: maxStaff }), {
          variant: 'error',
        });
        return;
      }

      const existEmails = values.emails.filter((email) => emails.includes(email));
      existEmails.forEach((email) => {
        enqueueSnackbar(`${email} is already a part of this workspace!`, {
          variant: 'warning',
        });
      });
      await Promise.all(
        newEmails.map(async (email) => {
          const inviteeEmail = email.toLowerCase();
          await dispatch(
            memberSlice.createInvite({
              inviteeEmail,
            })
          ).unwrap();
        })
      )
        .then(() => {
          if (newEmails.length === 1) {
            enqueueSnackbar('The invitation has been sent successfully', {
              variant: 'success',
            });
          } else if (newEmails.length > 1) {
            enqueueSnackbar('The invitations have been sent successfully', {
              variant: 'success',
            });
          }
        })
        .catch((err) =>
          enqueueSnackbar(err.message, {
            variant: 'error',
          })
        )
        .finally(handleClose);
    },
  });

  const handleClose = () => {
    onClose();
    formik.resetForm();
  };

  const onInvite = () => {
    formik.submitForm();
  };

  const onChange = (event: any, values: string[]) => {
    let schema = Yup.string().email();
    let emails: string[] = [];
    values.forEach((text) => {
      // eslint-disable-next-line no-control-regex
      text.split(new RegExp('[,; \n]', 'g')).forEach((email) => {
        email = email.trim();
        if (email && schema.isValidSync(email)) {
          !emails.includes(email) && emails.push(email);
        }
      });
    });
    formik.setFieldValue('emails', emails);
  };

  return (
    <Dialog fullWidth open={open}>
      <DialogTitle id="form-dialog-title">
        <Box display="flex" style={{ alignItems: 'center' }}>
          <PeopleIcon color="action" style={{ marginRight: 16, fontSize: 35 }}></PeopleIcon>
          <Typography variant="h6">Invite new member</Typography>
          <Tooltip title={refData.screen.member['invite-staff-to-join']}>
            <InfoIcon color="action" fontSize="small" style={{ marginLeft: 4 }} />
          </Tooltip>
        </Box>
      </DialogTitle>
      <DialogContent style={{ overflow: 'hidden' }}>
        <Box>
          <form onSubmit={formik.handleSubmit}>
            <Box mb={1}>
              <Autocomplete
                autoComplete={false}
                autoSelect={true}
                multiple
                freeSolo
                options={new Array<string>()}
                value={formik.values.emails}
                onChange={onChange}
                renderTags={(value: string[], getTagProps) =>
                  value.map((option: string, index: number) => (
                    <Chip variant="outlined" label={`${option}`} {...getTagProps({ index })} />
                  ))
                }
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Email address"
                      type="email"
                      id="email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      error={formik.touched.emails && Boolean(formik.errors.emails)}
                      helperText={formik.touched.emails && formik.errors.emails}
                    />
                  );
                }}
              />
            </Box>

            <ProgressIndicator loading={formik.isSubmitting} />
          </form>
        </Box>
      </DialogContent>
      <DialogActions style={{ padding: 16, paddingTop: 32 }}>
        <Button size="medium" color="primary" onClick={handleClose}>
          Cancel
        </Button>
        <Button size="medium" type="submit" onClick={onInvite} color="primary" variant="contained">
          Invite
        </Button>
      </DialogActions>
    </Dialog>
  );
}
