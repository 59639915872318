import {
  Box,
  Card,
  CardContent,
  Chip,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useTheme,
  capitalize,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { memberSlice, teamSlice, useAppSelector } from '@dayone/redux';

export default function StaffOverviewTable() {
  const theme = useTheme();
  const navigate = useNavigate();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [keyword, setKeyWord] = React.useState('');

  const onChangeKeywords = (keyword: string) => {
    setKeyWord(keyword);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const navigateToEdit = (id: string | number) => {
    navigate(generatePath(`edit/:id`, { id: id.toString() }));
  };

  const members = useAppSelector<any>(memberSlice.selectMembersWithRole);
  const teams = useAppSelector<any>(teamSlice.selectTeamsOverview);

  const staffWithBranchs = members
    .filter((m: any) => m.displayName.includes(keyword) || m.email.includes(keyword) || m.phoneNumber.includes(keyword))
    .map((member: any) => {
      return {
        id: member.memberId,
        staff: member.displayName ? member.displayName : member.email,
        branch: teams.filter((team: any) => team.users.indexOf(member.memberId) > -1).map((team: any) => team.name),
        role: member.role,
      };
    });

  const renderBranch = (branch: string[]) => {
    if (branch)
      return (
        <Box display="flex" flexWrap="wrap">
          {branch.map((x: string, index: number) => (
            <Box key={index} mr={2} mb={0.5}>
              <Chip label={x} />
            </Box>
          ))}
        </Box>
      );
  };
  const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

  return (
    <Paper elevation={0} square>
      <Box>
        <TextField
          fullWidth
          label="Search for member"
          variant="outlined"
          placeholder="Enter name, email, or phone number"
          value={keyword}
          onChange={(e) => {
            onChangeKeywords(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box mb={1} bgcolor={'red'}></Box>
      <Card elevation={0}>
        <CardContent>
          <Typography variant="h6">Staff Overview</Typography>
          <Box mx={-2} mb={-3}>
            <TableContainer>
              <Table style={{ width: '100%', minWidth: theme.spacing(86), overflow: 'auto' }}>
                <TableHead>
                  <TableRow>
                    <TableCell width={isXs ? 129 : '20%'}>Staff</TableCell>
                    <TableCell width={isXs ? 485 : '65%'}>Team</TableCell>
                    <TableCell width={isXs ? 74 : '15%'}>Role</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {staffWithBranchs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: any) => (
                    <TableRow key={row.id}>
                      <TableCell>
                        <span onClick={() => navigateToEdit(row.id)}>
                          <Link href="#" onClick={preventDefault}>
                            {row.staff}
                          </Link>
                        </span>
                      </TableCell>
                      <TableCell>{renderBranch(row.branch)}</TableCell>
                      <TableCell>{capitalize(row.role)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={staffWithBranchs.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </CardContent>
      </Card>
    </Paper>
  );
}
