import { Paper, Typography, Card, CardContent } from '@material-ui/core';
import React, { useEffect } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { announcementSlice, RootState, useAppSelector } from '@dayone/redux';
import AnnouncementTable from './components/AnnouncementTable';
import { EmptyData } from 'shared/components/emptyData';
import messageEmpty from 'assets/message-empty.svg';
import GAEvents from 'shared/utilities/events';
import { useCustomDimensionsEvents } from 'shared/components/hooks';

export default function AnnouncementOverview() {
  const navigate = useNavigate();
  const announcements: announcementSlice.IRemappedAnnouncement[] = useAppSelector<RootState>(
    announcementSlice.selectAnnouncements
  ).announcements;
  const logEvent = useCustomDimensionsEvents();

  useEffect(() => {
    logEvent(GAEvents.announcement_view);
  }, [logEvent]);

  const handleNavigateToEdit = (id: string | number) => {
    navigate(generatePath(`edit/:id`, { id: id.toString() }));
  };

  const data = announcements.filter((motd) => !motd.isArchived);

  if (!data || !data.length)
    return (
      <EmptyData
        imageSrc={messageEmpty}
        emptyText="No announcements yet"
        suggestion="Broadcast a message to your team"
      />
    );

  return (
    <Paper elevation={0} square>
      <Card elevation={0}>
        <CardContent>
          <Typography variant="h6">Active Messages</Typography>
          <AnnouncementTable data={data} navigateToEdit={handleNavigateToEdit} />
        </CardContent>
      </Card>
    </Paper>
  );
}
