import * as yup from 'yup';

export const phoneValidationSchema = yup.object({
  OTP: yup.array().of(yup.string().min(1).required('OTP needs to be entered')),
});

const phoneRegExp = /^[0-9]*$/;
const maximumPhone = 8;
export const phoneRegisterSchema = yup.object({
  phoneNumber: yup
    .string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .min(maximumPhone, `It should be at least ${maximumPhone} numbers`)
    .required('Phone Number is required'),
  countryCode: yup
    .object()
    .shape({
      iso: yup.string(),
      label: yup.string(),
      code: yup.string(),
    })
    .required('Country code is required')
    .typeError('Country code is required'),
});
