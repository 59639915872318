"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NOTIFICATION_TYPES = void 0;
var NOTIFICATION_TYPES;
(function (NOTIFICATION_TYPES) {
    // MOTDS/Announcement
    NOTIFICATION_TYPES["ANNOUNCEMENT"] = "announcement";
    // Knowledge
    NOTIFICATION_TYPES["KNOWLEDGE"] = "knowledge";
    // Task/Responsibilities
    NOTIFICATION_TYPES["TASK_CREATED"] = "task-created";
    NOTIFICATION_TYPES["TASK_UPDATED"] = "task-updated";
    NOTIFICATION_TYPES["TASK_COMPLETED"] = "task-completed";
    NOTIFICATION_TYPES["TASK_DELETED"] = "task-deleted";
    NOTIFICATION_TYPES["TASK_COMMENT"] = "task-comment";
    NOTIFICATION_TYPES["ALL_TASK_COMPLETED"] = "all-task-completed";
    NOTIFICATION_TYPES["TASK_REMINDER"] = "task-reminder";
    // Repository/File
    NOTIFICATION_TYPES["REPOSITORY"] = "repository";
    // Invitation
    NOTIFICATION_TYPES["MEMBER_INVITATION_ACCEPTED"] = "member-invitation-accepted";
    NOTIFICATION_TYPES["MEMBER_INVITATION_RECEIVED"] = "member-invitation-received";
    // Team
    NOTIFICATION_TYPES["TEAM_MEMBER_ADDED"] = "team-member-added";
})(NOTIFICATION_TYPES = exports.NOTIFICATION_TYPES || (exports.NOTIFICATION_TYPES = {}));
