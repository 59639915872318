import { FC } from 'react';
import { Button, Box, Typography } from '@material-ui/core';

import { meSlice, useAppSelector } from '@dayone/redux';

interface Props {
  onCancel: () => void;
  onSuccess: (phoneNumber: string) => void;
}

const ReceiveCode: FC<Props> = ({ onCancel, onSuccess }) => {
  const details = useAppSelector(meSlice.selectDetails);
  const onSubmit = () => details?.phoneNumber && onSuccess(details?.phoneNumber);

  return (
    <>
      <Box mt={5}>
        <Typography align="center">Click Login button to receive verification code.</Typography>
      </Box>
      <Box mt={5} display="flex" justifyContent="flex-end">
        <Button
          variant="text"
          color="primary"
          style={{ marginRight: 2 }}
          onClick={(e) => {
            e.preventDefault();
            onCancel();
          }}
        >
          Cancel
        </Button>
        <Button type="submit" variant="text" color="primary" onClick={onSubmit}>
          Login
        </Button>
      </Box>
    </>
  );
};

export default ReceiveCode;
