import React, { ChangeEvent } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Paper,
  Switch,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import InfoIcon from '@material-ui/icons/Info';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { useAppSelector, featureFlagSlice } from '@dayone/redux';
import { ITeam } from '@dayone/models';
import refData from '@dayone/refdata';
import { SelectedBranches } from 'shared/components/teamSelector';
import { DeleteConfirmDialog } from 'shared/components/deleteConfirmDialog';
import { RichText } from 'shared/components/richtext';
import { UploadImages, IImage } from 'shared/components/uploadImage';

export default function AnnouncementForm(props: {
  motd?: {
    pin: boolean;
    message: string;
    branch: ITeam;
    images: IImage[];
    isScheduled: boolean;
    broadcastDate: string;
  };
  creationLog?: React.ReactNode | undefined;
  branches: ITeam[];
  disabled?: boolean;

  broadcastDateEditable?: boolean;
  onEditBroadcastDate?: (broadcastDate: string) => void;

  displayBackButton?: boolean;
  onBack?: () => void;

  pinable?: boolean;
  onPin?: (pinned: boolean) => void;

  deletable?: boolean;
  onDelete?: () => void;

  showSubmitButton?: boolean;
  onFormSubmit?: (value: {
    pin: boolean;
    message: string;
    branches: ITeam[];
    images: IImage[];
    isScheduled: boolean;
    broadcastDate?: string;
  }) => void;
}) {
  const theme = useTheme();
  const featureFlag = useAppSelector(featureFlagSlice.selectFeatureFlags);
  const { numberOfFileLimit, filesizeLimit } = featureFlag.announcement;

  const formik = useFormik({
    initialValues: props.motd
      ? {
          ...props.motd,
          branches: [props.motd.branch],
        }
      : {
          pin: false,
          message: '',
          isScheduled: false,
          broadcastDate: null,
          branches: [] as ITeam[],
          images: [] as IImage[],
        },
    validationSchema: Yup.object().shape({
      message: Yup.string().required('Required'),
      isScheduled: Yup.boolean(),
      broadcastDate: Yup.string()
        .nullable()
        .when('isScheduled', {
          is: true,
          then: Yup.string().nullable().required('Please select a broadcast date'),
        }),
    }),
    onSubmit: (values) => {
      if (props.onFormSubmit) {
        // dont set a broadcastDate if msg is not scheduled
        const payload = {
          ...values,
          broadcastDate: values.isScheduled && values.broadcastDate ? values.broadcastDate : undefined,
        };
        props.onFormSubmit(payload);
      }
    },
  });

  const handleBranchCheck: (branch: ITeam, checked: boolean) => void = (branch, checked) => {
    if (checked) {
      if (formik.values.branches.findIndex(({ teamId }) => teamId === branch.teamId) === -1)
        formik.setFieldValue('branches', formik.values.branches.concat(branch));
    } else {
      formik.setFieldValue(
        'branches',
        formik.values.branches.filter(({ teamId }) => teamId !== branch.teamId)
      );
    }
  };

  const handleAllBranchCheck = (checked: boolean) => {
    if (checked) {
      formik.setFieldValue('branches', props.branches);
    } else {
      formik.setFieldValue('branches', []);
    }
  };

  const handlePinned = () => {
    if (props.onPin) props.onPin(!formik.values.pin);
    formik.setFieldValue('pin', !formik.values.pin);
  };

  const handleChangeBroadcastDate = (date: any) => {
    if (props.onEditBroadcastDate) props.onEditBroadcastDate(date);

    formik.setFieldValue('broadcastDate', date);
  };

  const handleUploadImage = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length) {
      const newFiles = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        newFiles.push({ file: file, url: URL.createObjectURL(file), action: 'ADD' });
      }
      formik.setFieldValue('images', [...formik.values.images, ...newFiles]);
    }
  };

  const handleRemove = (image: IImage) => {
    if (image.action === 'ADD') {
      formik.setFieldValue(
        'images',
        formik.values.images.filter((img) => img.url !== image.url)
      );
    } else if (image.action === 'UNCHANGE') {
      const newImage = { ...image, action: 'REMOVE' };
      const index = formik.values.images.indexOf(image);
      if (index !== -1) formik.setFieldValue(`images[${index}]`, newImage);
    }
  };

  const pinButton = (
    <Tooltip
      title={
        formik.values.pin ? refData.screen.announcement['button-unpin'] : refData.screen.announcement['button-pin']
      }
    >
      <IconButton onClick={handlePinned}>
        <BookmarkIcon color={formik.values.pin ? 'primary' : 'action'} />
      </IconButton>
    </Tooltip>
  );

  const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] = React.useState(false);
  const handleConfirmDelete = (confirm: boolean) => {
    setDeleteConfirmDialogOpen(false);
    if (confirm) {
      if (props.onDelete) props.onDelete();
    }
  };

  const deleteButton = (
    <>
      <Tooltip title={refData.screen.announcement['button-delete']}>
        <IconButton onClick={() => setDeleteConfirmDialogOpen(true)}>
          <DeleteIcon fontSize="medium" color="secondary" />
        </IconButton>
      </Tooltip>

      <DeleteConfirmDialog
        title="Are you sure you want to delete the message?"
        content="You can’t undo this action."
        open={deleteConfirmDialogOpen}
        onConfirm={handleConfirmDelete}
      />
    </>
  );

  const backButton = (
    <Grid item xs={12}>
      <Box marginLeft={{ xs: 2, sm: 0 }} marginBottom={{ xs: 2 }}>
        <Button color="primary" onClick={props.onBack} startIcon={<ArrowBackIcon />}>
          Back
        </Button>
      </Box>
    </Grid>
  );

  const formContent = (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={8}>
        <Paper elevation={0}>
          <Card elevation={0}>
            <CardContent>
              <Box display="flex" justifyContent="flex-end">
                {props.pinable && pinButton}
                {props.deletable && deleteButton}
              </Box>
              <Box mb={3}>{props.creationLog}</Box>
              <Box mb={4}>
                <RichText
                  label="Message"
                  disabled={props.disabled}
                  defaultValue={formik.values.message}
                  onChange={(val) => formik.setFieldValue('message', val)}
                  error={formik.touched.message && Boolean(formik.errors.message)}
                  helperText={formik.touched.message && formik.errors.message}
                />
              </Box>

              <Grid item xs={12} container>
                <Grid item xs={12} container spacing={2}>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center">
                      <FormControlLabel
                        control={
                          <Switch
                            disabled={props.disabled}
                            name="flag"
                            checked={formik.values.isScheduled}
                            color="primary"
                            onChange={(event, checked) => formik.setFieldValue('isScheduled', checked)}
                          />
                        }
                        label="Future broadcast"
                      />
                    </Box>
                  </Grid>
                  {formik.values.isScheduled && (
                    <Grid item xs={12} sm={7} lg={7}>
                      <Box pb={3}>
                        <FormLabel component="legend">Schedule message for </FormLabel>
                      </Box>
                      <DatePicker
                        disabled={props.disabled && !props.broadcastDateEditable}
                        error={formik.touched.broadcastDate && Boolean(formik.errors.broadcastDate)}
                        helperText={formik.touched.broadcastDate && formik.errors.broadcastDate}
                        invalidDateMessage=""
                        onChange={handleChangeBroadcastDate}
                        value={formik.values.broadcastDate}
                        fullWidth
                        label="Date"
                        type="text"
                        format="DD MMM yyyy"
                        // min broadcastDate is tomorrow
                        minDate={new Date(+new Date() + 86400000)}
                        okLabel={props.disabled ? 'Save' : 'OK'}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        TextFieldComponent={(params: any) => <TextField {...params} variant="outlined" />}
                      />
                      <Box pt={3} />
                      <TimePicker
                        disabled={props.disabled && !props.broadcastDateEditable}
                        invalidDateMessage=""
                        error={formik.touched.broadcastDate && Boolean(formik.errors.broadcastDate)}
                        helperText={formik.touched.broadcastDate && formik.errors.broadcastDate && 'Time is required'}
                        onChange={handleChangeBroadcastDate}
                        value={formik.values.broadcastDate}
                        fullWidth
                        label="Time"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        TextFieldComponent={(params: any) => <TextField {...params} variant="outlined" />}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Box>
                      <Box>
                        <Typography variant="h6">Team</Typography>
                        <Box display="flex" alignItems="center">
                          <Typography style={{ marginRight: theme.spacing(1) }} variant="subtitle1">
                            Broadcast message to team
                          </Typography>
                          <Tooltip title={refData.screen.announcement['broadcast-message-to-branch']}>
                            <InfoIcon color="action" />
                          </Tooltip>
                        </Box>
                      </Box>
                      {!!formik.values?.branches?.length && (
                        <Box p={1}>
                          <SelectedBranches disabled={props.disabled} branches={formik.values.branches} />
                        </Box>
                      )}
                    </Box>
                  </Grid>
                  {!!formik.values.branches?.length && (
                    <Grid item xs={12}>
                      <Typography variant="body1" color="textSecondary">
                        All teams ({formik.values.branches?.length} selected)
                      </Typography>
                    </Grid>
                  )}
                  <Grid item xs={12} container>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            disabled={props.disabled}
                            checked={formik.values.branches.length === props.branches.length}
                            onChange={(event, checked) => {
                              handleAllBranchCheck(checked);
                            }}
                            name="allTeams"
                          />
                        }
                        label="All teams"
                      />
                    </Grid>
                    {props.branches.map((branch) => (
                      <Grid key={branch.teamId} item xs={12} sm={6}>
                        <FormControlLabel
                          style={{ marginLeft: 0 }}
                          control={
                            <Checkbox
                              disabled={props.disabled}
                              color="primary"
                              checked={
                                formik.values.branches.findIndex(({ teamId }) => teamId === branch.teamId) !== -1
                              }
                              onChange={(event, checked) => {
                                handleBranchCheck(branch, checked);
                              }}
                              name={branch.name}
                            />
                          }
                          label={<Typography variant="body1">{branch.name}</Typography>}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Paper>
      </Grid>
      <Grid item xs={12} lg={4}>
        <Paper elevation={0}>
          <Card elevation={0}>
            <CardContent>
              <UploadImages
                isDisabled={props.disabled}
                key={'uploadImage'}
                text="UPLOAD IMAGE"
                acceptType="image/*"
                images={formik.values.images}
                onUploadImage={(event) => handleUploadImage(event)}
                onRemove={(event) => handleRemove(event)}
                limit={numberOfFileLimit}
                fileSizeLimit={filesizeLimit}
                numberOfFileLimitError="Announcement.NumberOfFileLimit"
                fileSizeLimitError="Announcement.FileSizeLimit"
              />
            </CardContent>
          </Card>
        </Paper>
      </Grid>
    </Grid>
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={3}>
        {props.displayBackButton && backButton}
        <Grid item xs={12}>
          {formContent}
        </Grid>
        {props.showSubmitButton && (
          <Grid item xs={12}>
            <Box mt={2}>
              <Button
                size="large"
                color="primary"
                variant="contained"
                type="submit"
                disabled={!formik.values.branches?.length}
              >
                Broadcast message
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
    </form>
  );
}
