"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useKnowledge = void 0;
var react_1 = require("react");
var firestore_1 = require("firebase/firestore");
var firestore;
function KnowledgeStateConfig(_firebaseApp) {
    firestore = (0, firestore_1.getFirestore)(_firebaseApp);
}
exports.default = KnowledgeStateConfig;
// MUST MIRROR THE DATA STRUCTURE OF FIREBASE
/**
 * Firebase hook for getting knowledge
 * This hook is for getting the user's collection knowledge based on company
 * @param {string | null} uid
 * @param {string | null} companyId
 * @returns Firestore knowledge
 *
 */
function useKnowledge(companyId) {
    var _a = (0, react_1.useState)(), knowledges = _a[0], setKnowledges = _a[1];
    (0, react_1.useEffect)(function () {
        var unsubscribe = null;
        if (companyId) {
            unsubscribe = (0, firestore_1.onSnapshot)((0, firestore_1.collection)(firestore, 'companies', companyId, 'faqs'), function (querySnapshot) {
                var docs = [];
                querySnapshot.forEach(function (doc) {
                    docs.push(__assign(__assign({}, doc.data()), { faqId: doc.id }));
                });
                setKnowledges(docs);
            });
        }
        return function () {
            unsubscribe && unsubscribe();
        };
    }, [companyId]);
    return knowledges;
}
exports.useKnowledge = useKnowledge;
