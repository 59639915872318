import { useAuth } from '@dayone/hooks';
import { meSlice, useAppDispatch, useAppSelector } from '@dayone/redux';
import { Box } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect } from 'react';
import { Route, Navigate, useNavigate, Routes } from 'react-router-dom';
import MenuData, { IMenuCategory } from 'shared/utilities/menuData';
import { SideNav } from 'shared/components/navigations';
import { TopNav } from 'shared/components/navigations';
import { ProgressIndicator } from 'shared/components/progressIndicator';
import OnboardingRoute from './OnboardingRoute';
import AuthenticationRoute from './AuthenticationRoute';
import MainContentRoute from './MainRoute';
import { withAccount } from 'shared/components/data';
import analytics from 'shared/utilities/analytics';
import intercom from 'shared/utilities/intercom';

const categories: IMenuCategory[] = MenuData;

/**
 * Main router
 */

// export default NavigationRoute;
export default function NavigationRoute() {
  const dispatch = useAppDispatch();
  const firebaseUser = useAuth();

  useEffect(() => {
    if (firebaseUser.initialised) {
      dispatch(meSlice.setDetails(firebaseUser.user));
    }
  }, [firebaseUser, dispatch]);

  // Waiting for firebase SDK auth to initialise
  // Waiting for internalAdmins data to initilize too
  if (firebaseUser.initialised) {
    // when user logged-in successfully
    if (firebaseUser.user) {
      return <PostLoginRoute />;
    }
    //when user haven't logged-in yet.
    return <PreLoginRoute />;
  }

  // Waiting for auth to init
  return <ProgressIndicator />;
}

/**
 *  Main content routing will construct the page
 */
const PostLoginRoute = withAccount(() => {
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const isFullyRegistered = useAppSelector<any>(meSlice.selectIsFullyRegistered);

  // User is not fully registered
  if (!isFullyRegistered) {
    return <OnboardingRoute />;
  }

  return (
    <React.Fragment>
      <SideNav
        mobileOpen={menuOpen}
        handleDrawerToggle={() => setMenuOpen((prev) => !prev)}
        categories={categories}
        displayName="Administrator"
        role="admin"
        workspaceName="Workspace Name"
        callback={(action, item) => {
          if (item) {
            navigate(item.url);
            analytics.pageView(item.url);
            // intercom.update(item.url);
            setMenuOpen(false);
          }
        }}
      />
      <Box className={classes.container} display="flex" flexDirection="column" flexGrow={1}>
        <TopNav
          handleDrawerToggle={() => setMenuOpen((prev) => !prev)}
          callback={(action: string) => {
            switch (action) {
              case 'LOGOUT':
              default:
                navigate('/logout');
                break;
            }
          }}
        />
        <Box px={[2, 5]} pb={7}>
          <MainContentRoute />
        </Box>
      </Box>
    </React.Fragment>
  );
});

/**
 *  Prelogin route
 */

function PreLoginRoute() {
  return (
    <Routes>
      <Route path="/authentication/*" element={<AuthenticationRoute />} />
      <Route path="/logout" element={<Navigate replace to="/authentication/login" />} />
      <Route path="*" element={<Navigate replace to="/authentication/login" />} />
    </Routes>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginLeft: '260px',
      [theme.breakpoints.down('sm')]: {
        marginLeft: 'auto',
      },
    },
  })
);
