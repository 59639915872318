"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useWorkspace = void 0;
var react_1 = require("react");
var firestore_1 = require("firebase/firestore");
var firebaseApp;
var firestore;
function WorkspaceStateConfig(_firebaseApp) {
    firebaseApp = _firebaseApp;
    firestore = (0, firestore_1.getFirestore)(firebaseApp);
}
exports.default = WorkspaceStateConfig;
/**
 * Firebase hook for getting team
 * This hook is for getting the user's collection team based on company
 * @param {string | null} companyId
 * @param {boolean} isInternalAdmin if internal admins then we have to fetch all workspace
 * @returns Firestore team
 *
 */
function useWorkspace(companyIds, isInternalAdmin) {
    if (isInternalAdmin === void 0) { isInternalAdmin = false; }
    var _a = (0, react_1.useState)(), companies = _a[0], setCompanies = _a[1];
    (0, react_1.useEffect)(function () {
        var unsubscribe = null;
        if (isInternalAdmin) {
            var q = (0, firestore_1.query)((0, firestore_1.collection)(firestore, 'companies'));
            unsubscribe = (0, firestore_1.onSnapshot)(q, function (snapshot) {
                var docs = [];
                snapshot.docs.forEach(function (doc) {
                    docs.push(__assign(__assign({}, doc.data()), { id: doc.id }));
                });
                setCompanies(docs);
            });
        }
        else {
            if (companyIds && companyIds.length > 0) {
                var batch = splitArrayChunks(companyIds);
                // TODO This is major issue, we only allow 10 workspaces max
                var q = (0, firestore_1.query)((0, firestore_1.collection)(firestore, 'companies'), (0, firestore_1.where)((0, firestore_1.documentId)(), 'in', batch[0]));
                unsubscribe = (0, firestore_1.onSnapshot)(q, function (snapshot) {
                    var docs = [];
                    snapshot.docs.forEach(function (doc) {
                        docs.push(__assign(__assign({}, doc.data()), { id: doc.id }));
                    });
                    setCompanies(docs);
                });
            }
            else {
                setCompanies([]);
            }
        }
        return function () {
            unsubscribe && unsubscribe();
        };
    }, [JSON.stringify(companyIds), isInternalAdmin]);
    return companies;
}
exports.useWorkspace = useWorkspace;
var splitArrayChunks = function (inputArray) {
    var perChunk = 10;
    var i, j;
    var batch = [];
    for (i = 0, j = inputArray.length; i < j; i += perChunk) {
        batch.push(inputArray.slice(i, i + perChunk));
    }
    return batch;
};
