import { Typography, Box, Link } from '@material-ui/core';
import googlePlayBtnIcon from '../../assets/google-play-btn.png';
import appleStoreBtnIcon from '../../assets/apple-store-btn.png';

export default function Dashboard() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      style={{ height: 'calc(100vh - 260px)' }}
    >
      <Box my={3}>
        <Typography variant="h5" align="center">
          For first time users <br />
          Please download and log on to DayOne App for the mobile version of DayOne
        </Typography>
      </Box>
      <Box display="flex">
        <Link target={'_blank'} href="https://apps.apple.com/pl/app/dayone-productivity/id1548855109">
          <Box mx={2}>
            <img width="170px" height="51px" alt="appstore logo" src={appleStoreBtnIcon} />
          </Box>
        </Link>
        <Link target={'_blank'} href="https://play.google.com/store/apps/details?id=expert.dayone.app">
          <Box mx={2}>
            <img width="170px" height="51px" alt="google play logo" src={googlePlayBtnIcon} />
          </Box>
        </Link>
      </Box>
    </Box>
  );
}
