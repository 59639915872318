import { FC, useState } from 'react';
import { Box, Dialog, Stepper, Step, StepLabel } from '@material-ui/core';

import ReLogin from './ReLogin';
import Update from './Update';
import Complete from './Complete';

interface Props {
  updateField: 'email' | 'phone' | null;
  setUpdateField: (updateField: 'phone' | 'email' | null) => void;
}

const UpdateFieldDialog: FC<Props> = ({ updateField, setUpdateField }) => {
  const open = !!updateField;
  const onClose = () => {
    setUpdateField(null);
    setActiveStep(0);
    setCredential(null);
  };

  const isEmail = updateField === 'email';

  const steps = ['Re-login', isEmail ? 'Update Email' : 'Update Number', 'Complete'];

  const [activeStep, setActiveStep] = useState<number>(0);

  const next = () => setActiveStep(activeStep + 1);

  const [credential, setCredential] = useState<any>(null);

  if (!updateField) return null;

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <Stepper activeStep={activeStep} style={{ padding: '40px 32px 0px' }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box m={5} border="1px solid #ccc" borderRadius={0.5} boxShadow="0px 0px 0px 1px #e0e0e0">
        {activeStep === 0 ? (
          <ReLogin next={next} cancel={onClose} setCredential={setCredential} />
        ) : activeStep === 1 ? (
          <Update updateField={updateField} cancel={onClose} next={next} credential={credential} />
        ) : (
          <Complete updateField={updateField} cancel={onClose} />
        )}
      </Box>
    </Dialog>
  );
};

export default UpdateFieldDialog;
