import { IStorageBucketConfig } from '@dayone/firebase';

const config = {
  apiKey: process.env['REACT_APP_FIREBASE_API_KEY'],
  authDomain: process.env['REACT_APP_FIREBASE_AUTH_DOMAIN'],
  projectId: process.env['REACT_APP_FIREBASE_PROJECT_ID'],
  storageBucket: process.env['REACT_APP_FIREBASE_STORAGE_BUCKET'],
  messagingSenderId: process.env['REACT_APP_FIREBASE_MESSAGING_SENDER_ID'],
  appId: process.env['REACT_APP_FIREBASE_APP_ID'],
  measurementId: process.env['REACT_APP_FIREBASE_MEASUREMENT_ID'],
};

const storageBucketConfig: IStorageBucketConfig = {
  repository: process.env['REACT_APP_FIREBASE_STORAGE_BUCKET_REPOSITORY'],
  knowledge: process.env['REACT_APP_FIREBASE_STORAGE_BUCKET_KNOWLEDGE'],
  playbook: process.env['REACT_APP_FIREBASE_STORAGE_BUCKET_PLAYBOOK'],
  announcement: process.env['REACT_APP_FIREBASE_STORAGE_BUCKET_ANNOUNCEMENT'],
};

const env = {
  FIREBASE: {
    useFirebaseEmulator: process.env['REACT_APP_USE_FIREBASE_EMULATOR'] === 'true',
    config,
    storageBucketConfig,
  },
  SENTRY: {
    dsn: process.env['REACT_APP_SENTRY'],
    tracesSampleRate: parseFloat(process.env['REACT_APP_SENTRY_SAMPLERATE'] ?? '1.0'),
  },
  XGRID: {
    license: process.env['REACT_APP_XGRID_LICENSE'] ?? '',
  },
  TINYMCE: {
    serial: process.env['REACT_APP_TINYMCE_SERIAL'] ?? '',
  },
  HOTJAR: {
    id: process.env['REACT_APP_HOTJAR_ID'] ?? '',
  },
  MIXPANEL: {
    id: process.env['REACT_APP_MIXPANEL_ID'] ?? '',
  },
  CLARITY: {
    key: process.env['REACT_APP_CLARITY_KEY'] ?? '',
  },
  INTERCOM: {
    id: process.env['REACT_APP_INTERCOM_ID'] ?? '',
  },
  RECAPTCHA: {
    key: process.env['REACT_APP_RECAPTCHA_KEY'] ?? '',
    debugToken: process.env['REACT_APP_APPCHECK_DEBUG_TOKEN'] ?? '',
    enableAppCheckDebug: process.env['REACT_APP_FIREBASE_APPCHECK_DEBUG_TOKEN'] === 'true',
  },
};

export default env;
