import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Typography } from '@material-ui/core';
import React, { useCallback } from 'react';
import { TaskStatus } from '@dayone/models';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';

const statusFilter = [
  { label: 'Open', value: TaskStatus.open.toString() },
  { label: 'Close', value: TaskStatus.closed.toString() },
];

export default function TaskOverviewFilter(props: {
  selectedBranches: string[];
  onBranchChange: (checked: boolean, value: string) => void;
  selectedStatus: string[];
  onStatusChange: (checked: boolean, value: string) => void;
  allBranches: { label: string; value: string }[];
  onSearchAllTasks: () => void;
}) {
  const renderFilter = useCallback(
    (
      title: string,
      selectedValues: string[],
      items: { label: string; value: string }[],
      onChange: (checked: boolean, value: string) => void
    ) => {
      return (
        <Box pb={3}>
          <FormControl component="fieldset">
            <FormLabel component="legend">{title}</FormLabel>
            <FormGroup>
              {items.map((item, index) => {
                return (
                  <FormControlLabel
                    key={index}
                    style={{ marginLeft: 10 }}
                    label={`${item.label}`}
                    control={
                      <Checkbox
                        id={`${index}`}
                        checked={selectedValues.some((value) => value === item.value)}
                        color="primary"
                        onChange={(event, checked) => onChange(checked, item.value)}
                        name={`${item.label}`}
                      />
                    }
                  />
                );
              })}
            </FormGroup>
          </FormControl>
        </Box>
      );
    },
    []
  );

  return (
    <>
      {renderFilter('Teams', props.selectedBranches, props.allBranches, props.onBranchChange)}
      {renderFilter('Status', props.selectedStatus, statusFilter, props.onStatusChange)}
      <Button variant="text" size="large" startIcon={<SearchIcon color="action" />} onClick={props.onSearchAllTasks}>
        <Typography color="textSecondary" style={{ fontWeight: 500 }}>
          search all tasks
        </Typography>
      </Button>
    </>
  );
}
