import { Card, CardContent, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { announcementSlice, RootState, useAppSelector } from '@dayone/redux';
import { generatePath, useNavigate } from 'react-router-dom';
import AnnouncementTable from './components/AnnouncementTable';

export default function AnnouncementArchive() {
  const navigate = useNavigate();
  const announcements: announcementSlice.IRemappedAnnouncement[] = useAppSelector<RootState>(
    announcementSlice.selectAnnouncements
  ).announcements;

  const handleNavigateToEdit = (id: string | number) => {
    navigate(generatePath(`../edit/:id`, { id: id.toString() }));
  };

  return (
    <Paper elevation={0} square>
      <Card elevation={0}>
        <CardContent>
          <Typography variant="h6">Archived Messages</Typography>
          <AnnouncementTable
            data={announcements.filter((motd) => motd.isArchived)}
            navigateToEdit={handleNavigateToEdit}
          />
        </CardContent>
      </Card>
    </Paper>
  );
}
