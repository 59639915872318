import React, { Ref, PropsWithChildren } from 'react';
import { cx, css } from '@emotion/css';

import { BaseProps } from 'shared/utilities/richTextHelpers';

const Menu = React.forwardRef(({ className, ...props }: PropsWithChildren<BaseProps>, ref: Ref<HTMLDivElement>) => (
  <div
    {...props}
    ref={ref}
    className={cx(
      className,
      css`
        & > * {
          display: inline-block;
        }
        & > * + * {
          margin-left: 15px;
        }
      `
    )}
  />
));

export default Menu;
