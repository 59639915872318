import { FC } from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';

interface Props {
  imageSrc: string;
  emptyText: string;
  suggestion: String;
}

const EmptyData: FC<Props> = ({ imageSrc, emptyText, suggestion }) => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      style={{ marginTop: theme.spacing(10) }}
    >
      <img src={imageSrc} alt="" />
      <Typography style={{ fontWeight: 500, fontSize: 14 }}>{emptyText}</Typography>
      <Typography style={{ fontWeight: 400, fontSize: 14, textAlign: 'center' }}>{suggestion}</Typography>
    </Box>
  );
};

export default EmptyData;
