import React from 'react';
import { TextField, InputAdornment } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';

export default function SearchInput(props: { searchText: string; onSearchTextChange: (newText: string) => void }) {
  const { searchText, onSearchTextChange } = props;
  return (
    <TextField
      autoComplete="off"
      variant="outlined"
      fullWidth
      size="small"
      id="search"
      label="Search for file"
      value={searchText}
      onChange={(event) => onSearchTextChange(event.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon color="action" />
          </InputAdornment>
        ),
      }}
    />
  );
}
