import { FC } from 'react';
import { TextField, Button, Grid, Box } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useFormik, FormikHelpers } from 'formik';
import * as yup from 'yup';

import { meSlice, useAppSelector } from '@dayone/redux';
import { signInWithEmailAndPassword } from '@dayone/firebase';
import { ProgressIndicator } from 'shared/components/progressIndicator';

interface IFormikInitialValue {
  email: string;
  password: string;
}

const validationSchema = yup.object({
  email: yup.string().email('Enter a valid email').defined('Email is required'),
  password: yup.string().defined('Password is required'),
});

interface Props {
  cancel: () => void;
  next: () => void;
  setCredential: (credential: any) => void;
}

const LoginEmail: FC<Props> = ({ cancel, next, setCredential }) => {
  const details = useAppSelector(meSlice.selectDetails);

  const onSubmit = async (values: IFormikInitialValue, { setSubmitting }: FormikHelpers<IFormikInitialValue>) => {
    try {
      const { email, password } = values;
      const credential = await signInWithEmailAndPassword(email, password);
      if (credential) {
        setCredential(credential);
      }
      next();
    } catch (err: any) {
      formik.setStatus(err.message);
    }
    setSubmitting(false);
  };

  const initialValues: IFormikInitialValue = {
    email: details?.email || '',
    password: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: onSubmit,
    enableReinitialize: true,
  });
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        {formik.isSubmitting && <ProgressIndicator />}

        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Box px={8}>
              <Grid item container direction="column" spacing={3}>
                <Grid item>
                  {formik.status && (
                    <Alert variant="outlined" severity="error" style={{ marginTop: 16 }}>
                      {formik.status}
                    </Alert>
                  )}
                </Grid>
                <Grid item>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="email"
                    label="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    disabled
                  />
                </Grid>
                <Grid item>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="password"
                    label="Password"
                    type="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Box mt={5} display="flex" justifyContent="flex-end">
          <Button
            variant="text"
            color="primary"
            disabled={formik.isSubmitting}
            style={{ marginRight: 2 }}
            onClick={(e) => {
              e.preventDefault();
              cancel();
            }}
          >
            Cancel
          </Button>
          <Button type="submit" variant="text" color="primary" disabled={formik.isSubmitting}>
            Login
          </Button>
        </Box>
      </form>
    </>
  );
};

export default LoginEmail;
