import { Box, Container } from '@material-ui/core';
import React from 'react';
import { Logo } from 'shared/components/logo';
import ForgotPasswordSuccess from './components/ForgotPasswordSuccess';
import ForgotPasswordForm from './components/ForgotPasswordForm';

export default function ForgotPassword() {
  const [success, setSuccess] = React.useState(false);

  if (success) {
    return <ForgotPasswordSuccess />;
  }

  return (
    <React.Fragment>
      <Box m={5} mb={8}>
        <Logo />
      </Box>
      <Container maxWidth="md">
        <ForgotPasswordForm onSuccess={() => setSuccess(true)} />
      </Container>
    </React.Fragment>
  );
}
