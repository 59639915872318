import { Box, Button, Tooltip } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { CloudUpload } from '@material-ui/icons';
import React from 'react';
import refData from '@dayone/refdata';

import { IImage } from 'shared/components/uploadImage';

function UploadAvatar(props: {
  acceptType: string;
  image?: IImage;
  onUploadImage?: React.ChangeEventHandler<HTMLInputElement>;
  onRemove?: (image: IImage) => void;
  isDisabled?: boolean;
}) {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Box className={classes.root} flex="column">
      <input
        accept={`${props.acceptType}`}
        style={{
          display: 'none',
        }}
        id={`${props.acceptType}`}
        type="file"
        onChange={(event) => {
          if (props.onUploadImage) {
            props.onUploadImage(event);
          }
        }}
      />
      <Box display="flex" justifyContent="center" pb={2} pr={2} pl={2}>
        <label htmlFor={`${props.acceptType}`}>
          <Tooltip title={refData.screen.profile['button-upload-avatar']}>
            <Button
              disabled={props.isDisabled}
              style={{
                width: theme.spacing(36),
              }}
              variant="outlined"
              color="primary"
              component="span"
              startIcon={<CloudUpload />}
            >
              UPLOAD IMAGE
            </Button>
          </Tooltip>
        </label>
      </Box>
      <Box display="flex" justifyContent="center" pb={2} pr={2} pl={2}>
        <Button
          onClick={() => {
            if (props.onRemove && props.image) props.onRemove(props.image);
          }}
          color="primary"
        >
          REMOVE IMAGE
        </Button>
      </Box>
      {/* <Box height={theme.spacing(4)}></Box> */}
    </Box>
  );
}

export default UploadAvatar;

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),

    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
  },
}));
