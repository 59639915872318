import { IRepositoryModel } from '@dayone/models';
import { repositorySlice, useAppSelector } from '@dayone/redux';
import { Box } from '@material-ui/core';
import React from 'react';
import RenameDialog from './CreateOrEditFolderDialog';

export default function RenameRepository(props: {
  children: JSX.Element | JSX.Element[];
  repository: IRepositoryModel;
  containerStyle?: React.CSSProperties;
}) {
  const parent = useAppSelector<any>(repositorySlice.selectRepositoryByPath)(props.repository.folderPath);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const handleOpenDialog = () => {
    setDialogOpen(true);
  };
  return (
    <>
      <Box style={props.containerStyle} onClick={handleOpenDialog}>
        {props.children}
      </Box>
      <RenameDialog
        parentId={parent?.id ?? ''}
        repository={props.repository}
        open={dialogOpen}
        setOpen={(_open) => setDialogOpen(_open)}
      />
    </>
  );
}
