import {
  makeStyles,
  useTheme,
  Theme,
  createStyles,
  TextField,
  Chip,
  Button,
  CardContent,
  useMediaQuery,
} from '@material-ui/core';
import * as yup from 'yup';
import { Avatar, Box, Grid, Typography, Paper, Card } from '@material-ui/core';
import { useFormik } from 'formik';
import { IImage } from 'shared/components/uploadImage';
import { IAccountState, IProfile, meSlice, teamSlice, useAppDispatch, useAppSelector } from '@dayone/redux';
import UploadAvatar from './components/UploadAvatar';
import { useState } from 'react';
import { ITeam } from '@dayone/models';
import { useSnackbar } from 'notistack';
import { ProgressIndicator } from 'shared/components/progressIndicator';

export default function Profile() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const account: any = useAppSelector<any>(meSlice.selectAccount);
  const details: any = useAppSelector<any>(meSlice.selectDetails);
  const activeTeam = useAppSelector<ITeam[] | undefined>(teamSlice.selectActiveTeams);

  const teams = activeTeam?.filter((team) => team.users?.indexOf(details.uid) !== -1);
  const initialAccountValue =
    account && details
      ? ({
          image: {
            url: details.photoURL,
          },
          firstName: account.firstName,
          lastName: account.lastName,
          displayName: account.displayName,
          // teams: _.sortBy(teams, 'name'),
        } as IProfileForm)
      : ({} as IProfileForm);

  const onSubmit = (profileForm: IProfileForm) => {
    const email = (account?.email as string) ?? '';

    dispatch(
      meSlice.updateAccount({
        account: {
          firstName: profileForm.firstName ? profileForm.firstName : null,
          lastName: profileForm.lastName ? profileForm.lastName : null,
          displayName: profileForm.displayName ? profileForm.displayName : null,
          email: email.toLowerCase(),
        } as IAccountState,
        photo: profileForm.image.file ? profileForm.image.file : null,
      } as IProfile)
    )
      .then(() => {
        enqueueSnackbar('Your personal details have been updated successfully!', {
          variant: 'success',
          anchorOrigin: {
            vertical: matches ? 'bottom' : 'top',
            horizontal: matches ? 'center' : 'right',
          },
        });
      })
      .catch((err) => {})
      .finally(() => {
        formik.setSubmitting(false);
      });
  };
  const formik = useFormik({
    initialValues: initialAccountValue,
    onSubmit: onSubmit,
    validationSchema: validationFormSchema,
  });
  const [avatar, setAvatar] = useState(formik.values.image);

  const handleUploadImage = (event: any) => {
    const file = event.target.files[0] as File;
    const url = URL.createObjectURL(file);
    const selectedAvatar: IImage = { file: file, url: url, action: 'ADD' };
    formik.setFieldValue('image', selectedAvatar);
    setAvatar(selectedAvatar);
  };

  const handleRemove = (image: IImage) => {
    formik.setFieldValue('image', { ...image, action: 'REMOVE' });
    setAvatar({} as IImage);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={3}>
        <Grid item>
          <Paper elevation={0}>
            <Card elevation={0}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item lg={4} container direction="column">
                    <Box p={2}>
                      <Box display="flex" justifyContent="flex-start" pb={2}>
                        <Typography variant="h6">Profile Photo</Typography>
                      </Box>
                      <Box display="flex" justifyContent="center" p={2}>
                        <Avatar
                          className={classes.avatar}
                          alt={avatar ? avatar.alt : ''}
                          src={avatar ? avatar.url : ''}
                        ></Avatar>
                      </Box>
                      <UploadAvatar
                        key={'uploadImage'}
                        acceptType="image/*"
                        image={formik.values.image}
                        onUploadImage={(event) => handleUploadImage(event)}
                        onRemove={(event) => handleRemove(event)}
                        // isDisabled
                      />
                    </Box>
                  </Grid>
                  <Grid item lg={8} container direction="column">
                    <Box m={3}>
                      <Grid item container spacing={2}>
                        <Grid item sm={7} xs={12}>
                          <TextField
                            fullWidth
                            label="First name"
                            name="firstName"
                            rows={2}
                            defaultValue={formik.values.firstName}
                            variant="outlined"
                            onChange={formik.handleChange}
                            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                            helperText={formik.touched.firstName && formik.errors.firstName}
                            inputProps={{
                              maxLength: 60,
                            }}
                          />
                        </Grid>
                        <Grid item sm={5} xs={12}>
                          <TextField
                            fullWidth
                            label="Last name"
                            name="lastName"
                            rows={2}
                            defaultValue={formik.values.lastName}
                            variant="outlined"
                            onChange={formik.handleChange}
                            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                            helperText={formik.touched.lastName && formik.errors.lastName}
                            inputProps={{
                              maxLength: 60,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            label="Display name"
                            name="displayName"
                            rows={2}
                            defaultValue={formik.values.displayName}
                            variant="outlined"
                            onChange={formik.handleChange}
                            error={formik.touched.displayName && Boolean(formik.errors.displayName)}
                            helperText={formik.touched.displayName && formik.errors.displayName}
                            inputProps={{
                              maxLength: 60,
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Box pt={4} />
                      <Grid container direction="column">
                        <Box alignItems="flex-start" mb={2}>
                          <Typography variant="h6">Assigned Teams</Typography>
                        </Box>
                        <Box>
                          {teams?.map((team, index) => (
                            <Chip
                              key={index}
                              label={`${team.name}`}
                              style={{ marginRight: theme.spacing(2), marginBottom: theme.spacing(1) }}
                            />
                          ))}
                        </Box>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Paper>
        </Grid>

        <Grid item xs={2} md={12}>
          <Box pt={2} />
          <Button type="submit" variant="contained" color="primary">
            Update
          </Button>
        </Grid>
      </Grid>
      <ProgressIndicator loading={formik.isSubmitting} />
    </form>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      width: '122px',
      height: '122px',
    },
  })
);

const validationFormSchema = yup.object({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name required'),
  displayName: yup.string().required('Display name is required'),
});
interface IProfileForm {
  image: IImage;
  firstName: string;
  lastName: string;
  displayName: string;
  // teams: ITeam[];
}
