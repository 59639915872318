"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setFeatureFlags = exports.selectFeatureFlags = exports.featureFlagSliceConfig = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var firestore_1 = require("firebase/firestore");
var firestore;
var featureFlagSliceConfig = function (_firebaseApp) {
    // eslint-disable-next-line
    firestore = (0, firestore_1.getFirestore)(_firebaseApp);
};
exports.featureFlagSliceConfig = featureFlagSliceConfig;
var initialState = {
    initialised: false,
    featureFlags: {
        user: { maxStaff: 0, maxAdmin: 0 },
        announcement: { numberOfFileLimit: 0, filesizeLimit: 0 },
        team: { maxTeam: 0 },
        task: { numberOfFileLimit: 0, filesizeLimit: 0, numberOfFileLimitComment: 0, filesizeLimitComment: 0 },
        repository: { storageLimit: 0, filesizeLimitImage: 0, filesizeLimitVideo: 0, filesizeLimitPdf: 0 },
        knowledge: {
            storageLimit: 0,
            numberOfFileLimitImage: 0,
            numberOfFileLimitVideo: 0,
            filesizeLimitImage: 0,
            filesizeLimitVideo: 0,
        },
    },
};
var featureFlagSlice = (0, toolkit_1.createSlice)({
    name: 'featureFlag',
    initialState: initialState,
    reducers: {
        setFeatureFlags: {
            reducer: function (state, action) {
                if (action.payload) {
                    state.initialised = true;
                    state.featureFlags = action.payload;
                }
            },
            prepare: function (payload) {
                var _a, _b, _c, _d, _e, _f;
                if (payload) {
                    var featureFlagModel = {
                        user: (_a = payload.user) !== null && _a !== void 0 ? _a : { maxStaff: 0, maxAdmin: 0 },
                        announcement: (_b = payload.announcement) !== null && _b !== void 0 ? _b : { numberOfFileLimit: 0, filesizeLimit: 0 },
                        team: (_c = payload.team) !== null && _c !== void 0 ? _c : { maxTeam: 0 },
                        task: (_d = payload.task) !== null && _d !== void 0 ? _d : {
                            numberOfFileLimit: 0,
                            filesizeLimit: 0,
                            numberOfFileLimitComment: 0,
                            filesizeLimitComment: 0,
                        },
                        repository: (_e = payload.repository) !== null && _e !== void 0 ? _e : {
                            storageLimit: 0,
                            filesizeLimitImage: 0,
                            filesizeLimitVideo: 0,
                            filesizeLimitPdf: 0,
                        },
                        knowledge: (_f = payload.knowledge) !== null && _f !== void 0 ? _f : {
                            storageLimit: 0,
                            numberOfFileLimitImage: 0,
                            numberOfFileLimitVideo: 0,
                            filesizeLimitImage: 0,
                            filesizeLimitVideo: 0,
                        },
                    };
                    return { payload: featureFlagModel };
                }
                return { payload: null };
            },
        },
    },
});
// Selectors
var selectFeatureFlags = function (state) { return state.featureFlag.featureFlags; };
exports.selectFeatureFlags = selectFeatureFlags;
// Actions
exports.setFeatureFlags = featureFlagSlice.actions.setFeatureFlags;
exports.default = featureFlagSlice.reducer;
