import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LoginPhone from 'screens/authentication/LoginPhone';
import LoginEmail from 'screens/authentication/LoginEmail';
import ForgotPassword from 'screens/authentication/ForgotPassword';

export default function AuthenticationRoute() {
  return (
    <React.Fragment>
      <Routes>
        <Route path={`/login`} element={<LoginPhone />} />
        <Route path={`/email`} element={<LoginEmail />} />
        <Route path={`/forgotpassword`} element={<ForgotPassword />} />
      </Routes>
    </React.Fragment>
  );
}
