import FBAnalytics, { FirebaseAnalyticsConfig } from '@dayone/firebase-analytics';
import firebaseAnalytics from '@dayone/firebase-analytics';
import { FirebaseApp } from '@firebase/app';
import mixpanel from 'mixpanel-browser';
import env from './environment';

import GAEvents from './events';

const hasMixPanel = env.MIXPANEL.id.length > 0;

const config = (firebaseApp: FirebaseApp) => {
  FirebaseAnalyticsConfig(firebaseApp);
};

const getBaseDimensions: () => { time?: number; osName: string; platform: string } = () => {
  const now = new Date();
  const time = now.getHours() * 60 + now.getMinutes();
  const osName = navigator?.userAgentData?.platform || navigator?.platform || 'unknown';
  const platform = 'web';

  return { time, osName, platform };
};

const logEvent = async (name: string, params?: object) => {
  const baseDimensions = getBaseDimensions();
  FBAnalytics.logEvent(`${name}`, { ...baseDimensions, ...params });

  if (hasMixPanel) {
    delete baseDimensions.time;
    mixpanel.track(name, { ...baseDimensions, ...params });
  }
};

const pageView = (url: string) => {
  firebaseAnalytics.logEvent(GAEvents.page_view, {
    page_path: `web: ${url}`,
  });
};

const uniqueUser = (firebaseUser: any) => {
  firebaseAnalytics.logUser(firebaseUser);
};

const setCompany = (company: { companyID: string; companyName: string }) => {
  firebaseAnalytics.logCompany(company);
};

const analytics = { config, logEvent, pageView, uniqueUser, setCompany };

export default analytics;
