"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePromo = void 0;
var react_1 = require("react");
var firestore_1 = require("firebase/firestore");
var firestore;
function PromoStateConfig(_firebaseApp) {
    firestore = (0, firestore_1.getFirestore)(_firebaseApp);
}
exports.default = PromoStateConfig;
/**
 * Firebase hook for getting promos
 * This hook is for getting all promos
 * @returns Firestore promos
 *
 */
function usePromo() {
    var _a = (0, react_1.useState)(), promos = _a[0], setPromos = _a[1];
    (0, react_1.useEffect)(function () {
        var unsubscribe = null;
        var promosRef = (0, firestore_1.collection)(firestore, 'promos');
        unsubscribe = (0, firestore_1.onSnapshot)(promosRef, function (snapshot) {
            var docs = [];
            snapshot.forEach(function (doc) {
                docs.push(__assign({ promoId: doc.id }, doc.data()));
            });
            setPromos(docs);
        });
        return function () {
            unsubscribe && unsubscribe();
        };
    }, []);
    return promos;
}
exports.usePromo = usePromo;
