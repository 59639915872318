import { Box, Avatar, Typography, Button, useTheme, capitalize } from '@material-ui/core';

const SharingUser = (props: {
  avatarUrl: string;
  avatarType: 'square' | 'circle';
  name: string;
  title: string;
  subTitle: string;
  sx?: object;
  onRemove?: Function | undefined;
}) => {
  const { name, avatarUrl, avatarType, title, subTitle, onRemove, ...restProps } = props;
  const theme = useTheme();

  return (
    <Box display="flex" flexDirection="row" alignItems="center" sx={{ mt: 2.5 }} {...restProps}>
      <Avatar variant={avatarType} src={avatarUrl}>
        {name && name[0]}
      </Avatar>
      <Box
        sx={{ ml: 2 }}
        flexDirection="row"
        display="flex"
        justifyContent="space-between"
        flex={1}
        alignItems="center"
      >
        <Box>
          <Typography variant="body2" color="textPrimary">
            {title}
          </Typography>
          <Typography variant="body2" style={{ color: theme.palette.action.active }}>
            {capitalize(subTitle ?? '')}
          </Typography>
        </Box>
        {onRemove && (
          <Button variant="outlined" color="primary" onClick={() => onRemove()}>
            REMOVE
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default SharingUser;
