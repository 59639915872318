import { useSlate } from 'slate-react';
import { Editor, Transforms, Element as SlateElement, BaseEditor, BaseText } from 'slate';
import { DescendantExt } from 'shared/utilities/richTextHelpers';

import Button from './Button';
import Icon from './Icon';

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

export const isBlockActive = (editor: any, format: any) => {
  const nodes = Editor.nodes(editor, {
    match: (n) => (n as DescendantExt).type === format,
  });
  return !!nodes.next().value;
};

const toggleBlock = (editor: BaseEditor, format: string) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);
  Transforms.unwrapNodes(editor, {
    match: (n) => LIST_TYPES.includes(!Editor.isEditor(n) && SlateElement.isElement(n) && (n as any).type),
    split: true,
  });
  const newProperties = {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  };
  Transforms.setNodes(editor, newProperties as Partial<BaseText>);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const BlockButton = ({ format, icon }: { format: string; icon: string }) => {
  const editor = useSlate();
  return (
    <Button
      active={isBlockActive(editor, format)}
      onMouseDown={(event: Event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  );
};

export default BlockButton;
