import { useCallback } from 'react';
import { Box, IconButton, Typography, Breadcrumbs, Link, useTheme } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { People as PeopleIcon, NavigateNext as NavigateNextIcon } from '@material-ui/icons';

export default function Breadscrumbs(props: {
  folderPath: string;
  setFolderPath: (path: string) => void;
  onShareFolder: (event: any) => void;
  setPage: (page: number) => void;
}) {
  const theme = useTheme();
  const { folderPath, setFolderPath, onShareFolder, setPage } = props;
  const paths = folderPath ? folderPath.split('/').filter((path) => !isEmpty(path)) : [];
  const isInRoot = paths.length === 0;

  const redactFolderName = useCallback((name: string) => {
    try {
      if (name.length >= 25) return `${name.slice(0, 22)}...`;
      return name;
    } catch {
      return '';
    }
  }, []);

  return (
    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
      <Link
        component="button"
        disabled={isInRoot}
        color="inherit"
        onClick={() => setFolderPath('')}
        style={{ height: theme.spacing(5.5) }}
      >
        <Typography variant="h6">All files</Typography>
      </Link>
      {!isInRoot &&
        paths.map((path, index) =>
          index < paths.length - 1 ? (
            <Link
              key={index}
              component="button"
              color="inherit"
              onClick={() => {
                setFolderPath(paths.slice(0, index + 1).join('/'));
                setPage(0);
              }}
            >
              <Typography variant="h6">{redactFolderName(path)}</Typography>
            </Link>
          ) : (
            <Box
              key={index}
              flexDirection="row"
              display="flex"
              alignItems="center"
              justifyContent="center"
              height={theme.spacing(5.5)}
            >
              <Typography variant="h6" color="textPrimary">
                {redactFolderName(path)}
              </Typography>
              <IconButton size="medium" onClick={onShareFolder}>
                <PeopleIcon fontSize="small" />
              </IconButton>
            </Box>
          )
        )}
    </Breadcrumbs>
  );
}
