import { Grid, Paper, Card, CardContent, Box, useMediaQuery, useTheme } from '@material-ui/core';
import { meSlice, useAppDispatch, useAppSelector } from '@dayone/redux';
import VerifyBox from './components/VerifyBox';
import React, { useState } from 'react';
import PhoneVerifyDialog from './components/PhoneVerifyDialog';
import ResetPassword from './components/ResetPassword';
import UpdateFieldDialog from './components/UpdateFieldDialog';
import { ProgressIndicator } from 'shared/components/progressIndicator';
import { useSnackbar } from 'notistack';

export default function Account() {
  const details = useAppSelector<any>(meSlice.selectDetails);
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [updateField, setUpdateField] = useState<'phone' | 'email' | null>(null);
  const [openPhoneVerification, setOpenPhoneVerification] = useState<boolean>(false);
  const [phoneNumber, setPhonenumber] = useState<string>(details?.phoneNumber ?? '');
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const { enqueueSnackbar } = useSnackbar();

  const handlePhoneVerification = (inputPhone: string) => {
    setPhonenumber(inputPhone);
    setOpenPhoneVerification(true);
  };

  const handleEmailVerification = async (inputEmail: string) => {
    setIsLoading(true);
    dispatch(meSlice.setEmail(inputEmail))
      .unwrap()
      .then(() => {
        enqueueSnackbar(`An email has been sent to ${inputEmail} for verification`, {
          variant: 'success',
          anchorOrigin: {
            vertical: matches ? 'bottom' : 'top',
            horizontal: matches ? 'center' : 'right',
          },
        });
      })
      .catch((err) => {
        if (err.code === 'auth/requires-recent-login') {
          //Do something if the error is 'auth/requires-recent-login'
        }
      })
      .finally(() => setIsLoading(false));
  };

  const handleClosePhoneVerificationDialog = () => {
    setOpenPhoneVerification(false);
  };

  return (
    <React.Fragment>
      <ProgressIndicator loading={isLoading} />
      <Grid container spacing={3}>
        <Grid item>
          <Paper elevation={0}>
            <Box p={2}>
              <Paper elevation={0}>
                <Card elevation={0}>
                  <CardContent>
                    <VerifyBox
                      subtitle="Registered Phone Number"
                      textFieldTitle="Phone number"
                      btnText="VERIFY NUMBER"
                      isVerified={details?.phoneNumber ? true : false}
                      initialValue={details?.phoneNumber ?? ''}
                      onFieldUpdate={handlePhoneVerification}
                      openLoginDialog={() => setUpdateField('phone')}
                    />
                  </CardContent>
                </Card>
              </Paper>
              <Paper elevation={0}>
                <Card elevation={0}>
                  <CardContent>
                    <VerifyBox
                      subtitle="Registered Email"
                      textFieldTitle="Email"
                      btnText="VERIFY EMAIL"
                      isVerified={details?.emailVerified}
                      initialValue={details?.email ?? ''}
                      isEmail
                      onFieldUpdate={handleEmailVerification}
                      openLoginDialog={() => setUpdateField('email')}
                    />
                  </CardContent>
                </Card>
              </Paper>
              <Paper elevation={0}>
                <Card elevation={0}>
                  <CardContent>
                    <ResetPassword email={details?.email ?? ''} />
                  </CardContent>
                </Card>
              </Paper>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <PhoneVerifyDialog
        phoneNumber={phoneNumber}
        open={openPhoneVerification}
        onClose={handleClosePhoneVerificationDialog}
      />
      <UpdateFieldDialog updateField={updateField} setUpdateField={setUpdateField} />
    </React.Fragment>
  );
}
