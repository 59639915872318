import { Route, Routes } from 'react-router-dom';
import Logout from 'screens/authentication/Logout';
import TeamRoute from './TeamRoute';
import CatchAllRoute from './CatchAllRoute';
import DashboardRoute from './DashboardRoute';
import MemberRoute from './MemberRoute';
import AnnouncementRoute from './AnnouncementRoute';
import InformationRoute from './InformationRoute';
import KnowledgeRoute from './KnowledgeRoute';
import TaskRoute from './TaskRoute';
import RepositoryRoute from './RepositoryRoute';
import DebugRoute from './DebugRoute';
import { withFirestore } from 'shared/components/data';
import ProfileRoute from './ProfileRoute';
import SettingRoute from './SettingRoute';
import PlaybookRoute from './PlaybookRoute';
import NotificationRoute from './NotificationRoute';

const MainContentRoute = () => {
  return (
    <Routes>
      <Route path="/logout" element={<Logout />} />
      <Route path="/dashboard/*" element={<DashboardRoute />} />
      <Route path="/notifications/*" element={<NotificationRoute />} />
      <Route path="/teams/*" element={<TeamRoute />} />
      <Route path="/announcements/*" element={<AnnouncementRoute />} />
      <Route path="/information/*" element={<InformationRoute />} />
      <Route path="/knowledge/*" element={<KnowledgeRoute />} />
      <Route path="/tasks/*" element={<TaskRoute />} />
      <Route path="/members/*" element={<MemberRoute />} />
      <Route path="/repository/*" element={<RepositoryRoute />} />
      <Route path="/profile/*" element={<ProfileRoute />} />
      <Route path="/setting/*" element={<SettingRoute />} />
      <Route path="/playbook/*" element={<PlaybookRoute />} />
      <Route path="/debug/*" element={<DebugRoute />} />
      <Route path="*" element={<CatchAllRoute />} />
    </Routes>
  );
};

export default withFirestore(MainContentRoute);
