import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { IWorkspaceModel } from '@dayone/models';

export default function DropDownWorkspace(props: {
  curWorkspace: string;
  selectOptions: IWorkspaceModel[];
  onChange: (workspace: string) => void;
}) {
  const { curWorkspace, selectOptions, onChange } = props;
  return (
    <FormControl size="small" variant="outlined" fullWidth>
      <InputLabel>Workspace</InputLabel>
      <Select value={curWorkspace} onChange={(event) => onChange(event.target.value as string)} label="Workspace">
        {selectOptions.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
