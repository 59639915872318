import React, { useState, useEffect } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import { Box } from '@material-ui/core';
import { IRepositoryModel } from '@dayone/models';
import SugestList from './SugestList';

export default function SearchBar(props: {
  openFile: (file: IRepositoryModel) => void;
  openFolder: (path: string) => void;
  navigateToSearch: (text: string) => void;
}) {
  const [text, setText] = useState('');
  const [sugestListShow, setSugestListShow] = useState(false);

  const showSugestion = () => {
    if (text && text !== '') {
      setSugestListShow(true);
    }
  };

  useEffect(showSugestion, [text]);

  useEffect(() => {
    const handleWindowClick = () => {
      setSugestListShow(false);
    };
    if (sugestListShow) {
      setTimeout(() => window.addEventListener('click', handleWindowClick), 100);
    } else {
      window.removeEventListener('click', handleWindowClick);
    }
    return () => window.removeEventListener('click', handleWindowClick);
  }, [sugestListShow]);

  return (
    <Box>
      <form onSubmit={() => props.navigateToSearch(text)}>
        <TextField
          autoComplete="off"
          onFocus={showSugestion}
          variant="outlined"
          fullWidth
          size="small"
          id="search"
          label="Search for file"
          value={text}
          onChange={(event) => setText(event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="action" />
              </InputAdornment>
            ),
          }}
        />
      </form>
      {sugestListShow && <SugestList text={text} sugestListShow={sugestListShow} {...props} />}
    </Box>
  );
}
