import { FC } from 'react';
import { Box, Typography, Button } from '@material-ui/core';

import checked from 'assets/checked.svg';

interface Props {
  updateField: 'email' | 'phone' | null;
  cancel: () => void;
}

const Complete: FC<Props> = ({ updateField, cancel }) => {
  if (!updateField) return null;

  const isEmail = updateField === 'email';

  return (
    <Box py={2} px={5} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <Box mb={5}>
        <img src={checked} alt="checked" />
      </Box>
      <Box mb={5}>
        <Typography variant="h5" align="center">
          Success! Your {isEmail ? 'email' : 'phone number'} has been updated!
        </Typography>
      </Box>
      <Button variant="text" color="primary" onClick={cancel}>
        Close
      </Button>
    </Box>
  );
};

export default Complete;
