import React from 'react';
import {
  Box,
  IconButton,
  Typography,
  makeStyles,
  createStyles,
  Theme,
  Popover,
  MenuItem,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
} from '@material-ui/core';
import moment from 'moment';
import {
  Create as CreateIcon,
  People as PeopleIcon,
  MoreHoriz as MoreHorizIcon,
  Info as InfoIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { IRepositoryModel } from '@dayone/models';
import RepositoryIcon from './RepositoryIcon';
import RenameRepository from './RenameRepository';
import DeleteButton from './DeleteButton';

export default function SearchResultItem(props: {
  repository: IRepositoryModel;
  isSelected: boolean;
  updatedBy: string;
  onSelectFile: (repository: IRepositoryModel, selected?: boolean) => void;
  onClickFileName: (event: React.MouseEvent, repository: IRepositoryModel) => void;
  onClickFolderPath: (event: React.MouseEvent, repository: IRepositoryModel) => void;
  openInfoModal: () => void;
  onAfterDeleted: (repository: IRepositoryModel) => void;
  openShareModal: (repository: IRepositoryModel) => void;
}) {
  const classes = useStyles();
  const {
    repository,
    isSelected,
    updatedBy,
    onSelectFile,
    onClickFileName,
    onClickFolderPath,
    openInfoModal,
    onAfterDeleted,
    openShareModal,
  } = props;
  return (
    <Box key={repository.id} className={`${classes.item} ${isSelected ? classes.activeItem : ''}`}>
      <ListItem
        onClick={() => {
          onSelectFile(repository);
        }}
      >
        <ListItemIcon>
          <RepositoryIcon fileType={repository.fileType} uri={repository.thumbnailUrl} />
        </ListItemIcon>
        <ListItemText
          primary={
            <span
              style={{ cursor: 'pointer' }}
              className={classes.listItemText}
              onClick={(event) => onClickFileName(event, repository)}
            >
              {repository.fileName}
            </span>
          }
          secondary={
            <span
              style={{ cursor: 'pointer' }}
              className={classes.listItemText}
              onClick={(event) => onClickFolderPath(event, repository)}
            >
              {`${
                !repository.folderPath || repository.folderPath === '' ? 'All files' : repository.folderPath
              } • ${moment(repository.updatedAt).format('DD MMM YY')} by ${updatedBy}`}
            </span>
          }
        />
        <ListItemSecondaryAction>
          <PopupState variant="popover" popupId="demo-popup-popover">
            {(popupState) => {
              const popupTrigger = bindTrigger(popupState);
              return (
                <Box className="popup">
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <RenameRepository repository={repository}>
                      <IconButton size="medium">
                        <CreateIcon fontSize="small" />
                      </IconButton>
                    </RenameRepository>

                    <IconButton size="medium" onClick={() => openShareModal(repository)}>
                      <PeopleIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      size="medium"
                      {...popupTrigger}
                      onClick={(event) => {
                        onSelectFile(repository, true);
                        popupTrigger.onClick(event);
                      }}
                    >
                      <MoreHorizIcon fontSize="small" />
                    </IconButton>
                  </Box>
                  <Popover
                    {...bindPopover(popupState)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <MenuItem>
                      <Box
                        sx={{ py: 0.5, minWidth: '180px' }}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        onClick={openInfoModal}
                      >
                        <InfoIcon fontSize="small" style={{ marginRight: 8 }} />
                        <Typography variant="body1">Get Info</Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem>
                      <DeleteButton repository={repository} onDeleted={() => onAfterDeleted(repository)}>
                        <Box sx={{ py: 0.5, minWidth: '180px' }} display="flex" flexDirection="row" alignItems="center">
                          <DeleteIcon fontSize="small" style={{ marginRight: 8 }} />
                          <Typography variant="body1">Delete</Typography>
                        </Box>
                      </DeleteButton>
                    </MenuItem>
                  </Popover>
                </Box>
              );
            }}
          </PopupState>
        </ListItemSecondaryAction>
      </ListItem>
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      backgroundColor: theme.palette.background.paper,
      marginBottom: theme.spacing(2),
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      '& .popup': { display: 'none' },
      '&:hover': {
        background: `${theme.palette.action.hover} !important`,
        '& .popup': { display: 'block !important' },
      },
    },
    activeItem: {
      background: `${theme.palette.action.hover} !important`,
      '& .popup': { display: 'block !important' },
    },
    listItemText: {
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  })
);
