import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Paper,
  Typography,
  TablePagination,
  useTheme,
} from '@material-ui/core';
import { useState, useCallback, useEffect, useMemo } from 'react';
import moment from 'moment';
import { useNavigate, generatePath, useSearchParams } from 'react-router-dom';
import { Close as CloseIcon } from '@material-ui/icons';
import { IRepositoryModel } from '@dayone/models';
import { repositorySlice, memberSlice, useAppSelector, teamSlice } from '@dayone/redux';

import FilePreviewer from './components/FilePreviewer';
import InfoDialog from './components/InfoDialog';
import ShareDialog from './components/ShareDialog';
import SearchBar from './components/SearchBar';
import StorageProgressBar from './components/StorageProgressBar';
import NewButton from './components/NewButton';
import Breadcrumbs from './components/Breadcrumbs';
import { EmptyData } from 'shared/components/emptyData';
import boxEmpty from 'assets/box-empty.svg';
import RepositoryOverviewTable from './components/RepositoryOverviewTable';
import { IRepositoryOverviewModel } from './helpers/iRepositoryOverviewModel';
import GAEvents from 'shared/utilities/events';
import { useCustomDimensionsEvents } from 'shared/components/hooks';

export default function Repository() {
  const repositories: IRepositoryModel[] = useAppSelector<any>(repositorySlice.selectSharedRepositoriesWithCurrentUser);
  const members = useAppSelector<any>(memberSlice.selectMembersWithRole);
  const teams = useAppSelector<any>(teamSlice.selectTeams);
  const theme = useTheme();
  const navigate = useNavigate();

  const [selectedFileId, setSelectedFileId] = useState<string | null>(null);
  const [page, setPage] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(25);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [isSharingModalOpen, setIsSharingModalOpen] = useState(false);
  const [isOpenedFromSearch, setIsOpenedFromSearch] = useState(false);

  const [sortBy, setSortBy] = useState<'name' | 'time' | 'size'>('time');
  const [isDescending, setIsDescending] = useState<boolean>(true);

  const [searchParams, setSearchParams] = useSearchParams();
  const folderPath = searchParams.get('path') ?? '';
  const setFolderPath = useCallback((text: string) => setSearchParams({ path: text }), [setSearchParams]);
  const logEvent = useCustomDimensionsEvents();

  const selectedFile: IRepositoryModel | null = repositories.find(({ id: _id }) => _id === selectedFileId) || null;

  useEffect(() => {
    logEvent(GAEvents.repository_view);
  }, [logEvent]);

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleNavigateToSearch = (searchString?: string) => {
    navigate(generatePath(`search?keyword=:keyword`, { keyword: searchString }));
  };

  const getLastActiveTime = useCallback((repo: IRepositoryModel) => {
    if (moment(repo.updatedAt).isValid()) return repo.updatedAt;
    if (moment(repo.createdAt).isValid()) return repo.createdAt;
    return '';
  }, []);

  const getSize = useCallback(
    (file: IRepositoryModel) => {
      if (!file.isFolder) return -1;

      return repositories.filter((repository) => repository.parentID === file.id && !repository.isFolder)?.length || 0;
    },
    [repositories]
  );

  const sort = useCallback(
    (repo1: IRepositoryOverviewModel, repo2: IRepositoryOverviewModel) => {
      try {
        //naming is really hard
        const descending = isDescending ? 1 : -1;

        if (sortBy === 'name') {
          return repo2.fileName.localeCompare(repo1.fileName) * descending;
        }

        if (sortBy === 'time') {
          const time1 = getLastActiveTime(repo1);
          const time2 = getLastActiveTime(repo2);

          return new Date(time2).getTime() - new Date(time1).getTime() * descending;
        }

        if (sortBy === 'size') {
          return (repo2.size - repo1.size) * descending;
        }

        return 0;
      } catch {
        return 0;
      }
    },
    [sortBy, isDescending, getLastActiveTime]
  );

  const filteredFiles = useMemo(
    () =>
      repositories
        .filter((repository) => repository.folderPath === folderPath)
        .map((x) => ({ ...x, size: getSize(x) }))
        .sort(sort),
    [repositories, folderPath, getSize, sort]
  );

  const pagedFiles: IRepositoryOverviewModel[] = useMemo(
    () => filteredFiles.slice(page * rowPerPage, (page + 1) * rowPerPage),
    [filteredFiles, page, rowPerPage]
  );

  const onFileNameClick = useCallback(
    (event: React.MouseEvent, file: IRepositoryModel) => {
      if (file.isFolder) {
        setFolderPath(folderPath ? `${folderPath}/${file.fileName}` : file.fileName);
        setSelectedFileId(null); // Set file to null to prevent a case that user select a file and then click on a folder
        setPage(0);
      } else {
        // Set selected file and stop propagation to prevent the case that user
        // click on the file name continously
        setSelectedFileId(file.id);
        setIsPreviewModalOpen(true);
      }
      event.stopPropagation();
    },
    [setFolderPath, setSelectedFileId, setPage, folderPath]
  );

  const onSort = useCallback(
    (newSortBy: 'name' | 'size' | 'time') => {
      if (newSortBy === sortBy) {
        setIsDescending(!isDescending);
        return;
      }

      setSortBy(newSortBy);
      setIsDescending(false);
    },
    [sortBy, isDescending]
  );

  const onDeleted = useCallback(() => {
    setSelectedFileId(null);
  }, [setSelectedFileId]);

  const handleShareFolder = useCallback(
    (event: any) => {
      if (folderPath === '') return;
      const paths = folderPath.split('/');
      const path = paths.slice(0, paths.length - 1).join('/');
      const folderName = paths[paths.length - 1];
      const folder = repositories.find((repo) => repo.fileName === folderName && repo.folderPath === path);

      if (folder) {
        setSelectedFileId(folder.id);
        setIsSharingModalOpen(true);
      }

      event.stopPropagation();
    },
    [folderPath, repositories, setSelectedFileId, setIsSharingModalOpen]
  );

  const onCloseSharingModal = useCallback(() => {
    setIsSharingModalOpen(false);
    setSelectedFileId(null);
  }, [setIsSharingModalOpen, setSelectedFileId]);

  useEffect(() => {
    const handleClick = () => {
      !isPreviewModalOpen && !isInfoModalOpen && !isSharingModalOpen && setSelectedFileId(null);
    };

    window.addEventListener('click', handleClick);

    return () => window.removeEventListener('click', handleClick);
  }, [isPreviewModalOpen, isInfoModalOpen, isSharingModalOpen, selectedFileId]);

  const renderContent = () => {
    if ((!pagedFiles || !pagedFiles.length) && !folderPath)
      return (
        <EmptyData
          imageSrc={boxEmpty}
          emptyText="No files or folders yet"
          suggestion="Click on the New + button to get started"
        />
      );

    return (
      <Paper elevation={0}>
        <Card elevation={0}>
          <CardContent>
            {!selectedFile ? (
              <Breadcrumbs
                folderPath={folderPath}
                setFolderPath={setFolderPath}
                onShareFolder={handleShareFolder}
                setPage={setPage}
              />
            ) : (
              <Box
                flexDirection="row"
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                style={{ height: theme.spacing(5.5) }}>
                <Typography variant="h6" color="textSecondary">{`${selectedFile.fileName} is selected`}</Typography>
                <IconButton size="small" onClick={() => setSelectedFileId(null)}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              </Box>
            )}
            <Box mx={-2} mb={-3}>
              <RepositoryOverviewTable
                files={pagedFiles}
                selectedFile={selectedFile}
                onSelectFile={setSelectedFileId}
                onFileNameClick={onFileNameClick}
                openShareModal={() => setIsSharingModalOpen(true)}
                openInfoModal={() => setIsInfoModalOpen(true)}
                onDeleted={onDeleted}
                onSort={onSort}
                members={members}
              />
              <TablePagination
                component="div"
                rowsPerPageOptions={[5, 10, 25]}
                rowsPerPage={rowPerPage}
                onRowsPerPageChange={(event) => {
                  setRowPerPage(parseInt(event?.target?.value, 10));
                  setPage(0);
                }}
                count={filteredFiles.length}
                page={page}
                onPageChange={(event, page) => handleChangePage(page)}
              />
              {selectedFile && (
                <FilePreviewer
                  open={isPreviewModalOpen}
                  onClose={() => {
                    setIsPreviewModalOpen(false);
                    if (isOpenedFromSearch) {
                      setIsOpenedFromSearch(false);
                      setSelectedFileId(null);
                    }
                  }}
                  url={selectedFile.fileUrl}
                  type={selectedFile.fileType}
                />
              )}
              {selectedFile && (
                <InfoDialog
                  isModalOpen={isInfoModalOpen}
                  onCloseModal={() => setIsInfoModalOpen(false)}
                  selectedFile={selectedFile}
                  members={members}
                />
              )}
              {selectedFile && (
                <ShareDialog
                  isModalOpen={isSharingModalOpen}
                  onCloseModal={onCloseSharingModal}
                  members={members}
                  teams={teams}
                  selectedFile={selectedFile}
                />
              )}
            </Box>
          </CardContent>
        </Card>
      </Paper>
    );
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <Box display="flex" alignItems="start">
          <Box flex={1}>
            <SearchBar
              openFile={(file) => {
                setSelectedFileId(file.id);
                setIsPreviewModalOpen(true);
                setIsOpenedFromSearch(true);
              }}
              openFolder={(path) => {
                setFolderPath(path);
              }}
              navigateToSearch={handleNavigateToSearch}
            />
            <StorageProgressBar />
          </Box>
          <Box pl={4}>
            <NewButton path={folderPath} />
          </Box>
        </Box>
        {renderContent()}
      </Grid>
    </Grid>
  );
}
