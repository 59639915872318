import { useAppSelector, notificationSlice } from '@dayone/redux';
import moment from 'moment';
import { useMemo } from 'react';

const DATE_FORMAT = 'YYYY/MM/DD';
const now = moment();
const today = now.format(DATE_FORMAT);
const yesterday = now.add(-1, 'day').format(DATE_FORMAT);
const sixDaysAgo = now.add(-6, 'days').format(DATE_FORMAT);

const useFilterNotifications = () => {
  const notifications = useAppSelector(notificationSlice.selectAllNotifications);

  const isEmpty = useMemo(() => !notifications?.length, [notifications]);
  const todayNotifications = useMemo(
    () => notifications.filter((item) => moment(new Date(item.createdAt)).format(DATE_FORMAT) === today),
    [notifications]
  );
  const yesterdayNotifications = useMemo(
    () => notifications.filter((item) => moment(new Date(item.createdAt)).format(DATE_FORMAT) === yesterday),
    [notifications]
  );
  const thisWeekNotifications = useMemo(
    () =>
      notifications.filter((item) => {
        const time = moment(new Date(item.createdAt)).format(DATE_FORMAT);
        return sixDaysAgo <= time && time < yesterday;
      }),
    [notifications]
  );
  const earlierNotifications = useMemo(
    () => notifications.filter((item) => moment(new Date(item.createdAt)).format(DATE_FORMAT) < sixDaysAgo),
    [notifications]
  );

  const notificationTypes = useMemo(
    () => [
      { name: 'Today', notifications: todayNotifications },
      { name: 'Yesterday', notifications: yesterdayNotifications },
      { name: 'This week', notifications: thisWeekNotifications },
      { name: 'Earlier', notifications: earlierNotifications },
    ],
    [todayNotifications, yesterdayNotifications, thisWeekNotifications, earlierNotifications]
  );

  return { isEmpty, notificationTypes };
};

export default useFilterNotifications;
