import { FC } from 'react';
import { Button, Grid, TextField, Box, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useFormik, FormikHelpers } from 'formik';

import countryCode, { ICountryCode, countryToFlag, labelOrIso } from 'shared/utilities/countryCode';
import { phoneRegisterSchema as validationSchema } from 'shared/utilities/schemas';
import pageError from 'shared/utilities/phoneLoginPageError';
import { ProgressIndicator } from 'shared/components/progressIndicator';

interface IFormikInitialValue {
  countryCode: ICountryCode;
  phoneNumber: string;
}

const initialValues: IFormikInitialValue = {
  countryCode: { iso: 'SG', label: 'Singapore', code: '65' },
  phoneNumber: '',
};

interface Props {
  errorMessage: string;
  onCancel: () => void;
  onSuccess: (phoneNumber: string) => void;
}

const UpdatePhoneLogin: FC<Props> = ({ errorMessage, onCancel, onSuccess }) => {
  const onSubmit = async (values: IFormikInitialValue, { setSubmitting }: FormikHelpers<IFormikInitialValue>) => {
    const phoneWithCountryCode = `+${values.countryCode.code}${values.phoneNumber}`;
    onSuccess(phoneWithCountryCode);
    setSubmitting(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: onSubmit,
  });

  return (
    <Box mt={2} pb={1} px={1}>
      <Box mb={5}>
        <Typography variant="h6" align="center">
          Update Phone Number
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        {formik.isSubmitting && <ProgressIndicator />}
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Box px={8}>
              <Grid item container direction="column" spacing={3}>
                <Grid item>
                  {errorMessage && (
                    <Alert variant="outlined" severity="error" style={{ marginTop: 16 }}>
                      {pageError(errorMessage)}
                    </Alert>
                  )}
                </Grid>
                <Grid item>
                  <Autocomplete
                    autoSelect
                    fullWidth
                    options={countryCode}
                    onChange={(event, value) => formik.setFieldValue('countryCode', value)}
                    value={formik.values.countryCode}
                    defaultValue={formik.values.countryCode}
                    getOptionLabel={(option) => `${labelOrIso(option)} (+${option.code} )`}
                    getOptionSelected={(option, value) => (value ? option.code === value.code : true)}
                    renderOption={(option) => (
                      <Box display="flex" alignItems="center" textOverflow="ellipsis">
                        {`${countryToFlag(option.iso)} ${labelOrIso(option)} (+${option.code})`}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Country"
                        variant="outlined"
                        error={formik.touched.countryCode && Boolean(formik.errors.countryCode)}
                        helperText={formik.touched.countryCode && formik.errors.countryCode}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    fullWidth
                    placeholder="Phone number"
                    variant="outlined"
                    type="tel"
                    name="phoneNumber"
                    label="Phone number"
                    defaultValue={formik.values.phoneNumber}
                    onChange={formik.handleChange}
                    error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                    helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Box mt={5} display="flex" justifyContent="flex-end">
          <Button
            variant="text"
            color="primary"
            disabled={formik.isSubmitting}
            style={{ marginRight: 2 }}
            onClick={(e) => {
              e.preventDefault();
              onCancel();
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="text"
            color="primary"
            disabled={formik.isSubmitting}
            onClick={() => formik.handleSubmit()}
          >
            Change
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default UpdatePhoneLogin;
