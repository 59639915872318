import { Typography } from '@material-ui/core';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useLogEventWithViewDuration, useSnackbar } from 'shared/components/hooks';
import { announcementSlice, meSlice, teamSlice, useAppDispatch, useAppSelector } from '@dayone/redux';
import AnnouncementForm from './components/AnnouncementForm';
import moment from 'moment';
import GAEvents from 'shared/utilities/events';

const dateFormat = 'D MMMM YYYY';

export default function EditAnnouncement() {
  const details = useAppSelector(meSlice.selectDetails);
  const displayName = details?.displayName ?? null;
  const id: string | undefined = useParams()?.id;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const enqueueSnackbar = useSnackbar();
  const announcements: announcementSlice.IRemappedAnnouncement[] = useAppSelector<any>(
    announcementSlice.selectAnnouncements
  ).announcements;
  const teams = useAppSelector<any>(teamSlice.selectActiveTeams);
  const edittingAnnouncement = announcements.find(({ id: _id }) => _id === id);

  useLogEventWithViewDuration(GAEvents.announcement_view_details, {
    length: edittingAnnouncement?.message.length,
    isAcknowledged: !!edittingAnnouncement?.acknowledged?.find((user: string) => user === displayName),
  });

  useEffect(() => {
    if (!edittingAnnouncement) {
      enqueueSnackbar('This message is deleted!', {
        variant: 'warning',
      });
    }
  }, [edittingAnnouncement, enqueueSnackbar]);

  if (!edittingAnnouncement) {
    return null;
  }

  const today = moment();
  const scheduledMessageText = `, scheduled to broadcast on ${moment(edittingAnnouncement.broadcastDate).format(
    dateFormat
  )}.`;
  const handleDelete = () => {
    dispatch(announcementSlice.deleteAnnouncementWithID({ id: edittingAnnouncement.id }));
    enqueueSnackbar('The message has been deleted!', {
      variant: 'warning',
    });
    navigate(-1);
  };

  const handleEditBroadcastDate = (broadcastDate: string) => {
    dispatch(
      announcementSlice.editBroadcastDateForAnnouncementWithID({
        id: edittingAnnouncement.id,
        broadcastDate,
      })
    );

    enqueueSnackbar('The message has been rescheduled successfully!', {
      variant: 'success',
    });
  };

  const handlePinned = (pin: boolean) => {
    dispatch(
      announcementSlice.updatePinForAnnouncementWithID({
        id: edittingAnnouncement.id,
        pin,
      })
    );

    if (pin)
      enqueueSnackbar('The message has been pinned!', {
        variant: 'success',
      });
    else
      enqueueSnackbar('The message has been unpinned!', {
        variant: 'warning',
      });
  };

  return (
    <AnnouncementForm
      motd={{
        ...edittingAnnouncement,
        images: edittingAnnouncement.images.map((image: string) => ({ url: image, action: 'UNCHANGE' })),
      }}
      displayBackButton={true}
      onBack={() => {
        navigate(-1);
      }}
      creationLog={
        <>
          {edittingAnnouncement.createdAt !== '' && moment(edittingAnnouncement.createdAt).isValid() && (
            <Typography variant="caption">
              Posted on {moment(edittingAnnouncement.createdAt).format(dateFormat)} by{' '}
              {edittingAnnouncement.createdByDisplayName}
              {edittingAnnouncement.isScheduled && scheduledMessageText}
              <br />
            </Typography>
          )}
          {edittingAnnouncement.updatedAt !== '' &&
            edittingAnnouncement.updatedAt !== edittingAnnouncement.createdAt &&
            moment(edittingAnnouncement.updatedAt).isValid() && (
              <Typography variant="caption">
                Updated on {moment(edittingAnnouncement.updatedAt).format(dateFormat)} by{' '}
                {edittingAnnouncement.updatedByDisplayName}
              </Typography>
            )}
        </>
      }
      branches={teams}
      broadcastDateEditable={
        edittingAnnouncement.isScheduled && today.isBefore(edittingAnnouncement.broadcastDate, 'day')
      }
      onEditBroadcastDate={handleEditBroadcastDate}
      pinable
      onPin={handlePinned}
      deletable
      onDelete={handleDelete}
      disabled
    />
  );
}
