"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.setPlaybooks = exports.selectPlaybookByID = exports.selectPlaybooksByTopicID = exports.selectAllPlaybookModels = exports.selectUID = exports.selectAllPlaybooks = exports.updateThumbUp = exports.addRead = exports.deletePlaybook = exports.createPlaybook = exports.playbookStorageConfig = exports.playbookSliceConfig = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var firestore_1 = require("@firebase/firestore");
var storage_1 = require("@firebase/storage");
var firebase_1 = require("@dayone/firebase");
var firebaseApp;
var firestore;
var storage;
var playbookSliceConfig = function (_firebaseApp) {
    firebaseApp = _firebaseApp;
    // eslint-disable-next-line
    firestore = (0, firestore_1.getFirestore)(_firebaseApp);
};
exports.playbookSliceConfig = playbookSliceConfig;
var playbookStorageConfig = function (storageBucketConfig) {
    // console.log('faqStorageConfig', storageBucketConfig.knowledge);
    var playbookStorageBucket = storageBucketConfig.playbook;
    storage = (0, storage_1.getStorage)(firebaseApp, playbookStorageBucket);
};
exports.playbookStorageConfig = playbookStorageConfig;
var initialState = {
    initialized: false,
    playbooks: [],
};
exports.createPlaybook = (0, toolkit_1.createAsyncThunk)('playbook/create', function (payload) { return __awaiter(void 0, void 0, void 0, function () {
    var firestore, basePlaybooksColRef, playbookDocRef, data, baseStorageRef, uploadTask, url, baseStorageRef, uploadTask, url;
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                console.log('createPlaybook', payload);
                firestore = (0, firestore_1.getFirestore)(firebaseApp);
                basePlaybooksColRef = (0, firestore_1.collection)(firestore, 'playbooks');
                playbookDocRef = (0, firestore_1.doc)(basePlaybooksColRef);
                return [4 /*yield*/, (0, firestore_1.setDoc)(playbookDocRef, {
                        title: payload.title,
                        description: payload.description,
                        status: payload.status,
                        topic: payload.topic,
                    })];
            case 1:
                _c.sent();
                data = { url: '', thumbnailUrl: '' };
                if (!payload.file) return [3 /*break*/, 4];
                baseStorageRef = (0, storage_1.ref)(storage, "/playbooks/".concat(playbookDocRef.id, "/").concat(payload.file.name));
                return [4 /*yield*/, (0, storage_1.uploadBytes)(baseStorageRef, payload.file, { contentType: (_a = payload.file) === null || _a === void 0 ? void 0 : _a.type })];
            case 2:
                uploadTask = _c.sent();
                return [4 /*yield*/, (0, storage_1.getDownloadURL)(uploadTask.ref)];
            case 3:
                url = _c.sent();
                data.url = url;
                _c.label = 4;
            case 4:
                if (!payload.thumbnail) return [3 /*break*/, 7];
                baseStorageRef = (0, storage_1.ref)(storage, "/playbooks/".concat(playbookDocRef.id, "/").concat(payload.thumbnail.name));
                return [4 /*yield*/, (0, storage_1.uploadBytes)(baseStorageRef, payload.thumbnail, { contentType: (_b = payload.thumbnail) === null || _b === void 0 ? void 0 : _b.type })];
            case 5:
                uploadTask = _c.sent();
                return [4 /*yield*/, (0, storage_1.getDownloadURL)(uploadTask.ref)];
            case 6:
                url = _c.sent();
                data.thumbnailUrl = url;
                _c.label = 7;
            case 7: return [4 /*yield*/, (0, firestore_1.updateDoc)(playbookDocRef, data)];
            case 8:
                _c.sent();
                return [2 /*return*/];
        }
    });
}); });
exports.deletePlaybook = (0, toolkit_1.createAsyncThunk)('playbook/delete', function (payload) { return __awaiter(void 0, void 0, void 0, function () {
    var firestore, basePlaybooksColRef, playbookDocRef;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                firestore = (0, firestore_1.getFirestore)(firebaseApp);
                basePlaybooksColRef = (0, firestore_1.collection)(firestore, 'playbooks');
                playbookDocRef = (0, firestore_1.doc)(basePlaybooksColRef, payload.id);
                return [4 /*yield*/, (0, firestore_1.deleteDoc)(playbookDocRef)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}); });
exports.addRead = (0, toolkit_1.createAsyncThunk)('playbook/addRead', function (arg) { return __awaiter(void 0, void 0, void 0, function () {
    var playbookID;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                playbookID = arg.playbookID;
                return [4 /*yield*/, firebase_1.callable.addRead(firebaseApp, { playbookID: playbookID })];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}); });
exports.updateThumbUp = (0, toolkit_1.createAsyncThunk)('playbook/updateThumbUp', function (arg) { return __awaiter(void 0, void 0, void 0, function () {
    var playbookID, isThumbUp;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                playbookID = arg.playbookID, isThumbUp = arg.isThumbUp;
                return [4 /*yield*/, firebase_1.callable.updateThumbUp(firebaseApp, { playbookID: playbookID, isThumbUp: isThumbUp })];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}); });
var playbookSlice = (0, toolkit_1.createSlice)({
    name: 'playbook',
    initialState: initialState,
    reducers: {
        setPlaybooks: {
            reducer: function (state, action) {
                if (action.payload) {
                    state.playbooks = action.payload;
                    state.initialized = true;
                }
            },
            prepare: function (payload) {
                if (payload) {
                    var playbooks = payload.map(function (playbook) {
                        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
                        return {
                            id: playbook.id,
                            title: (_a = playbook.title) !== null && _a !== void 0 ? _a : '',
                            topic: (_b = playbook.topic) !== null && _b !== void 0 ? _b : 0,
                            description: (_c = playbook.description) !== null && _c !== void 0 ? _c : '',
                            status: (_d = playbook.status) !== null && _d !== void 0 ? _d : '0',
                            readTimes: (_e = playbook.readTimes) !== null && _e !== void 0 ? _e : 0,
                            thumbnailUrl: (_f = playbook.thumbnailUrl) !== null && _f !== void 0 ? _f : '',
                            url: (_g = playbook.url) !== null && _g !== void 0 ? _g : '',
                            readUser: (_h = playbook.readUser) !== null && _h !== void 0 ? _h : [],
                            thumbsUp: (_j = playbook.thumbsUp) !== null && _j !== void 0 ? _j : [],
                        };
                    });
                    return { payload: playbooks };
                }
                return { payload: null };
            },
        },
    },
});
// Selectors
var selectAllPlaybooks = function (state) { return state.playbook.playbooks; };
exports.selectAllPlaybooks = selectAllPlaybooks;
var selectUID = function (state) { var _a; return (_a = state.me.details) === null || _a === void 0 ? void 0 : _a.uid; };
exports.selectUID = selectUID;
exports.selectAllPlaybookModels = (0, toolkit_1.createSelector)(exports.selectAllPlaybooks, exports.selectUID, function (playbooks, uid) {
    return playbooks
        .map(function (p) {
        var _a;
        return __assign(__assign({}, p), { isNew: !p.readUser.includes(uid !== null && uid !== void 0 ? uid : ''), isThumbUp: (_a = p.thumbsUp.find(function (t) { return t.uid === uid; })) === null || _a === void 0 ? void 0 : _a.isThumbUp });
    })
        .sort(function (a, b) {
        if (a.status === '0')
            return 1;
        if (b.status === '0')
            return -1;
        if (a.isNew)
            return -1;
        return a.title.localeCompare(b.title);
    });
});
exports.selectPlaybooksByTopicID = (0, toolkit_1.createSelector)(exports.selectAllPlaybookModels, function (playbooks) {
    return function callbackFunction(topicID) {
        return playbooks.filter(function (p) { return p.topic === topicID; });
    };
});
exports.selectPlaybookByID = (0, toolkit_1.createSelector)(exports.selectAllPlaybookModels, function (playbooks) {
    return function callbackFunction(id) {
        return playbooks.find(function (p) { return p.id === id; });
    };
});
// Actions
exports.setPlaybooks = playbookSlice.actions.setPlaybooks;
exports.default = playbookSlice.reducer;
