"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setPromos = exports.selectActivePromo = exports.selectAllPromos = exports.promoSliceConfig = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var firestore_1 = require("firebase/firestore");
var firebaseApp;
var firestore;
var promoSliceConfig = function (_firebaseApp) {
    // eslint-disable-next-line
    firebaseApp = _firebaseApp;
    // eslint-disable-next-line
    firestore = (0, firestore_1.getFirestore)(_firebaseApp);
};
exports.promoSliceConfig = promoSliceConfig;
var initialState = {
    initialized: false,
    promos: [],
};
var promoSlice = (0, toolkit_1.createSlice)({
    name: 'promo',
    initialState: initialState,
    reducers: {
        setPromos: {
            reducer: function (state, action) {
                if (action.payload) {
                    state.promos = action.payload;
                    state.initialized = true;
                }
            },
            prepare: function (payload) {
                if (payload) {
                    var promoModels = payload.map(function (promo) {
                        var _a, _b, _c, _d, _e, _f, _g;
                        return {
                            promoId: (_a = promo.promoId) !== null && _a !== void 0 ? _a : '',
                            title: (_b = promo.title) !== null && _b !== void 0 ? _b : '',
                            active: (_c = promo.active) !== null && _c !== void 0 ? _c : false,
                            description: (_d = promo.description) !== null && _d !== void 0 ? _d : '',
                            featureButton: (_e = promo.featureButton) !== null && _e !== void 0 ? _e : '',
                            imageUri: (_f = promo.imageUri) !== null && _f !== void 0 ? _f : '',
                            screen: (_g = promo.screen) !== null && _g !== void 0 ? _g : '',
                        };
                    });
                    return { payload: promoModels };
                }
                return { payload: null };
            },
        },
    },
});
// Selectors
var selectAllPromos = function (state) { return state.promo.promos; };
exports.selectAllPromos = selectAllPromos;
var selectActivePromo = function (state) { return state.promo.promos.find(function (x) { return x.active; }) || null; };
exports.selectActivePromo = selectActivePromo;
// Actions
exports.setPromos = promoSlice.actions.setPromos;
exports.default = promoSlice.reducer;
