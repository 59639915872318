import { Box, Button, Card, CardContent, Grid, Paper, Tooltip, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { useCallback, useState } from 'react';
import DropDownWorkspace from './components/Dropdown';
import { IWorkspaceModel } from '@dayone/models';
import { settingSlice, useAppDispatch, useAppSelector, workspaceSlice } from '@dayone/redux';
import refData from '@dayone/refdata';
import { useCustomDimensionsEvents, useSnackbar } from 'shared/components/hooks';
import analytics from 'shared/utilities/analytics';
import GAEvents from 'shared/utilities/events';

export default function SettingOverview() {
  const dispatch = useAppDispatch();
  const enqueueSnackbar = useSnackbar();
  const initialWorkSpace = useAppSelector<IWorkspaceModel | undefined>(settingSlice.selectActiveWorkspace);
  const workspaces = useAppSelector<IWorkspaceModel[]>(workspaceSlice.getWorkspaces);
  const logEvent = useCustomDimensionsEvents();

  const [currentWorkspaceId, setCurrentWorkspaceId] = useState<string>(initialWorkSpace?.id || '');
  const handleSubmit = useCallback(() => {
    if (initialWorkSpace && currentWorkspaceId !== initialWorkSpace.id) {
      const { id: companyID, name: companyName } = initialWorkSpace;
      analytics.setCompany({ companyID, companyName });
      logEvent(GAEvents.select_company, { switchFromCompanyId: currentWorkspaceId });
    }

    dispatch(settingSlice.setActiveWorkspace(currentWorkspaceId));
    const curWorkspace = workspaces.find((ws) => ws.id === currentWorkspaceId);
    enqueueSnackbar(`${curWorkspace?.name} is now active!`, {
      variant: 'success',
    });
  }, [dispatch, initialWorkSpace, workspaces, currentWorkspaceId, enqueueSnackbar, logEvent]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={8}>
        <Paper elevation={0}>
          <Card elevation={0}>
            <CardContent>
              <Grid container spacing={2} direction="column">
                <Grid item>
                  <Box>
                    <Typography variant="h6">Workspace</Typography>
                  </Box>
                  <Box display="flex" flexDirection="row">
                    <Typography variant="subtitle1">Select a workspace</Typography>
                    <Box ml={1} />
                    <Tooltip title={refData.screen.setting['select-workspace']}>
                      <InfoIcon />
                    </Tooltip>
                  </Box>
                </Grid>

                <Grid item xs={12} md={8}>
                  <DropDownWorkspace
                    curWorkspace={currentWorkspaceId}
                    selectOptions={workspaces}
                    onChange={setCurrentWorkspaceId}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Paper>
      </Grid>
      <Grid item xs={12} lg={8}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Box mt={1} style={{ minWidth: '230px' }}>
              <Button fullWidth type="submit" variant="contained" color="primary" onClick={handleSubmit}>
                SWITCH ACTIVE WORKSPACE
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
