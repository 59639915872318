import { playbookSlice, useAppDispatch } from '@dayone/redux';
import * as Yup from 'yup';
import {
  CardContent,
  Grid,
  TextField,
  Paper,
  Card,
  useMediaQuery,
  useTheme,
  Select,
  MenuItem,
  InputLabel,
  Box,
  Button,
  FormControl,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ProgressIndicator } from 'shared/components/progressIndicator';

interface IPlaybookFormikValues {
  title: string;
  description: string;
  topic: number;
  status: string;
  file: File | null;
  thumbnail: File | null;
}

export default function PlaybookForm() {
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  const onSubmit = (values: IPlaybookFormikValues) => {
    dispatch(playbookSlice.createPlaybook(values))
      .unwrap()
      .then(() => {
        enqueueSnackbar('The playbook has been created successfully!', {
          variant: 'success',
          anchorOrigin: {
            vertical: isXs ? 'bottom' : 'top',
            horizontal: isXs ? 'center' : 'right',
          },
        });
        navigate(-1);
      })
      .catch((ex) => {
        enqueueSnackbar('Error when create playbook, please try again later!', {
          variant: 'error',
          anchorOrigin: {
            vertical: isXs ? 'bottom' : 'top',
            horizontal: isXs ? 'center' : 'right',
          },
        });
      })
      .finally(() => {
        formik.setSubmitting(false);
      });
  };
  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
      topic: 1,
      status: '',
      file: null,
      thumbnail: null,
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      title: Yup.string().required('Required'),
      description: Yup.string().required('Required'),
      topic: Yup.number().required('Required'),
      status: Yup.string().required('Required'),
      thumbnail: Yup.mixed().required('File is required'),
    }),
    onSubmit: onSubmit,
  });

  const handleSelectFile = (event: any, input: string) => {
    const files = event.target.files as File[];
    if (files && files.length) {
      const file = files[0];
      formik.setFieldValue(input, file);
    }
  };
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} lg={8}>
            <Paper elevation={0}>
              <Card elevation={0}>
                <CardContent>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        label="Title"
                        name="title"
                        multiline
                        rows={2}
                        variant="outlined"
                        onChange={formik.handleChange}
                        // error={formik.touched.title && Boolean(formik.errors.title)}
                        // helperText={formik.touched.title && formik.errors.title}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        label="Description"
                        name="description"
                        multiline
                        rows={2}
                        variant="outlined"
                        onChange={formik.handleChange}
                        // error={formik.touched.description && Boolean(formik.errors.description)}
                        // helperText={formik.touched.description && formik.errors.description}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="topic-label">Topic</InputLabel>
                        <Select
                          required
                          value={formik.values.topic}
                          labelId="topic-label"
                          label="Topic"
                          onChange={(event) => {
                            formik.setFieldValue('topic', event.target.value);
                          }}
                          // error={formik.touched.topic && Boolean(formik.errors.topic)}
                          fullWidth
                        >
                          <MenuItem value={1}>Career progression</MenuItem>
                          <MenuItem value={2}>Interpersonal skills</MenuItem>
                          <MenuItem value={3}>Leadership skills</MenuItem>
                          <MenuItem value={4}>Personal finance</MenuItem>
                          <MenuItem value={5}>Personal development</MenuItem>
                          <MenuItem value={6}>Time management</MenuItem>
                          <MenuItem value={7}>Mental health</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="status-label">Status</InputLabel>
                        <Select
                          required
                          labelId="status-label"
                          value={formik.values.status}
                          label="Status"
                          onChange={(event) => {
                            formik.setFieldValue('status', event.target.value);
                          }}
                          error={formik.touched.status && Boolean(formik.errors.status)}
                        >
                          <MenuItem value={'1'}>Ready</MenuItem>
                          <MenuItem value={'0'}>Coming Soon</MenuItem>
                        </Select>
                      </FormControl>
                      {/* {Boolean(formik.errors.status) && <FormHelperText>{formik.errors.status}</FormHelperText>} */}
                    </Grid>

                    <Grid item xs={12}>
                      <InputLabel id="status-label">Thumbnail Image</InputLabel>
                      <Box pt={2} />
                      <input
                        accept="image/*"
                        type="file"
                        onChange={(event) => {
                          handleSelectFile(event, 'thumbnail');
                        }}
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel id="status-label">PDF Document</InputLabel>
                      <Box pt={2} />
                      <label htmlFor="contained-button-file">
                        <input
                          accept="pdf/*"
                          id="contained-button-file"
                          type="file"
                          onChange={(e) => handleSelectFile(e, 'file')}
                        />
                      </label>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12} lg={8}>
            <Box pt={2} />
            <Button fullWidth={matches} type="submit" variant="contained" color="primary">
              Save
            </Button>
          </Grid>
        </Grid>
        <ProgressIndicator loading={formik.isSubmitting}></ProgressIndicator>
      </form>
    </>
  );
}
