import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTheme } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { repositorySlice, useAppDispatch } from '@dayone/redux';
import { useSnackbar } from 'shared/components/hooks';
import { IRepositoryModel } from '@dayone/models';

export default function CreateOrEditFolderDialog(props: {
  open: boolean;
  setOpen: (open: boolean) => void;
  parentId: string;
  parentPath?: string;
  repository?: IRepositoryModel;
}) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const enqueueSnackbar = useSnackbar();

  const { repository } = props;
  const isNew = !repository?.id || repository?.id === '';
  const [fileExtension, setFileExtension] = useState('');

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .matches(
          /^((?!\\|\/|:|\*|\?|"|<|>|\|).)*$/,
          `A file name can't contain any of following characters:  \\ / : * ? ” < > |`
        )
        .required((isNew || repository?.isFolder ? 'Folder' : 'File') + ' name is required'),
    }),
    onSubmit: async (values: { name: string }) => {
      const name = values.name.trim();
      if (isNew) {
        await createFolder(name);
      } else {
        await updateFolder(name);
      }
    },
  });

  useEffect(() => {
    if (props.open) {
      let fileName = repository?.fileName ?? 'Untitled folder';
      if (!isNew && !repository?.isFolder) {
        setFileExtension(fileName.substring(fileName.lastIndexOf('.')));
        fileName = fileName.substring(0, fileName.lastIndexOf('.'));
      }
      formik.setFieldValue('name', fileName);
    }
    // eslint-disable-next-line
  }, [repository, props.open, isNew]);

  const createFolder = async (name: string) => {
    await dispatch(
      repositorySlice.createRepository({
        fileName: name,
        parentID: props.parentId,
        isFolder: true,
        staffs: ['*'],
        teams: ['*'],
      })
    )
      .unwrap()
      .then(() => {
        handleClose();
        const message = `${name} has been created in ${
          props.parentPath && props.parentPath !== '' ? props.parentPath : 'All files'
        }!`;

        enqueueSnackbar(message, { variant: 'success' });
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: 'error' });
      });
  };

  const updateFolder = async (name: string) => {
    if (!repository?.id || repository?.id === '') return;

    if (repository?.fileName === name) {
      handleClose();
      return;
    }

    await dispatch(
      repositorySlice.renameRepository({
        name: name + fileExtension,
        repositoryId: repository.id,
      })
    )
      .unwrap()
      .then(() => {
        handleClose();
        enqueueSnackbar(`"${name}" has been updated successfully!`, { variant: 'success' });
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: 'error' });
      });
  };

  const handleClose = () => {
    props.setOpen(false);
    formik.resetForm();
  };

  return (
    <Dialog
      fullWidth
      open={props.open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') handleClose();
      }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{isNew ? 'New folder' : 'Rename item'} </DialogTitle>
      <DialogContent style={{ overflow: 'hidden' }}>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            id="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onFocus={(event) => event.target.select()}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            fullWidth
            placeholder="Folder name"
            variant="outlined"
            autoFocus
          />
        </form>
      </DialogContent>
      <DialogActions style={{ paddingRight: theme.spacing(3), paddingBottom: theme.spacing(3) }}>
        <Button onClick={handleClose} color="primary" disabled={formik.isSubmitting}>
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          onClick={() => formik.handleSubmit()}
          color="primary"
          disabled={formik.isSubmitting}
        >
          {isNew ? 'Create' : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
