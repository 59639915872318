import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Tooltip,
  TextField,
  useTheme,
} from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { People as PeopleIcon, Info as InfoIcon } from '@material-ui/icons';
import { IRepositoryModel, ITeamModel } from '@dayone/models';
import { memberSlice, repositorySlice, settingSlice, useAppDispatch, useAppSelector } from '@dayone/redux';
import refData from '@dayone/refdata';
import { useSnackbar } from 'shared/components/hooks';

import SharingUser from './SharingUser';

export default function ShareDialog(props: {
  isModalOpen: boolean;
  onCloseModal: () => void;
  members: memberSlice.IMemberData[];
  teams: ITeamModel[];
  selectedFile: IRepositoryModel;
}) {
  const dispatch = useAppDispatch();
  const enqueueSnackbar = useSnackbar();
  const workspace = useAppSelector<any>(settingSlice.selectActiveWorkspace);
  const { members, teams, selectedFile } = props;
  const theme = useTheme();

  const isSharingModalOpen = props.isModalOpen;
  const selectedFileOwner = members.find((member) => member.memberId === selectedFile.createdBy);
  const isFileSharedForAnyone = selectedFile.staffs.includes('*');

  const [selectedSharingOptions, setSelectedSharingOptions] = useState<IAutocompleteOption[]>([]);

  useEffect(() => {
    setSelectedSharingOptions([]);
  }, [isSharingModalOpen]);

  const filterOptions = useCallback(
    (options: any, state: any): IAutocompleteOption[] =>
      defaultFilterOptions(
        options.filter(
          (option: IAutocompleteOption) =>
            !selectedSharingOptions.find(({ id: _selectedOptionId }) => _selectedOptionId === option.id)
        ),
        state
      ).slice(0, OPTIONS_LIMIT),
    [selectedSharingOptions]
  );

  const normalizedMemberAndTeamsSuggestion: any = useMemo(
    () => [
      ...(!isFileSharedForAnyone
        ? [
            {
              name: `Everyone at ${workspace?.name}`,
              type: 'user',
              metaData: workspace,
              id: '*',
            },
          ]
        : []),
      ...members
        .filter(
          (_member) => _member.memberId !== selectedFile.createdBy && !selectedFile.staffs.includes(_member.memberId)
        )
        .map((_member) => ({
          name: _member.displayName,
          type: 'user',
          metaData: _member,
          id: _member.memberId,
        })),
      ...teams
        .filter((_team) => !_team.isInactive && !selectedFile.teams.includes(_team.teamId))
        .map((_team) => ({
          name: _team.name,
          id: _team.teamId,
          metaData: _team,
          type: 'team',
        })),
    ],
    // eslint-disable-next-line
    [selectedFile, members, teams]
  );

  const autocompleteRenderOption = (option: IAutocompleteOption) => {
    const { type, name, metaData, id } = option;
    const isTeam = type === 'team';
    const isSharedWithWholeWorkspaceOption = isTeam && id === '*';

    if (isSharedWithWholeWorkspaceOption) {
      <SharingUser
        key={workspace?.id}
        title={`Everyone at ${workspace?.name}`}
        subTitle={`${workspace?.usersCount} members`}
        avatarType="square"
        avatarUrl={workspace?.companyLogo || ''}
        name=""
      />;
    }

    return (
      <SharingUser
        sx={{}}
        avatarType={isTeam ? 'square' : 'circle'}
        avatarUrl={isTeam ? '' : metaData.photoURL}
        name={name}
        title={name}
        subTitle={isTeam ? `${metaData?.users?.length} members` : metaData?.role}
      />
    );
  };

  const renderSharedUserList = () => {
    return selectedFile.staffs.map((_staffId) => {
      const member = members.find((_member) => _member.memberId === _staffId);

      if (_staffId === '*') {
        return (
          <SharingUser
            key={workspace?.id}
            title={`Everyone at ${workspace?.name}`}
            subTitle={`${workspace?.usersCount} members`}
            avatarType="square"
            avatarUrl={workspace?.companyLogo || ''}
            name=""
            onRemove={() => handleRemove(_staffId, 'user')}
          />
        );
      }

      if (!member || selectedFile.createdBy === _staffId) {
        return null;
      }

      return (
        <SharingUser
          key={_staffId}
          title={member.displayName}
          subTitle={member.role}
          avatarType="circle"
          avatarUrl={member.photoURL}
          name={member.displayName}
          onRemove={() => handleRemove(_staffId, 'user')}
        />
      );
    });
  };

  const handleShare = () => {
    dispatch(
      repositorySlice.sharePermission({
        companyId: workspace?.id,
        sharingTeamAndStaff: selectedSharingOptions,
        repositoryID: selectedFile.id,
      })
    )
      .unwrap()
      .then(() =>
        enqueueSnackbar(`"${selectedFile.fileName}" permission has been updated successfully!`, {
          variant: 'success',
        })
      );
    props.onCloseModal();
  };

  const handleRemove = (id: string, type: 'user' | 'team') => {
    dispatch(
      repositorySlice.revokePermission({
        companyId: workspace?.id,
        revokeTeamOrStaff: { type: type, id: id },
        repositoryID: selectedFile.id,
      })
    );
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={isSharingModalOpen} onClose={props.onCloseModal}>
      <DialogTitle disableTypography>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box sx={{ mr: 2 }}>
            <PeopleIcon color="action" fontSize="large" />
          </Box>
          <Typography variant="h6" noWrap component="p">
            {`Share "${selectedFile.fileName}"`}
          </Typography>
          <Box sx={{ ml: 1 }}>
            <Tooltip title={refData.screen.repository['sharing-information']}>
              <InfoIcon fontSize="small" color="action" />
            </Tooltip>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Autocomplete
            onChange={(event, selectedOptions) => {
              setSelectedSharingOptions(selectedOptions as IAutocompleteOption[]);
            }}
            value={selectedSharingOptions}
            filterOptions={filterOptions}
            options={normalizedMemberAndTeamsSuggestion}
            getOptionLabel={(_option: IAutocompleteOption) => _option.name}
            renderOption={autocompleteRenderOption}
            multiple
            freeSolo
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Add teams and individual members" />
            )}
            style={{ marginBottom: theme.spacing(3) }}
          />
          <SharingUser
            title={selectedFileOwner?.displayName || ''}
            subTitle="Owner"
            avatarType="circle"
            avatarUrl={selectedFileOwner?.photoURL || ''}
            name={selectedFileOwner?.displayName || ''}
          />
          {renderSharedUserList()}
          {selectedFile.teams.map((_teamId) => {
            const team = teams.find((_team) => _team.teamId === _teamId);

            if (!team) {
              return null;
            }

            return (
              <SharingUser
                key={_teamId}
                title={team.name}
                subTitle={`${team.users.length} members`}
                avatarType="square"
                avatarUrl=""
                name={team.name}
                onRemove={() => handleRemove(_teamId, 'team')}
              />
            );
          })}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ marginBottom: theme.spacing(2), paddingRight: theme.spacing(3) }}>
        <Button color="primary" onClick={props.onCloseModal} variant="text">
          CANCEL
        </Button>
        <Button
          color="primary"
          onClick={handleShare}
          variant="contained"
          disabled={selectedSharingOptions?.length === 0}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

interface IAutocompleteOption {
  name: string;
  type: 'user' | 'team';
  metaData: any;
  id: string;
}

const OPTIONS_LIMIT = 5;
const defaultFilterOptions = createFilterOptions<IAutocompleteOption>();
