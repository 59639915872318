import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import isHotkey from 'is-hotkey';
import { Editable, withReact, Slate, ReactEditor } from 'slate-react';
import { createEditor, Descendant } from 'slate';
import { withHistory } from 'slate-history';
import LinkIcon from '@material-ui/icons/Link';
import { InputLabel } from '@material-ui/core';
import { css } from '@emotion/css';
import DOMPurify from 'dompurify';

import Button from './components/Button';
import Toolbar from './components/Toolbar';
import Element from './components/Element';
import Leaf from './components/Leaf';
import BlockButton, { isBlockActive } from './components/BlockButton';
import MarkButton, { toggleMark } from './components/MarkButton';
import { insertLink, withLinks, DescendantExt, deserialize, serialize } from 'shared/utilities/richTextHelpers';

type typeOfHotKeys = 'mod+b' | 'mod+i' | 'mod+u' | 'mod+`';
const HOTKEYS: { [key in typeOfHotKeys]: string } = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
};

const RichText = (props: {
  label?: string;
  disabled?: boolean;
  defaultValue: string;
  onChange: (val: string) => void;
  error: boolean | undefined;
  helperText: string | ReactNode | false | undefined;
}) => {
  let initvalue: DescendantExt[] = [
    {
      type: 'paragraph',
      children: [{ text: '' }],
    },
  ];
  try {
    if (props.defaultValue) {
      initvalue = deserialize(
        props.defaultValue.startsWith('<') ? props.defaultValue : '<p>' + props.defaultValue
      ) as DescendantExt[];
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }

  const [value, setValue] = useState<any[]>(initvalue);
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(() => withHistory(withLinks(withReact(createEditor() as ReactEditor))), []);

  const handleInsertLink = () => {
    const url = prompt('Enter a URL', 'https://');
    insertLink(editor, url ?? '');
  };

  const onChange = (val: Descendant[]) => {
    if (val !== value) {
      setValue(val);
      props.onChange && props.onChange(serialize(val));
    }
  };

  const renderTextbox = () =>
    props.disabled ? (
      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.defaultValue) }} />
    ) : (
      <Slate editor={editor} value={value} onChange={onChange}>
        <Toolbar>
          <MarkButton format="bold" icon="format_bold" />
          <MarkButton format="italic" icon="format_italic" />
          <MarkButton format="underline" icon="format_underlined" />
          <BlockButton format="bulleted-list" icon="format_list_bulleted" />
          <Button active={isBlockActive(editor, 'link')} onMouseDown={handleInsertLink}>
            <LinkIcon fontSize="small" />
          </Button>
        </Toolbar>
        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder="Enter some rich text…"
          spellCheck
          autoFocus
          className={css`
            padding: 18.5px 14px;
            border: 1px solid #c4c4c4;
            border-radius: 3px;
          `}
          onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
            for (const hotkey in HOTKEYS) {
              if (!isHotkey(hotkey, event)) continue;
              event.preventDefault();
              const mark = HOTKEYS[hotkey as typeOfHotKeys] as string;
              toggleMark(editor, mark);
            }
          }}
        />
      </Slate>
    );

  return (
    <React.Fragment>
      <InputLabel
        style={{
          fontWeight: 700,
          marginBottom: '8px',
        }}
      >
        {props.label}
      </InputLabel>
      {renderTextbox()}
      {props.error && (
        <span
          style={{
            color: '#f44336',
            fontSize: '12px',
            fontWeight: 400,
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            marginLeft: '14px',
          }}
        >
          {props.helperText}
        </span>
      )}
    </React.Fragment>
  );
};

export default RichText;
