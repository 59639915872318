import { Helmet } from 'react-helmet-async';
import env from './environment';

function ClarityFunction() {
  if (!env.CLARITY.key.length) return <div></div>;

  return (
    <Helmet>
      <script type="text/javascript">
        {`
        (function(c,l,a,r,i,t,y){
          c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
          t=l.createElement(r);
          t.async=1;
          t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];
          y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "${env.CLARITY.key}");`}
      </script>
    </Helmet>
  );
}

export default ClarityFunction;
