import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { IImage } from 'shared/components/uploadImage';
import { faqCategorySlice, faqSlice, teamSlice, useAppSelector } from '@dayone/redux';
import KnowledgeForm, { IFaqObject } from './components/KnowledgeForm';
import { IVideo } from 'shared/components/uploadVideo';
import { useSnackbar } from 'shared/components/hooks';

export default function KnowledgeEdit() {
  const categories = useAppSelector<any>(faqCategorySlice.selectAllFaqCategories);
  const { faqID } = useParams();
  const faqbyID = useAppSelector<any>(faqSlice.selectFAQByID);
  const teams = useAppSelector(teamSlice.selectActiveTeams);
  const selectedKnowledge = faqbyID(faqID ?? '');
  const enqueueSnackbar = useSnackbar();

  useEffect(() => {
    if (!selectedKnowledge) {
      enqueueSnackbar('This FAQ is deleted!', {
        variant: 'warning',
      });
    }
  }, [selectedKnowledge, enqueueSnackbar]);

  const knowledge: IFaqObject = {
    answer: '',
    branchName: '',
    tags: [],
    categories: [],
    companyID: '',
    companyName: '',
    branchID: '',
    branches: teams,
    createBy: '',
    displayName: '',
    faqId: '',
    helpfulNo: [],
    helpfulYes: [],
    isAnon: false,
    isArchived: false,
    createdBy: '',
    updatedBy: '',
    question: '',
    status: 'UNANSWERED',
    ...selectedKnowledge,
    createdAt: selectedKnowledge?.createdAt ?? '',
    updatedAt: selectedKnowledge?.updatedAt ?? '',
    images: selectedKnowledge
      ? selectedKnowledge.images.map((url: IImage) => {
          return { url: url, action: 'UNCHANGE' };
        })
      : [],
    videos: selectedKnowledge
      ? selectedKnowledge.videos.map((video: IVideo) => {
          return { ...video, action: 'UNCHANGE' };
        })
      : [],
    createdByDisplayName: selectedKnowledge?.createdByDisplayName ?? '',
    updatedByDisplayName: selectedKnowledge?.updatedByDisplayName ?? '',
  };

  return <KnowledgeForm knowledge={knowledge} categories={categories} editable={true}></KnowledgeForm>;
}
