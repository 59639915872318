import {
  Box,
  IconButton,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  createStyles,
  Theme,
  useTheme,
  Popover,
  MenuItem,
} from '@material-ui/core';
import { useState, useCallback } from 'react';
import moment from 'moment';
import {
  Create as CreateIcon,
  People as PeopleIcon,
  MoreHoriz as MoreHorizIcon,
  Info as InfoIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { IRepositoryModel } from '@dayone/models';
import { memberSlice } from '@dayone/redux';

import DeleteButton from './DeleteButton';
import RenameRepository from './RenameRepository';
import RepositoryIcon from './RepositoryIcon';
import { IRepositoryOverviewModel } from '../helpers/iRepositoryOverviewModel';

export default function RepositoryOverviewTable(props: {
  files: IRepositoryOverviewModel[];
  selectedFile: IRepositoryModel | null;
  onSelectFile: (fileId: string | null) => void;
  onFileNameClick: (event: React.MouseEvent, file: IRepositoryModel) => void;
  openShareModal: () => void;
  openInfoModal: () => void;
  onDeleted: () => void;
  onSort: (sortBy: 'name' | 'size' | 'time') => void;
  members: memberSlice.IMemberData[];
}) {
  const {
    files,
    selectedFile,
    onSelectFile,
    onFileNameClick,
    members,
    openShareModal,
    openInfoModal,
    onDeleted,
    onSort,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [hoveringRowId, setHoveringRowId] = useState<string | null>(null);

  const renderTime = useCallback((repo: IRepositoryModel) => {
    if (moment(repo.updatedAt).isValid()) {
      return moment(repo.updatedAt).format('DD MMM YY');
    }

    if (moment(repo.createdAt).isValid()) {
      return moment(repo.createdAt).format('DD MMM YY');
    }

    return '';
  }, []);

  return (
    <TableContainer>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell width="50%" className={classes.columnHeader} onClick={() => onSort('name')}>
              Name
            </TableCell>
            <TableCell width="17%" className={classes.columnHeader} onClick={() => onSort('time')}>
              Updated
            </TableCell>
            <TableCell width="17%" className={classes.columnHeader} onClick={() => onSort('size')}>
              Size
            </TableCell>
            <TableCell width="16%" />
          </TableRow>
        </TableHead>
        <TableBody>
          {files.map((file) => {
            const isRowSelected = selectedFile?.id === file.id;

            return (
              <TableRow
                key={file.id}
                hover
                classes={{
                  selected: classes.selectedTableRow,
                }}
                selected={isRowSelected}
                onClick={(event: any) => {
                  onSelectFile(isRowSelected ? null : file.id);
                  event.stopPropagation();
                }}
                onMouseEnter={() => setHoveringRowId(file.id)}
                onMouseLeave={() => setHoveringRowId(null)}>
                <TableCell onClick={(e) => onFileNameClick(e, file)}>
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Box sx={{ mr: 1, color: theme.palette.warning.light }}>
                      <RepositoryIcon fileType={file.fileType} uri={file.thumbnailUrl} />
                    </Box>
                    <Typography
                      variant="body2"
                      style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}>
                      {file.fileName}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{`${renderTime(file)} by ${
                    members.find((member) => member.memberId === file.updatedBy)?.displayName ?? file.displayName
                  }`}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">
                    {!file.isFolder ? '-' : `${file.size} ${file.size === 1 ? 'file' : 'files'}`}
                  </Typography>
                </TableCell>
                <TableCell
                  onClick={(event) => {
                    onSelectFile(file.id);
                    event.stopPropagation();
                  }}>
                  {(hoveringRowId === file.id || selectedFile?.id === file.id) && (
                    <PopupState variant="popover" popupId="popover">
                      {(popupState) => (
                        <div>
                          <Box display="flex" flexDirection="row" alignItems="center">
                            <RenameRepository repository={file}>
                              <IconButton size="medium">
                                <CreateIcon fontSize="small" />
                              </IconButton>
                            </RenameRepository>
                            <IconButton size="medium" onClick={openShareModal}>
                              <PeopleIcon fontSize="small" />
                            </IconButton>
                            <IconButton {...bindTrigger(popupState)} size="medium">
                              <MoreHorizIcon fontSize="small" />
                            </IconButton>
                          </Box>
                          <Popover
                            {...bindPopover(popupState)}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'center',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'center',
                            }}>
                            <MenuItem>
                              <Box
                                sx={{ py: 0.5, minWidth: '180px' }}
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                onClick={openInfoModal}>
                                <InfoIcon fontSize="small" style={{ marginRight: 8 }} />
                                <Typography variant="body1">Get Info</Typography>
                              </Box>
                            </MenuItem>
                            <MenuItem>
                              <DeleteButton repository={file} onDeleted={onDeleted}>
                                <Box
                                  sx={{ py: 0.5, minWidth: '180px' }}
                                  display="flex"
                                  flexDirection="row"
                                  alignItems="center">
                                  <DeleteIcon fontSize="small" style={{ marginRight: 8 }} />
                                  <Typography variant="body1">Delete</Typography>
                                </Box>
                              </DeleteButton>
                            </MenuItem>
                          </Popover>
                        </div>
                      )}
                    </PopupState>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    columnHeader: {
      cursor: 'pointer',
    },
    table: {
      minWidth: theme.spacing(130),
      tableLayout: 'fixed',
      '& td': {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
      '& tbody tr': {
        cursor: 'pointer',
        height: '77px',
      },
    },
    selectedTableRow: {
      background: `${theme.palette.action.hover} !important`,
    },
  })
);
