import { Route, Routes } from 'react-router-dom';
import Notification from 'screens/notification/Notification';
import { UpdatePageTitle } from 'shared/components/navigations';

export default function DashboardRoute() {
  return (
    <Routes>
      <Route
        path={'/'}
        element={
          <>
            <UpdatePageTitle title="Dashboard" />
            <Notification />
          </>
        }
      />
    </Routes>
  );
}
