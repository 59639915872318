import { settingSlice, useAppSelector } from '@dayone/redux';
import analytics from 'shared/utilities/analytics';

/**
 * Hook to add custom base metrics to events
 */
const useCustomDimensionsEvents = () => {
  const company = useAppSelector(settingSlice.selectActiveWorkspace) || { id: '', name: '' };

  const logEventsWithCustomDimensions = async (eventName: string, params?: any) => {
    await analytics.logEvent(eventName, { companyId: company.id, companyName: company.name, ...params });
  };

  return logEventsWithCustomDimensions;
};

export default useCustomDimensionsEvents;
