import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, makeStyles, createStyles } from '@material-ui/core';
import TimeAgo from 'javascript-time-ago';
import ReactTimeAgo from 'react-time-ago';
import moment from 'moment';

import en from 'javascript-time-ago/locale/en.json';

import { INotificationModel, NOTIFICATION_TYPES } from '@dayone/models';
import { useAppDispatch, notificationSlice, taskSlice } from '@dayone/redux';
import NotificationIcon from './NotificationIcon';

TimeAgo.addDefaultLocale(en);

interface Props {
  name: string;
  item: INotificationModel;
}

const NotificationItem: FC<Props> = ({ name, item }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const styles = useStyles({ isRead: item.isRead });

  const renderTime = useCallback(() => {
    const time = item.createdAt;
    const date = new Date(time);
    if (name === 'Today') return <ReactTimeAgo date={date} locale="en-US" />;
    if (name === 'Yesterday') return `Yesterday at ${moment(date).format('HH:mm A')}`;
    if (name === 'This week')
      return (
        <>
          <ReactTimeAgo date={date} locale="en-US" /> at {moment(date).format('HH:mm A')}
        </>
      );
    if (name === 'Earlier') return `${moment(date).format('D MMM YYYY')} at ${moment(date).format('HH:mm A')}`;
    return '';
  }, [name, item]);

  const maskAsRead = useCallback(async () => {
    if (!item.isRead) {
      const { id } = item;
      await dispatch(notificationSlice.markAsReadByID({ id })).unwrap();
    }
  }, [item, dispatch]);

  const navigateNotification = useCallback(() => {
    const { motdID, faqID, taskID, type } = item.metaData;

    switch (type || item.type) {
      case NOTIFICATION_TYPES.ANNOUNCEMENT:
        motdID && navigate(`/announcements/edit/${motdID}`);
        break;
      case NOTIFICATION_TYPES.KNOWLEDGE:
        faqID && navigate(`/knowledge/edit/${faqID}`);
        break;
      case NOTIFICATION_TYPES.TASK_CREATED:
      case NOTIFICATION_TYPES.TASK_UPDATED:
      case NOTIFICATION_TYPES.TASK_COMPLETED:
      case NOTIFICATION_TYPES.ALL_TASK_COMPLETED:
      case NOTIFICATION_TYPES.TASK_REMINDER:
        taskID && navigate(`/tasks/detail/${taskID}`);
        break;
      case NOTIFICATION_TYPES.TASK_DELETED:
        navigate(`/tasks`);
        break;
      case NOTIFICATION_TYPES.TASK_COMMENT:
        if (taskID) {
          dispatch(taskSlice.setSelectedTaskID(taskID));
          navigate(`/tasks/topic/detail`);
        }
        break;
      case NOTIFICATION_TYPES.REPOSITORY:
        navigate(`/repository`);
        break;
      case NOTIFICATION_TYPES.MEMBER_INVITATION_ACCEPTED:
      case NOTIFICATION_TYPES.MEMBER_INVITATION_RECEIVED:
      case NOTIFICATION_TYPES.TEAM_MEMBER_ADDED:
        break;
    }
  }, [item, navigate, dispatch]);

  const onClickNotification = useCallback(async () => {
    try {
      await maskAsRead();
      navigateNotification();
    } catch (err) {
      // console.error(err);
    }
  }, [maskAsRead, navigateNotification]);

  return (
    <Box
      px={2}
      py={1}
      display="flex"
      alignItems="center"
      className={styles.container}
      style={{ backgroundColor: item.isRead ? 'white' : '#eaf4fc', cursor: 'pointer' }}
      onClick={onClickNotification}>
      <Box mr={4} display="flex" alignItems="center">
        <NotificationIcon type={item.metaData?.type || item.type} />
      </Box>
      <Box>
        <Typography className={styles.message}>{item.message}</Typography>
        <Typography className={styles.time}>{renderTime()}</Typography>
      </Box>
    </Box>
  );
};

export default NotificationItem;

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      transition: 'all ease .3s',
      '&:hover': {
        backgroundColor: (props: { isRead: boolean }) => (props.isRead ? '#fafafa !important' : '#cce7fdb3 !important'),
      },
    },
    message: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
    },
    time: {
      fontSize: 14,
      color: 'rgba(0, 0, 0, .54)',
    },
  })
);
