"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailTypes = exports.EmailSendingStatus = void 0;
var EmailSendingStatus;
(function (EmailSendingStatus) {
    EmailSendingStatus["InProgress"] = "Inprogress";
    EmailSendingStatus["Success"] = "Success";
    EmailSendingStatus["Failed"] = "Failed";
})(EmailSendingStatus = exports.EmailSendingStatus || (exports.EmailSendingStatus = {}));
var EmailTypes;
(function (EmailTypes) {
    EmailTypes["Registration"] = "Registration";
    EmailTypes["InviteUser"] = "InviteUser";
    EmailTypes["PaymentRegistration"] = "PaymentRegistration";
    // PaymentSuccessful = 'PaymentSuccessful',
})(EmailTypes = exports.EmailTypes || (exports.EmailTypes = {}));
