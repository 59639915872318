import { Box, Button, Grid, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import FullRegistrationLayout from './FullRegistrationLayout';

interface IRegistrationCompleteProps {
  gotoDashboard: () => void;
}

const RegistrationComplete = ({ gotoDashboard }: IRegistrationCompleteProps) => {
  return (
    <FullRegistrationLayout xs={12} sm={12} md={12} lg={12}>
      <Grid container direction="column" alignItems="center" justifyContent="center" spacing={3}>
        <Grid item xs={12}>
          <CheckCircleIcon style={{ fontSize: 80, color: '#4CAF50' }} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" align="center">
            Success! Your registration has been confirmed!
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box mb={[20, 10, 5]}>
            <Button color="primary" onClick={gotoDashboard}>
              GO TO DASHBOARD
            </Button>
          </Box>
        </Grid>
      </Grid>
    </FullRegistrationLayout>
  );
};

export default RegistrationComplete;
