import { FC } from 'react';
import { Box, Typography } from '@material-ui/core';

import notificationEmpty from 'assets/notification-empty.svg';

const NotificationEmpty: FC = () => {
  return (
    <Box
      mt="20vh"
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center">
      <img src={notificationEmpty} alt="notification-empty" />
      <Typography style={{ fontSize: 14, fontWeight: 500, marginTop: 20 }}>No notifications yet</Typography>
      <Typography style={{ fontSize: 14, fontWeight: 400, marginTop: 8 }}>Check back again for new updates </Typography>
    </Box>
  );
};

export default NotificationEmpty;
