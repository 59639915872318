"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useComment = void 0;
var react_1 = require("react");
var firestore_1 = require("firebase/firestore");
var firestore;
function CommentStateConfig(_firebaseApp) {
    firestore = (0, firestore_1.getFirestore)(_firebaseApp);
}
exports.default = CommentStateConfig;
/**
 * Firebase hook for getting comment
 * This hook is for getting collection comment based on task
 * @param {string | null} companyId
 * @param {string | null} taskId
 * @returns Firestore topics
 *
 */
function useComment(companyId, taskId) {
    var _a = (0, react_1.useState)(), comments = _a[0], setComments = _a[1];
    (0, react_1.useEffect)(function () {
        var unsubscribe = null;
        if (companyId && taskId) {
            unsubscribe = (0, firestore_1.onSnapshot)((0, firestore_1.query)((0, firestore_1.collection)(firestore, 'companies', companyId, 'tasks', taskId, 'comments'), (0, firestore_1.orderBy)('ts', 'desc')), function (querySnapshot) {
                var docs = [];
                querySnapshot.forEach(function (doc) {
                    docs.push(__assign(__assign({}, doc.data()), { commentId: doc.id }));
                });
                setComments(docs);
            });
        }
        return function () {
            unsubscribe && unsubscribe();
        };
    }, [companyId, taskId]);
    return comments;
}
exports.useComment = useComment;
