import { Box, Grid, List } from '@material-ui/core';
import React, { useState } from 'react';
import Pagination from '@material-ui/lab/Pagination';
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';
import { IRepositoryModel } from '@dayone/models';
import { repositorySlice, memberSlice, useAppSelector, teamSlice } from '@dayone/redux';
import FilePreviewer from './components/FilePreviewer';

import BackButton from './components/BackButton';
import InfoDialog from './components/InfoDialog';
import SearchResultItem from './components/SearchResultItem';
import SearchInput from './components/SearchInput';
import ShareDialog from './components/ShareDialog';
import { EmptyData } from 'shared/components/emptyData';
import listEmpty from 'assets/list-empty.svg';

export default function RepositorySearch() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const searchText = searchParams.get('keyword') ?? '';

  const repositories: IRepositoryModel[] = useAppSelector<any>(repositorySlice.selectSharedRepositoriesWithCurrentUser);
  const members = useAppSelector<any>(memberSlice.selectMembersWithRole);
  const teams = useAppSelector<any>(teamSlice.selectTeams);

  const [selectedFile, setSelectedFile] = useState<IRepositoryModel | null>(null);
  const [page, setPage] = useState(1);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [isSharingModalOpen, setIsSharingModalOpen] = useState(false);
  const rowPerPage = 5;

  const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  const filteredFiles =
    searchText === ''
      ? []
      : repositories.filter((file) => file.fileName.toLowerCase().includes(searchText.toLowerCase()));

  const pagedFiles = filteredFiles.slice((page - 1) * rowPerPage, page * rowPerPage);

  const handleDeleted = () => {
    setSelectedFile(null);
  };

  const handleSelectFile = (repository: IRepositoryModel, selected?: boolean) => {
    if (selected) {
      setSelectedFile(repository);
    } else {
      setSelectedFile((prev) => (prev?.id === repository.id ? null : repository));
    }
  };

  const handleFileNameClicked = (event: React.MouseEvent, repository: IRepositoryModel) => {
    if (repository.isFolder) {
      const folderPath =
        repository.folderPath && repository.folderPath !== ''
          ? `${repository.folderPath}/${repository.fileName}`
          : repository.fileName;
      navigate(generatePath(`/repository?path=:path`, { path: folderPath }));
    } else {
      setSelectedFile(repository);
      setIsPreviewModalOpen(true);
    }
    event.stopPropagation();
  };

  const handleFolderPathClicked = (event: React.MouseEvent, repository: IRepositoryModel) => {
    navigate(generatePath(`/repository?path=:path`, { path: repository.folderPath }));
    event.stopPropagation();
  };

  const handleOpenInfoModal = () => setIsInfoModalOpen(true);
  const handleOpenShareModal = (repository: IRepositoryModel) => {
    setSelectedFile(repository);
    setIsSharingModalOpen(true);
  };

  const renderContent = () => {
    if (!pagedFiles || !pagedFiles.length)
      return (
        <Grid item xs={12}>
          <EmptyData imageSrc={listEmpty} emptyText="No results found" suggestion="Search for something else" />
        </Grid>
      );

    return (
      <>
        <Grid item xs={12}>
          <List>
            {pagedFiles.map((repository, index) => (
              <SearchResultItem
                key={index}
                repository={repository}
                isSelected={selectedFile?.id === repository.id}
                updatedBy={
                  members.find((member: any) => member.memberId === repository.updatedBy)?.displayName ??
                  repository.displayName
                }
                onSelectFile={handleSelectFile}
                onClickFileName={handleFileNameClicked}
                onClickFolderPath={handleFolderPathClicked}
                openInfoModal={handleOpenInfoModal}
                onAfterDeleted={handleDeleted}
                openShareModal={handleOpenShareModal}
              />
            ))}
          </List>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Pagination
              count={Math.ceil(filteredFiles.length / rowPerPage)}
              page={page}
              onChange={handleChangePage}
              size="large"
              showFirstButton
              showLastButton
            />
          </Box>
        </Grid>
      </>
    );
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item>
          <BackButton />
        </Grid>
        <Grid item xs={12} md={12}>
          <SearchInput
            searchText={searchParams.get('keyword') ?? ''}
            onSearchTextChange={(text) => setSearchParams({ keyword: text }, { replace: true })}
          />
        </Grid>
        {renderContent()}
      </Grid>
      {selectedFile && (
        <FilePreviewer
          open={isPreviewModalOpen}
          onClose={() => setIsPreviewModalOpen(false)}
          url={selectedFile.fileUrl}
          type={selectedFile.fileType}
        />
      )}
      {selectedFile && (
        <InfoDialog
          isModalOpen={isInfoModalOpen}
          onCloseModal={() => setIsInfoModalOpen(false)}
          selectedFile={selectedFile}
          members={members}
        />
      )}
      {selectedFile && (
        <ShareDialog
          isModalOpen={isSharingModalOpen}
          onCloseModal={() => setIsSharingModalOpen(false)}
          members={members}
          teams={teams}
          selectedFile={selectedFile}
        />
      )}
    </>
  );
}
