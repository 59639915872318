import { Editor, Transforms, Path, Range, Element } from 'slate';
import { ReactEditor } from 'slate-react';

import { jsx } from 'slate-hyperscript';
import escapeHtml from 'escape-html';
import { Text } from 'slate';

export interface BaseProps {
  className: string;
  [key: string]: unknown;
}

export const createParagraphNode = (
  children: {
    type: string;
    href: string;
    children: { text: string }[];
  }[] = []
) => ({
  type: 'paragraph',
  children,
});

export const createLinkNode = (href: string, text: string) => ({
  type: 'link',
  href,
  children: [{ text }],
});

export const insertLink = (editor: ReactEditor, url: string) => {
  if (!url) return;

  const { selection } = editor;
  const link = createLinkNode(url, 'New Link');

  ReactEditor.focus(editor);

  if (!!selection) {
    const [parentNode, parentPath] = Editor.parent(editor, selection.focus?.path);

    // Remove the Link node if we're inserting a new link node inside of another
    // link.
    if ((parentNode as any).type === 'link') {
      removeLink(editor);
    }

    if (editor.isVoid(parentNode)) {
      // Insert the new link after the void node
      Transforms.insertNodes(editor, createParagraphNode([link]), {
        at: Path.next(parentPath),
        select: true,
      });
    } else if (Range.isCollapsed(selection)) {
      // Insert the new link in our last known locatio
      Transforms.insertNodes(editor, link, { select: true });
    } else {
      // Wrap the currently selected range of text into a Link
      Transforms.wrapNodes(editor, link, { split: true });
      Transforms.collapse(editor, { edge: 'end' });
    }
  } else {
    // Insert the new link node at the bottom of the Editor when selection
    // is falsey
    Transforms.insertNodes(editor, createParagraphNode([link]));
  }
};

export const removeLink = (editor: ReactEditor, opts = {}) => {
  Transforms.unwrapNodes(editor, {
    ...opts,
    match: (n) => !Editor.isEditor(n) && Element.isElement(n) && (n as any).type === 'link',
  });
};

export const withLinks = (editor: ReactEditor) => {
  const { isInline } = editor;

  editor.isInline = (element) => ((element as any).type === 'link' ? true : isInline(element));

  return editor;
};

export type DescendantExt = {
  type?: 'paragraph' | 'bulleted-list' | 'list-item' | 'link';
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  href?: string;
  text?: string;
  children?: DescendantExt[];
};

const serializeNode = (node: DescendantExt): string => {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text);
    if ((node as DescendantExt).bold) {
      string = `<strong>${string}</strong>`;
    }
    if ((node as DescendantExt).italic) {
      string = `<i>${string}</i>`;
    }
    if ((node as DescendantExt).underline) {
      string = `<u>${string}</u>`;
    }
    return string;
  }

  const children = node.children ? node.children.map((n: any) => serializeNode(n)).join('') : '';

  if (children.length === 0) return '';
  switch (node.type) {
    case 'bulleted-list':
      return `<ul>${children}</ul>`;
    case 'list-item':
      return `<li>${children}</li>`;
    case 'paragraph':
      return `<p>${children}</p>`;
    case 'link':
      return `<a href="${escapeHtml(node.href)}">${children}</a>`;
    default:
      return children;
  }
};

export const serialize = (nodes: any[]) => {
  return nodes.map(serializeNode).join('');
};

const deserializeNode = (el: ChildNode): unknown => {
  if (el.nodeType === 3) {
    return el.textContent;
  } else if (el.nodeType !== 1) {
    return null;
  }

  let children = el.childNodes ? Array.from(el.childNodes).map((node) => deserializeNode(node)) : [];

  if (children.length === 0) {
    children = [''];
  }

  const combineDescendant = (node: DescendantExt) => {
    let combineNode;
    if (children.length > 1) {
      combineNode = jsx('element', node, children);
    } else if (typeof children[0] === 'string') {
      combineNode = jsx('element', { ...node, text: children[0] });
    } else {
      combineNode = jsx('element', { ...node, ...(children[0] ? (children[0] as any) : {}) });
    }
    if (combineNode.children.length === 0) {
      const { children, ...restOfNode } = combineNode;
      return restOfNode;
    }
    return combineNode;
  };

  switch (el.nodeName) {
    case 'BODY':
      return jsx('fragment', {}, children);
    case 'STRONG':
      return combineDescendant({ bold: true });
    case 'I':
      return combineDescendant({ italic: true });
    case 'U':
      return combineDescendant({ underline: true });
    case 'BR':
      return '\n';
    case 'BLOCKQUOTE':
      return jsx('element', { type: 'quote' }, children);
    case 'UL':
      return jsx('element', { type: 'bulleted-list' }, children);
    case 'LI':
      return jsx('element', { type: 'list-item' }, children);
    case 'P':
      return jsx('element', { type: 'paragraph' }, children);
    case 'A':
      return jsx('element', { type: 'link', href: (el as HTMLElement).getAttribute('href') }, children);
    default:
      return el.textContent;
  }
};

export const deserialize = (html: string): unknown => {
  const document = new DOMParser().parseFromString(html, 'text/html');
  return deserializeNode(document.body);
};
