import { Chip, Box, useTheme, InputAdornment, TextField, Typography, useMediaQuery } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import React, { useCallback } from 'react';
import { IFaqCategoryModel } from '@dayone/models';
import GAEvents from 'shared/utilities/events';
import { useCustomDimensionsEvents } from 'shared/components/hooks';

interface IProps {
  categories: IFaqCategoryModel[];
  selectedChips: string[];
  handleSelected: (chip: string) => void;
  keyword: string;
  onChangeKeywords: (text: string) => void;
}

export default function SearchBar(props: IProps) {
  const { categories, selectedChips, handleSelected, keyword, onChangeKeywords } = props;

  const renderOthersChip = () => {
    const selected = selectedChips.filter((c) => c === 'Others').length > 0 ? 'primary' : 'default';

    return (
      <Chip
        key={`selectedchipOthers`}
        label="Others"
        color={selected}
        style={{ margin: theme.spacing(1) }}
        clickable
        onClick={() => handleSelected('Others')}
      />
    );
  };

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const logEvent = useCustomDimensionsEvents();

  const redactString = useCallback((name: string, characterLimit: number) => {
    try {
      if (name.length >= characterLimit) return `${name.slice(0, characterLimit - 3)}...`;
      return name;
    } catch {
      return '';
    }
  }, []);

  const redactCategoryName = useCallback(
    (name: string) => {
      if (matches) return redactString(name, 10);
      return redactString(name, 25);
    },
    [matches, redactString]
  );

  return (
    <React.Fragment>
      <TextField
        fullWidth
        label="Type keywords to find answers"
        variant="outlined"
        value={keyword}
        onChange={(e) => {
          logEvent(GAEvents.knowledge_search);
          onChangeKeywords(e.target.value);
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <Box
        pt={2}
        display="flex"
        alignItems="center"
        sx={matches ? { overflow: 'hidden', alignItems: 'flex-start', flexWrap: 'wrap' } : { overflow: 'hidden' }}
      >
        <Typography variant="subtitle2">Categories</Typography>
        <Box display="flex" alignItems="center" sx={{ overflow: 'hidden', flexWrap: 'wrap' }}>
          {categories.map((category, index) => {
            const selected = selectedChips.filter((c) => c === category.name).length > 0 ? 'primary' : 'default';
            return (
              <Chip
                key={`selectedchip${index}`}
                label={redactCategoryName(category.name)}
                color={selected}
                style={{
                  margin: theme.spacing(1),
                }}
                clickable
                onClick={() => handleSelected(category.name)}
              />
            );
          })}
          {renderOthersChip()}
        </Box>
      </Box>
    </React.Fragment>
  );
}
