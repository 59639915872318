"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.setTopics = exports.selectTopicByID = exports.selectTopics = exports.deleteTopic = exports.updateTopic = exports.createTopic = exports.topicSliceConfig = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var firestore_1 = require("firebase/firestore");
var firestore;
var topicSliceConfig = function (_firebaseApp) {
    firestore = (0, firestore_1.getFirestore)(_firebaseApp);
};
exports.topicSliceConfig = topicSliceConfig;
var initialState = {
    topics: [],
};
// CRUD - Create, Read, Update, Delete
exports.createTopic = (0, toolkit_1.createAsyncThunk)('topic/create', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var state, companyID, uid, topicCollectionRef, data;
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                state = thunkAPI.getState();
                companyID = state.setting.activeWorkspaceID;
                uid = (_b = (_a = state.me.details) === null || _a === void 0 ? void 0 : _a.uid) !== null && _b !== void 0 ? _b : '';
                if (!(companyID && payload.taskId)) return [3 /*break*/, 2];
                topicCollectionRef = (0, firestore_1.collection)(firestore, 'companies', companyID, 'tasks', payload.taskId, 'topics');
                data = {
                    topicName: payload.topicName,
                    createdAt: firestore_1.Timestamp.now(),
                    createdBy: uid,
                };
                return [4 /*yield*/, (0, firestore_1.addDoc)(topicCollectionRef, data)];
            case 1:
                _c.sent();
                _c.label = 2;
            case 2: return [2 /*return*/];
        }
    });
}); });
exports.updateTopic = (0, toolkit_1.createAsyncThunk)('topic/update', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var state, companyID, topicDocRef, data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                state = thunkAPI.getState();
                companyID = state.setting.activeWorkspaceID;
                if (!(companyID && payload.taskId && payload.topicId)) return [3 /*break*/, 2];
                topicDocRef = (0, firestore_1.doc)(firestore, 'companies', companyID, 'tasks', payload.taskId, 'topics', payload.topicId);
                data = {
                    topicName: payload.topicName,
                };
                return [4 /*yield*/, (0, firestore_1.updateDoc)(topicDocRef, data)];
            case 1:
                _a.sent();
                _a.label = 2;
            case 2: return [2 /*return*/];
        }
    });
}); });
exports.deleteTopic = (0, toolkit_1.createAsyncThunk)('topic/delete', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var state, companyID, topicDocRef;
    return __generator(this, function (_a) {
        state = thunkAPI.getState();
        companyID = state.setting.activeWorkspaceID;
        if (companyID && payload.taskId && payload.topicId) {
            topicDocRef = (0, firestore_1.doc)(firestore, 'companies', companyID, 'tasks', payload.taskId, 'topics', payload.topicId);
            (0, firestore_1.deleteDoc)(topicDocRef);
        }
        return [2 /*return*/];
    });
}); });
var topicSlice = (0, toolkit_1.createSlice)({
    name: 'topic',
    initialState: initialState,
    reducers: {
        setTopics: {
            reducer: function (state, action) {
                if (!action.payload)
                    state.topics = [];
                else
                    state.topics = action.payload;
            },
            prepare: function (payload) {
                if (payload && payload.topics) {
                    var topicModels = payload.topics.map(function (topic) {
                        var _a, _b;
                        return {
                            taskID: payload.taskId,
                            topicId: topic.topicId || '',
                            topicName: topic.topicName || '',
                            createdAt: topic.createdAt instanceof firestore_1.Timestamp ? (_b = (_a = topic.createdAt) === null || _a === void 0 ? void 0 : _a.toDate().toISOString()) !== null && _b !== void 0 ? _b : '' : '',
                            createdBy: topic.createdBy || '',
                        };
                    });
                    return { payload: topicModels };
                }
                return { payload: null };
            },
        },
    },
});
// Selectors
var selectTopics = function (state) { return state.topic.topics; };
exports.selectTopics = selectTopics;
exports.selectTopicByID = (0, toolkit_1.createSelector)(exports.selectTopics, function (topics) {
    return function callbackFunction(topicId) {
        return (topics === null || topics === void 0 ? void 0 : topics.find(function (topic) { return topic.topicId === topicId; })) || null;
    };
});
// Actions
exports.setTopics = topicSlice.actions.setTopics;
exports.default = topicSlice.reducer;
