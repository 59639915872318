import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import { Paper, Box, Typography, Card, CardContent } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import TaskOverviewFilter from './TaskSearchFilter';
import { useTheme } from '@material-ui/core/styles';

export default function TaskOverviewFilterDrawer(props: {
  mobileOpen: boolean;
  onDrawerToggle: () => void;
  selectedBranches: string[];
  onBranchChange: (checked: boolean, value: string) => void;
  selectedInterval: string[];
  onIntervalChange: (checked: boolean, value: string) => void;
  allBranches: { label: string; value: string }[];
}) {
  const theme = useTheme();
  return (
    <Drawer
      variant="temporary"
      anchor={'right'}
      open={props.mobileOpen}
      onClose={props.onDrawerToggle}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <Paper elevation={0}>
        <Card elevation={0}>
          <CardContent>
            <Box width={theme.spacing(31)}>
              <Box display="flex" alignItems="center" justifyContent="flex-end" onClick={props.onDrawerToggle}>
                <CloseIcon style={{ color: theme.palette.action.active }} />
              </Box>
              <Box pt={2} />
              <Typography variant="h6">Filter</Typography>
              <Box pt={3} />
              <TaskOverviewFilter
                selectedBranches={props.selectedBranches}
                selectedInterval={props.selectedInterval}
                onBranchChange={props.onBranchChange}
                onIntervalChange={props.onIntervalChange}
                allBranches={props.allBranches}
              />
              <Box pt={5} />
            </Box>
          </CardContent>
        </Card>
      </Paper>
    </Drawer>
  );
}
