import { Avatar, Box, Button, Card, CardActions, CardContent, Grid, Typography } from '@material-ui/core';
import { useSnackbar } from 'shared/components/hooks';
import { myInviteSlice, useAppDispatch } from '@dayone/redux';
import { IDecoratedInvite } from '../SettingInvite';
import { useCallback } from 'react';

export default function Invite(props: { decoratedInvite: IDecoratedInvite }) {
  const { invite, workspace, user } = props.decoratedInvite;

  const companyLogo = workspace?.companyLogo ? workspace?.companyLogo : '';
  const workspaceName = invite.companyName;
  const inviter = user?.displayName;

  const dispatch = useAppDispatch();
  const enqueueSnackbar = useSnackbar();

  const handleClick = useCallback(() => {
    dispatch(myInviteSlice.acceptInvite(invite))
      .then(() => {
        enqueueSnackbar(`You are now a member of ${invite.companyName}!`, {
          variant: 'success',
        });
      })
      .catch((err) => {});
  }, [dispatch, enqueueSnackbar, invite]);
  return (
    <Card>
      <CardContent>
        <Grid container direction="row" alignItems="center">
          <Box m={2}>
            <Avatar src={companyLogo} />
          </Box>
          <Box>
            <Typography variant="subtitle1">{workspaceName}</Typography>
            <Typography variant="body1">{`Invitation by ${inviter}`}</Typography>
          </Box>
        </Grid>
      </CardContent>
      <CardActions>
        <Button color="primary" component="span" onClick={handleClick}>
          ACCEPT INVITE
        </Button>
      </CardActions>
    </Card>
  );
}
