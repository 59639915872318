import { Box, Button } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Notifications } from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';
import { useNavigate } from 'react-router-dom';
import { settingSlice, meSlice, useAppSelector } from '@dayone/redux';
import { useCheckUnreadNotification } from '@dayone/hooks';

export default function TopNav(props: { handleDrawerToggle: () => void; callback: (input: string) => void }) {
  const { handleDrawerToggle, callback } = props;
  const pageTitle = useAppSelector<any>(settingSlice.selectPageTitle);
  const classes = useStyles();
  const navigate = useNavigate();

  const companyId = useAppSelector<any>(settingSlice.selectActiveWorkspaceID);
  const currentUserId = useAppSelector<any>(meSlice.selectCurrentUid);
  const { hasUnreadNotification } = useCheckUnreadNotification(currentUserId, companyId);

  return (
    <AppBar position="relative" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Box display="flex" alignItems="center">
          <IconButton
            color="primary"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6">{pageTitle}</Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <IconButton
            color="primary"
            aria-label="open drawer"
            edge="start"
            className={classes.notificationsIcon}
            onClick={() => navigate('/notifications')}
          >
            {hasUnreadNotification && <Box className={classes.notificationBadge}></Box>}
            <Notifications color="action" />
          </IconButton>
          <Button size="small" variant="contained" color="primary" onClick={() => callback('Logout')}>
            LOGOUT
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    boxShadow: 'none',
    height: theme.spacing(7),
    backgroundColor: 'white',
    color: theme.palette.text.primary,
    [theme.breakpoints.up('sm')]: {
      height: theme.spacing(8),
    },
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(5),
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(2),
    },
  },
  menuButton: {
    padding: theme.spacing(2),
    marginLeft: theme.spacing(0),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  notificationsIcon: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(1),
    },
    position: 'relative',
  },
  notificationBadge: {
    width: 8,
    height: 8,
    backgroundColor: '#f50057',
    borderRadius: '50%',
    position: 'absolute',
    top: 10,
    right: 10,
  },
}));
