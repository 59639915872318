import React from 'react';
import {
  Box,
  Collapse,
  makeStyles,
  TableCell,
  TableRow,
  Typography,
  Checkbox,
  styled,
  Link,
  Tooltip,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import UpdateIcon from '@material-ui/icons/Update';
import moment from 'moment';
import { announcementSlice } from '@dayone/redux';
import { removeHTMLTag } from '@dayone/common';

export default function AnnouncementTableRow(
  props: announcementSlice.IRemappedAnnouncement & { navigateToEdit: (id: string | number) => void }
) {
  const { id, date, branch, message, acknowledged, branchUsers, pin, isScheduled, broadcastDate } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const acknowledgement = `${acknowledged.length} out of ${branchUsers.length}`;
  const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

  let displayedBroadcastDate = pin ? date.format('DD MMM YYYY') : date.fromNow();
  if (isScheduled) displayedBroadcastDate = moment(broadcastDate).format('DD MMM YYYY');

  const renderAcknowledgedStaff = (name: string, isAcknowledged: boolean = false) => {
    return (
      <label className={classes.acknowledgeCheckBox}>
        <Checkbox checked={isAcknowledged} disabled />
        <Typography variant="body2" component="span">
          {name}
        </Typography>
      </label>
    );
  };
  return (
    <React.Fragment>
      <StyledTableRow pin={pin ? 'true' : 'false'}>
        <TableCell>
          <Typography variant={'body2'}>
            {displayedBroadcastDate}
            {isScheduled && (
              <Tooltip title="Scheduled announcement">
                <UpdateIcon color="action" fontSize="inherit" className={classes.scheduledIcon} />
              </Tooltip>
            )}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant={'body2'}>{branch.name}</Typography>
        </TableCell>
        <TableCell title={removeHTMLTag(message)}>
          <Typography variant={'body2'} component="span" onClick={() => props.navigateToEdit(id)}>
            <Link href="#" onClick={preventDefault}>
              {removeHTMLTag(message)}
            </Link>
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant={'body2'}>{acknowledgement}</Typography>
        </TableCell>
        <TableCell align="center">
          {open ? (
            <KeyboardArrowUpIcon onClick={() => setOpen(!open)} fontSize="small" />
          ) : (
            <KeyboardArrowDownIcon onClick={() => setOpen(!open)} fontSize="small" />
          )}
        </TableCell>
      </StyledTableRow>
      <TableRow>
        <TableCell className={classes.collapseCell} style={open ? undefined : { borderBottom: 'none' }} colSpan={2} />
        <TableCell className={classes.collapseCell} style={open ? undefined : { borderBottom: 'none' }} colSpan={3}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box py={2} display="flex" flexWrap="wrap">
              {branchUsers?.map((user) => (
                <React.Fragment key={user}>
                  {renderAcknowledgedStaff(user, acknowledged.indexOf(user) !== -1)}
                </React.Fragment>
              ))}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const StyledTableRow = styled(TableRow)((props: { pin: string }) => {
  return {
    backgroundColor: props.pin === 'false' ? 'inherit' : '#EAF4FC',
  };
});

const useStyles = makeStyles({
  collapseCell: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  acknowledgeCheckBox: {
    width: '160px',
    boxSizing: 'border-box',
    paddingRight: '16px',
  },
  scheduledIcon: {
    margin: '0 0 -2px 2px',
  },
});
