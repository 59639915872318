import {
  Box,
  Card,
  CardContent,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
  Tooltip,
  useTheme,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import Invite from './components/Invite';
import { myInviteSlice, useAppSelector } from '@dayone/redux';
import { useAccounts, useWorkspace } from '@dayone/hooks';
import { IInviteModel } from '@dayone/models';
import { IAccount, ICompany } from '@dayone/models';
import NotificationsIcon from '@material-ui/icons/Notifications';
import refData from '@dayone/refdata';

export default function SettingInvite() {
  const invites = useAppSelector<IInviteModel[]>(myInviteSlice.getInvites);
  let invitesPresent = invites.length > 0;
  const classes = useStyles();
  const theme = useTheme();

  const userIds = new Set<string>();
  const workspaceIds = new Set<string>();
  invites.forEach((invite) => {
    userIds.add(invite.uid);
    workspaceIds.add(invite.companyID);
  });
  const users = useAccounts(Array.from(userIds));
  const workspaces = useWorkspace(Array.from(workspaceIds));

  const decoratedInvites = invites.map((invite) => {
    const user = users?.find((user) => (user.id = invite.uid));
    const workspace = workspaces?.find((workspace) => workspace.id === invite.companyID);
    return {
      invite,
      user,
      workspace,
    } as IDecoratedInvite;
  });
  const haveInvites = () => {
    return (
      <Grid container spacing={3}>
        <Grid item sm={12} lg={8}>
          <Paper elevation={0}>
            <Card elevation={0}>
              <CardContent>
                <Grid container spacing={2} direction="column">
                  <Grid item>
                    <Box>
                      <Typography variant="h6">Workspace invites</Typography>
                    </Box>
                    <Box display="flex" flexDirection="row">
                      <Typography variant="subtitle1">Join a workspace</Typography>
                      <Box ml={1} />
                      <Tooltip title={refData.screen.setting['join-workspace']}>
                        <InfoIcon />
                      </Tooltip>
                    </Box>
                  </Grid>
                  <Grid item container>
                    {decoratedInvites.map((decoratedInvite, index) => {
                      return (
                        <Grid item key={index} md={6} lg={6}>
                          <Box m={1}>
                            <Invite decoratedInvite={decoratedInvite} />
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>

                  <Grid item lg={7}></Grid>
                </Grid>
              </CardContent>
            </Card>
          </Paper>
        </Grid>
      </Grid>
    );
  };
  const noInvites = () => {
    return (
      <Box position="relative" height="50vh">
        <Box
          position="absolute"
          left={0}
          top="50%"
          width="100%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <NotificationsIcon className={classes.notificationIcon} style={{ marginBottom: theme.spacing(3) }} />
          <Typography variant="h5">We’ll inform you when there’s a new workspace invite!</Typography>
        </Box>
      </Box>
    );
  };

  return invitesPresent ? haveInvites() : noInvites();
}

export interface IDecoratedInvite {
  invite: IInviteModel;
  user?: IAccount;
  workspace?: ICompany;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullScreen: {
      height: '100%',
    },
    notificationIcon: {
      height: '39px',
      width: '32px',
    },
  })
);
