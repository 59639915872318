import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Box, Typography, Paper, useMediaQuery, Grid, Card, Link, Tooltip } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { DataGrid, GridCellParams, GridColDef } from '@material-ui/data-grid';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { useNavigate } from 'react-router-dom';
import { memberSlice, teamSlice, useAppSelector } from '@dayone/redux';
import { ITeamOverview } from '../../../../redux/build/reducers/teamSlice';
import { EmptyData } from 'shared/components/emptyData';
import boxEmpty from 'assets/box-empty.svg';

const columnWithResponsive = (matches: boolean): GridColDef[] => {
  const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();
  return [
    {
      field: 'name',
      headerName: 'Team name',
      sortable: false,
      flex: matches ? 0 : 1,
      width: matches ? 300 : 0,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        return (
          <Box pl={1} overflow="hidden">
            <Typography variant={'body2'} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
              <Link href="#" onClick={preventDefault}>
                {params.value}
              </Link>
            </Typography>
          </Box>
        );
      },
    },
    {
      field: 'staffs',
      headerName: 'Staff',
      sortable: false,
      flex: matches ? 0 : 1,
      width: matches ? 300 : 0,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        const staffs = params.value as { id: string; name: string; avatar: string }[];
        return (
          <AvatarGroup max={5}>
            {staffs?.map((staff, staffIndex) => (
              <Tooltip key={staffIndex} title={`${staff.name}`}>
                <Avatar key={`branch${staff.avatar}${staffIndex}`} alt={`${staff.name}`} src={`${staff.avatar}`}>
                  {staff.name[0]}
                </Avatar>
              </Tooltip>
            ))}
          </AvatarGroup>
        );
      },
    },
    {
      field: 'dailyTask',
      headerName: 'Daily task',
      flex: matches ? 0 : 0.3,
      width: matches ? 150 : 0,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        return (
          <Box pl={1}>
            <Typography variant={'body2'}>{params.value}</Typography>
          </Box>
        );
      },
    },
    {
      field: 'adhocTask',
      headerName: 'Ad hoc tasks',
      flex: matches ? 0 : 0.3,
      width: matches ? 150 : 0,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        return (
          <Box pl={1}>
            <Typography variant={'body2'}>{params.value}</Typography>
          </Box>
        );
      },
    },
  ];
};

export default function TeamTable() {
  const theme = useTheme();
  const navigate = useNavigate();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const members = useAppSelector<any>(memberSlice.selectMembers);
  const teams = useAppSelector<any>(teamSlice.selectTeamsOverview);
  const teamWithStaffs = teams.map((team: any) => ({
    ...team,
    staffs: Array.from(new Set(team.users))
      .map((user) => {
        const member = members.find((member: any) => member.memberId === user);
        if (!!member)
          return {
            id: user,
            name: member.displayName,
            avatar: member.photoURL,
          };
        return null;
      })
      .filter((user) => !!user),
  }));

  const renderContent = () => {
    if (!teams || !teams.length)
      return (
        <EmptyData imageSrc={boxEmpty} emptyText="No teams yet" suggestion="Click on create team to get started" />
      );

    return (
      <Paper elevation={0}>
        <Card elevation={0}>
          <Box p={2}>
            <Typography variant="h6" align="left">
              {'Team Overview'}
            </Typography>
          </Box>
          <DataGrid
            autoHeight
            style={{ borderWidth: 0 }}
            rowHeight={theme.spacing(7)}
            disableSelectionOnClick
            getRowId={(row) => row.teamId}
            /** start pagination configs */
            pagination
            pageSize={10}
            /** end pagination configs */
            rows={teamWithStaffs}
            columns={columnWithResponsive(matches)}
            nonce={'No Teams'}
            onCellClick={(params: GridCellParams, event: React.MouseEvent) => {
              if (params.field === 'name') {
                const team: ITeamOverview = params.row as ITeamOverview;
                navigate(`edit/${team.teamId}`);
              }
            }}
          />
        </Card>
      </Paper>
    );
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {renderContent()}
      </Grid>
    </Grid>
  );
}
