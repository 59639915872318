"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FirebaseAnalyticsConfig = void 0;
var analytics_1 = require("firebase/analytics");
var analytics;
var FirebaseAnalyticsConfig = function (firebaseApp) {
    if (firebaseApp) {
        analytics = (0, analytics_1.getAnalytics)(firebaseApp);
    }
};
exports.FirebaseAnalyticsConfig = FirebaseAnalyticsConfig;
var logEvent = function (events, value) {
    return (0, analytics_1.logEvent)(analytics, events, value);
};
var logUser = function (user) {
    var _a = user !== null && user !== void 0 ? user : {}, _b = _a.displayName, displayName = _b === void 0 ? '' : _b, _c = _a.email, email = _c === void 0 ? '' : _c, _d = _a.emailVerified, emailVerified = _d === void 0 ? false : _d, _e = _a.phoneNumber, phoneNumber = _e === void 0 ? '' : _e, _f = _a.photoURL, photoURL = _f === void 0 ? '' : _f, _g = _a.uid, uid = _g === void 0 ? '' : _g;
    return (0, analytics_1.setUserProperties)(analytics, { displayName: displayName, email: email, emailVerified: emailVerified, phoneNumber: phoneNumber, photoURL: photoURL, uid: uid });
};
var logCompany = function (company) {
    var _a = company !== null && company !== void 0 ? company : {}, _b = _a.companyID, companyId = _b === void 0 ? '' : _b, _c = _a.companyName, companyName = _c === void 0 ? '' : _c;
    return (0, analytics_1.setUserProperties)(analytics, { companyId: companyId, companyName: companyName });
};
exports.default = { logEvent: logEvent, logUser: logUser, logCompany: logCompany };
