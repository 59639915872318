import { faqCategorySlice, teamSlice, useAppSelector } from '@dayone/redux';
import KnowledgeForm, { IFaqObject } from './components/KnowledgeForm';

export default function KnowledgeCreate() {
  const categories = useAppSelector<any>(faqCategorySlice.selectAllFaqCategories);
  const teams = useAppSelector(teamSlice.selectActiveTeams);

  const initialValues: IFaqObject = {
    videos: [],
    images: [],
    createBy: '',
    branchName: '',
    branches: teams,
    status: 'UNANSWERED',
    faqId: '',
    answer: '',
    question: '',
    tags: [],
    categories: [],
    companyID: '',
    branchID: '',
    displayName: '',
    companyName: '',
    helpfulNo: [],
    helpfulYes: [],
    isAnon: false,
    isArchived: false,
    createdAt: new Date().toDateString(),
    updatedAt: new Date().toDateString(),
    createdBy: '',
    updatedBy: '',
    createdByDisplayName: '',
    updatedByDisplayName: '',
  };

  return <KnowledgeForm knowledge={initialValues} categories={categories} editable={true}></KnowledgeForm>;
}
