import { signInWithEmailAndPassword } from '@dayone/firebase';
import { TextField, Button, Grid, Box, Container, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import * as Sentry from '@sentry/react';
import React from 'react';
import { ProgressIndicator } from 'shared/components/progressIndicator';
import { useFormik, FormikHelpers } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { Logo } from 'shared/components/logo';
import analytics from 'shared/utilities/analytics';
import GAEvents from 'shared/utilities/events';

export default function LoginEmail() {
  const navigate = useNavigate();

  const onSubmit = async (values: IFormikInitialValue, { setSubmitting }: FormikHelpers<IFormikInitialValue>) => {
    await analytics.logEvent(GAEvents.login, { method: 'email' });

    const { email, password } = values;
    signInWithEmailAndPassword(email, password)
      .catch((error) => {
        formik.setStatus(error.message);
        Sentry.captureException(error);
      })
      .finally(() => setSubmitting(false));
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: onSubmit,
  });
  return (
    <React.Fragment>
      <Box m={5} mb={8}>
        <Logo />
      </Box>
      <Container maxWidth="md">
        <form onSubmit={formik.handleSubmit}>
          {formik.isSubmitting && <ProgressIndicator />}

          <Grid container justifyContent="center">
            <Grid item md={6} xs={12}>
              <Box mb={7}>
                <Typography variant="h4" align="center">
                  Log in via email.
                </Typography>
              </Box>

              <Box px={5}>
                <Grid item container direction="column" spacing={3}>
                  <Grid item>
                    {formik.status && (
                      <Alert variant="outlined" severity="error">
                        {formik.status}
                      </Alert>
                    )}
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="email"
                      label="Email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={formik.touched.email && Boolean(formik.errors.email)}
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="password"
                      label="Password"
                      type="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      error={formik.touched.password && Boolean(formik.errors.password)}
                      helperText={formik.touched.password && formik.errors.password}
                    />
                  </Grid>
                  <Grid item>
                    <Box px={5} mt={1}>
                      <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={formik.isSubmitting}
                      >
                        Login
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box display="flex" justifyContent="center" alignItems="center">
                      <Button color="primary" onClick={() => navigate('../forgotpassword')}>
                        Forgot password?
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box mt={5}>
                <Button color="primary" startIcon={<ArrowBackIcon />} onClick={() => navigate(-1)}>
                  Back to phone number login
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Container>
    </React.Fragment>
  );
}

interface IFormikInitialValue {
  email: string;
  password: string;
}

const initialValues: IFormikInitialValue = {
  email: '',
  password: '',
};

const validationSchema = yup.object({
  email: yup.string().email('Enter a valid email').defined('Email is required'),
  password: yup.string().defined('Password is required'),
});
