import { Box, Button, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { CloudUpload } from '@material-ui/icons';
import React, { ChangeEvent } from 'react';
import { OptionsObject, useSnackbar } from 'notistack';

import ImageCell, { IImage } from './components/ImageCell';
import bytesToMegaBytes from 'shared/utilities/bytesToMegaBytes';
import { validationErrors } from '@dayone/common';
export type { IImage } from './components/ImageCell';

function UploadImages(props: {
  text: string;
  acceptType: string;
  images: IImage[];
  onUploadImage?: React.ChangeEventHandler<any>; // changed `HTMLInputElement` to `any` to use `validImages` array
  onRemove?: (image: IImage) => void;
  isDisabled?: boolean;
  limit?: number;
  /**
   * Max image size in byte
   */
  fileSizeLimit?: number;
  numberOfFileLimitError?: string;
  fileSizeLimitError?: string;
}) {
  const classes = useStyles();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  const warningSnackOptions: OptionsObject = {
    variant: 'error',
    anchorOrigin: {
      vertical: isXs ? 'bottom' : 'top',
      horizontal: isXs ? 'center' : 'right',
    },
  };

  const handleUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const addedImages = event.target.files || [];
    let validImages: File[] = [];
    if (props.fileSizeLimit && addedImages && addedImages.length) {
      for (let i = 0; i < addedImages.length; i++) {
        const file = addedImages[i];

        if (file.size > props.fileSizeLimit) {
          const fileSizeLimit = bytesToMegaBytes(props.fileSizeLimit, 1);
          const errMessage = validationErrors.default(props.fileSizeLimitError as string, {
            FILE_NAME: file.name,
            FILE_SIZE: fileSizeLimit,
          });
          enqueueSnackbar(errMessage, warningSnackOptions);
          return;
        } else validImages.push(file);
      }
    } else validImages = Array.from(addedImages);

    const totalImagesCount = validImages.length + props.images.length;
    if (props.limit && totalImagesCount > props.limit) {
      enqueueSnackbar(
        validationErrors.default(props.numberOfFileLimitError as string, {
          NUMBER_OF_FILE_LIMIT: props.limit,
          NUMBER_OF_FILES_TO_REMOVE: totalImagesCount - props.limit,
        }),
        warningSnackOptions
      );
      return;
    }

    if (props.onUploadImage) {
      const updatedEvent = { ...event, target: { ...event.target, files: validImages } };
      props.onUploadImage(updatedEvent);
      event.target.value = '';
    }
  };

  return (
    <Box className={classes.root} flex="column">
      <input
        accept={`${props.acceptType}`}
        disabled={props.isDisabled}
        style={{
          display: 'none',
        }}
        id={`${props.acceptType}`}
        multiple
        type="file"
        onChange={handleUpload}
      />
      <Box display="flex" justifyContent="center" pb={2} pr={2} pl={2}>
        <label htmlFor={`${props.acceptType}`}>
          <Button
            disabled={props.isDisabled}
            style={{
              width: theme.spacing(36),
            }}
            variant="outlined"
            color="primary"
            component="span"
            startIcon={<CloudUpload />}
          >
            {props.text}
          </Button>
        </label>
      </Box>
      {/* {!props.isDisabled && ( */}
      <>
        <Box display="flex" flexWrap="wrap" justifyContent="center" overflow="auto">
          {props.images &&
            props.images
              .filter((x) => x.action !== 'REMOVE')
              .map((image, index) => {
                if (props.isDisabled) {
                  return (
                    <ImageCell key={image.url} image={image} hasDivider={index !== (props.images?.length ?? 0) - 1} />
                  );
                }
                return (
                  <ImageCell
                    key={image.url}
                    image={image}
                    onRemove={() => {
                      if (props.onRemove) props.onRemove(image);
                    }}
                    hasDivider={index !== (props.images?.length ?? 0) - 1}
                  />
                );
              })}
        </Box>
      </>
      {/* )} */}
      {/* <Box height={theme.spacing(4)}></Box> */}
    </Box>
  );
}

export default UploadImages;

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),

    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
  },
}));
