import React, { Ref, PropsWithChildren } from 'react';
import { cx, css } from '@emotion/css';

import { BaseProps } from 'shared/utilities/richTextHelpers';
import Menu from './Menu';

const Toolbar = React.forwardRef(({ className, ...props }: PropsWithChildren<BaseProps>, ref: Ref<HTMLDivElement>) => (
  <Menu
    {...props}
    ref={ref}
    className={cx(
      className,
      css`
        position: relative;
        display: flex;
        align-content: center;
      `
    )}
  />
));

export default Toolbar;
