import React from 'react';
import {
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  TablePagination,
  Box,
} from '@material-ui/core';
import AnnouncementTableRow from './AnnouncementTableRow';
import { announcementSlice } from '@dayone/redux';

export default function AnnouncementTable(props: {
  data: announcementSlice.IRemappedAnnouncement[];
  navigateToEdit: (id: string | number) => void;
}) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box mx={-2} mb={-3}>
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell width="13%">Broadcast Date</TableCell>
              <TableCell width="18%">Branch</TableCell>
              <TableCell width="49%">Message</TableCell>
              <TableCell width="14%">Acknowledgement</TableCell>
              <TableCell width="6%" />
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <AnnouncementTableRow key={row.id} {...row} navigateToEdit={props.navigateToEdit} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={props.data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(event, page) => handleChangePage(page)}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: theme.spacing(130),
      tableLayout: 'fixed',
      '& td': {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
    },
  })
);
