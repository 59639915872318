import { Grid } from '@material-ui/core';
import { faqSlice, useAppSelector } from '@dayone/redux';
import KnowledgeList from './components/KnowledgeList';
import React from 'react';

export default function KnowledgeUnanswered() {
  const faqs = useAppSelector(faqSlice.selectUnansweredFaqs);
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <KnowledgeList knowledges={faqs} statusType="UNANSWERED"></KnowledgeList>
      </Grid>
    </Grid>
  );
}
