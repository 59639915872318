import React, { ChangeEvent, useEffect } from 'react';
import {
  useTheme,
  Box,
  Grid,
  Button,
  TextField,
  Typography,
  Chip,
  useMediaQuery,
  Paper,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Card,
  CardContent,
  IconButton,
  Tooltip,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from '@material-ui/core';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import DeleteIcon from '@material-ui/icons/Delete';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { UploadImages, IImage } from 'shared/components/uploadImage';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ArchiveIcon from '@material-ui/icons/Archive';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {
  faqSlice,
  useAppDispatch,
  useAppSelector,
  IFaqData,
  featureFlagSlice,
  workspaceSlice,
  settingSlice,
  teamSlice,
} from '@dayone/redux';
import { IFaqCategoryModel, ITeamModel, IWorkspaceModel } from '@dayone/models';
import Moment from 'react-moment';
import { ProgressIndicator } from 'shared/components/progressIndicator';
import refData from '@dayone/refdata';
import { RichText } from 'shared/components/richtext';
import { UploadVideos, IVideo } from 'shared/components/uploadVideo';
import bytesToMegaBytes from 'shared/utilities/bytesToMegaBytes';
import { validationErrors } from '@dayone/common';
import { useLogEventWithViewDuration } from 'shared/components/hooks';
import GAEvents from 'shared/utilities/events';
import { SelectedBranches } from 'shared/components/teamSelector';
import _ from 'lodash';

export interface IFaqObject extends Omit<IFaqData, 'images' | 'videos'> {
  videos: IVideo[];
  images: IImage[];
}

export default function KnowledgeForm(props: {
  knowledge: IFaqObject;
  categories: IFaqCategoryModel[];
  editable?: boolean;
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const featureFlag = useAppSelector(featureFlagSlice.selectFeatureFlags);
  const workSpaces = useAppSelector<IWorkspaceModel[]>(workspaceSlice.selectAllWorkspaces);
  const teams = useAppSelector(teamSlice.selectActiveTeams);
  const activeWorkspaceID = useAppSelector<any>(settingSlice.selectActiveWorkspaceID);
  const workSpace = workSpaces?.find((item) => item.id === activeWorkspaceID);
  const { storageLimit, numberOfFileLimitImage, numberOfFileLimitVideo, filesizeLimitImage, filesizeLimitVideo } =
    featureFlag.knowledge;
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  const [open, setOpen] = React.useState(false);
  const [searchParams] = useSearchParams();
  const { knowledge, categories, editable } = props;

  useLogEventWithViewDuration(GAEvents.knowledge_view_details, {
    isFromSearch: searchParams.get('isFromSearch') === 'true',
  });

  const categoryMapByItsIds = categories.reduce(
    (
      _categoryMapByItsIds: {
        [key: string]: IFaqCategoryModel;
      },
      category
    ) => ({
      ..._categoryMapByItsIds,
      [category.id]: category,
    }),
    {}
  );

  useEffect(() => {
    if (knowledge.faqId) {
      const activeFaqBranches = _.intersection(teams, knowledge.branches);
      formik.setFieldValue('branches', activeFaqBranches);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [knowledge, teams]);

  const onSubmit = (values: IFaqObject) => {
    if (values.faqId) {
      dispatch(
        faqSlice.updateKnowledge({
          knowledge: {
            faqId: values.faqId,
            answer: values.answer,
            categories: values.categories,
            question: values.question,
            tags: values.tags,
            branches: values.branches,
          },
          images: values.images,
          videos: values.videos,
        })
      )
        .unwrap()
        .then(() => {
          navigate(-1);

          enqueueSnackbar('The FAQ is now updated!', {
            variant: 'success',
            anchorOrigin: {
              vertical: matches ? 'bottom' : 'top',
              horizontal: matches ? 'center' : 'right',
            },
          });
        })
        .catch((err) => {
          enqueueSnackbar(err.message, {
            variant: 'error',
            anchorOrigin: {
              vertical: matches ? 'bottom' : 'top',
              horizontal: matches ? 'center' : 'right',
            },
          });
        })
        .finally(() => {
          formik.setSubmitting(false);
        });
    } else {
      let imageFiles: File[] = [];
      values.images.forEach((element) => {
        if (element.file) {
          imageFiles.push(element.file);
        }
      });

      let videoFiles: File[] = [];
      values.videos.forEach((element) => {
        if (element.file) {
          videoFiles.push(element.file);
        }
      });
      dispatch(
        faqSlice.createKnowledge({
          answer: values.answer,
          branchID: values.branchID,
          branches: values.branches,
          categories: values.categories,
          isAnon: values.isAnon,
          question: values.question,
          tags: values.tags,
          imageFiles: imageFiles,
          videoFiles: videoFiles,
        })
      )
        .unwrap()
        .then(() => {
          navigate(-1);
          enqueueSnackbar('The FAQ is now active!', {
            variant: 'success',
            anchorOrigin: {
              vertical: matches ? 'bottom' : 'top',
              horizontal: matches ? 'center' : 'right',
            },
          });
        })
        .catch((err) => {
          enqueueSnackbar(err.message, {
            variant: 'error',
            anchorOrigin: {
              vertical: matches ? 'bottom' : 'top',
              horizontal: matches ? 'center' : 'right',
            },
          });
        })
        .finally(() => {
          formik.setSubmitting(false);
        });
    }
  };

  const formik = useFormik({
    initialValues: knowledge,
    validationSchema: validationFormSchema,
    enableReinitialize: true,
    onSubmit: onSubmit,
  });

  const handleArchive = (faqID: string) => {
    formik.setSubmitting(true);
    dispatch(faqSlice.archiveKnowledge({ faqID: faqID, isArchived: !knowledge.isArchived }))
      .then(() => {
        navigate(-1);
        enqueueSnackbar(`The FAQ has been ${knowledge.isArchived ? 'unarchived' : 'archived'} successfully!`, {
          variant: 'success',
          anchorOrigin: {
            vertical: matches ? 'bottom' : 'top',
            horizontal: matches ? 'center' : 'right',
          },
        });
      })
      .catch((err) => {
        //console.log(err);
      })
      .finally(() => {
        formik.setSubmitting(false);
      });
  };
  const handleDisplayDialogDelete = () => {
    setOpen(true);
  };

  const handleClose = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, isDelete: boolean) => {
    setOpen(false);
    if (isDelete) {
      formik.setSubmitting(true);
      dispatch(faqSlice.deleteKnowledge({ faqID: knowledge.faqId }))
        .then(() => {
          navigate(-1);
          enqueueSnackbar('The FAQ has been deleted!', {
            variant: 'warning',
            anchorOrigin: {
              vertical: matches ? 'bottom' : 'top',
              horizontal: matches ? 'center' : 'right',
            },
          });
        })
        .finally(() => {
          formik.setSubmitting(false);
        });
    }
  };

  const handleAllBranchCheck = (checked: boolean) => {
    if (checked) {
      formik.setFieldValue('branches', teams);
    } else {
      formik.setFieldValue('branches', []);
    }
  };

  const handleBranchCheck: (branch: ITeamModel, checked: boolean) => void = (branch, checked) => {
    if (checked) {
      if (formik.values.branches.findIndex(({ teamId }) => teamId === branch.teamId) === -1)
        formik.setFieldValue('branches', formik.values.branches.concat(branch));
    } else {
      formik.setFieldValue(
        'branches',
        formik.values.branches.filter(({ teamId }) => teamId !== branch.teamId)
      );
    }
  };
  const validateStorageLimit = (addedFiles: File[]) => {
    const currentUploadedFiles = [...formik.values.images, ...formik.values.videos];
    const currentUploadedSize = currentUploadedFiles.reduce((total, file) => total + (file.file?.size || 0), 0);
    const totalAddedSize = addedFiles.reduce((total, file) => total + file.size, 0);
    const currentStorageSize = workSpace?.knowledgeStorageSize || 0;

    if (currentStorageSize + currentUploadedSize + totalAddedSize > storageLimit) {
      enqueueSnackbar(
        validationErrors.default('Knowledge.StorageLimit', { STORAGE_LIMIT: bytesToMegaBytes(storageLimit, 1) }),
        {
          variant: 'error',
          anchorOrigin: {
            vertical: matches ? 'bottom' : 'top',
            horizontal: matches ? 'center' : 'right',
          },
        }
      );
      return false;
    }
    return true;
  };

  const handleUploadImage = (event: ChangeEvent<any>) => {
    const files = event.target.files as File[];
    if (files && files.length) {
      if (!validateStorageLimit(files)) return;
      const newFiles = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const url = URL.createObjectURL(file);
        if (!formik.values.images.find((f) => f.file?.name === file.name))
          newFiles.push({ file: file, url: url, action: 'ADD' });
      }
      formik.setFieldValue('images', [...formik.values.images, ...newFiles]);
    }
  };
  const handleUploadVideo = (event: ChangeEvent<any>) => {
    const files = event.target.files as File[];
    if (files && files.length) {
      if (!validateStorageLimit(files)) return;
      const newFiles = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const url = URL.createObjectURL(file);
        if (!formik.values.videos.find((f) => f.file?.name === file.name))
          newFiles.push({ file: file, url: url, action: 'ADD' });
      }
      formik.setFieldValue('videos', [...formik.values.videos, ...newFiles]);
    }
  };

  const handleRemoveImage = (image: IImage) => {
    const meadiaArr = formik.values.images
      .filter((img: IImage) => img.action !== 'ADD' || img.url !== image.url)
      .map((img) => ({ ...img, action: img.url !== image.url ? img.action : 'REMOVE' }));
    formik.setFieldValue('images', meadiaArr);
  };

  const handleRemoveVideo = (video: IVideo) => {
    const meadiaArr = formik.values.videos
      .filter((vi) => vi.action !== 'ADD' || vi.url !== video.url)
      .map((vi) => ({ ...vi, action: vi.url !== video.url ? vi.action : 'REMOVE' }));
    formik.setFieldValue('videos', meadiaArr);
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={3}>
        {/* Back button */}
        {knowledge.faqId && (
          <Grid item xs={12}>
            <Box pb={2}>
              <Button color="primary" startIcon={<ArrowBackIcon />} onClick={handleBack}>
                {`BACK`}
              </Button>
            </Box>
          </Grid>
        )}

        {/* Edit form */}
        <Grid item xs={12} sm={12} lg={8}>
          <Paper elevation={0}>
            <Card elevation={0}>
              <CardContent>
                {knowledge.faqId && (
                  <Box pb={4}>
                    <Box display="flex" justifyContent="flex-end">
                      {knowledge.status !== 'ARCHIVED' && (
                        <IconButton onClick={() => handleArchive(knowledge.faqId)}>
                          {knowledge.isArchived ? (
                            <Tooltip title={refData.screen.knowledge['button-unarchive']}>
                              <UnarchiveIcon style={{ color: theme.palette.primary.main, cursor: 'pointer' }} />
                            </Tooltip>
                          ) : (
                            <Tooltip title={refData.screen.knowledge['button-archive']}>
                              <ArchiveIcon style={{ color: theme.palette.action.active, cursor: 'pointer' }} />
                            </Tooltip>
                          )}
                        </IconButton>
                      )}
                      {/* <Box display="flex" pl={3} /> */}
                      <IconButton onClick={handleDisplayDialogDelete}>
                        <Tooltip title={refData.screen.knowledge['button-delete']}>
                          <DeleteIcon style={{ color: theme.palette.secondary.main, cursor: 'pointer' }} />
                        </Tooltip>
                      </IconButton>
                    </Box>
                    <Box pt={3} display="flex">
                      <Typography variant="body2">Question by</Typography>
                      <Box pl={1} />
                      <Typography variant="body2" style={{ fontWeight: 700 }}>
                        {knowledge.isAnon ? 'Anonymous' : knowledge.createBy}{' '}
                        {!knowledge.isAnon && !knowledge.branchName && `(Admin)`}
                      </Typography>
                      {!knowledge.isAnon ? (
                        knowledge.branchName ? (
                          <>
                            <Box pl={1} />
                            <Typography variant="body2">from</Typography>
                            <Box pl={1} />
                            <Typography
                              variant="body2"
                              style={{ fontWeight: 700 }}
                            >{`${knowledge.branchName}`}</Typography>{' '}
                          </>
                        ) : null
                      ) : (
                        ''
                      )}
                    </Box>
                    <Box pt={1}>
                      {knowledge.createdAt && (
                        <Typography variant="caption">
                          {`Posted on `}
                          <Moment format=" DD MMMM YYYY">{knowledge.createdAt}</Moment>
                          {/* {knowledge.createdAt} */}
                        </Typography>
                      )}
                      {knowledge.updatedAt && knowledge.updatedAt !== knowledge.createdAt && (
                        <Typography variant="caption">
                          {`; last updated at `}
                          {knowledge.updatedAt ? (
                            <Moment format="HH:mm A on DD MMMM YYYY">{knowledge.updatedAt}</Moment>
                          ) : (
                            ''
                          )}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                )}
                <TextField
                  fullWidth
                  label="Question"
                  name="question"
                  disabled={!editable}
                  multiline
                  rows={2}
                  defaultValue={formik.values.question}
                  variant="outlined"
                  onChange={formik.handleChange}
                  error={formik.touched.question && Boolean(formik.errors.question)}
                  helperText={formik.touched.question && formik.errors.question}
                />
                <Box pt={3} />

                <RichText
                  label="Answer"
                  disabled={!editable}
                  defaultValue={formik.values.answer}
                  onChange={(val) => formik.setFieldValue('answer', val)}
                  error={formik.touched.answer && Boolean(formik.errors.answer)}
                  helperText={formik.touched.answer && formik.errors.answer}
                />
                <Box pt={4} />
                <Autocomplete
                  multiple
                  disabled={!editable}
                  options={categories}
                  getOptionLabel={({ name }) => name}
                  defaultValue={formik.values.categories.map((_categoryId) => categoryMapByItsIds[_categoryId])}
                  value={formik.values.categories.map((_categoryId) => categoryMapByItsIds[_categoryId])}
                  onChange={(event, value) => {
                    formik.setFieldValue(
                      'categories',
                      value.map(({ id }) => id)
                    );
                  }}
                  renderTags={(value: IFaqCategoryModel[], getTagProps) => {
                    return value.map((option, index: number) => (
                      <Chip variant="outlined" label={`${option?.name}`} {...getTagProps({ index })} />
                    ));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Category"
                      placeholder={formik.values.categories.length ? '' : 'Select category'}
                    />
                  )}
                />
                <Box>
                  <Box pt={3}>
                    <Typography variant="h6">Team</Typography>
                    <Box display="flex" alignItems="center">
                      <Typography style={{ marginRight: theme.spacing(1) }} variant="subtitle1">
                        Show knowledge to specific teams
                      </Typography>
                    </Box>
                    {formik.touched.branches && <FormHelperText error>{formik.errors.branches}</FormHelperText>}
                  </Box>
                  {!!formik.values?.branches?.length && (
                    <Box p={1}>
                      <SelectedBranches branches={formik.values.branches} />
                    </Box>
                  )}
                  {!formik.values?.branches?.length && (
                    <Box m={2} p={3.5}>
                      
                    </Box>
                  )}
                </Box>
                {!!formik.values.branches?.length && (
                  <Typography variant="body1" color="textSecondary">
                    All teams ({formik.values.branches?.length} selected)
                  </Typography>
                )}
                <Grid item xs={12} container>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={formik.values.branches.length === teams.length}
                          onChange={(event, checked) => {
                            handleAllBranchCheck(checked);
                          }}
                          name="allTeams"
                        />
                      }
                      label="All teams"
                    />
                  </Grid>
                  {teams.map((branch) => (
                    <Grid key={branch.teamId} item xs={12} sm={6}>
                      <FormControlLabel
                        style={{ marginLeft: 0 }}
                        control={
                          <Checkbox
                            color="primary"
                            checked={formik.values.branches.findIndex(({ teamId }) => teamId === branch.teamId) !== -1}
                            onChange={(event, checked) => {
                              handleBranchCheck(branch, checked);
                            }}
                            name={branch.name}
                          />
                        }
                        label={<Typography variant="body1">{branch.name}</Typography>}
                      />
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
            </Card>
          </Paper>
        </Grid>
        {/* Upload component */}
        <Grid item xs={12} sm={12} lg={4}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} lg={12}>
              <Paper elevation={0}>
                <Card elevation={0}>
                  <CardContent>
                    <UploadImages
                      key={'uploadImage'}
                      text="UPLOAD IMAGE"
                      isDisabled={!editable}
                      acceptType="image/*"
                      images={formik.values.images}
                      onUploadImage={handleUploadImage}
                      onRemove={handleRemoveImage}
                      limit={numberOfFileLimitImage}
                      fileSizeLimit={filesizeLimitImage}
                      numberOfFileLimitError="Knowledge.NumberOfFileLimitImage"
                      fileSizeLimitError="Knowledge.FileSizeLimitImage"
                    />
                  </CardContent>
                </Card>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} lg={12}>
              <Paper elevation={0}>
                <Card elevation={0}>
                  <CardContent>
                    <UploadVideos
                      key={'uploadVideo'}
                      isDisabled={!editable}
                      text="UPLOAD VIDEO"
                      acceptType="video/*"
                      videos={formik.values.videos}
                      onUploadVideos={handleUploadVideo}
                      onRemove={handleRemoveVideo}
                      limit={numberOfFileLimitVideo}
                      fileSizeLimit={filesizeLimitVideo}
                      numberOfFileLimitError="Knowledge.NumberOfFileLimitVideo"
                      fileSizeLimitError="Knowledge.FileSizeLimitVideo"
                    />
                  </CardContent>
                </Card>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        {/* Submit Button */}
        <Grid item xs={12} sm={3} lg={2}>
          <Box pt={2} />
          <Button disabled={!editable} fullWidth={matches} type="submit" variant="contained" color="primary">
            {knowledge.faqId ? 'UPDATE' : 'PUBLISH FAQ'}
          </Button>
        </Grid>

        <Dialog open={open}>
          <DialogTitle>{`Are you sure you want to delete the FAQ?`}</DialogTitle>
          <DialogContent>
            <DialogContentText>{`You can't undo this action.`}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={(event) => handleClose(event, false)} color="primary">
              {'Cancel'}
            </Button>
            <Button onClick={(event) => handleClose(event, true)} color="primary">
              {'Delete'}
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
      <ProgressIndicator loading={formik.isSubmitting} />
    </form>
  );
}

export const validationFormSchema = yup.object({
  question: yup.string().required('Question is required'),
  answer: yup.string().required('Answer is required'),
  branches: yup.array().min(1, 'Please select at least 1 team.'),
});
