import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Input,
  InputLabel,
} from '@material-ui/core';
import moment from 'moment';
import { InfoOutlined as InfoOutlinedIcon } from '@material-ui/icons';
import { IRepositoryModel, IMemberModel } from '@dayone/models';

export default function InfoDialog(props: {
  isModalOpen: boolean;
  onCloseModal: () => void;
  selectedFile: IRepositoryModel;
  members: IMemberModel[];
}) {
  const { isModalOpen, onCloseModal, selectedFile, members } = props;
  const TIME_FORMAT = 'h:mm A [on] DD MMMM YYYY';

  return (
    <Dialog
      open={isModalOpen}
      maxWidth="sm"
      fullWidth
      onClose={onCloseModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box sx={{ mr: 2 }}>
            <InfoOutlinedIcon color="action" fontSize="large" />
          </Box>
          File information
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <InputLabel color="secondary">Name</InputLabel>
          <Input value={selectedFile.fileName} fullWidth disableUnderline disabled color="secondary" multiline />
        </DialogContentText>
        <DialogContentText>
          <InputLabel color="secondary">Created by</InputLabel>
          <Input
            value={members.find((member) => member.memberId === selectedFile.createdBy)?.displayName}
            fullWidth
            disableUnderline
            disabled
            color="secondary"
            multiline
          />
        </DialogContentText>
        <DialogContentText>
          <InputLabel color="secondary">Created</InputLabel>
          <Input
            value={moment(selectedFile.createdAt).format(TIME_FORMAT)}
            fullWidth
            disableUnderline
            disabled
            color="secondary"
            multiline
          />
        </DialogContentText>
        <DialogContentText>
          <InputLabel color="secondary">Last updated by</InputLabel>
          <Input
            value={members.find((member) => member.memberId === selectedFile.updatedBy)?.displayName}
            fullWidth
            disableUnderline
            disabled
            color="secondary"
            multiline
          />
        </DialogContentText>
        <DialogContentText>
          <InputLabel color="secondary">Modified</InputLabel>
          <Input
            value={moment(selectedFile.updatedAt).format(TIME_FORMAT)}
            fullWidth
            disableUnderline
            disabled
            color="secondary"
            multiline
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onCloseModal} variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
