import { Dialog, DialogContent, DialogTitle, DialogActions, TextField, Button, createTheme } from '@material-ui/core';
import { useState, forwardRef, Ref, useImperativeHandle } from 'react';

interface IProps {
  useDialogFor: 'edit' | 'create';
  formik: any;
}

export interface IRef {
  toggle: Function;
}

const CategoryCreateEditDialog = ({ useDialogFor, formik }: IProps, ref: Ref<IRef>) => {
  const theme = createTheme();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const isEditing = useDialogFor === 'edit';
  const toggleDialog = () => setIsDialogOpen(!isDialogOpen);

  useImperativeHandle(ref, () => ({
    toggle: () => toggleDialog(),
  }));

  return (
    <Dialog fullWidth open={isDialogOpen} onClose={() => toggleDialog()}>
      <DialogTitle>{isEditing ? 'Rename category' : 'New category'}</DialogTitle>
      <DialogContent>
        <TextField
          value={formik.values.category?.name || ''}
          onChange={(event) => {
            formik.setFieldValue('category.name', event.target.value);
          }}
          label=""
          name="category"
          placeholder="Category name"
          fullWidth
          variant="outlined"
          error={formik.touched.category && Boolean(formik.errors.category)}
          helperText={formik.touched.category && formik.errors.category}
        />
      </DialogContent>
      <DialogActions style={{ paddingRight: theme.spacing(3), paddingBottom: theme.spacing(3) }}>
        <Button
          onClick={() => {
            toggleDialog();
            formik.resetForm();
          }}
          color="primary"
        >
          CANCEL
        </Button>
        <Button onClick={() => formik.handleSubmit()} color="primary" variant="contained">
          {isEditing ? 'SAVE' : 'CREATE'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default forwardRef(CategoryCreateEditDialog);
