import React, { useCallback } from 'react';
import {
  useTheme,
  Box,
  Grid,
  Button,
  TextField,
  Typography,
  Chip,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  useMediaQuery,
  Paper,
  Card,
  CardContent,
  Tooltip,
} from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';
import InfoIcon from '@material-ui/icons/Info';
import { useFormik } from 'formik';
import DeleteIcon from '@material-ui/icons/Delete';
import * as yup from 'yup';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { memberSlice, teamSlice, useAppDispatch, useAppSelector, settingSlice } from '@dayone/redux';
import { ITeam, IMemberModel } from '@dayone/models';
import { ProgressIndicator } from 'shared/components/progressIndicator';
import refData from '@dayone/refdata';
import { useSnackbar } from 'shared/components/hooks';
interface ITeamStaff {
  id: string;
  name: string;
  avatar: string;
}

interface IFormikInitialValue {
  branchName: string;
  staffs: ITeamStaff[];
}

export default function TeamDetail() {
  const theme = useTheme();
  const navigate = useNavigate();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const enqueueSnackbar = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const { branchID = '' } = useParams();
  const branch: ITeam | null = useAppSelector<any>(teamSlice.selectTeamByID)(branchID);

  const members = useAppSelector<any>(memberSlice.selectAndAddAdminPrefixForAdminMembers);

  const companyId = useAppSelector<any>(settingSlice.selectActiveWorkspaceID);

  const dispatch = useAppDispatch();

  const refDataTeam = refData.screen.team;

  const initialValues: IFormikInitialValue = {
    branchName: (branchID && branch?.name) ?? '',
    staffs: members
      .filter((member: any) => branch && branch.users && branch!.users!.indexOf(member.memberId) > -1)
      .map((member: any) => {
        return { id: member.memberId, name: member?.displayName ?? '', avatar: member?.displayName ?? '' };
      }),
  };

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onSubmit = (values: IFormikInitialValue) => {
    if (branch && branch.teamId) {
      updateTeam(values);
    } else {
      createTeam(values);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationFormSchema,
    onSubmit: onSubmit,
  });

  const createTeam = useCallback(
    (values: IFormikInitialValue) => {
      const team: ITeam = {
        teamId: '',
        name: values.branchName,
        users: values.staffs.map((staff) => staff.id),
        isInactive: false,
      };
      if (companyId) {
        formik.setSubmitting(true);
        dispatch(teamSlice.createTeam({ team, companyID: companyId }))
          .unwrap()
          .then(() => {
            navigate(-1);
            enqueueSnackbar('A new team has been created successfully!', {
              variant: 'success',
            });
          })
          .catch((err) => {
            enqueueSnackbar(err.message, {
              variant: 'error',
            });
          })
          .finally(() => {
            formik.setSubmitting(false);
          });
      }
    },
    // eslint-disable-next-line
    [companyId, dispatch, navigate, enqueueSnackbar]
  );

  const updateTeam = useCallback(
    (values: IFormikInitialValue) => {
      if (!branch || !branch.teamId) return;
      const team: ITeam = {
        teamId: branch.teamId,
        name: values.branchName,
        users: values.staffs.map((staff) => staff.id),
        isInactive: false,
      };
      if (companyId) {
        formik.setSubmitting(true);
        dispatch(teamSlice.updateTeam({ team, companyID: companyId })).then(() => {
          formik.setSubmitting(false);
          navigate(-1);
          enqueueSnackbar('The team has been updated successfully!', {
            variant: 'success',
          });
        });
      }
    },
    // eslint-disable-next-line
    [branch, companyId, dispatch, navigate, enqueueSnackbar]
  );

  const handleDelete = useCallback(() => {
    setOpen(false);
    if (companyId && branch?.teamId) {
      formik.setSubmitting(true);
      dispatch(teamSlice.deleteTeam({ teamId: branch.teamId, companyID: companyId })).then(() => {
        formik.setSubmitting(false);
        enqueueSnackbar('The team has been deleted!', {
          variant: 'warning',
        });
        navigate(-1);
      });
    }
  }, [setOpen, companyId, branch, formik, dispatch, enqueueSnackbar, navigate]);

  const memberToStaff = useCallback((member: IMemberModel): ITeamStaff => {
    return { id: member.memberId, name: member?.displayName ?? '', avatar: member?.displayName ?? '' } as ITeamStaff;
  }, []);

  const handleSelectAll = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        formik.setFieldValue(
          'staffs',
          members.map((member: any) => memberToStaff(member))
        );
      } else {
        formik.setFieldValue('staffs', []);
      }
    },
    // eslint-disable-next-line
    [members, memberToStaff]
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, member: IMemberModel) => {
      const newStaff = { id: member.memberId, name: member?.displayName ?? '', avatar: member?.displayName ?? '' };

      if (event.target.checked) {
        formik.setFieldValue('staffs', [...formik.values.staffs, newStaff]);
      } else {
        formik.setFieldValue(
          'staffs',
          formik.values.staffs.filter((staff) => staff.id !== newStaff.id)
        );
      }
    },
    [formik]
  );

  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={3}>
        {/* Back button */}
        {branchID && (
          <Grid item xs={12}>
            <Box pb={2}>
              <Button color="primary" startIcon={<ArrowBackIcon />} onClick={handleBack}>
                {`BACK`}
              </Button>
            </Box>
          </Grid>
        )}

        {/* Edit form */}
        <Grid item xs={12} sm={12} lg={8}>
          <Paper elevation={0}>
            <Card elevation={0}>
              <CardContent>
                {branchID && branch ? (
                  <Box pb={3} justifyContent="flex-end" alignItems="flex-end" display="flex">
                    <Tooltip title={refData.screen.team['btn-delete-team']}>
                      <DeleteIcon onClick={handleClickOpen} style={{ color: theme.palette.secondary.main }} />
                    </Tooltip>
                  </Box>
                ) : null}
                <TextField
                  fullWidth
                  name="branchName"
                  label="Team name"
                  variant="outlined"
                  value={formik.values.branchName}
                  onChange={formik.handleChange}
                  error={formik.touched.branchName && Boolean(formik.errors.branchName)}
                  helperText={formik.touched.branchName && formik.errors.branchName}
                />
                <Box pt={4} />
                <Typography variant="h6">Staff</Typography>
                <Box pr={1} />
                <Box display="flex">
                  <Typography variant="subtitle1">Adding staff to branch</Typography>
                  <Tooltip title={refDataTeam['adding-staff-to-branch']}>
                    <InfoIcon />
                  </Tooltip>
                </Box>
                <Box pt={3} />
                <Box display="flex" justifyContent="flex-start" flexWrap="wrap">
                  {formik.values.staffs.map(
                    (staff: ITeamStaff, index: number) =>
                      staff.name && (
                        <Chip key={`${index}select`} label={`${staff.name}`} style={{ margin: theme.spacing(0.5) }} />
                      )
                  )}
                  {formik.values.staffs.length === 0 && formik.errors && formik.errors.staffs && (
                    <Box px={2}>
                      <FormHelperText error>{formik.errors.staffs}</FormHelperText>
                    </Box>
                  )}
                </Box>
                <Box pt={5} />
                <Typography variant="body1">{`All staff (${formik.values.staffs.length} selected) `}</Typography>

                <Box pt={2} />

                <Grid container>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="All staff"
                          id="all-staff"
                          color="primary"
                          checked={members.length === formik.values.staffs.length}
                          onChange={(event) => handleSelectAll(event)}
                        />
                      }
                      label="All staff"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box pl={2}>
                      <Grid container>
                        {members.map((staff: any, index: number) => {
                          const checked = formik.values.staffs.filter((st) => st.id === staff.memberId).length > 0;
                          return (
                            <Grid key={`staff${index}`} item xs={12} sm={6}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name={`${staff.displayName}`}
                                    id={`${staff.memberId}`}
                                    color="primary"
                                    checked={checked}
                                    onChange={(event) => handleChange(event, staff)}
                                  />
                                }
                                label={`${staff.displayName}`}
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Paper>
          <Box pt={2} />
          <Button fullWidth={matches} type="submit" variant="contained" color="primary">
            CONFIRM
          </Button>
        </Grid>
      </Grid>
      <Dialog open={open}>
        <DialogTitle>{`Are you sure you want to delete ${formik.values.branchName}?`}</DialogTitle>
        <DialogContent>
          <DialogContentText>{`The team will be deleted immediately. You can’t undo this action.`}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {'Cancel'}
          </Button>
          <Button onClick={handleDelete} color="primary">
            {'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
      <ProgressIndicator loading={formik.isSubmitting} />
    </form>
  );
}

const validationFormSchema = yup.object({
  branchName: yup.string().max(25, `Team name can only be up to 25 characters`).required('Team name is required'),
  staffs: yup.array().required('Must have staff').min(1, 'Minimum of 1 staff'),
});
