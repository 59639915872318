import React, { useCallback, useEffect, useMemo } from 'react';
import { Chip, List, ListItem, Box, useTheme, ListItemText, Typography, Grid } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { faqCategorySlice, IFaqData, useAppSelector } from '@dayone/redux';
import SearchBar from './SearchBar';
import Moment from 'react-moment';
import moment from 'moment';

import { EmptyData } from 'shared/components/emptyData';
import boxEmpty from 'assets/box-empty.svg';
import listEmpty from 'assets/list-empty.svg';

interface IProps {
  knowledges: IFaqData[];
  statusType?: 'ANSWERED' | 'UNANSWERED' | 'ARCHIVED';
}

export default function KnowledgeList(props: IProps) {
  const { statusType, knowledges } = props;
  const theme = useTheme();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const value = searchParams.get('keyword') ?? '';
  const selectedChips = useMemo((): string[] => {
    if (!searchParams.get('tags') || searchParams.get('tags') === '') return [];
    return searchParams.get('tags')?.split(',') ?? [];
  }, [searchParams]);

  const setValue = useCallback(
    (text: string) => {
      const queryParams = { tags: selectedChips.join(',') ?? '', keyword: value };
      setSearchParams({ ...queryParams, keyword: text }, { replace: true });
    },
    [value, selectedChips, setSearchParams]
  );
  const setSelectedChips = useCallback(
    (tags: string[]) => {
      const queryParams = { tags: selectedChips.join(',') ?? '', keyword: value };
      setSearchParams({ ...queryParams, tags: tags?.join(',') ?? '' }, { replace: true });
    },
    [value, selectedChips, setSearchParams]
  );

  const categories = useAppSelector<any>(faqCategorySlice.selectAllFaqCategories);
  const [page, setPage] = React.useState(1);
  const itemsPerPage = 10;
  const handleChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setPage(page);
  };

  const onChangeKeywords = (text: string) => {
    setValue(text);
  };
  useEffect(() => {
    setPage(1);
  }, [searchParams]);

  const filterFaq = (faq: IFaqData, keyword: string) => {
    const categories = faq?.categoriesNames?.map((categoryName) => categoryName?.toLowerCase());
    const word = keyword.toLowerCase();
    return (
      (faq.question.toLowerCase().includes(word) || categories?.find((a) => a.includes(word))) &&
      (selectedChips.length === 0 || selectedChips.some((chip) => faq?.categoriesNames?.includes(chip)))
    );
  };

  const handleSelected = (chip: string) => {
    const newChips = selectedChips.filter((c) => c !== chip);
    if (newChips.length === selectedChips.length || selectedChips.length === 0) {
      setSelectedChips([...newChips, chip]);
    } else {
      setSelectedChips(newChips);
    }
  };

  const handleDetail = (event: React.MouseEvent<HTMLElement, MouseEvent>, faq: IFaqData) => {
    navigate(`/knowledge/edit/${faq.faqId}?isFromSearch=${value.length > 0}`);
  };

  const renderItem = (faq: IFaqData, index: number) => {
    return (
      <Box key={`faq${index}`} pb={2} onClick={(event) => handleDetail(event, faq)}>
        <ListItem style={{ backgroundColor: theme.palette.background.paper, cursor: 'pointer' }}>
          {renderSubItem(faq)}
        </ListItem>
      </Box>
    );
  };

  const renderSubItem = (faq: IFaqData) => {
    if (statusType === 'ANSWERED') {
      return (
        <ListItemText
          primary={
            <Box>
              {faq.question}
              <Box display="flex" flexWrap="wrap" pt={2}>
                {faq.categoriesNames?.map((chip: string, index: number) => (
                  <Chip
                    key={`chip${index}`}
                    label={`${chip}`}
                    variant="outlined"
                    style={{ margin: theme.spacing(1) }}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleSelected(chip);
                    }}
                  />
                ))}
              </Box>
            </Box>
          }
        />
      );
    } else {
      return (
        <ListItemText
          primary={
            <Box>
              {faq.question}
              <Box pt={3} display="flex">
                <Typography variant="body2">Question by</Typography>
                <Box pl={1} />
                <Typography variant="body2" style={{ fontWeight: 700 }}>
                  {faq.isAnon ? 'Anonymous' : faq.createBy} {!faq.isAnon && !faq.branchName && `(Admin)`}
                </Typography>
                {!faq.isAnon ? (
                  faq.branchName ? (
                    <>
                      <Box pl={1} />
                      <Typography variant="body2">from</Typography>
                      <Box pl={1} />
                      <Typography variant="body2" style={{ fontWeight: 700 }}>{`${faq.branchName}`}</Typography>{' '}
                    </>
                  ) : null
                ) : (
                  ''
                )}
              </Box>
              {statusType !== 'ARCHIVED' ? (
                <Box pt={1}>
                  {faq.createdAt !== '' && (
                    <Typography variant="caption">
                      {`Posted on `}
                      <Moment format=" DD MMMM YYYY">{faq.createdAt}</Moment>
                    </Typography>
                  )}
                  {faq.updatedAt !== '' && faq.updatedAt !== faq.createdAt && (
                    <Typography variant="caption">
                      {`; last updated at `}
                      {faq.updatedAt !== '' ? <Moment format="HH:mm A on DD MMMM YYYY">{faq.updatedAt}</Moment> : ''}
                    </Typography>
                  )}
                </Box>
              ) : (
                <Box pt={1}>
                  {faq.createdAt !== '' && moment(faq.createdAt).isValid() && (
                    <Typography variant="caption">
                      Posted on {moment(faq.createdAt).format('D MMMM YYYY')} by {faq.createdByDisplayName}
                    </Typography>
                  )}
                  {faq.updatedAt !== '' && faq.updatedAt !== faq.createdAt && moment(faq.updatedAt).isValid() && (
                    <Typography variant="caption">
                      ; last updated on {moment(faq.updatedAt).format('D MMMM YYYY')} by {faq.updatedByDisplayName}
                    </Typography>
                  )}
                </Box>
              )}
            </Box>
          }
        />
      );
    }
  };
  const results = knowledges.filter((knowledge) => filterFaq(knowledge, value));

  const renderResults = results.slice((page - 1) * itemsPerPage, page * itemsPerPage);

  const renderContent = () => {
    const isSearch = !!value || !!selectedChips.length;
    const imageSrc = isSearch ? listEmpty : boxEmpty;
    const emptyText = isSearch ? 'No results found' : 'No FAQs yet';
    const suggestion = isSearch ? 'Search for something else or create new FAQ' : 'Click on Create FAQ to get started';

    if (!renderResults || !renderResults.length)
      return (
        <Grid item xs={12}>
          <EmptyData imageSrc={imageSrc} emptyText={emptyText} suggestion={suggestion} />
        </Grid>
      );

    return (
      <>
        <Grid item xs={12}>
          <List>{renderResults.map(renderItem)}</List>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Pagination
              count={Math.ceil(results.length / itemsPerPage)}
              page={page}
              onChange={handleChange}
              size="large"
              showFirstButton
              showLastButton
            />
          </Box>
        </Grid>
      </>
    );
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <SearchBar
          categories={categories}
          selectedChips={selectedChips}
          handleSelected={(e) => handleSelected(e)}
          keyword={value}
          onChangeKeywords={(e) => onChangeKeywords(e)}
        />
      </Grid>
      {renderContent()}
    </Grid>
  );
}
