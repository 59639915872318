import { Avatar, Box, capitalize, Typography } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import { grey } from '@material-ui/core/colors';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Logo } from 'shared/components/logo';
import { IMenuCategory } from 'shared/utilities/menuData';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import MenuCategory from './MenuCategory';
import { useAppSelector, meSlice, settingSlice } from '@dayone/redux';

const drawerWidth = 260;

export interface IProps {
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
  displayName: string;
  role: string;
  categories: IMenuCategory[];
  workspaceName: string;
  callback: (
    type: string,
    item?: {
      icon: string;
      label: string;
      url: string;
    }
  ) => void;
}
/**
 * Component for side navigation
 *
 * @component
 * @param {boolean} mobileOpen Open and close the navigation
 * @param {string} displayName User's display name
 * @param {string} role The user's role
 * @param {IMenuCategory} categories
 * @param {string} workspaceName
 *
 * @callback handleDrawerToggle
 *
 * @callback callback
 * @param {string} type
 * @param {object} item
 */
export default function SideNav(props: IProps) {
  const { mobileOpen, handleDrawerToggle, categories, callback } = props;
  const displayName = useAppSelector<any>(meSlice.selectAccountName);
  const role = useAppSelector<any>(meSlice.selectRole);
  const workspace = useAppSelector<any>(settingSlice.selectActiveWorkspace);
  const profileURL = useAppSelector<any>(meSlice.selectPhotoURL);
  const workspaceName = workspace?.name;
  const mediaQueryHeight = useMediaQuery(`(min-height:600px)`);
  const classes = useStyles(mediaQueryHeight);
  const theme = useTheme();
  const isInternalAdmin = role === 'internalAdmin';
  const filteredCategories = isInternalAdmin
    ? categories
    : categories.filter((item) => item.categoryHeader !== 'Internal Admin');

  const drawer = (
    <Box display="flex" flexDirection="column" justifyContent="space-between" flexGrow={1}>
      <Box>
        <Box onClick={() => callback('Logo')} display="flex" alignItems="center" p={1.5}>
          <Logo />
        </Box>

        <Box
          px={2}
          py={3}
          borderRadius={theme.spacing(0.5)}
          display="flex"
          alignItems="center"
          bgcolor={theme.palette.grey[300]}
        >
          <Box mr={2}>
            <Avatar
              style={{
                width: theme.spacing(5),
                height: theme.spacing(5),
              }}
              src={profileURL ?? ''}
            >
              {displayName && displayName[0]}
            </Avatar>
          </Box>

          <Box flexGrow={1}>
            <Typography
              style={{ wordBreak: 'break-word' }}
              variant="subtitle2"
              className={classes.clickableTypo}
              onClick={() => callback('Profile', { icon: 'profile', label: 'Profile', url: '/profile' })}
            >
              {displayName}
            </Typography>
            <Typography style={{ wordBreak: 'break-word' }} variant="body2">
              {capitalize(role ?? '')}
            </Typography>
          </Box>
        </Box>
        {(role === 'admin' || role === 'internalAdmin') &&
          filteredCategories &&
          filteredCategories.map((cat) => (
            <MenuCategory
              key={cat.categoryHeader}
              callback={(item) => callback('MenuItem', item)}
              category={cat}
            ></MenuCategory>
          ))}
      </Box>
      <Box m={-2} pl={4} py={3} display="flex" alignItems="center" bgcolor={theme.palette.grey[300]}>
        <Box mr={2}>
          <Avatar
            variant="square"
            style={{
              width: theme.spacing(5),
              height: theme.spacing(5),
            }}
            src={workspace?.companyLogo ?? ''}
          >
            {workspaceName && workspaceName[0]}
          </Avatar>
        </Box>
        <Box>
          <Typography style={{ wordBreak: 'break-word' }} variant="subtitle2">
            {workspaceName}
          </Typography>
          <Typography
            className={classes.clickableTypo}
            variant="body2"
            noWrap
            onClick={() => callback('Settings', { icon: 'setting', label: 'Setting', url: '/setting' })}
          >
            Settings
          </Typography>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box className={classes.sideNavContainer} display="flex">
      <CssBaseline />
      <nav className={classes.drawer} aria-label="navigation">
        <Hidden mdUp>
          <Drawer
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown>
          <Drawer classes={{ paper: classes.staticDrawerPaper }} variant="permanent" open>
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  sideNavContainer: {
    position: (mediaQueryHeight) => (mediaQueryHeight ? 'fixed' : 'fixed'),
    height: '100vh',
  },
  drawer: {
    overflowX: 'hidden',
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    padding: theme.spacing(2),
    width: drawerWidth,
    backgroundColor: grey[200],
  },
  staticDrawerPaper: {
    position: 'static',
    minHeight: '100vh',
    padding: theme.spacing(2),
    width: drawerWidth,
    backgroundColor: grey[200],
  },
  clickableTypo: {
    cursor: 'pointer',
    '&:hover, &:focus, &:active': {
      textDecoration: 'underline',
    },
  },
}));
