import { Box, Button, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { CloudUpload } from '@material-ui/icons';
import React, { ChangeEvent } from 'react';
import { OptionsObject, useSnackbar } from 'notistack';

import VideoCell, { IVideo } from './components/VideoCell';
import bytesToMegaBytes from 'shared/utilities/bytesToMegaBytes';
import { validationErrors } from '@dayone/common';

function UploadVideos(props: {
  text: string;
  acceptType: string;
  videos: IVideo[];
  onUploadVideos?: React.ChangeEventHandler<any>; // changed `HTMLInputElement` to `any` to use `validVideos` array
  onRemove?: (video: IVideo) => void;
  isDisabled?: boolean;
  limit?: number;
  /**
   * Max video size in byte
   */
  fileSizeLimit?: number;
  numberOfFileLimitError?: string;
  fileSizeLimitError?: string;
}) {
  const classes = useStyles();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  const warningSnackOptions: OptionsObject = {
    variant: 'error',
    anchorOrigin: {
      vertical: isXs ? 'bottom' : 'top',
      horizontal: isXs ? 'center' : 'right',
    },
  };

  const handleUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const addedVideos = event.target.files || [];
    let validVideos: File[] = [];
    if (props.fileSizeLimit && addedVideos && addedVideos.length) {
      for (let i = 0; i < addedVideos.length; i++) {
        const file = addedVideos[i];

        if (file.size > props.fileSizeLimit) {
          const fileSizeLimit = bytesToMegaBytes(props.fileSizeLimit, 1);
          const errMessage = validationErrors.default(props.fileSizeLimitError as string, {
            FILE_NAME: file.name,
            FILE_SIZE: fileSizeLimit,
          });
          enqueueSnackbar(errMessage, warningSnackOptions);
          return;
        } else validVideos.push(file);
      }
    } else validVideos = Array.from(addedVideos);

    const totalVideosCount = validVideos.length + props.videos.length;
    if (props.limit && totalVideosCount > props.limit) {
      enqueueSnackbar(
        validationErrors.default(props.numberOfFileLimitError as string, {
          NUMBER_OF_FILE_LIMIT: props.limit,
          NUMBER_OF_FILES_TO_REMOVE: totalVideosCount - props.limit,
        }),
        warningSnackOptions
      );
      return;
    }

    if (props.onUploadVideos) {
      const updatedEvent = { ...event, target: { ...event.target, files: validVideos } };
      props.onUploadVideos(updatedEvent);
    }
  };

  return (
    <Box className={classes.root} flex="column">
      <input
        accept={`${props.acceptType}`}
        disabled={props.isDisabled}
        style={{
          display: 'none',
        }}
        id={`${props.acceptType}`}
        multiple
        type="file"
        onChange={handleUpload}
      />
      <Box display="flex" justifyContent="center" pb={2} pr={2} pl={2}>
        <label htmlFor={`${props.acceptType}`}>
          <Button
            disabled={props.isDisabled}
            style={{
              width: theme.spacing(36),
            }}
            variant="outlined"
            color="primary"
            component="span"
            startIcon={<CloudUpload />}
          >
            {props.text}
          </Button>
        </label>
      </Box>
      {!props.isDisabled && (
        <>
          <Box display="flex" flexWrap="wrap" justifyContent="center" overflow="auto">
            {props.videos &&
              props.videos
                .filter((x) => x.action !== 'REMOVE')
                .map((video, index) => (
                  <VideoCell
                    key={video.url}
                    video={video}
                    onRemove={() => {
                      if (props.onRemove) props.onRemove(video);
                    }}
                    hasDivider={index !== (props.videos?.length ?? 0) - 1}
                  />
                ))}
          </Box>
        </>
      )}
      {/* <Box height={theme.spacing(4)}></Box> */}
    </Box>
  );
}

export default UploadVideos;

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),

    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
  },
}));
