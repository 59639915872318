"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setInvites = exports.setRoles = exports.setMembers = exports.selectMemberByID = exports.selectInvites = exports.selectRoles = exports.selectAndAddAdminPrefixForAdminMembers = exports.selectMembersWithRole = exports.selectMembers = exports.deleteInvites = exports.createInvite = exports.deleteMember = exports.updateMember = exports.memberSliceConfig = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var firestore_1 = require("firebase/firestore");
var common_1 = require("@dayone/common");
var firestore;
var memberSliceConfig = function (_firebaseApp) {
    firestore = (0, firestore_1.getFirestore)(_firebaseApp);
};
exports.memberSliceConfig = memberSliceConfig;
var initialState = {
    initialised: false,
    members: [],
    roles: [],
    invites: [],
};
exports.updateMember = (0, toolkit_1.createAsyncThunk)('members', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var memberId, role, branches, state, companyID, maxAdmin, roles, oldRole, admins, teamRef, us, err_1;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                memberId = payload.memberId, role = payload.role, branches = payload.branches;
                state = thunkAPI.getState();
                companyID = state.setting.activeWorkspaceID;
                maxAdmin = state.featureFlag.featureFlags.user.maxAdmin;
                roles = state.member.roles;
                oldRole = (_a = roles.find(function (item) { return item.userId === memberId; })) === null || _a === void 0 ? void 0 : _a.role;
                if (oldRole !== role) {
                    if (role === 'admin') {
                        admins = roles.filter(function (item) { return item.role === 'admin'; });
                        if (maxAdmin <= admins.length)
                            throw new Error(common_1.validationErrors.default('User.MaxAdmin', { MAX_ADMIN: maxAdmin }));
                    }
                }
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 5]);
                return [4 /*yield*/, Promise.all(state.team.teams.map(function (team) { return __awaiter(void 0, void 0, void 0, function () {
                        var docRef, tm;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    docRef = (0, firestore_1.doc)(firestore, 'companies', companyID, 'branches', team.teamId);
                                    tm = {
                                        users: branches.indexOf(team.teamId) === -1
                                            ? team.users.filter(function (x) { return x !== memberId; })
                                            : __spreadArray(__spreadArray([], team.users.filter(function (x) { return x !== memberId; }), true), [memberId], false),
                                    };
                                    return [4 /*yield*/, (0, firestore_1.updateDoc)(docRef, tm)];
                                case 1:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    }); }))];
            case 2:
                _b.sent();
                teamRef = (0, firestore_1.doc)(firestore, 'companies', companyID, 'users', memberId);
                us = {
                    role: role,
                };
                return [4 /*yield*/, (0, firestore_1.updateDoc)(teamRef, us)];
            case 3:
                _b.sent();
                return [3 /*break*/, 5];
            case 4:
                err_1 = _b.sent();
                console.log('Error: Delete user link branch', err_1);
                throw err_1;
            case 5: return [2 /*return*/];
        }
    });
}); });
exports.deleteMember = (0, toolkit_1.createAsyncThunk)('members', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var memberId, state, companyID, teams, err_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                memberId = payload.memberId;
                state = thunkAPI.getState();
                companyID = state.setting.activeWorkspaceID;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 4, , 5]);
                teams = state.team.teams.filter(function (team) { return team.users.indexOf(memberId) > -1; });
                return [4 /*yield*/, Promise.all(teams.map(function (team) { return __awaiter(void 0, void 0, void 0, function () {
                        var docRef, tm;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    docRef = (0, firestore_1.doc)(firestore, 'companies', companyID, 'branches', team.teamId);
                                    tm = {
                                        users: team.users.filter(function (x) { return x !== memberId; }),
                                    };
                                    return [4 /*yield*/, (0, firestore_1.updateDoc)(docRef, tm)];
                                case 1:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    }); }))];
            case 2:
                _a.sent();
                // Remove user in company users
                return [4 /*yield*/, (0, firestore_1.deleteDoc)((0, firestore_1.doc)(firestore, 'companies', companyID, 'users', memberId))];
            case 3:
                // Remove user in company users
                _a.sent();
                return [3 /*break*/, 5];
            case 4:
                err_2 = _a.sent();
                console.log('Error: Delete user link branch', err_2);
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}); });
exports.createInvite = (0, toolkit_1.createAsyncThunk)('members', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var inviteeEmail, state, companyID, invites, queryCheckExist, querySnapshot, docs_1, result, status_1, inviteeUid, companyName, uid, email, docRef, invite, result;
    var _a, _b, _c, _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                inviteeEmail = payload.inviteeEmail;
                state = thunkAPI.getState();
                companyID = state.setting.activeWorkspaceID;
                invites = state.member.invites.filter(function (inv) { return inv.inviteeEmail === inviteeEmail; });
                if (!(invites.length === 0)) return [3 /*break*/, 5];
                queryCheckExist = (0, firestore_1.query)((0, firestore_1.collection)(firestore, 'accounts'), (0, firestore_1.where)('email', '==', inviteeEmail));
                return [4 /*yield*/, (0, firestore_1.getDocs)(queryCheckExist)];
            case 1:
                querySnapshot = _e.sent();
                docs_1 = [];
                querySnapshot.forEach(function (doc) {
                    docs_1.push(__assign(__assign({}, doc.data()), { id: doc.id }));
                });
                if (!(docs_1.length > 0 &&
                    docs_1[0].email === inviteeEmail &&
                    docs_1[0].companies &&
                    docs_1[0].companies.indexOf(companyID) > -1)) return [3 /*break*/, 2];
                result = thunkAPI.rejectWithValue('emailalready');
                return [2 /*return*/, result];
            case 2:
                status_1 = docs_1.length > 0 ? 'pending acceptance' : 'pending registration';
                inviteeUid = docs_1.length > 0 ? (_a = docs_1[0].id) !== null && _a !== void 0 ? _a : '' : '';
                companyName = (_b = state.workspace.workspaces.find(function (ws) { return ws.id === companyID; })) === null || _b === void 0 ? void 0 : _b.name;
                uid = (_c = state.me.details) === null || _c === void 0 ? void 0 : _c.uid;
                email = (_d = state.me.account) === null || _d === void 0 ? void 0 : _d.email;
                docRef = (0, firestore_1.collection)(firestore, 'companies', companyID, 'invites');
                invite = {
                    email: email,
                    inviteeUid: inviteeUid,
                    inviteeEmail: inviteeEmail.toLowerCase(),
                    companyID: companyID,
                    companyName: companyName,
                    status: status_1,
                    createdAt: firestore_1.Timestamp.now(),
                    uid: uid,
                };
                return [4 /*yield*/, (0, firestore_1.addDoc)(docRef, invite)];
            case 3: return [2 /*return*/, _e.sent()];
            case 4: return [3 /*break*/, 6];
            case 5:
                result = thunkAPI.rejectWithValue('invited');
                return [2 /*return*/, result];
            case 6: return [2 /*return*/];
        }
    });
}); });
exports.deleteInvites = (0, toolkit_1.createAsyncThunk)('members', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var state, companyID, inviteIds;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                state = thunkAPI.getState();
                companyID = state.setting.activeWorkspaceID;
                inviteIds = payload.inviteIds;
                return [4 /*yield*/, Promise.all(inviteIds.map(function (inviteId) { return __awaiter(void 0, void 0, void 0, function () {
                        var docRef;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    docRef = (0, firestore_1.doc)(firestore, 'companies', companyID, 'invites', inviteId);
                                    return [4 /*yield*/, (0, firestore_1.deleteDoc)(docRef)];
                                case 1: return [2 /*return*/, _a.sent()];
                            }
                        });
                    }); }))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); });
var teamSlice = (0, toolkit_1.createSlice)({
    name: 'member',
    initialState: initialState,
    reducers: {
        setMembers: {
            reducer: function (state, action) {
                state.members = action.payload;
                state.initialised = true;
            },
            prepare: function (payload) {
                if (payload) {
                    var memberModels = payload.map(function (member) {
                        var _a, _b, _c, _d;
                        return ({
                            memberId: member.memberId,
                            activeCompanies: member.activeCompanies || [],
                            companies: member.companies || [],
                            createAt: member.createAt instanceof firestore_1.Timestamp ? (_b = (_a = member.createAt) === null || _a === void 0 ? void 0 : _a.toDate().toISOString()) !== null && _b !== void 0 ? _b : '' : '',
                            updatedAt: member.updatedAt instanceof firestore_1.Timestamp ? (_d = (_c = member.updatedAt) === null || _c === void 0 ? void 0 : _c.toDate().toISOString()) !== null && _d !== void 0 ? _d : '' : '',
                            displayName: member.displayName || '',
                            email: member.email || '',
                            firstName: member.firstName || '',
                            lastName: member.lastName || '',
                            invites: member.invites || [],
                            phoneNumber: member.phoneNumber || '',
                            photoURL: member.photoURL || '',
                        });
                    });
                    return { payload: memberModels };
                }
                return { payload: null };
            },
        },
        setRoles: {
            reducer: function (state, action) {
                state.roles = action.payload;
            },
            prepare: function (payload) {
                if (payload) {
                    var roleModels = payload.map(function (role) { return ({
                        userId: role.userId,
                        email: role.email || '',
                        role: role.role || '',
                    }); });
                    return { payload: roleModels };
                }
                return { payload: null };
            },
        },
        setInvites: {
            reducer: function (state, action) {
                state.invites = action.payload;
            },
            prepare: function (payload) {
                if (payload) {
                    var inviteModels = payload.map(function (invite) {
                        var _a, _b;
                        return ({
                            id: invite.id,
                            createdAt: invite.createdAt instanceof firestore_1.Timestamp ? (_b = (_a = invite.createdAt) === null || _a === void 0 ? void 0 : _a.toDate().toISOString()) !== null && _b !== void 0 ? _b : '' : '',
                            companyID: invite.companyID || '',
                            companyName: invite.companyName || '',
                            email: invite.email || '',
                            inviteeEmail: invite.inviteeEmail || '',
                            inviteeUid: invite.inviteeUid || '',
                            status: invite.status || '',
                            uid: invite.uid || '',
                        });
                    });
                    return { payload: inviteModels };
                }
                return { payload: null };
            },
        },
    },
});
// Selectors
exports.selectMembers = (0, toolkit_1.createSelector)(function (state) { var _a; return ((_a = state.member) === null || _a === void 0 ? void 0 : _a.members) || []; }, function (members) { return __spreadArray([], members, true).sort(function (a, b) { return a.displayName.localeCompare(b.displayName); }); });
exports.selectMembersWithRole = (0, toolkit_1.createSelector)(function (state) { return state.member.members; }, function (state) { return state.member.roles; }, function (members, roles) {
    var tmp = members
        .map(function (member) {
        var rls = roles.filter(function (role) { return role.userId === member.memberId; });
        var obj = __assign(__assign({}, member), { role: rls && rls.length > 0 ? rls[0].role : '' });
        return obj;
    })
        .filter(function (obj) { return !!obj.role; });
    var result = __spreadArray(__spreadArray([], tmp
        .filter(function (x) { return x.role.toLocaleLowerCase() === 'admin'; })
        .sort(function (a, b) { return a.displayName.localeCompare(b.displayName); }), true), tmp
        .filter(function (x) { return x.role.toLocaleLowerCase() !== 'admin'; })
        .sort(function (a, b) { return a.displayName.localeCompare(b.displayName); }), true);
    return result;
});
exports.selectAndAddAdminPrefixForAdminMembers = (0, toolkit_1.createSelector)([exports.selectMembersWithRole], function (memberWithRole) {
    return memberWithRole.map(function (member) { return (__assign(__assign({}, member), { displayName: member.role.toLocaleLowerCase() === 'admin' ? "".concat(member.displayName, " (Admin)") : member.displayName })); });
});
var selectRoles = function (state) { return state.member.roles; };
exports.selectRoles = selectRoles;
exports.selectInvites = (0, toolkit_1.createSelector)(function (state) { return state.member.invites; }, function (invites) { return invites.filter(function (i) { return i.status !== 'accepted'; }); });
exports.selectMemberByID = (0, toolkit_1.createSelector)(exports.selectMembersWithRole, function (members) {
    return function callbackFunction(memberId) {
        return members.find(function (member) { return member.memberId === memberId; }) || null;
    };
});
// Actions
exports.setMembers = (_a = teamSlice.actions, _a.setMembers), exports.setRoles = _a.setRoles, exports.setInvites = _a.setInvites;
exports.default = teamSlice.reducer;
