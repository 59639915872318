import { useState } from 'react';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  TextField,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  Button,
  CardMedia,
  Box,
  useTheme,
} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import BusinessCenter from '@material-ui/icons/BusinessCenter';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { meSlice, useAppDispatch } from '@dayone/redux';
import { ProgressIndicator } from 'shared/components/progressIndicator';
import { useSnackbar } from 'shared/components/hooks';
import industries from 'screens/onboarding/helpers/industries.json';
import numberOfEmployees from 'screens/onboarding/helpers/numberOfEmployees.json';

const validationFormSchema = yup.object({
  name: yup.string().required('Business name is required'),
  companyLogoFile: yup.mixed(),
  industry: yup.string().typeError('Industry is required'),
  companySize: yup.number().typeError('Field is required'),
});

const InformationCreateCompany = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);
  const [file, setFile] = useState<string>();
  const enqueueSnackbar = useSnackbar();
  const formik = useFormik({
    initialValues: {
      name: '',
      companyLogoFile: null,
      industry: null,
      companySize: null,
    },
    validationSchema: validationFormSchema,
    onSubmit: async (values: any) => {
      try {
        setLoading(true);
        const { name, companyLogoFile, industry, companySize } = values;

        await dispatch(
          meSlice.setBusinessInfo({
            companyLogoFile: companyLogoFile as File,
            businessInfo: {
              name,
              industry,
              companySize,
            },
            inviteCode: 'superAdmin',
          })
        );

        enqueueSnackbar(`${name} has been created successfully!`, {
          variant: 'success',
        });
      } finally {
        setLoading(false);
      }
    },
  });

  const onFileChange = (e: any) => {
    const companyLogoPath = window.URL.createObjectURL(e.target.files[0]);
    setFile(companyLogoPath);
    formik.setFieldValue('companyLogoFile', e.target.files[0]);
  };
  const onFileRemoved = () => {
    setFile('');
    formik.setFieldValue('companyLogoFile', null);
  };

  return (
    <>
      <Card elevation={0}>
        <CardContent>
          <Grid container direction="column" spacing={3}>
            <Grid xs={12} item container>
              <Typography variant="h6">Company Logo</Typography>
            </Grid>
            <Grid item container direction="row" spacing={3}>
              <Grid xs={12} md={6} lg={4} direction="column" container spacing={3} item>
                <Grid item direction="column" justifyContent="center" alignItems="center" container>
                  {file ? (
                    <CardMedia
                      image={file}
                      style={{ height: 96, width: 96, borderRadius: '4px', backgroundSize: 'contain' }}
                    />
                  ) : (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      style={{ backgroundColor: '#BDBDBD', height: 122, width: 122, borderRadius: '50%' }}
                    >
                      <BusinessCenter style={{ color: '#fff', width: 30, height: 30 }} />
                    </Box>
                  )}
                  <Button
                    style={{ width: '100%', marginTop: 24, marginBottom: 24 }}
                    variant="outlined"
                    color="primary"
                    component="label"
                    startIcon={<CloudUploadIcon />}
                  >
                    UPLOAD IMAGE
                    <input type="file" hidden name="upload-photo" accept="image/*" onChange={onFileChange} />
                  </Button>
                  <Button
                    style={{ width: '100%', marginBottom: theme.spacing(4) }}
                    color="primary"
                    onClick={onFileRemoved}
                  >
                    REMOVE IMAGE
                  </Button>
                </Grid>
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={8} direction="column" spacing={3} item container>
                <Grid item>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="name"
                    label="Company name"
                    helperText={formik.touched.name && formik.errors.name && formik.errors.name}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                  />
                </Grid>
                <Grid item>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="outlined-age-native-simple">Industry</InputLabel>
                    <Select
                      name="industry"
                      label="Industry"
                      displayEmpty
                      value={formik.values.industry}
                      onChange={formik.handleChange}
                      error={formik.touched.industry && Boolean(formik.errors.industry)}
                    >
                      <MenuItem disabled value="">
                        <em>Select industry</em>
                      </MenuItem>
                      {industries.map((item, index) => (
                        <MenuItem key={index + ''} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched.industry && <FormHelperText error>{formik.errors.industry}</FormHelperText>}
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="outlined-age-native-simple">Number of employees</InputLabel>
                    <Select
                      name="companySize"
                      label="Number of employees"
                      displayEmpty
                      value={formik.values.companySize}
                      onChange={formik.handleChange}
                      error={formik.touched.companySize && Boolean(formik.errors.companySize)}
                    >
                      <MenuItem disabled value="">
                        <em>Select number of employees</em>
                      </MenuItem>
                      {numberOfEmployees.map((item, index) => (
                        <MenuItem key={index + ''} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched.companySize && <FormHelperText error>{formik.errors.companySize}</FormHelperText>}
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Button
        onClick={() => formik.handleSubmit()}
        style={{ marginTop: theme.spacing(5) }}
        color="primary"
        variant="contained"
      >
        CREATE NEW COMPANY
      </Button>
      <ProgressIndicator loading={isLoading} />
    </>
  );
};

export default InformationCreateCompany;
