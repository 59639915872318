import {
  useKnowledge,
  useAnnoucement,
  useTeam,
  useMember,
  useRepository,
  useResponsibility,
  useRole,
  useInvite,
  useTopic,
  useComment,
  useFaqCategory,
  useFeatureFlag,
} from '@dayone/hooks';
import {
  announcementSlice,
  faqSlice,
  memberSlice,
  repositorySlice,
  teamSlice,
  taskSlice,
  settingSlice,
  useAppDispatch,
  useAppSelector,
  topicSlice,
  commentSlice,
  faqCategorySlice,
  featureFlagSlice,
} from '@dayone/redux';
import React, { useEffect } from 'react';
import { ProgressIndicator } from 'shared/components/progressIndicator';

/**
 * withFirestore Higher order component
 * To get all firestore collection of company
 * @param {jsx} WrappedComponent Child JSX component
 * @returns Child component or Loading screen
 *
 */

const withFirestore = (WrappedComponent: any) => {
  return (props: any) => {
    const dispatch = useAppDispatch();
    const companyId = useAppSelector<any>(settingSlice.selectActiveWorkspaceID);
    const initialised = useAppSelector<any>(settingSlice.selectWebInitialised);

    /**
     * Load knowledge
     */
    const knowledges = useKnowledge(companyId);
    useEffect(() => {
      dispatch(faqSlice.setFaqs(knowledges));
      // Dispatch is a function that will not change and it is not a dependancy
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [knowledges]);

    /**
     * Load announcement
     */
    const announcements = useAnnoucement(companyId, null);
    useEffect(() => {
      dispatch(announcementSlice.setAnnouncements(announcements));
      // Dispatch is a function that will not change and it is not a dependancy
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [announcements]);

    /**
     * Load members
     */
    const members = useMember(companyId);
    useEffect(() => {
      dispatch(memberSlice.setMembers(members));
      // Dispatch is a function that will not change and it is not a dependancy
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [members]);

    /**
     * Load repository
     */
    const repositories = useRepository(companyId);
    useEffect(() => {
      dispatch(repositorySlice.setRepositories(repositories));
      // Dispatch is a function that will not change and it is not a dependancy
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [repositories]);

    /**
     * Load role
     */
    const roles = useRole(companyId);
    useEffect(() => {
      dispatch(memberSlice.setRoles(roles));
      // Dispatch is a function that will not change and it is not a dependancy
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roles]);

    /**
     * Load invite
     */
    const invites = useInvite(companyId);
    useEffect(() => {
      dispatch(memberSlice.setInvites(invites));
      // Dispatch is a function that will not change and it is not a dependancy
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invites]);

    /**
     * Load team
     */
    const teams = useTeam(companyId);
    useEffect(() => {
      dispatch(teamSlice.setTeams(teams));
      // Dispatch is a function that will not change and it is not a dependancy
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teams]);

    /**
     * Load responsiblity
     */
    const tasks = useResponsibility(companyId);
    useEffect(() => {
      dispatch(taskSlice.setTasks(tasks));
      // Dispatch is a function that will not change and it is not a dependancy
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tasks]);

    /**
     * Load Comments Topics
     */
    const selectedTaskID = useAppSelector<any>(taskSlice.selectSelectedTaskID);
    const topics = useTopic(companyId, selectedTaskID || null) || [];
    useEffect(() => {
      dispatch(topicSlice.setTopics({ topics: topics, taskId: selectedTaskID || null }));
      // Dispatch is a function that will not change and it is not a dependancy
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [topics]);

    /**
     * Load Comments
     */
    const comments = useComment(companyId, selectedTaskID || null) || [];
    useEffect(() => {
      dispatch(commentSlice.setComments(comments));
      // Dispatch is a function that will not change and it is not a dependancy
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [comments]);

    const faqCategories = useFaqCategory(companyId);

    useEffect(() => {
      dispatch(faqCategorySlice.setFaqCategories(faqCategories));

      // Dispatch is a function that will not change and it is not a dependancy
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [faqCategories]);

    const featureFlag = useFeatureFlag(companyId);
    useEffect(() => {
      dispatch(featureFlagSlice.setFeatureFlags(featureFlag));
      // Dispatch is a function that will not change and it is not a dependancy
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [featureFlag]);

    // const currentUserId = useAppSelector(meSlice.selectCurrentUid);
    // const notifications = useNotification(currentUserId, companyId);
    // useEffect(() => {
    //   dispatch(notificationSlice.setNotifications(notifications));
    // }, [notifications, dispatch]);

    // Everything is loaded
    if (initialised) {
      return <WrappedComponent {...props} />;
    }

    return <ProgressIndicator />;
  };
};

export default withFirestore;
