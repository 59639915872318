const pageError = (errorMsg: string) => {
  switch (errorMsg) {
    case 'We have blocked all requests from this device due to unusual activity. Try again later.':
      return 'Too many attempts. Please try again in 20 minutes';
    case 'TOO_SHORT':
      return 'The phone number is too short for the selected country';
    case 'reCAPTCHA has already been rendered in this element':
      return 'An error has been detected, please refresh the page';
    default:
      return `${errorMsg}`;
  }
};

export default pageError;
