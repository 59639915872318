import React, { useCallback, useEffect } from 'react';
import {
  useTheme,
  Box,
  Grid,
  Button,
  Typography,
  useMediaQuery,
  Paper,
  CardContent,
  Card,
  TextField,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';

import {
  RootState,
  useAppDispatch,
  useAppSelector,
  commentSlice,
  memberSlice,
  featureFlagSlice,
  taskSlice,
} from '@dayone/redux';
import { UploadImages, IImage } from 'shared/components/uploadImage';
import { useCustomDimensionsEvents, useSnackbar } from 'shared/components/hooks';
import Comment from './components/Comment';
import GAEvents from 'shared/utilities/events';
import { TaskType } from '@dayone/models';

// interface ITask
export default function TaskThreadOverview() {
  const topicID = '';

  const theme = useTheme();
  const navigate = useNavigate();
  const enqueueSnackbar = useSnackbar();

  const itemsPerPage = 5;
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const [page, setPage] = React.useState(1);

  const selectedTaskID = useAppSelector<RootState>(taskSlice.selectSelectedTaskID);
  const task = useAppSelector<any>(taskSlice.selectTaskByID)(selectedTaskID);
  const comments = useAppSelector<RootState>(commentSlice.selectComments);
  const members = useAppSelector<RootState>(memberSlice.selectMembers);
  const featureFlag = useAppSelector(featureFlagSlice.selectFeatureFlags);
  const { numberOfFileLimitComment, filesizeLimitComment } = featureFlag.task;
  const logEvent = useCustomDimensionsEvents();

  const initialValues: {
    comment: string;
    images: IImage[];
  } = { comment: '', images: [] };
  const dispatch = useAppDispatch();
  const displayedComments = comments.filter((comment: any) => !comment.isDeleted);

  const onSubmit = async (values: { comment: string; images: IImage[] }) => {
    await logEvent(GAEvents.task_comment, {
      isAdHoc: task.type === TaskType.adHoc,
      noOfPictures: values.images.length,
    });

    dispatch(
      commentSlice.createComment({
        message: values.comment,
        topicID: topicID || null,
        attachments: values.images.map((image) => ({
          file: image.file ?? null,
          url: image.url,
          action: image.action,
        })),
        isCompletedTask: false,
      })
    )
      .unwrap()
      .then(() => formik.resetForm())
      .catch((err) => enqueueSnackbar(err.message, { variant: 'error' }));
  };

  const handleDeleteComment = useCallback(
    (commentId: string | null) => {
      if (commentId) {
        dispatch(
          commentSlice.deleteComment({
            commentId: commentId,
          })
        )
          .unwrap()
          .then(() => {
            enqueueSnackbar('The comment has been deleted successfully', { variant: 'success' });
          });
      }
    },
    [dispatch, enqueueSnackbar]
  );

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationFormSchema,
    enableReinitialize: true,
    onSubmit: onSubmit,
  });

  const handleChange = useCallback(
    (event: React.ChangeEvent<unknown>, page: number) => {
      setPage(page);
    },
    [setPage]
  );

  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleUploadImage = useCallback(
    (event: any) => {
      const files = event.target.files;
      if (files && files.length) {
        const newFiles = [];
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          newFiles.push({ file: file, url: URL.createObjectURL(file), action: 'ADD' });
        }
        formik.setFieldValue('images', [...formik.values.images, ...newFiles]);
      }
    },
    [formik]
  );

  const handleRemove = useCallback(
    (image: IImage) => {
      if (image.action === 'ADD') {
        formik.setFieldValue(
          'images',
          formik.values.images.filter((img) => img.url !== image.url)
        );
      } else if (image.action === 'UNCHANGE') {
        const newImage = { ...image, action: 'REMOVE' };
        const index = formik.values.images.indexOf(image);
        if (index !== -1) formik.setFieldValue(`images[${index}]`, newImage);
      }
    },
    [formik]
  );

  useEffect(() => {
    if (!task) {
      enqueueSnackbar('This task is deleted!', {
        variant: 'warning',
      });
    }
  }, [task, enqueueSnackbar]);

  const header = (
    <Box display="flex">
      <Typography variant="h6">Task Comments</Typography>
    </Box>
  );

  const commentForm = (
    <form onSubmit={formik.handleSubmit}>
      <Paper elevation={0}>
        <Card elevation={0}>
          <CardContent>
            <TextField
              fullWidth
              label="Comment"
              name="comment"
              multiline
              rows={11}
              value={formik.values.comment}
              variant="outlined"
              onChange={formik.handleChange}
              error={formik.touched.comment && Boolean(formik.errors.comment)}
              helperText={formik.touched.comment && formik.errors.comment}
            />
            <Box pt={3} />
            <UploadImages
              key={'uploadImage'}
              text="UPLOAD IMAGE"
              acceptType="image/*"
              images={formik.values.images}
              onUploadImage={(event) => handleUploadImage(event)}
              onRemove={(event) => handleRemove(event)}
              limit={numberOfFileLimitComment}
              fileSizeLimit={filesizeLimitComment}
              numberOfFileLimitError="Task.NumberOfFileLimitComment"
              fileSizeLimitError="Task.FileSizeLimitComment"
            />
          </CardContent>
        </Card>
      </Paper>
      <Box mt={3} />
      <Button fullWidth={matches} type="submit" variant="contained" color="primary">
        PUBLISH COMMENT
      </Button>
    </form>
  );

  if (!task) return null;

  return (
    <Grid container spacing={3}>
      {/* Back button */}
      <Grid item xs={12}>
        <Box pb={2}>
          <Button color="primary" startIcon={<ArrowBackIcon />} onClick={handleBack}>
            {`BACK`}
          </Button>
        </Box>
      </Grid>

      <Grid item xs={12}>
        {header}
      </Grid>
      <Grid item xs={12} sm={12} lg={8}>
        <Paper elevation={0}>
          <Card elevation={0}>
            <CardContent>
              {displayedComments.slice((page - 1) * itemsPerPage, page * itemsPerPage).map((item: any) => (
                <Comment key={item.commentId} comment={item} members={members} onDelete={handleDeleteComment} />
              ))}
            </CardContent>
          </Card>
        </Paper>
      </Grid>

      <Grid item xs={12} sm={12} lg={4}>
        {commentForm}
      </Grid>

      <Grid item xs={12} sm={12} lg={8}>
        <Box display="flex" justifyContent="flex-end" pt={4}>
          <Pagination
            count={Math.ceil(displayedComments.length / itemsPerPage)}
            page={page}
            onChange={handleChange}
            size="large"
            showFirstButton
            showLastButton
          />
        </Box>
      </Grid>
    </Grid>
  );
}

const validationFormSchema = yup.object({
  comment: yup.string().when('images', {
    is: (images: IImage[]) => !images || images.length === 0,
    then: yup.string().required('Comment is required'),
    otherwise: yup.string(),
  }),
});
