import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { devSlice } from '@dayone/redux';
import { useAppDispatch } from '@dayone/redux';

export default function Debug() {
  const dispatch = useAppDispatch();

  return (
    <Grid container spacing={3}>
      <Grid item sm={12} lg={8}>
        <Button
          color="primary"
          variant="contained"
          component="span"
          onClick={() => {
            dispatch(devSlice.invokeCallFunction('testCallWithAppCheckDebug'));
          }}
        >
          testCallWithAppCheckDebug Callable
        </Button>
      </Grid>
      <Grid item sm={12} lg={8}>
        <Button
          color="primary"
          variant="contained"
          component="span"
          onClick={() => {
            dispatch(devSlice.invokeCallFunction('testCallDebug'));
          }}
        >
          testCallDebug Callable
        </Button>
      </Grid>
      <Grid item sm={12} lg={8}>
        <Button
          color="primary"
          variant="contained"
          component="span"
          onClick={() => {
            dispatch(devSlice.invokeCallFunction('testWithErrorCallDebug'));
          }}
        >
          testWithErrorCallDebug Callable
        </Button>
      </Grid>
      <Grid item sm={12} lg={8}>
        <Button
          color="primary"
          variant="contained"
          component="span"
          onClick={() => {
            dispatch(devSlice.invokeCallFunction('testWithDataCallDebug'));
          }}
        >
          testWithDataCallDebug Callable
        </Button>
      </Grid>

      <Grid item sm={12} lg={8}>
        <Button
          color="primary"
          variant="contained"
          component="span"
          onClick={() => {
            dispatch(devSlice.invokeCallFunction('analyticsCallDebug'));
          }}
        >
          analyticsCallDebug Callable
        </Button>
      </Grid>

      <Grid item sm={12} lg={8}>
        <Button
          color="primary"
          variant="contained"
          component="span"
          onClick={() => {
            dispatch(devSlice.invokeCallFunction('postgresCallDebug'));
          }}
        >
          postgresCallDebug Callable
        </Button>
      </Grid>
    </Grid>
  );
}
