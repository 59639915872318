import { useState, useImperativeHandle, forwardRef, Ref } from 'react';
import { Dialog, DialogTitle, DialogActions, Button } from '@material-ui/core';

interface IProps {
  onCancel: (ev: React.MouseEvent<HTMLButtonElement>) => void;
  onConfirm: (ev: React.MouseEvent<HTMLButtonElement>) => void;
}

export interface IConfirmationRef {
  toggle: (_message?: string) => void;
}

const ConfirmationDialog = ({ onCancel, onConfirm }: IProps, ref: Ref<IConfirmationRef>) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');

  useImperativeHandle(ref, () => ({
    toggle: (_message?: string) => {
      if (_message) {
        setMessage(_message);
      }

      setIsOpen(!isOpen);
    },
  }));

  return (
    <Dialog open={isOpen}>
      <DialogTitle>{message}</DialogTitle>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          {'Cancel'}
        </Button>
        <Button onClick={onConfirm} color="primary">
          {'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default forwardRef(ConfirmationDialog);
