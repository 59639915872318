import React from 'react';
import { Box, Typography, Link } from '@material-ui/core';
import { GridCellParams, GridColDef } from '@material-ui/data-grid';
import { ITaskModel } from '@dayone/models';
import moment from 'moment';
import RecurrenceTooltip from 'screens/task/components/RecurrenceTooltip';

export default function TaskSearchColumns(
  matches: boolean,
  getBranchName: (branchID: string | undefined) => string | undefined
): GridColDef[] {
  const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();
  return [
    {
      field: 'nextOccurrent',
      headerName: 'Date',
      flex: matches ? 0 : 0.125,
      width: matches ? 100 : 0,
      disableColumnMenu: true,
      valueFormatter: (value) =>
        moment(value.value as unknown as string).calendar({
          sameDay: 'D MMM',
          nextDay: '[Tomorrow]',
          nextWeek: 'D MMM',
          lastDay: 'D MMM',
          lastWeek: 'D MMM',
          sameElse: 'D MMM',
        }),
    },
    {
      field: 'nextOccurrentUnix',
      headerName: 'Time',
      flex: matches ? 0 : 0.125,
      width: matches ? 90 : 0,
      disableColumnMenu: true,
      valueFormatter: (value) =>
        value.value ? moment.unix(value.value as unknown as number).format('h:mm A') : '--:--',
    },
    {
      field: 'name',
      headerName: 'Name',
      sortable: false,
      flex: matches ? 0 : 0.4,
      width: matches ? 360 : 0,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        const task = params.row as ITaskModel;
        return (
          <Box display="flex" alignItems="center" width="100%">
            {/* {task.flag === TaskFlag.urgent && <PriorityHighIcon fontSize="small" />} */}
            <Box pl={1} overflow="hidden">
              <Typography
                variant={'body2'}
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                <Link href={`/tasks/detail/${task.taskID}`} onClick={preventDefault}>
                  {params.value}
                </Link>
              </Typography>
            </Box>
          </Box>
        );
      },
    },
    {
      field: 'recurringType',
      headerName: 'Interval',
      flex: matches ? 0 : 0.15,
      width: matches ? 100 : 0,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        const task = params.row as ITaskModel;
        let recurringTypeText = 'One-off';

        switch (task.recurringType) {
          case 0:
            recurringTypeText = 'Day';
            break;
          case 1:
            recurringTypeText = 'Week';
            break;
          case 2:
            recurringTypeText = 'Month';
            break;
          default:
            break;
        }

        return (
          <Box pl={1}>
            <RecurrenceTooltip task={task}>
              <Typography variant={'body2'}>{recurringTypeText}</Typography>
            </RecurrenceTooltip>
          </Box>
        );
      },
    },
    {
      field: 'branchID',
      headerName: 'Team',
      flex: matches ? 0 : 0.2,
      width: matches ? 160 : 0,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        const branchID = params.value?.toString();
        return (
          <Box pl={1}>
            <Typography variant={'body2'}>{getBranchName(branchID) ?? ''}</Typography>
          </Box>
        );
      },
    },
  ];
}
