import { repositorySlice, useAppDispatch, useAppSelector } from '@dayone/redux';
import { Box, Button, useTheme, Popover, MenuItem, Typography } from '@material-ui/core';
import { useRef, useState } from 'react';
import { useSnackbar } from 'shared/components/hooks';
import { Folder as FolderIcon, InsertDriveFile as FileIcon, Add as AddIcon } from '@material-ui/icons';
import { usePopupState, bindTrigger, bindPopover } from 'material-ui-popup-state/hooks';
import CreateFolderDialog from './CreateOrEditFolderDialog';
import { featureFlagSlice, settingSlice, workspaceSlice } from '@dayone/redux';
import { IWorkspaceModel } from '@dayone/models';
import bytesToMegaBytes from 'shared/utilities/bytesToMegaBytes';
import { validationErrors } from '@dayone/common';

export default function NewButton(props: { path: string }) {
  const theme = useTheme();
  const inputFileRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const parent = useAppSelector<any>(repositorySlice.selectRepositoryByPath)(props.path);
  const parentName = (parent?.fileName ?? '') === '' ? 'All files' : parent?.fileName ?? '';
  const enqueueSnackbar = useSnackbar();
  const [createFolderDialogOpen, setCreateFolderDialogOpen] = useState(false);
  const featureFlag = useAppSelector(featureFlagSlice.selectFeatureFlags);
  const workSpaces = useAppSelector<IWorkspaceModel[]>(workspaceSlice.selectAllWorkspaces);
  const activeWorkspaceID = useAppSelector<any>(settingSlice.selectActiveWorkspaceID);

  const workSpace = workSpaces?.find((item) => item.id === activeWorkspaceID);

  const popupState = usePopupState({ variant: 'popover', popupId: 'demo-popup-popover' });

  const handleCreateFolderClick = () => {
    setCreateFolderDialogOpen(true);
  };

  const handleUploadFileClick = () => {
    inputFileRef.current?.click();
  };

  const onFileChange = (event: any) => {
    if (!event.target.files?.length) return;

    const files: File[] = [];
    for (let index = 0; index < event.target.files.length; index++) {
      const file = event.target.files[index];
      files.push(file);
    }

    try {
      const isOnline = navigator.onLine;
      if (!isOnline) throw new Error('No internet connection. Try again later!');

      enqueueSnackbar(`Uploading to ${parentName}. Please stay on page.`, {
        variant: 'warning',
      });

      const { filesizeLimitImage, filesizeLimitVideo, filesizeLimitPdf, storageLimit } = featureFlag.repository;
      const repoStorageSize = workSpace?.repoStorageSize || 0;
      const totalSize = files.reduce((total, file) => total + file.size, 0);
      if (repoStorageSize + totalSize > storageLimit)
        throw new Error(
          validationErrors.default('Repository.StorageLimit', { STORAGE_LIMIT: `${bytesToMegaBytes(storageLimit)}MB` })
        );

      const imageFiles = files.filter((file) => file.type.includes('image/'));
      const videoFiles = files.filter((file) => file.type.includes('video/'));
      const pdfFiles = files.filter((file) => file.type.includes('pdf'));

      const overLimitImage = imageFiles.find((file) => file.size > filesizeLimitImage);
      if (overLimitImage)
        throw new Error(
          validationErrors.default('Repository.FileSizeLimitImage', {
            FILE_NAME: overLimitImage.name,
            FILE_SIZE: bytesToMegaBytes(filesizeLimitImage),
          })
        );

      const overLimitVideo = videoFiles.find((file) => file.size > filesizeLimitVideo);
      if (overLimitVideo)
        throw new Error(
          validationErrors.default('Repository.FileSizeLimitVideo', {
            FILE_NAME: overLimitVideo.name,
            FILE_SIZE: bytesToMegaBytes(filesizeLimitVideo),
          })
        );

      const overLimitPdf = pdfFiles.find((file) => file.size > filesizeLimitPdf);
      if (overLimitPdf)
        throw new Error(
          validationErrors.default('Repository.FileSizeLimitPdf', {
            FILE_NAME: overLimitPdf.name,
            FILE_SIZE: bytesToMegaBytes(filesizeLimitPdf),
          })
        );
    } catch (err: any) {
      enqueueSnackbar(err.message, {
        variant: 'error',
      });
      event.target.value = '';
      popupState.close();
      return;
    }

    dispatch(
      repositorySlice.createRepository({
        fileName: '',
        parentID: parent?.id ?? '',
        staffs: ['*'],
        teams: ['*'],
        isFolder: false,
        files: files,
      })
    )
      .unwrap()
      .then(() => {
        enqueueSnackbar(`Upload to ${parentName} is complete!`, {
          variant: 'success',
        });
      })
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      })
      .finally(() => {
        event.target.value = '';
        popupState.close();
      });
  };

  const popover = (
    <div>
      <Button
        color="primary"
        variant="outlined"
        title="New"
        endIcon={<AddIcon />}
        style={{ paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4) }}
        {...bindTrigger(popupState)}
      >
        New
      </Button>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        style={{ top: theme.spacing(1) }}
      >
        <Box py={0.5}>
          <CustomMenuItem
            text="Folder"
            icon={<FolderIcon color="action" style={{ fill: theme.palette.warning.light }} />}
            action={handleCreateFolderClick}
          />
          <CustomMenuItem text="File upload" icon={<FileIcon color="action" />} action={handleUploadFileClick} />
        </Box>
      </Popover>
    </div>
  );
  return (
    <>
      {popover}
      <CreateFolderDialog
        parentId={parent?.id ?? ''}
        open={createFolderDialogOpen}
        setOpen={(_open) => setCreateFolderDialogOpen(_open)}
        parentPath={parentName}
      />
      <input
        id="upload-file-input"
        ref={inputFileRef}
        type="file"
        multiple
        accept="image/*,video/*,.pdf"
        style={{
          display: 'none',
        }}
        onChange={onFileChange}
      />
    </>
  );
}

const CustomMenuItem = (props: { icon: JSX.Element; text: string; action: () => void }) => {
  return (
    <MenuItem>
      <Box display="flex" flexDirection="row" alignItems="center" onClick={props.action}>
        <Box mr={1} display="flex" justifyContent="center">
          {props.icon}
        </Box>
        <Typography variant="body1">{props.text}</Typography>
      </Box>
    </MenuItem>
  );
};
