import React, { FC, useRef, useState, useEffect } from 'react';
import { Button, Grid, TextField, Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useFormik } from 'formik';

import { recaptchaVerifier, signInWithPhoneNumber, ConfirmationResult } from '@dayone/firebase';
import { ProgressIndicator } from 'shared/components/progressIndicator';
import { phoneValidationSchema as validationSchema } from 'shared/utilities/schemas';

interface IFormikInitialValue {
  OTP: Array<string>;
}

interface Props {
  phoneNumber: string;
  onError: (errorMessage: string) => void;
  onBack: () => void;
  onNext: () => void;
  setCredential: (credential: any) => void;
}

const PhoneVerification: FC<Props> = ({ phoneNumber, onError, onBack, onNext, setCredential }) => {
  const [captchaResponse, setCaptchaResponse] = useState<ConfirmationResult | null>(null);
  const classes = useStyles();
  const inputRef = useRef<HTMLDivElement[]>([]);
  const captchaRef = useRef<HTMLDivElement>(null);
  const initialValues: IFormikInitialValue = {
    OTP: new Array(6).fill(''),
  };

  useEffect(() => {
    if (captchaResponse === null) {
      signInWithPhoneNumber(phoneNumber, recaptchaVerifier())
        .then((confirmationResult) => {
          setCaptchaResponse(confirmationResult);
          inputRef.current[0].focus();
        })
        .catch((error) => {
          onError(error.message);
        });
    }
    // eslint-disable-next-line
  }, [captchaResponse]);

  const resendCodeHandler = async () => {
    formik.resetForm({ values: { ...initialValues } });
    inputRef.current[0].focus();
    recaptchaVerifier().clear();
    if (captchaRef.current) {
      captchaRef.current.innerHTML = '<div id="recaptchaContainer"></div>';
    }
    setCaptchaResponse(null);
  };

  const onSubmit = async (values: IFormikInitialValue) => {
    if (captchaResponse) {
      try {
        const credential = await captchaResponse.confirm(values.OTP.join(''));
        if (credential) {
          setCredential(credential);
        }
        onNext();
      } catch (err: any) {
        formik.setStatus(err.message);
      }
      formik.setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: onSubmit,
  });

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    formik.values.OTP.splice(index, 1, e.target.value);
    formik.setFieldValue('OTP', formik.values.OTP);

    if (index < initialValues.OTP.length - 1 && e.target.value.length > 0) {
      inputRef.current[index + 1].focus();
    }

    if (formik.values.OTP.join('').length >= 6) {
      formik.submitForm();
    }
  };

  const onKeyDownHandler = (e: React.KeyboardEvent<HTMLDivElement>, index: number) => {
    ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

    if (e.key === 'Backspace' && index > 0 && formik.values.OTP[index] === '') {
      inputRef.current[index - 1].focus();
    }
    if (e.key === 'ArrowLeft' && index > 0) {
      inputRef.current[index - 1].focus();
    }
    if (e.key === 'ArrowRight' && index < initialValues.OTP.length - 1) {
      inputRef.current[index + 1].focus();
    }
  };

  const onPasteHandler = (e: React.ClipboardEvent<HTMLDivElement>, index: number) => {
    e.stopPropagation();
    e.preventDefault();
    const reg = /^\d$/;

    const clipboardData = e.clipboardData;
    const pastedData = clipboardData.getData('Text');
    const dataToArray = pastedData.split('').filter((char: string) => reg.test(char));
    if (dataToArray.length > 0) {
      const pastedOTP = formik.values.OTP.slice(0, index).concat(dataToArray.slice(0, 6 - index));
      if (dataToArray.length + index >= 6) {
        formik.setFieldValue('OTP', pastedOTP).then(() => {
          formik.submitForm();
        });
      }
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div ref={captchaRef}>
        <div id="recaptchaContainer"></div>
      </div>
      {(formik.isSubmitting || !captchaResponse) && <ProgressIndicator />}
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Box mb={5}>
            <Typography variant="h6" align="center">
              Please enter verification code.
            </Typography>
          </Box>

          <Grid item container direction="column" justifyContent="flex-start" spacing={1}>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" alignItems="center">
                {initialValues.OTP.map((item, index) => {
                  return (
                    <TextField
                      type="tel"
                      inputRef={(el) => (inputRef.current[index] = el)}
                      variant="outlined"
                      key={`optField${index}`}
                      name={`optField${index}`}
                      onKeyDown={(e) => onKeyDownHandler(e, index)}
                      onPaste={(e) => onPasteHandler(e, index)}
                      focused={formik.values.OTP[index].length > 0}
                      autoFocus={index === 0}
                      value={formik.values.OTP[index]}
                      inputProps={{
                        maxLength: 1,
                        min: 0,
                        autoComplete: 'new-password',
                        style: { textAlign: 'center' },
                      }}
                      className={classes.otpField}
                      onChange={(e) => onChangeHandler(e, index)}
                    />
                  );
                })}
              </Box>
            </Grid>
            <Grid item>
              {formik.status && (
                <Typography align="center" variant="caption" color="error" component="div">
                  {formik.status}
                </Typography>
              )}
            </Grid>
            <Grid item>
              <Typography align="center" variant="caption" component="div">
                {`A code has been sent to ${phoneNumber} via SMS`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box mt={5} display="flex" justifyContent="flex-end">
        <Button variant="text" color="primary" onClick={onBack}>
          Cancel
        </Button>
        <Button type="submit" variant="text" color="primary" disabled={formik.isSubmitting} onClick={resendCodeHandler}>
          Resend code
        </Button>
      </Box>
    </form>
  );
};

export default PhoneVerification;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    otpField: {
      width: '56px',
      height: '56px',
      margin: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        width: '52px',
        height: '52px',
        margin: theme.spacing(0.5),
      },
    },
  })
);
