import { Box, Button, Grid, GridSize, Hidden } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Logo } from 'shared/components/logo';

interface IFullRegistrationLayoutProps {
  renderHeader?: Function;
  renderFooter?: Function;
  gotoPreStep?: () => void;
  children: any;
  xs?: GridSize;
  sm?: GridSize;
  md?: GridSize;
  lg?: GridSize;
}

const FullRegistrationLayout = ({
  renderHeader,
  renderFooter,
  gotoPreStep,
  children,
  xs = 12,
  sm = 12,
  md = 8,
  lg = 6,
}: IFullRegistrationLayoutProps) => (
  <Box pt={[0, 0, 5]} style={{ backgroundColor: '#FAFAFA' }}>
    <Box pt={[4.5, 5, 0]} pb={[0, 3]} pl={[3.5, 3.5, 4.5]} position={['', '', 'absolute']}>
      <Logo />
    </Box>
    <Grid container alignItems="center" style={{ minHeight: '100vh' }}>
      <Grid item xs></Grid>
      <Grid item xs={xs} sm={sm} md={md} lg={lg} container direction="column">
        <Grid item container>
          <Grid item xs></Grid>
          <Grid item xs={12} sm={8}>
            <Box mb={3}>{renderHeader && renderHeader()}</Box>
            <Grid item container>
              <Hidden only="xs">
                <Grid item xs></Grid>
              </Hidden>
              <Grid item xs={12} sm={10}>
                {children}
                {renderFooter && renderFooter()}
              </Grid>
              <Hidden only="xs">
                <Grid item xs></Grid>
              </Hidden>
            </Grid>
          </Grid>
          <Grid item xs></Grid>
        </Grid>
        <Grid item>
          {gotoPreStep && (
            <Box mt={2} mb={5} pl={1}>
              <Button color="primary" startIcon={<ArrowBackIcon />} onClick={gotoPreStep}>
                Back
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>
      <Grid item xs></Grid>
    </Grid>
  </Box>
);

export default FullRegistrationLayout;
