import { sendPasswordResetEmail } from '@dayone/firebase';
import { TextField, Button, Grid, Box, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import * as Sentry from '@sentry/react';
import React, { useCallback } from 'react';
import { ProgressIndicator } from 'shared/components/progressIndicator';
import { useFormik, FormikHelpers } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

export default function ForgotPasswordForm(props: { onSuccess: () => void }) {
  const navigate = useNavigate();
  const onBack = useCallback(() => navigate(-1), [navigate]);

  const onSubmit = (values: IFormikInitialValue, { setSubmitting }: FormikHelpers<IFormikInitialValue>) => {
    const { email } = values;

    sendPasswordResetEmail(email)
      .then(() => {
        props.onSuccess();
      })
      .catch((error) => {
        formik.setStatus(error.message);
        Sentry.captureException(error);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: onSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      {formik.isSubmitting && <ProgressIndicator />}

      <Grid container justifyContent="center">
        <Grid item md={6} xs={12}>
          <Box mb={7}>
            <Typography variant="h4" align="center">
              Forgot password?
            </Typography>
          </Box>
          <Box mb={7}>
            <Typography variant="body1" align="center">
              Please enter your email or username. We will send you an email to reset your password.
            </Typography>
          </Box>

          <Box px={5}>
            <Grid item container direction="column" spacing={3}>
              <Grid item>
                {formik.status && (
                  <Alert variant="outlined" severity="error">
                    {formik.status}
                  </Alert>
                )}
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="email"
                  label="Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>

              <Grid item>
                <Box px={5} mt={1}>
                  <Button fullWidth type="submit" variant="contained" color="primary" disabled={formik.isSubmitting}>
                    Reset password
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box mt={5}>
            <Button color="primary" startIcon={<ArrowBackIcon />} onClick={onBack}>
              Back to email login
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
}

interface IFormikInitialValue {
  email: string;
}

const initialValues: IFormikInitialValue = {
  email: '',
};

const validationSchema = yup.object({
  email: yup.string().email('Enter a valid email').defined('Email is required'),
});
