import { usePlaybook } from '@dayone/hooks';
import { IPlaybook } from '@dayone/models';
import { Card, CardContent, CardMedia, Grid, IconButton, Link, Paper, Typography, useTheme } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { GridCellParams, GridColDef } from '@material-ui/x-grid';
import React, { useCallback, useEffect } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import { playbookSlice, useAppDispatch } from '@dayone/redux';
import { useCustomDimensionsEvents, useSnackbar } from 'shared/components/hooks';
import { ProgressIndicator } from 'shared/components/progressIndicator';
import { DeleteConfirmDialog } from 'shared/components/deleteConfirmDialog';
import GAEvents from 'shared/utilities/events';

const topics = [
  '',
  'Career progression',
  'Interpersonal skills',
  'Leadership skills',
  'Personal finance',
  'Personal development',
  'Time management',
  'Mental health',
];

export default function PlaybookOverview() {
  const dispatch = useAppDispatch();
  const playbooks = usePlaybook();
  const theme = useTheme();
  const enqueueSnackbar = useSnackbar();
  const [deleteID, setDeleteID] = React.useState<string>('');
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [pageSize, setPageSize] = React.useState<number>(10);
  const logEvent = useCustomDimensionsEvents();

  useEffect(() => {
    logEvent(GAEvents.repository_view);
  }, [logEvent]);

  //   console.log(playbooks);
  const handleDelete = useCallback(() => {
    setLoading(true);
    dispatch(playbookSlice.deletePlaybook({ id: deleteID }))
      .unwrap()
      .then(() => {
        enqueueSnackbar('The playbook has been deleted successfully!', {
          variant: 'success',
        });
      })
      .catch((ex) => {
        enqueueSnackbar('Error when create playbook, please try again later!', {
          variant: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setLoading, dispatch, deleteID, enqueueSnackbar]);

  const onDelete = useCallback(
    (id: string) => {
      setDeleteID(id);
      setDeleteDialogOpen(true);
    },
    [setDeleteID, setDeleteDialogOpen]
  );

  const onDeleteConfirm = useCallback(
    (confirm) => {
      setDeleteDialogOpen(false);
      if (confirm) {
        handleDelete();
      }
    },
    [setDeleteDialogOpen, handleDelete]
  );

  const columns = (): GridColDef[] => {
    return [
      {
        width: 180,
        field: 'topic',
        headerName: 'Topic',
        disableColumnMenu: true,
        valueFormatter: (value) => {
          return topics[value.value as number];
        },
      },
      {
        width: 320,
        field: 'title',
        headerName: 'Title',
      },
      {
        width: 270,
        field: 'description',
        headerName: 'Description',
      },
      {
        width: 150,
        field: 'status',
        headerName: 'Status',
        valueFormatter: (value) => {
          return value.value === '0' ? 'Coming Soon' : 'Ready';
        },
      },
      {
        width: 120,
        field: 'url',
        headerName: 'File',
        renderCell: (param: GridCellParams) => {
          const p = param.row as IPlaybook;
          if (p.status === '1') {
            return (
              <Typography>
                <Link href={p.url}>{'Open'}</Link>
              </Typography>
            );
          } else return '';
        },
      },
      {
        width: 240,
        field: 'thumbnail',
        headerName: 'Thumbnail',
        renderCell: (param: GridCellParams) => {
          const p = param.row as IPlaybook;
          return <CardMedia component="img" width={343} height="125" image={p.thumbnailUrl}></CardMedia>;
        },
      },
      {
        width: 120,
        field: 'id',
        headerName: 'Delete',
        renderCell: (param: GridCellParams) => {
          const p = param.row as IPlaybook;
          return (
            <IconButton aria-label="delete" onClick={() => onDelete(p.id)}>
              <DeleteIcon />
            </IconButton>
          );
        },
      },
    ];
  };
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {/* <List>{playbooks?.map(renderItem)}</List> */}
          <Paper elevation={0}>
            <Card elevation={0}>
              <CardContent>
                <DataGrid
                  getRowId={(row) => row.id}
                  autoHeight
                  rowHeight={theme.spacing(7)}
                  disableSelectionOnClick
                  rows={playbooks ?? []}
                  columns={columns()}
                  nonce={'No Tasks'}
                  pagination
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[5, 10, 20]}
                />
              </CardContent>
            </Card>
          </Paper>
        </Grid>
      </Grid>
      <DeleteConfirmDialog
        open={deleteDialogOpen}
        title="Are you sure you want to delete playbook?"
        content="The playbook will be deleted immediately. You can’t undo this action."
        onConfirm={onDeleteConfirm}
      />
      <ProgressIndicator loading={loading}></ProgressIndicator>
    </React.Fragment>
  );
}
