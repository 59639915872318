"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.setNotifications = exports.selectAllNotifications = exports.markAsReadOnNavigation = exports.markAsReadByID = exports.markAllAsRead = exports.notificationSliceConfig = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var models_1 = require("@dayone/models");
var firestore_1 = require("firebase/firestore");
var firestore;
// let firebaseApp: FirebaseApp;
var notificationSliceConfig = function (_firebaseApp) {
    // eslint-disable-next-line
    firestore = (0, firestore_1.getFirestore)(_firebaseApp);
    // firebaseApp = _firebaseApp;
};
exports.notificationSliceConfig = notificationSliceConfig;
exports.markAllAsRead = (0, toolkit_1.createAsyncThunk)('notification/markAllAsRead', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var state, uid, companyId, q, snapshot, batch_1;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                state = thunkAPI.getState();
                uid = ((_a = state.me.details) !== null && _a !== void 0 ? _a : {}).uid;
                companyId = state.setting.activeWorkspaceID;
                if (!(uid && companyId)) return [3 /*break*/, 3];
                q = (0, firestore_1.query)((0, firestore_1.collection)(firestore, 'notifications'), (0, firestore_1.where)('receiveUserId', '==', uid), (0, firestore_1.where)('companyID', '==', companyId));
                return [4 /*yield*/, (0, firestore_1.getDocs)(q)];
            case 1:
                snapshot = _b.sent();
                batch_1 = (0, firestore_1.writeBatch)(firestore);
                snapshot.docs.map(function (notification) {
                    batch_1.update((0, firestore_1.doc)(firestore, 'notifications', notification.id), { isRead: true });
                });
                return [4 /*yield*/, batch_1.commit()];
            case 2:
                _b.sent();
                _b.label = 3;
            case 3: return [2 /*return*/];
        }
    });
}); });
exports.markAsReadByID = (0, toolkit_1.createAsyncThunk)('notification/markAsReadByID', function (payload) { return __awaiter(void 0, void 0, void 0, function () {
    var notiCollectionRef, notiDocRef;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                notiCollectionRef = (0, firestore_1.collection)(firestore, 'notifications');
                notiDocRef = (0, firestore_1.doc)(notiCollectionRef, payload.id);
                return [4 /*yield*/, (0, firestore_1.updateDoc)(notiDocRef, {
                        isRead: true,
                    })];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}); });
exports.markAsReadOnNavigation = (0, toolkit_1.createAsyncThunk)('notification/markAsReadOnNavigation', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var state, uid, notificationTypes, docId, batch_2, queries, idFieldName, notificationQuery, snapshot;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                state = thunkAPI.getState();
                uid = ((_a = state.me.details) !== null && _a !== void 0 ? _a : {}).uid;
                notificationTypes = payload.notificationTypes, docId = payload.docId;
                if (!uid) return [3 /*break*/, 3];
                batch_2 = (0, firestore_1.writeBatch)(firestore);
                queries = [(0, firestore_1.where)('receiveUserId', '==', uid), (0, firestore_1.where)('metaData.type', 'in', notificationTypes)];
                idFieldName = detectIdFromNotificationTypes(notificationTypes);
                if (idFieldName.length) {
                    queries.push((0, firestore_1.where)("metaData.".concat(idFieldName), '==', docId));
                }
                notificationQuery = firestore_1.query.apply(void 0, __spreadArray([(0, firestore_1.collection)(firestore, 'notifications')], queries, false));
                return [4 /*yield*/, (0, firestore_1.getDocs)(notificationQuery)];
            case 1:
                snapshot = _b.sent();
                console.log();
                snapshot.docs.map(function (notification) {
                    batch_2.update((0, firestore_1.doc)(firestore, 'notifications', notification.id), { isRead: true });
                });
                return [4 /*yield*/, batch_2.commit()];
            case 2:
                _b.sent();
                _b.label = 3;
            case 3: return [2 /*return*/];
        }
    });
}); });
var detectIdFromNotificationTypes = function (notificationTypes) {
    if (notificationTypes.some(function (item) { return item.includes(models_1.NOTIFICATION_TYPES.ALL_TASK_COMPLETED); }))
        return 'branchID';
    if (notificationTypes.some(function (item) { return item.includes('task'); }))
        return 'taskID';
    if (notificationTypes.some(function (item) { return item.includes('announcement'); }))
        return 'motdID';
    if (notificationTypes.some(function (item) { return item.includes('knowledge'); }))
        return 'faqID';
    if (notificationTypes.some(function (item) { return item.includes('repository'); }))
        return 'repositoryID';
    if (notificationTypes.some(function (item) { return item.includes('member'); }))
        return 'companyID';
    return '';
};
var initialState = {
    initialised: false,
    notifications: [],
};
var notificationSlice = (0, toolkit_1.createSlice)({
    name: 'notification',
    initialState: initialState,
    reducers: {
        setNotifications: {
            reducer: function (state, action) {
                if (action.payload) {
                    state.initialised = true;
                    state.notifications = action.payload;
                }
            },
            prepare: function (payload) {
                if (payload) {
                    var notificationModels = payload.map(function (notification) {
                        var _a, _b, _c, _d, _e, _f;
                        return {
                            id: (_a = notification.id) !== null && _a !== void 0 ? _a : '',
                            companyID: (_b = notification.companyID) !== null && _b !== void 0 ? _b : '',
                            receiveUserId: (_c = notification.receiveUserId) !== null && _c !== void 0 ? _c : '',
                            sendingStatus: (_d = notification.sendingStatus) !== null && _d !== void 0 ? _d : { tickets: [], receipts: [] },
                            retryingTime: notification.retryingTime || 0,
                            title: notification.title || '',
                            message: notification.message || '',
                            metaData: notification.metaData || {},
                            type: notification.type,
                            isRead: !!notification.isRead,
                            createdAt: notification.createdAt instanceof firestore_1.Timestamp ? (_f = (_e = notification.createdAt) === null || _e === void 0 ? void 0 : _e.toDate().toISOString()) !== null && _f !== void 0 ? _f : '' : '',
                        };
                    });
                    return { payload: notificationModels };
                }
                return { payload: null };
            },
        },
    },
    extraReducers: function (builder) {
        builder.addCase('notification/setNotifications', function (state, action) {
            console.log('notification/setNotifications/fulfilled', { state: state, action: action });
        });
    },
});
// Selectors
var selectAllNotifications = function (state) { return state.notification.notifications; };
exports.selectAllNotifications = selectAllNotifications;
// Actions
exports.setNotifications = notificationSlice.actions.setNotifications;
exports.default = notificationSlice.reducer;
