import { FC } from 'react';
import { Button, Grid, TextField, Box, Typography } from '@material-ui/core';
import { useFormik, FormikHelpers } from 'formik';
import * as yup from 'yup';

import { meSlice, useAppSelector, useAppDispatch } from '@dayone/redux';
import { reloadUser } from '@dayone/firebase';
import useSnackbar from 'shared/components/hooks/components/useSnackbar';
import { ProgressIndicator } from 'shared/components/progressIndicator';

const validationSchema = yup.object({
  email: yup.string().email('Enter a valid email').defined('Email is required'),
  newEmail: yup.string().email('Enter a valid email').defined('New email is required'),
});

interface IFormikInitialValue {
  email: string;
  newEmail: string;
}

interface Props {
  onCancel: () => void;
  onNext: () => void;
  credential: any;
}

const UpdateEmail: FC<Props> = ({ onCancel, onNext, credential }) => {
  const dispatch = useAppDispatch();
  const details = useAppSelector<any>(meSlice.selectDetails);
  const enqueueSnackbar = useSnackbar();

  const email = details?.email || '';

  const initialValues: IFormikInitialValue = {
    email,
    newEmail: '',
  };

  const onSubmit = async (
    { email, newEmail }: IFormikInitialValue,
    { setSubmitting, resetForm }: FormikHelpers<IFormikInitialValue>
  ) => {
    try {
      if (credential && credential.user) {
        await credential.user.updateEmail(newEmail);
        await dispatch(meSlice.updateUserEmail(email.trim())).unwrap();
        await reloadUser();
        await dispatch(meSlice.setDetails(null)).unwrap();
        resetForm();
        onNext();
        enqueueSnackbar(`Email is updated successfully.`, {
          variant: 'success',
        });
      }
    } catch (err: any) {
      enqueueSnackbar(err.message, {
        variant: 'error',
      });
    }
    setSubmitting(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: onSubmit,
    enableReinitialize: true,
  });

  return (
    <Box mt={2} pb={1} px={1}>
      <Box mb={5}>
        <Typography variant="h6" align="center">
          Update Email
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        {formik.isSubmitting && <ProgressIndicator />}

        <Grid container>
          <Grid item xs={12}>
            <Box px={8}>
              <Grid item container direction="column" spacing={3}>
                <Grid item>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="email"
                    label="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    disabled
                  />
                </Grid>
                <Grid item>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="newEmail"
                    label="New email"
                    value={formik.values.newEmail}
                    onChange={formik.handleChange}
                    error={formik.touched.newEmail && Boolean(formik.errors.newEmail)}
                    helperText={formik.touched.newEmail && formik.errors.newEmail}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Box mt={5} display="flex" justifyContent="flex-end">
          <Button
            variant="text"
            color="primary"
            disabled={formik.isSubmitting}
            style={{ marginRight: 2 }}
            onClick={(e) => {
              e.preventDefault();
              onCancel();
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="text"
            color="primary"
            disabled={formik.isSubmitting}
            onClick={() => formik.handleSubmit()}
          >
            Change
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default UpdateEmail;
