import { Button, Grid, Box, Container, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Logo } from 'shared/components/logo';

export default function ForgotPasswordSuccess() {
  const classes = useStyles();
  const navigate = useNavigate();
  const onBack = useCallback(() => navigate(-1), [navigate]);
  return (
    <React.Fragment>
      <Box m={5} mb={8}>
        <Logo />
      </Box>
      <Container maxWidth="md">
        <Grid container justifyContent="center" spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h4" align="center">
              Email sent!
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center">
              <CheckCircleIcon className={classes.success} />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1" align="center">
              An email containing your password reset link is on its way to your inbox.
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box mt={5}>
            <Button color="primary" startIcon={<ArrowBackIcon />} onClick={onBack}>
              Back to email login
            </Button>
          </Box>
        </Grid>
      </Container>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    success: {
      color: theme.palette.success.main,
      fontSize: '96px',
    },
  })
);
