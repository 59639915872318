import React from 'react';
import { Box, Button, makeStyles, createStyles, Theme } from '@material-ui/core';
import { matchPath, useLocation } from 'react-router-dom';
import { useTheme } from '@material-ui/core';
import analytics from 'shared/utilities/analytics';
export interface IProps {
  items: {
    label: string;
    url: string;
    hiddenNav: boolean;
  }[];
  callback: (item: { label: string; url: string; hiddenNav: boolean }) => void;
}

export default function SecondaryNav(props: IProps) {
  const { items, callback } = props;

  const classes = useStyles();
  const { pathname } = useLocation();
  // const path = useRouteMatch();
  const theme = useTheme();
  let hidden = !items.length;

  // const matches = items.map((item) => {
  //   const match = matchPath(item.url, {
  //     path: path.path,
  //     exact: true,
  //     strict: true,
  //   })?.isExact;
  //   if (match) hidden = item.hiddenNav;
  //   return { ...item, match };
  // });

  const matches = items.map((item) => {
    const match = matchPath(item.url, pathname);
    if (match) hidden = item.hiddenNav;
    return { ...item, match };
  });

  // console.log('SecondaryNav', { matches, pathname });
  analytics.pageView(pathname);

  return (
    <React.Fragment>
      {!hidden && (
        <Box
          className={classes.secondNav}
          justifyContent="flex-start"
          display="flex"
          overflow="auto"
          //flexWrap="wrap"
          bgcolor={theme.palette.background.paper}
          minHeight={{ xs: theme.spacing(7), sm: theme.spacing(8) }}
          mx={{ xs: -2, sm: -5 }}
          px={{ xs: 2, sm: 5 }}
          py={2}
        >
          {matches.map((item, index) => {
            return (
              <Box key={index} display="flex" m={-1} mr={3}>
                <Button
                  style={{
                    padding: theme.spacing(1),
                    whiteSpace: 'nowrap',
                  }}
                  color={item.match ? 'primary' : 'default'}
                  onClick={() => {
                    callback(item);
                  }}
                >
                  {item.label}
                </Button>
              </Box>
            );
          })}
        </Box>
      )}
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    secondNav: {
      scrollbarWidth: 'none' /* Firefox */,
      msOverflowStyle: 'none' /* Internet Explorer 10+ */,
      /* WebKit */
      '&::-webkit-scrollbar': {
        width: 0,
        height: 0,
      },
    },
  })
);
