import {
  Typography,
  Card,
  CardContent,
  Box,
  Avatar,
  IconButton,
  Popover,
  MenuItem,
  makeStyles,
} from '@material-ui/core';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Create';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { IFaqCategoryModel, IFaqModel } from '@dayone/models';

export default function KnowledgeCategory(props: {
  category: IFaqCategoryModel | { name: string };
  faqs: IFaqModel[];
  categoryCounter?: () => number;
  isEditable?: boolean;
  onEdit: (category: IFaqCategoryModel) => void;
  onDelete: (category: IFaqCategoryModel) => void;
}) {
  const classes = useStyles();
  const { category, faqs, categoryCounter, isEditable, onEdit, onDelete } = props;
  return (
    <Card elevation={1}>
      <CardContent>
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            <Avatar>
              <InfoIcon color="action" />
            </Avatar>
            <Box sx={{ ml: 2 }}>
              <Typography variant="body1" className={classes.categoryNameStyle}>
                {category.name}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {!categoryCounter
                  ? faqs.filter(({ categories }) => categories.includes((category as IFaqCategoryModel).id)).length
                  : categoryCounter()}
                {' questions'}
              </Typography>
            </Box>
          </Box>
          {Boolean(isEditable) && (
            <PopupState variant="popover" popupId="popover">
              {(popupState) => (
                <div>
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <IconButton {...bindTrigger(popupState)}>
                      <MoreHorizIcon />
                    </IconButton>
                  </Box>
                  <Popover
                    {...bindPopover(popupState)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}>
                    <Box py={0.5}>
                      <MenuItem
                        onClick={() => {
                          popupState.toggle();
                          onEdit(category as IFaqCategoryModel);
                        }}>
                        <Box sx={{ minWidth: '180px' }} display="flex" flexDirection="row" alignItems="center">
                          <EditIcon fontSize="small" style={{ marginRight: 8 }} />
                          <Typography variant="body1">Edit</Typography>
                        </Box>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          popupState.toggle();
                          onDelete(category as IFaqCategoryModel);
                        }}>
                        <Box sx={{ minWidth: '180px' }} display="flex" flexDirection="row" alignItems="center">
                          <DeleteIcon fontSize="small" style={{ marginRight: 8 }} />
                          <Typography variant="body1">Delete</Typography>
                        </Box>
                      </MenuItem>
                    </Box>
                  </Popover>
                </div>
              )}
            </PopupState>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}

const useStyles = makeStyles({
  categoryNameStyle: {
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': '1',
    '-webkit-box-orient': 'vertical',
  },
});
