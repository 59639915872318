import React, { Ref, PropsWithChildren } from 'react';
import { cx, css } from '@emotion/css';

import { BaseProps } from 'shared/utilities/richTextHelpers';

const Icon = React.forwardRef(({ className, ...props }: PropsWithChildren<BaseProps>, ref: Ref<HTMLSpanElement>) => (
  <span
    {...props}
    ref={ref}
    className={cx(
      'material-icons',
      className,
      css`
        font-size: 18px;
        vertical-align: text-bottom;
      `
    )}
  />
));

export default Icon;
