import { Box } from '@material-ui/core';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { MemberRoutes } from 'shared/utilities/routeConfig';
import { meSlice, useAppSelector } from '@dayone/redux';
import { UpdatePageTitle } from 'shared/components/navigations';

export default function MemberRoute() {
  const role = useAppSelector<any>(meSlice.selectRole) as 'admin' | 'user' | null;
  const routes = MemberRoutes.filter((i) => i.role.includes('*') || i.role.includes(role));

  return (
    <React.Fragment>
      <Box pb={5} />
      <Routes>
        {routes.map((item, index) => {
          const indexof = item.url.indexOf('/', 1);
          const pathURL = indexof > -1 ? item.url.substring(indexof) : '/';
          return (
            <Route
              path={pathURL}
              key={index}
              element={
                <>
                  <UpdatePageTitle title={item.pageTitle} />
                  <item.component />
                </>
              }
            />
          );
        })}
      </Routes>
    </React.Fragment>
  );
}
