import { useTheme, Box, Grid, Button, TextField, useMediaQuery, Paper, CardContent, Card } from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useSnackbar } from 'notistack';
import { ITaskModel } from '@dayone/models';
import { taskSlice, topicSlice, useAppDispatch, useAppSelector } from '@dayone/redux';
import React from 'react';

export default function TaskThreadCreate() {
  const theme = useTheme();
  const { taskID = '' } = useParams();
  const task: ITaskModel | null = useAppSelector<any>(taskSlice.selectTaskByID)(taskID);
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const initialValues = {
    name: '',
  };

  const onSubmit = (values: { name: string }) => {
    try {
      dispatch(topicSlice.createTopic({ topicName: values.name, taskId: taskID || null })).then(() => {
        navigate(-1);
        enqueueSnackbar(`The Comment Topic has been created successfully!`, {
          variant: 'success',
          anchorOrigin: {
            vertical: matches ? 'bottom' : 'top',
            horizontal: matches ? 'center' : 'right',
          },
        });
      });
    } catch (error) {
      //Do something about error
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationFormSchema,
    enableReinitialize: true,
    onSubmit: onSubmit,
  });

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={3}>
        {/* Back button */}
        <Grid item xs={12}>
          <Box pb={2}>
            <Button color="primary" startIcon={<ArrowBackIcon />} onClick={handleBack}>
              {`BACK`}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} lg={8}>
          <Paper elevation={0}>
            <Card elevation={0}>
              <CardContent>
                <TextField
                  fullWidth
                  label="Task name"
                  name="taskname"
                  disabled
                  value={task?.name ?? ''}
                  variant="outlined"
                />
                <Box pt={3} />
                <TextField
                  fullWidth
                  label="Comment topic"
                  name="name"
                  defaultValue={formik.values.name}
                  variant="outlined"
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </CardContent>
            </Card>
          </Paper>
        </Grid>
        {/* Submit Button */}
        <Grid item xs={12} sm={12} lg={8}>
          <Box pt={2} />
          <Button fullWidth={matches} type="submit" variant="contained" color="primary">
            Create Topic
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export const validationFormSchema = yup.object({
  name: yup.string().required('Topic name is required'),
});
