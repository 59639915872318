"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCheckUnreadNotification = exports.useNotification = exports.useFeatureFlag = exports.useFaqCategory = exports.usePlaybook = exports.useInternalAdminUsers = exports.useInternalAdminsIds = exports.useInternalAdminsCompaines = exports.useWorkspace = exports.useInvitesOfUser = exports.useComment = exports.useTopic = exports.useResponsibility = exports.useInvite = exports.useRole = exports.useRepository = exports.usePromo = exports.useMember = exports.useAnnoucement = exports.useTeam = exports.useKnowledge = exports.useAccounts = exports.useAccount = exports.useAuth = void 0;
var useAuth_1 = __importStar(require("./useAuth"));
Object.defineProperty(exports, "useAuth", { enumerable: true, get: function () { return useAuth_1.useAuth; } });
var useAccount_1 = __importStar(require("./useAccount"));
Object.defineProperty(exports, "useAccount", { enumerable: true, get: function () { return useAccount_1.useAccount; } });
Object.defineProperty(exports, "useAccounts", { enumerable: true, get: function () { return useAccount_1.useAccounts; } });
var useKnowledge_1 = __importStar(require("./useKnowledge"));
Object.defineProperty(exports, "useKnowledge", { enumerable: true, get: function () { return useKnowledge_1.useKnowledge; } });
var useAnnouncement_1 = __importStar(require("./useAnnouncement"));
Object.defineProperty(exports, "useAnnoucement", { enumerable: true, get: function () { return useAnnouncement_1.useAnnoucement; } });
var useTeam_1 = __importStar(require("./useTeam"));
Object.defineProperty(exports, "useTeam", { enumerable: true, get: function () { return useTeam_1.useTeam; } });
var useMember_1 = __importStar(require("./useMember"));
Object.defineProperty(exports, "useMember", { enumerable: true, get: function () { return useMember_1.useMember; } });
var useRepository_1 = __importStar(require("./useRepository"));
Object.defineProperty(exports, "useRepository", { enumerable: true, get: function () { return useRepository_1.useRepository; } });
var useInvite_1 = __importStar(require("./useInvite"));
Object.defineProperty(exports, "useInvite", { enumerable: true, get: function () { return useInvite_1.useInvite; } });
Object.defineProperty(exports, "useInvitesOfUser", { enumerable: true, get: function () { return useInvite_1.useInvitesOfUser; } });
var useResponsibility_1 = __importStar(require("./useResponsibility"));
Object.defineProperty(exports, "useResponsibility", { enumerable: true, get: function () { return useResponsibility_1.useResponsibility; } });
var useRole_1 = __importStar(require("./useRole"));
Object.defineProperty(exports, "useRole", { enumerable: true, get: function () { return useRole_1.useRole; } });
var useTaskTopic_1 = __importStar(require("./useTaskTopic"));
Object.defineProperty(exports, "useTopic", { enumerable: true, get: function () { return useTaskTopic_1.useTopic; } });
var useTaskComment_1 = __importStar(require("./useTaskComment"));
Object.defineProperty(exports, "useComment", { enumerable: true, get: function () { return useTaskComment_1.useComment; } });
var useWorkspace_1 = __importStar(require("./useWorkspace"));
Object.defineProperty(exports, "useWorkspace", { enumerable: true, get: function () { return useWorkspace_1.useWorkspace; } });
var useInternalAdmins_1 = __importStar(require("./useInternalAdmins"));
Object.defineProperty(exports, "useInternalAdminsCompaines", { enumerable: true, get: function () { return useInternalAdmins_1.useInternalAdminsCompaines; } });
Object.defineProperty(exports, "useInternalAdminsIds", { enumerable: true, get: function () { return useInternalAdmins_1.useInternalAdminsIds; } });
Object.defineProperty(exports, "useInternalAdminUsers", { enumerable: true, get: function () { return useInternalAdmins_1.useInternalAdminUsers; } });
var usePromo_1 = __importStar(require("./usePromo"));
Object.defineProperty(exports, "usePromo", { enumerable: true, get: function () { return usePromo_1.usePromo; } });
var usePlaybook_1 = __importStar(require("./usePlaybook"));
Object.defineProperty(exports, "usePlaybook", { enumerable: true, get: function () { return usePlaybook_1.usePlaybook; } });
var useFaqCategory_1 = __importStar(require("./useFaqCategory"));
Object.defineProperty(exports, "useFaqCategory", { enumerable: true, get: function () { return useFaqCategory_1.useFaqCategory; } });
var useFeatureFlag_1 = __importStar(require("./useFeatureFlag"));
Object.defineProperty(exports, "useFeatureFlag", { enumerable: true, get: function () { return useFeatureFlag_1.useFeatureFlag; } });
var useNotification_1 = __importStar(require("./useNotification"));
Object.defineProperty(exports, "useNotification", { enumerable: true, get: function () { return useNotification_1.useNotification; } });
var useCheckUnreadNotification_1 = __importStar(require("./useCheckUnreadNotification"));
Object.defineProperty(exports, "useCheckUnreadNotification", { enumerable: true, get: function () { return useCheckUnreadNotification_1.useCheckUnreadNotification; } });
var HookConfig = function (firebaseApp) {
    if (firebaseApp) {
        (0, useAuth_1.default)(firebaseApp);
        (0, useAccount_1.default)(firebaseApp);
        (0, useKnowledge_1.default)(firebaseApp);
        (0, useAnnouncement_1.default)(firebaseApp);
        (0, useTeam_1.default)(firebaseApp);
        (0, useMember_1.default)(firebaseApp);
        (0, useRepository_1.default)(firebaseApp);
        (0, useResponsibility_1.default)(firebaseApp);
        (0, useRole_1.default)(firebaseApp);
        (0, useInvite_1.default)(firebaseApp);
        (0, useTaskTopic_1.default)(firebaseApp);
        (0, useTaskComment_1.default)(firebaseApp);
        (0, useWorkspace_1.default)(firebaseApp);
        (0, useInternalAdmins_1.default)(firebaseApp);
        (0, usePromo_1.default)(firebaseApp);
        (0, usePlaybook_1.default)(firebaseApp);
        (0, useFaqCategory_1.default)(firebaseApp);
        (0, useFeatureFlag_1.default)(firebaseApp);
        (0, useNotification_1.default)(firebaseApp);
        (0, useCheckUnreadNotification_1.default)(firebaseApp);
    }
};
exports.default = HookConfig;
