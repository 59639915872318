import { Box, Button, Grid, Typography } from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';
import PeopleIcon from '@material-ui/icons/People';
import FullRegistrationLayout from './FullRegistrationLayout';

interface IAccountTypeProps {
  gotoPreStep: () => void;
  onChoosesAccountType: (type: string) => void;
}

const AccountType = ({ gotoPreStep, onChoosesAccountType }: IAccountTypeProps) => {
  const choosesAccountType = (type: string) => () => onChoosesAccountType(type);
  return (
    <>
      <FullRegistrationLayout
        renderHeader={() => (
          <Typography variant="h4" align="center">
            Are you a business owner?
          </Typography>
        )}
        gotoPreStep={gotoPreStep}>
        <Box p={2}>
          <Grid container direction="column" spacing={3}>
            <Grid item xs={12}>
              <Button
                fullWidth
                style={{ backgroundColor: '#fff', height: '96px', textTransform: 'inherit' }}
                onClick={choosesAccountType('business')}>
                <Grid container alignItems="center">
                  <Grid item>
                    <Box pl={5} pr={5}>
                      <BusinessIcon style={{ fontSize: 44, opacity: 0.6 }} />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Typography>Yes, I am a business owner</Typography>
                  </Grid>
                </Grid>
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                style={{ backgroundColor: '#fff', height: '96px', textTransform: 'inherit' }}
                onClick={choosesAccountType('employee')}>
                <Grid container alignItems="center">
                  <Grid item>
                    <Box pl={5} pr={5}>
                      <PeopleIcon style={{ fontSize: 44, opacity: 0.6 }} />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Typography>No, I am an employee</Typography>
                  </Grid>
                </Grid>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </FullRegistrationLayout>
    </>
  );
};

export default AccountType;
