"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAuth = void 0;
var react_1 = require("react");
var auth_1 = require("firebase/auth");
var auth;
function authStateConfig(_firebaseApp) {
    auth = (0, auth_1.getAuth)(_firebaseApp);
}
exports.default = authStateConfig;
/**
 * Firebase hook for authentication
 * This hook is used when you update when user log in or out in firebase.
 * If user is logged out, a null value to user is returned
 * @returns {initialised:boolean, firebase.user | null}
 *
 */
var useAuth = function () {
    var _a = (0, react_1.useState)({
        initialised: false,
        user: null,
    }), userAuth = _a[0], setUserAuth = _a[1];
    (0, react_1.useEffect)(function () {
        return (0, auth_1.onAuthStateChanged)(auth, function (user) {
            if (user) {
                setUserAuth({
                    initialised: true,
                    user: user,
                });
            }
            else {
                setUserAuth({
                    initialised: true,
                    user: null,
                });
            }
        });
    }, []);
    return userAuth;
};
exports.useAuth = useAuth;
