import { Button, Grid, TextField, Typography, useTheme } from '@material-ui/core';
import { useFormik } from 'formik';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';

export default function VerifyBox(props: {
  subtitle: string;
  textFieldTitle: string;
  btnText: string;
  initialValue?: string;
  isVerified?: boolean;
  isEmail?: boolean;
  openLoginDialog: () => void;
  onFieldUpdate: (value: string) => void;
}) {
  const theme = useTheme();
  const { subtitle, textFieldTitle, btnText, initialValue, isVerified, isEmail, openLoginDialog, onFieldUpdate } =
    props;
  const onSubmit = (verifyBoxForm: IVerifyBox) => {
    onFieldUpdate(verifyBoxForm.value);
  };
  const formik = useFormik({
    initialValues: {
      value: initialValue ? initialValue : '',
    },
    onSubmit: onSubmit,
    enableReinitialize: true,
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <Typography variant="h6">{subtitle}</Typography>
        </Grid>
        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={8}>
            <TextField
              fullWidth
              label={textFieldTitle}
              name="value"
              rows={2}
              value={formik.values.value}
              variant="outlined"
              onChange={formik.handleChange}
              error={formik.touched.value && Boolean(formik.errors.value)}
              helperText={formik.touched.value && formik.errors.value}
              disabled
            />
          </Grid>
          {isVerified ? (
            <Grid item xs={4} container alignItems="center" spacing={2}>
              <CheckCircleRoundedIcon style={{ color: '#4CAF50' }} />
              <Typography variant="caption">Verified</Typography>
            </Grid>
          ) : (
            <Grid item xs={4} container alignItems="center" spacing={2}>
              <HourglassFullIcon style={{ color: '#FFB547' }} />
              <Typography variant="caption">Pending verification</Typography>
            </Grid>
          )}
        </Grid>
        <Grid item>
          <Button type="submit" variant="contained" color="primary" disabled={isVerified}>
            {btnText}
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: theme.spacing(1.5) }}
            onClick={(e) => {
              e.preventDefault();
              openLoginDialog();
            }}
          >
            {isEmail ? 'Change email' : 'Change number'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
interface IVerifyBox {
  value: string;
}
