import { FileType } from '@dayone/models';
import { LightBox as ImageAndVideoPreviewer } from 'shared/components/uploadImage';
import { LightBoxPdf as PDFPreviewer } from 'shared/components/uploadPdf';

const FilePreviewer = (props: { open: boolean; onClose: () => void; url: string; type: FileType }) => {
  const { type } = props;

  if (type === 'Image' || type === 'Video') {
    const _type: 'Image' | 'Video' = type;

    return <ImageAndVideoPreviewer {...props} type={_type} />;
  } else if (type === 'Pdf') {
    return <PDFPreviewer {...props} />;
  }

  return null;
};

export default FilePreviewer;
